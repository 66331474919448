/**
 * Version : 1.0
 * 파일명 : group.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 그룹 CSS
 * 수정일자 : 2022-03-29
 * 수정자 : 강연승
 * 수정내역 : 주소록 팝업 css 수정
*/
.groupInputValue {
  width: 296px;
  height: 40px;
  text-align: center;
  margin: 16px auto 0px;
  display: block;
  background-color: #f1f3fb;
  border: none;
  border-radius: 10px;
}
.groupInputValue::placeholder {
  color: #b8c3eb;
}
.groupInputValue:focus {
  outline: none;
}
.groupInputValue:active {
  outline: none;
}
.groupCreateBtnActive {
  width: 296px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  height: 48px;
  border: none;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: white;
  cursor: pointer;
}
.makeGroupAddressPopup {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 24px;
  margin: 8px 92px;
  overflow-y: auto;
}
.makeGroupAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.makeGroupAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.inviteMeetingHost {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 24px;
  margin: 8px 92px;
  overflow-y: auto;
}
.inviteMeetingHost .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.inviteMeetingHost .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.addressPopupItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: left;
  padding: 0 16px;
  display: flex;
  /* justify-content: space-between; */
}
.addressPopupItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.groupCreateBtn {
  width: 296px;
  background: #8b9ddf 0% 0% no-repeat padding-box;
  height: 48px;
  border: none;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: white;
}
.groupSelectCtgry {
  /* appearance: none; */
  border: 1px solid #2b4ac4;
  background-color: #ffffff;
  width: 298px;
  height: 42px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
}
.groupSelectThemaPopup {
  width: 296px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  margin: 0 90px;
  position: absolute;
}
.groupSelectThemaPopupItem {
  width: 296px;
  height: 30px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
}
.groupSelectCtgry::-ms-expand {
  display: none;
}
.groupListScroll::-webkit-scrollbar {
  width: 8px;
}
.groupListScroll::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.groupListScroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* .inviteGroupAgreePopup .MuiBackdrop-root {
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.inviteGroupAgreePopup .MuiPaper-elevation24 {
  box-shadow: 0px 5px 16px #00000029;
}
.inviteGroupAgreePopup .MuiDialog-paperWidthXs {
  min-width: 536px;
  border-radius: 24px;
} */

/* 그룹 배경색상 */
.groupBgColor_red {
  background: #de8799;
}
.groupBgColor_orange {
  background: #d78e71;
}
.groupBgColor_yellow {
  background: #cdb14c;
}
.groupBgColor_bean {
  background: #aed057;
}
.groupBgColor_green {
  background: #64d483;
}
.groupBgColor_mint {
  background: #6cd6bf;
}
.groupBgColor_blue {
  background: #8b9ddf;
}
.groupBgColor_pine {
  background: #75bdd9;
}
.groupBgColor_pink {
  background: #e08ec9;
}
.groupBgColor_pink {
  background: #cb86dd;
}
.groupBgColor_purple {
  background: #a994e1;
}
.groupBgColor_black {
  background: #3b3f4c;
}

/* 그룹설명 색상 */
.groupExplnColor_red {
  color: #f0c9d2;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_orange {
  color: #edccbf;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_yellow {
  color: #ece1b9;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_bean {
  color: #e8f2cf;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_green {
  color: #d9f4e0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_mint {
  color: #d6f4ed;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_blue {
  color: #cad2f0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pine {
  color: #bfe3f1;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pink {
  color: #f4d9ed;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pink {
  color: #e7c9f0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_purple {
  color: #d8cef1;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_black {
  color: #84889b;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}

/* 그룹멤버 색상 */
.groupMemberColor_red {
  color: #c63353;
}
.groupMemberColor_orange {
  color: #b24e27;
}
.groupMemberColor_yellow {
  color: #806a1c;
}
.groupMemberColor_bean {
  color: #aed057;
}
.groupMemberColor_green {
  color: #28b750;
}
.groupMemberColor_mint {
  color: #229a80;
}
.groupMemberColor_blue {
  color: #4865cc;
}
.groupMemberColor_pine {
  color: #1e6e8d;
}
.groupMemberColor_pink {
  color: #721959;
}
.groupMemberColor_pink {
  color: #bd66d4;
}
.groupMemberColor_purple {
  color: #7855d0;
}
.groupMemberColor_black {
  color: #3b3f4c;
}

/* 그룹리더 색상 */
.groupLeaderColor_red {
  color: #c63353;
}
/* 누락됨 */
.groupLeaderColor_orange {
  color: #b24e27;
}
.groupLeaderColor_yellow {
  color: #806a1c;
}
.groupLeaderColor_bean {
  color: #749420;
}
/* 누락됨 */
.groupLeaderColor_green {
  color: #28b750;
}
.groupLeaderColor_mint {
  color: #229a80;
}
.groupLeaderColor_blue {
  color: #4865cc;
}
.groupLeaderColor_pine {
  color: #2993bc;
}
.groupLeaderColor_pink {
  color: #c23099;
}
/* 누락됨 */
.groupLeaderColor_pink {
  color: #bd66d4;
}
/* 누락됨 */
.groupLeaderColor_purple {
  color: #7855d0;
}
.groupLeaderColor_black {
  color: #3b3f4c;
}

/* 그룹미팅 색상 */
/* 누락됨 */
.groupMeetingColor_red {
  color: #c63353;
}
.groupMeetingColor_orange {
  color: #692e17;
}
/* 누락됨 */
.groupMeetingColor_yellow {
  color: #806a1c;
}
/* 누락됨 */
.groupMeetingColor_bean {
  color: #35450f;
}
.groupMeetingColor_green {
  color: #1b7c37;
}
/* 누락됨 */
.groupMeetingColor_mint {
  color: #229a80;
}
/* 누락됨 */
.groupMeetingColor_blue {
  color: #4865cc;
}
.groupMeetingColor_pine {
  color: #2993bc;
}
/* 누락됨 */
.groupMeetingColor_pink {
  color: #721959;
}
.groupMeetingColor_pink {
  color: #661b7a;
}
.groupMeetingColor_purple {
  color: #46239f;
}
.groupMeetingColor_black {
  color: #000000;
}
/* 그룹테두리 색상 */
.groupBorderColor_red {
  border: 1px solid #ebb6c1;
  border-top: none;
}
.groupBorderColor_orange {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_yellow {
  border: 1px solid #d9c375;
  border-top: none;
}
.groupBorderColor_bean {
  border: 1px solid #d7e8ac;
  border-top: none;
}
.groupBorderColor_green {
  border: 1px solid #9be3af;
  border-top: none;
}
.groupBorderColor_mint {
  border: 1px solid #95e1d1;
  border-top: none;
}
.groupBorderColor_blue {
  border: 1px solid #b8c3eb;
  border-top: none;
}
.groupBorderColor_pine {
  border: 1px solid #adcfdd;
  border-top: none;
}
.groupBorderColor_pink {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_pink {
  border: 1px solid #f2adf1;
  border-top: none;
}
.groupBorderColor_purple {
  border: 1px solid #a994e1;
  border-top: none;
}
.groupBorderColor_black {
  border: 1px solid #b8c3eb;
  border-top: none;
}

/* 그룹테두리 색상 */
.groupBorderColor_red_bottom_none {
  border: 1px solid #ebb6c1;
  border-bottom: none;
}
.groupBorderColor_orange_bottom_none {
  border: 1px solid #e7baa9;
  border-bottom: none;
}
.groupBorderColor_yellow_bottom_none {
  border: 1px solid #d9c375;
  border-bottom: none;
}
.groupBorderColor_bean_bottom_none {
  border: 1px solid #d7e8ac;
  border-bottom: none;
}
.groupBorderColor_green_bottom_none {
  border: 1px solid #9be3af;
  border-bottom: none;
}
.groupBorderColor_mint_bottom_none {
  border: 1px solid #95e1d1;
  border-bottom: none;
}
.groupBorderColor_blue_bottom_none {
  border: 1px solid #b8c3eb;
  border-bottom: none;
}
.groupBorderColor_pine_bottom_none {
  border: 1px solid #adcfdd;
  border-bottom: none;
}
.groupBorderColor_pink_bottom_none {
  border: 1px solid #e7baa9;
  border-bottom: none;
}
.groupBorderColor_pink_bottom_none {
  border: 1px solid #f2adf1;
  border-bottom: none;
}
.groupBorderColor_purple_bottom_none {
  border: 1px solid #a994e1;
  border-bottom: none;
}
.groupBorderColor_black_bottom_none {
  border: 1px solid #b8c3eb;
  border-bottom: none;
}

/* 그룹테두리 색상 */
.groupBorderColor_red_top_none {
  border: 1px solid #ebb6c1;
  border-top: none;
}
.groupBorderColor_orange_top_none {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_yellow_top_none {
  border: 1px solid #d9c375;
  border-top: none;
}
.groupBorderColor_bean_top_none {
  border: 1px solid #d7e8ac;
  border-top: none;
}
.groupBorderColor_green_top_none {
  border: 1px solid #9be3af;
  border-top: none;
}
.groupBorderColor_mint_top_none {
  border: 1px solid #95e1d1;
  border-top: none;
}
.groupBorderColor_blue_top_none {
  border: 1px solid #b8c3eb;
  border-top: none;
}
.groupBorderColor_pine_top_none {
  border: 1px solid #adcfdd;
  border-top: none;
}
.groupBorderColor_pink_top_none {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_pink_top_none {
  border: 1px solid #f2adf1;
  border-top: none;
}
.groupBorderColor_purple_top_none {
  border: 1px solid #a994e1;
  border-top: none;
}
.groupBorderColor_black_top_none {
  border: 1px solid #b8c3eb;
  border-top: none;
}

/* 그룹멤버 색상 */
.groupPebble_title_red {
  color: #96394d;
}
.groupPebble_title_orange {
  color: #7d371b;
}
.groupPebble_title_yellow {
  color: #725314;
}
.groupPebble_title_bean {
  color: #465b14;
}
.groupPebble_title_green {
  color: #104920;
}
.groupPebble_title_mint {
  color: #10483b;
}
.groupPebble_title_pine {
  color: #134457;
}
.groupPebble_title_pink {
  color: #5c1d6d;
}
.groupPebble_title_purple {
  color: #391d82;
}
.groupPebble_title_black {
  color: #000000;
}
