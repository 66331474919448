/**
 * Version : 1.0
 * 파일명 : profilesupportdetail.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 작동페이지 css
 **/
.Supmenu0, .Supmenu1,.Supmenu2,.Supmenu3,.Supmenu4{
    display : flex;
}

.titleFAQ , .titleDQ, .titleSuggest, .titlemyDQ, .titleannouncement{
    width: 932px;
    height: 195px;

    border-radius: 32px;
    text-align: center;

}
.titleFAQ{
    background-color: #f4f5fb;
}
.titleDQ{
    background-color: #fbf6f4;
}

.titleSuggest{
    background-color: #f6f6fc;
}
.titlemyDQ{
    background-color: #f4fcfb;
}
.titleannouncement{
    background-color: #f6f8fd;
}

.SubtitleFAQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #2b4ac4;
}
.SubtitleDQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #ff734f;
}

.SubtitleSuggest{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #7875f6;
}

.SubtitlemyDQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #23dec4;
}

.Subtitleannouncement{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color:#00205b;
}

.Supmenu0,.Supmenu1,.Supmenu2,.Supmenu3,.Supmenu4{
    margin-left: 97px;
}

.Supfaq, .Supdirectquestion, .Supsuggestion, .Supmyquestion, .Supannouncement{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    border: solid 1px #9da1b2;

    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #9da1b2;
}

.Supmenu0 .Supfaq{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #2b4ac4;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu1 .Supdirectquestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #ff734f;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu2 .Supsuggestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color:#7875f6;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu3 .Supmyquestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #23dec4;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}
.Supmenu4 .Supannouncement{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #00205b;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}
.SupContentArea{
 padding-top: 30px;
height: 195px;
}

