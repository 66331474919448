/**
 * Version : 1.0
 * 파일명 : account.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 회원가입 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 첫 로그인 팝업 수정
*/
.LoginFailAlert,
.availableDay,
.unavailableDayAlert,
.changePasswordAlert,
.agreeListAlert {
  transition: all 0.5s ease-out;
  border-radius: 15px;
  z-index: 800;
  outline: none;
  border: none;
}
.firstLoginAlert {
  border-radius: 15px;
  z-index: 999;
  outline: none;
  border: none;
}
.agreeListAlert {
  margin: 10% auto;
  width: 392px;
  height: 543px;
  background: #ffffff;
  border: 1px solid #dbe0f5;
  border-radius: 12px;
}
.changePasswordAlert {
  margin: 18% auto;
  width: 520px;
  height: 336px;
  background: #ffffff;
}
.LoginFailAlert {
  margin: 5% auto;
  width: 640px;
  height: 748px;
  background: #fcf7f8;
}
.firstLoginAlert {
  margin: 7% auto;
  width: 560px;
  height: 708px;
  background: #ffffff;
}
.availableDay {
  margin: 10% auto;
  width: 528px;
  height: 564px;
  background: #ffffff;
}
.unavailableDayAlert {
  margin: 10% auto;
  width: 536px;
  height: 593px;
  background: #ffffff;
}
.makeAccountInputGroup {
  border: none;
  height: 100%;
}
.makeAccountInputTitle {
  margin-left: 15px;
  padding-top: 10px;
}

.makeAccountInput {
  text-align: center;
  width: 384px;
  height: 56px;
  border: none;
  outline: none;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  border-radius: 16px;
  background: #ffffff;
}
.makeAccountInput::placeholder {
  color: #9b9faf;
}

.makeAccountEmailInput {
  width: 150px !important;
}
.makeAccountSelectBox {
  width: 145px;
  height: 34px;
  border: none;
  border-bottom: 1px solid gray;
}

.makeAccountBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #00000029;
  color: #ffffff;
  font: normal normal bold 14px Spoqa Han Sans Neo;
  outline: none;
  border: none;
  cursor: pointer;
}
.makeAccountBtn:disabled {
  background: #3b3f4c;
  color: #afb3bf;
  cursor: default;
}

.signUpBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 12px;
  font: normal normal bold 14px/57px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.signUpBtn:disabled {
  background: #3b3f4c;
  color: #afb3bf;
  cursor: default;
}

.loginFail_closeBtn,
.freeInfo_closeBtn,
.firstLogin_closeBtn {
  text-align: right;
}
.loginFail_closeBtn img,
.freeInfo_closeBtn img,
.firstLogin_closeBtn img {
  margin: 32px 32px 0 0;
}

.loginFail_content,
.freeInfo_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.loginFail_title {
  font-size: 32px;
  font-weight: bold;
  color: #3e3e4f;
  margin: 0px;
}

.loginFail_errorIMG {
  margin: 48px 0px;
}

.loginFail_coment {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  text-align: center;
  margin: 0px;
}

.loginFail_makeAccountBtn {
  width: 320px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  margin-top: 48px;
}

.loginFail_balloon {
  position: relative;
  margin: 16px;
  border-radius: 10px;
  width: 240px;
  height: 128px;
  background: #ee6c4d;
  box-shadow: 0px 5px 16px #00000048;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.loginFail_balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ee6c4d;
  content: "";
  position: absolute;
  top: -10px;
  left: 110px;
}

.loginFail_balloon img {
  margin-top: 24px;
}
.loginFail_balloon p {
  margin-top: 9.7px;
}

.freeInfo_title {
  color: #3e3e4f;
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
}
.freeInfo_font_orange {
  color: #ee6c4d;
}
.freeInfo_mainIMG {
  margin: 40px 0px;
}
.freeInfo_coment_main_blue,
.freeInfo_coment_main_orange {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
}
.freeInfo_coment_main_blue {
  color: #2b4ac4;
}
.freeInfo_coment_main_orange {
  color: #af4f39;
}
.freeInfo_coment_sub {
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 24px;
  margin-bottom: 40px;
  text-align: center;
}
.freeInfo_btn_area {
  display: flex;
  justify-content: space-between;
  width: 368px;
  margin-bottom: 40px;
}
.freeInfo_btn_area button {
  width: 176px;
  height: 48px;
  background: #2b4ac4;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: none;
}

.firstLogin_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}

.firstLogin_title {
  color: #3e3e4f;
  font-size: 28px;
  font-weight: bold;
}

.firstLogin_mainIMG {
  margin: 40px 0px;
}

.firstLogin_coment_main {
  font-size: 18px;
  font-weight: bold;
  color: #2b4ac4;
}

.firstLogin_coment_sub,
.firstLogin_coment_sub_red {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.firstLogin_coment_sub {
  margin-top: 41px;
  color: #3e3e4f;
}
.firstLogin_coment_sub_red {
  margin-top: 47px;
  color: #c63353;
}

.firstLogin_btn_area button {
  width: 407px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 55px;
  border: none;
}

.changePW_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.deleteAccount_Alert {
  width: 400px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}

.changePwInput {
  width: 328px;
  height: 48px;
  background: #eaedf9;
  border-radius: 10px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 14px;
  color: #3e3e4f;
}
.changePwInput::placeholder {
  font-size: 14px;
  letter-spacing: 0px;
  color: #8b9ddf;
}

.changePwBtn {
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  border: none;
}

.searchPwInput {
  width: 384px;
  height: 56px;
  background: #eaedf9;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  outline: none;
  border: none;
}
.searchPwInput::placeholder {
  color: #8b9ddf;
}

.searchPwBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  border: none;
}
.searchPwBtn:disabled {
  background: #8b9ddf;
  color: #cad2f0;
}

.insertInfoInput {
  font: normal normal normal 16px/57px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  width: 384px;
  height: 56px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbe0f5;
  border-radius: 12px;
  outline: none;
  margin-top: 12px;
  margin-bottom: 16px;
}
.insertInfoInput::placeholder {
  color: #9b9faf;
}


.account-info-success-btn {
  width: 400px;
  height: 56px;
  background: rgb(139, 95, 191);
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 20px;
  border-radius: 12px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
}
.account-info-success-btn:disabled {
  background: #9b9faf !important;
}