/**
 * Version : 1.0
 * 파일명 : mobile_auth.css
 * 작성일자 : 2022-03-25
 * 작성자 : 권도훈
 * 설명 : 핸드폰 인증 CSS
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.py-auth-popup {
    width: 440px;
    height: 545px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px #00000029;
    transition: all 0.5s ease-out;
    border-radius: 16px;
    z-index: 100;
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.py-auth-btn-close {
    position: absolute;
    right: 16px;
    top: 8px;
}

.py-auth-btn {
    width: 304px;
    height: 40px;
    border-radius: 8px;
    background: #2B4AC4;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    margin-bottom: 24px;
    transition: all 0.1s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.py-auth-btn.loading,
.py-auth-btn:disabled {
    background: #8B9DDF;
    color: #DBE0F5;
}
.py-auth-btn:hover {
    background: #7489d4;
}

.py-auth-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2B4AC4;
    margin-top: 24px;
}
.py-auth-title.term {
    margin-bottom: 8px;
}
.py-auth-title.mobile {
    margin-bottom: 37px;
}

.py-auth-description {
    width: 234px;
    height: 39px;
    text-align: center;
    margin-bottom: 24px;
}
.py-auth-description > p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    color: #3E3E4F;
}

.py-auth-sub-title {
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
}

.py-auth-container {
    width: 304px;
    height: calc(100% - 119px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.py-auth-content-term,
.py-auth-content-phone,
.py-auth-content-success {
    width: 100%;
}

.py-auth-content-term {
    height: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.py-auth-term-item {
    width: 304px;
    height: 36px;
    background: #FFFFFF;
    outline: 1px solid #C1C3CD;
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #3E3E4F;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-out;
}
.py-auth-term-item > div {
    margin-left: 16px;
}
.py-auth-term-item:hover {
    background: #2B4AC4;
    color: #FFFFFF;
}

.py-auth-content-phone {
    height: 338px;
    background: #EAEDF9;
    border-radius: 12px;
}

.py-auth-content-phone-inner {
    margin: 24px;
    height: calc(100% - 48px);
}

.py-auth-phone-item {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}
.py-auth-phone-item:last-child {
    margin-bottom: 0;
}

.py-auth-phone-item-title {
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
}
.py-auth-phone-item-textArea {
    width: 100%;
    height: 32px;
}
.py-auth-phone-input {
    background: none;
    width: 100%;
    height: 32px;
    border: none;
    outline: none;
}

/* .py-auth-phone-input.birth, */
.py-auth-phone-input.gender {
    text-align: center;
}

.py-auth-phone-item-line {
    position: relative;
}

.py-auth-phone-item-textArea.birth,
.py-auth-phone-item-line.birth {
    width: 72px !important;
}

.py-auth-phone-item-textArea.gender,
.py-auth-phone-item-line.gender {
    width: 24px !important;
}


.py-auth-phone-birth {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.py-auth-phone-item-min {
    margin: 0 4px;
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
}

.py-auth-phone-btn-carrier-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.py-auth-phone-btn-carrier {
    height: 32px;
    background: #AFB3BF;
    border-radius: 8px;
    color: #FFFFFF;
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    margin-right: 8px;
    margin-top: 8px;
    transition: all 0.1s ease-out;
}

.py-auth-phone-btn-carrier.active {
    background: #3E3E4F;
}

.py-auth-phone-item-auth {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.py-auth-phone-item-num-area {
    width: 75%;
    height: 32px;
}

.py-auth-btn-num {
    width: 64px;
    height: 32px;
    background: #3E3E4F;
    border-radius: 8px;
    border: none;
    outline: none;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    /* transition: all 0.2s ease-out; */
    transition: 0.2s;
}
.py-auth-btn-num:hover {
    background: #707072;
}

.py-authphone-item-line-default {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    transition: 0.4s;
    background-color: #9b9b9c;
}

.py-authphone-item-line-default.none {
    display: none;
}

.py-auth-success-header {
    margin-top: 232px;
}

.py-auth-content-success {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #3E3E4F;
    text-align: center;
}










.payment_auth_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    font-weight: bold;
    color: #2B4AC4;
    margin: 0 15px;
    height: 70px;
}
.payment_auth_content {
    height: 400px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment_auth_item:hover {
    color: #2B4AC4;
}

.payment_auth_btn, .payment_auth_success_btn, .payment_auth_btn_loading {
    border: none;
    outline: none;
    height: 45px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 12px;
}
.payment_auth_btn, .payment_auth_success_btn {
    background: #2B4AC4;
    color: #FFFFFF;
}
.payment_auth_success_btn {
    width: 90%;
}
.payment_auth_btn_loading {
    background: #e2e2e2;
}

.payment_mobile_article {
    position: relative;
    margin-bottom: 10px;
}
.payment_mobile_article > p {
    margin: 0;
}
.payment_mobile_article > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}

.payment_mobile_non_focus {
    position: absolute;
    width: 0;
    left: 50%;
    height: 2px;
	transition: 0.4s;
    /* background-color: #e2e2e2; */
}
.payment_mobile_focus {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
	transition: 0.4s;
    background-color: #84889B;
}

.payment_mobile_rrn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.payment_mobile_birthDate {
    position: relative;
    width: 16%;
}
.payment_mobile_genderBirth {
    position: relative;
    width: 45%;
}
.payment_mobile_rrn > div > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}
.payment_mobile_rrn > p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    padding: 0px 12px;
}

.payment_mobile_genderBirth {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.payment_mobile_genderBirthCode {
    position: relative;
    width: 10%;
}
.payment_mobile_genderBirthCode > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}
.payment_mobile_genderBirthCode > div {
    width: 80%;
    margin: 0;
}

.payment_mobile_genderBirth_star {
    width: 80%;
    padding: 0 3px;
    height: 15px;
}

.payment_mobile_carrier_area, .payment_mobile_carrier_mvno_area {
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_mobile_carrier_mvno_area {
    margin-top: 20px;
}
.payment_mobile_carrier_btn, .payment_mobile_carrier_btn_active {
    border: none;
    outline: none;
    height: 35px;
    width: 60px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
    margin: 0 6px;
    cursor: pointer;
}
.payment_mobile_carrier_btn {
    background: #e2e2e2;
    color: #000000;
}
.payment_mobile_carrier_btn_active {
    background: #2B4AC4;
    color: #FFFFFF;
}

.payment_auth_success_content {
    height: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.payment_auth_success_content > article {
    height: 400px;
    display: flex;
    align-items: center;
}