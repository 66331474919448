/**
 * Version : 1.0
 * 파일명 : alert.css
 * 작성일자 : 2021-11-01
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 : 2022-05-02
 * 수정자 : 권도훈
 * 수정내역 : 팝업 수정
*/
.Alert {
  /* margin: 20% auto; */
  max-width: 339px;
  /* min-height: 117px; */
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 15px;
  z-index: 999;
  outline: none;
  font-family: Spoqa Han Sans Neo !important;
  letter-spacing: 0px;
}
.AlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 800;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.defaultAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 600;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.NewAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 80;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.6);
  z-index: 80;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_okBtn,
.modal_cancelBtn {
  padding: 10px 30px;
  color: white;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: bold;
  border-radius: 5px;
}
.modal_okBtn {
  background: blue;
}
.modal_okBtn:hover {
  background: #4169e1;
}
.modal_cancelBtn {
  background: gray;
}
.modal_cancelBtn:hover {
  background: #a9a9a9;
}

.alert-font {
  font-size: 16px;
  text-align: left;
}
.alert-font.title {
  color: #2b4ac4;
  font-weight: bold;
}
.alert-font.content {
  color: #3e3e4f;
}

@media screen and (min-width: 600px) {
  .updatePopup {
    width: 930px;
    /* width: 475px; 1개 */
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
  }

  .updatePopup-1 {
    height: 601px;
    width: 475px;
    border-radius: 10px;
  }
  .updatePopup-1-img-area {
    height: 560px;
  }
  .updatePopup-1-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-1-footer {
    width: 475px;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-1-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-1-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-1-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }

  .updatePopup-2 {
    height: 601px;
    width: 410px;
    border-radius: 10px;
  }
  .updatePopup-2-img-area {
    height: 560px;
    position: relative;
  }
  .updatePopup-2-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-2-img-area > button {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(94px, 495px);
    font-family: Spoqa Han Sans Neo;
    width: 206px;
    height: 55px;
    border-radius: 27px;
    background-color: #00ffb9;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    border: none;
    outline: none;
  }
  .updatePopup-2-footer {
    width: 410px;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-2-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-2-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-2-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }
}
@media screen and (max-width: 599px) {
  .updatePopup {
    width: 500px;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
    display: flex !important;
    height: 100%;
    align-items: center !important;
    justify-content: center !important;
  }

  .updatePopup-1 {
    height: 601px;
    width: 90%;
    border-radius: 10px;
    position: absolute;
    z-index: 2;
  }
  .updatePopup-1-img-area {
    height: 560px;
  }
  .updatePopup-1-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-1-footer {
    width: 100%;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-1-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-1-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-1-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }

  .updatePopup-2 {
    height: 601px;
    width: 80%;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
  }
  .updatePopup-2-img-area {
    height: 560px;
    position: relative;
  }
  .updatePopup-2-img-area > img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .updatePopup-2-img-area > button {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(115px, 495px);
    font-family: Spoqa Han Sans Neo;
    width: 206px;
    height: 55px;
    border-radius: 27px;
    background-color: #00ffb9;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    border: none;
    outline: none;
  }
  .updatePopup-2-footer {
    width: 100%;
    height: 41px;
    background-color: #282828;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-2-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-2-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-2-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }
}
