/**
 * Version : 1.0
 * 파일명 : support.css
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : 고객센터 Landing 페이지 CSS
*/
.supportpebblemark{
    margin: 33px 0 39px 360px;
    height: 48px;
    width: 164px ;
    
}

.supportheader{
    height: 120px;

}

.supportbody{
    margin-left: 277px;
    width: 1363;
    height: 890;
    display: flex;
    z-index: 1;
 
}
.imageintroduce{
    color: #ffffff;
}
.spttitle{
    height: 42px;
    margin: 115px 22px 350px 114px;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #f9f9f9;
}
.sptmid-1{
    margin: 0px 0px 0px 114px;
    display: flex;
}

.tel-textbox{
    width: 152px;
    height: 54px;
    margin: 0px 29px 17px 0px;
    border-radius: 27px;
    background-color: rgba(255, 255, 255, 0.24);
}
.tel-text{
    width: 75px;
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left: 38px;
}
.telnumber{
    height: 33px;
    margin: 0px 0px 28px 0px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.sptmid-2{
    margin-left: 297px;
    margin-bottom: 83px;
}
.---1000-1800-1200-1300 {
    width: 250px;
    height: 44px;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.32px;
    text-align: left;
    color: #fff;
  }
  .---1000-1800-1200-1300 .text-style-1 {
    letter-spacing: normal;
  }
  .---1000-1800-1200-1300 .text-style-2 {
    font-weight: bold;
  }
  .Shape-1 {
    width: 493px;
    height: 1px;
    margin: 0px 0px 51px 117px;
    opacity: 0.48;
    background-color: #fff;
  }
 
.link-three{
    display: flex;
    margin-left: 115px;
    
}
.iglink{
    display: none;
}
.ytlink, .nblink, .iglink{
    width: 79px;
    height: 79px;
    margin: 0px 18px 0 0px;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    text-align: center;    
}
.sociallink1{
    padding-top: 28px;
}

.sociallink{
    padding-top: 25px;
}
.texttitle{
    width: 500px;
    height: 19px;
    margin: 111px 80px 18px 106px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #555;
}
.shape-2{
    width: 99px;
    height: 3px;
    margin: 21px 149px 92px 106px;
    background-color: #2b4ac4;
}
.textcategory{
    margin: 0px 0px 0px 106px;
    height: 17px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #111;
    display: flex;
}
.textblue{
    height: 17px;
    margin: 0px 0px 0px 11px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #2b4ac4;
}


.textinput1{
width: 482px;
height: 62px;
margin: 15px 0 36px 106px;
padding: 0px 0 0 33px;
border-radius: 10px;
border: solid 1px #c0c0c0;
font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
color: #aaa;
}

.textinput2{
    width: 482px;
    height: 146px;
    margin: 15px 0 30px 106px;
    padding: 22px 0 0 33px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #aaa;
    font-family:"Spoqa Han Sans Neo", "sans-serif";
    resize: none;    
}
.checkboxbox{
    display: flex;
    margin: 0 0 0 106px;
}
.checkboxtext{
    padding-left: 13px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
}
.checkbox{
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin: 0 0 0 0;
}

.buttonbox{
    margin: 28px 0 30px 199px;
    display: flex;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    
    
}
.cancelbutton{
    width: 160px;
    height: 20px;
    padding: 15px 0 23px;
    border-radius: 10px;
    background-color: #dcdcdc;
}
.sendbutton{
    width: 160px;
    margin-left: 15px;
    height: 20px;
    padding: 15px 0 23px;
    border-radius: 10px;
    background-color: #2b4ac4;
    color: #fff;
}

.supportbody input::placeholder {
    font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
color: #aaa;
  }
  
.supportbody  textarea::placeholder{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #aaa;
    font-family : Spoqa Han Sans Neo;
  }