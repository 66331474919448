/**
 * Version : 1.0
 * 파일명 : header.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 헤더 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
*/
.Header_MsgBtn {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.75rem;
}
.Header_UserBtn {
  height: 60px;
}
/* .Header_UserNM {
  margin-top: 1rem !important;
  font-size: 16px;
} */
.Header_groupProfileBtnArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header_groupProfileBtn {
  height: 60px;
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
}
.Header_groupLicenseBtn {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: rgba(139, 157, 223, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.Header_groupMeetingHostBtn {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: rgba(139, 157, 223, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.Header_groupLicenseBtn_red {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f9eaed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #791a2f;
}
.Header_groupMeetingHostBtn_red {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f9eaed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #791a2f;
}
.Header_groupLicenseBtn_orange {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f8ebe6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7d371b;
}
.Header_groupMeetingHostBtn_orange {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f8ebe6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7d371b;
}
.Header_groupLicenseBtn_yellow {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #fff1da 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #725314;
}
.Header_groupMeetingHostBtn_yellow {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #fff1da 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #725314;
}
.Header_groupLicenseBtn_bean {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #eef5db 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #35450f;
}
.Header_groupMeetingHostBtn_bean {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #eef5db 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #35450f;
}
.Header_groupLicenseBtn_green {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #e5f8ea 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #104920;
}
.Header_groupMeetingHostBtn_green {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #e5f8ea 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #104920;
}
.Header_groupLicenseBtn_mint {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #d6f4ed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #10483b;
}
.Header_groupMeetingHostBtn_mint {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #d6f4ed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #10483b;
}
.Header_groupLicenseBtn_pine {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #e0f0f6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #134457;
}
.Header_groupMeetingHostBtn_pine {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #e0f0f6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #134457;
}
.Header_groupLicenseBtn_pink {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f6eaf9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2e0f36;
}
.Header_groupMeetingHostBtn_pink {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f6eaf9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2e0f36;
}
.Header_groupLicenseBtn_purple {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #efebfa 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #331974;
}
.Header_groupMeetingHostBtn_purple {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #efebfa 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #331974;
}
.Header_groupLicenseBtn_black {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #000000;
}
.Header_groupMeetingHostBtn_black {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #000000;
}
.licenseDday {
  clear: both;
  position: absolute;
  margin: 16px 0 0 105px;
  width: 36px;
  height: 17px;
  background: #d7263d 0% 0% no-repeat padding-box;
  border-radius: 9px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  color: #ffffff;
}
