/**
 * Version : 1.0
 * 파일명 : calendar.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 캘린더 CSS
 * 수정일자 : 2022-05-24
 * 수정자 : 강연승
 * 수정내역 : 주간 캘린더 헤더 UI 변경 및, 주간 캘린더 선택영역 색상 강조
*/

/* 
 * 풀캘린더
 */
.fc .fc-toolbar.fc-header-toolbar {
  /* margin: 18px 18px 24px 18px !important; */
  margin: 18px 0px 18px 0px !important;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.fc .fc-prev-button,
.fc .fc-next-button {
  width: 36px;
  height: 36px;
  border-radius: 10px !important;
  padding: 0 !important;
}
.fc .fc-icon-chevron-left,
.fc .fc-icon-chevron-right {
  font-size: 20px !important;
  vertical-align: text-bottom !important;
  line-height: 16px;
}
.fc .fc-today-button {
  width: 72px;
  height: 36px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo !important;
  border-radius: 8px !important;
  border: none !important;
}
.fc .fc-dayGridMonth-button {
  border-radius: 8px 0px 0px 8px !important;
}
.fc .fc-timeGridWeek-button {
  border-radius: 0px 8px 8px 0px !important;
  /* margin-right: 8px !important; */
}
.fc .fc-dayGridMonth-button,
.fc .fc-timeGridWeek-button {
  width: 76px;
  height: 36px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo !important;
}
/* .fc .fc-timegrid-now-indicator-arrow {
  border-color: #2b4ac4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.fc .fc-timegrid-now-indicator-line {
  border: 1px solid #2b4ac4 !important;
} */
/* 캘린더 주간 슬롯 높이 */
.fc .fc-timegrid-slot {
  height: 1.7em !important;
}
/* 캘린더 왼쪽 타임 라벨 테두리 */
.fc .fc-timegrid-slot-label {
  border: none !important;
}
/* 캘린더 오른쪽 테두리 */
/* .fc-theme-standard th:last-child {
  border-right: none !important;
} */
/* 캘린더 오른쪽 아래쪽 테두리 */
.fc-theme-standard td:last-child {
  border-right: none !important;
  border-bottom: none !important;
}
.fc .fc-theme-standard th {
  border-right: none !important;
}
/* 캘린더 위쪽 왼쪽 테두리 */
.fc .fc-scrollgrid {
  border: none !important;
}
.fc .fc-daygrid-day-number {
  font-size: 13px;
  margin: 6px 6px 0 0;
  width: 20px;
  height: 20px;
  padding: 1px 0 0 0 !important;
  text-align: center;
}
.fc .fc-timegrid-event {
  margin-right: 10px;
  padding: 6px 6px 6px 6px;
  border-radius: 10px;
  cursor: pointer;
}
.fc .fc-event-title {
  font-size: 12px;
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fc .fc-event-time {
  font-size: 12px !important;
  font-weight: bold;
}
.fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
  padding-bottom: 8px !important;
}
.fc .fc-dayGridMonth-view .fc-col-header-cell-cushion {
  padding-bottom: 16px !important;
}
.fc .fc-dayGridMonth-view .fc-col-header {
  margin-top: 16px;
}
.fc .fc-scrollgrid-section-header .fc-scroller {
  overflow-y: hidden !important;
}
.fc .fc-daygrid-event {
  border-radius: 4px !important;
  margin: 1.5px 4px !important;
  padding-right: 4px;
  cursor: pointer;
}
.fc .fc-scrollgrid th[role="presentation"] {
  border: none;
}
.fc-theme-standard th {
  border-right: none !important;
  border-left: none !important;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px;
}
.fc-scroller::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.fc-scroller::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* 색상별 분리 예정 */
/* 주간 캘린더 과거 배경 */
.fc .fc-timegrid-col.fc-day-past {
  background-color: rgba(132, 136, 155, 0.15);
}
/* 월간 캘린더 오늘 배경 */
.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}
/* 주간 캘린더 오늘 배경 */
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(43, 74, 196, 0.08) !important;
}
/* 드래그 이벤트 색상 */
.fc .fc-event-mirror {
  opacity: 0.8;
  /* background-color: var(
    --fc-event,
    rgba(43, 74, 196, 0.08)
  ) !important; */
}
/* 주간 캘린더 좌측 시간 색상 */
.fc .fc-timegrid-slot-label-cushion {
  font-size: 14px;
  font-weight: bold;
  color: #666b82;
  padding-right: 10px !important;
}
/* 월간 캘린더 더보기 텍스트 */
.fc .fc-daygrid-more-link {
  font-size: 12px;
  color: #84889b;
  font-weight: bold;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: 0px 3px 16px #00000029 !important;
}

.fc .fc-highlight {
  background: none !important;
}

/* 
 * 마이캘린더
 */
.myCalendar .fc .fc-prev-button,
.myCalendar .fc .fc-next-button {
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4 !important;
}
.myCalendar .fc .fc-prev-button:hover,
.myCalendar .fc .fc-next-button:hover {
  background: #6b7cc2 0% 0% no-repeat padding-box;
}
.myCalendar .fc .fc-prev-button:active,
.myCalendar .fc .fc-next-button:active {
  background: #6b7cc2 0% 0% no-repeat padding-box !important;
}
.myCalendar .fc .fc-icon-chevron-left,
.myCalendar .fc .fc-icon-chevron-right {
  color: #ffffff;
}

.myCalendar .fc .fc-today-button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #2b4ac4 !important;
}

.myCalendar .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #2b4ac4;
}

.myCalendar .fc .fc-dayGridMonth-button,
.myCalendar .fc .fc-timeGridWeek-button {
  color: #8b9ddf !important;
  background: #eaedf9 0% 0% no-repeat padding-box !important;
  border-color: #eaedf9 !important;
}
.myCalendar .fc .fc-dayGridMonth-button.fc-button-active,
.myCalendar .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background-color: #2b4ac4 !important;
  border-color: #2b4ac4 !important;
}
.myCalendar .fc .fc-timegrid-now-indicator-arrow {
  border-color: #2b4ac4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.myCalendar .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #2b4ac4 !important;
}
.myCalendar .fc .fc-day-today {
  color: #2b4ac4;
}
.myCalendar .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #2b4ac4;
  border-radius: 20px;
  color: #ffffff;
}
.myCalendar .fc .fc-col-header-cell .fc-col-header-cell-cushion .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.myCalendar .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.myCalendar .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.myCalendar .fc .fc-col-header-cell,
.myCalendar .fc .fc-timegrid-axis {
  border-bottom: 1px solid #2b4ac4;
}
.myCalendar .fc .fc-dayGridMonth-view .fc-col-header-cell {
  border-right: none !important;
  border-left: none !important;
}
/* 마이캘린더 월간 스케줄 배경*/
.myCalendar .fc .fc-daygrid-event {
  background-color: rgba(43, 74, 196, 0.1);
  color: #3b3f4c;
}

.myCalendar .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.myCalendar .fc .fc-timegrid-cols .fc-day-today.selectDate,
.myCalendar .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: rgba(43, 74, 196, 0.08) 0% 0% no-repeat padding-box !important;
}

/*
 * 그룹 캘린더 - 공통
 */
.groupCalendar .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #3b3f4c;
}
.groupCalendar .fc .fc-dayGridMonth-button,
.groupCalendar .fc .fc-timeGridWeek-button {
  color: #afb3bf !important;
  background: #dfe1e5 0% 0% no-repeat padding-box !important;
  border-color: #dfe1e5 !important;
}
.groupCalendar .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
  border-color: #3b3f4c !important;
}

/* 
 * 그룹캘린더 - 레드
 */
.groupCalendar_red .fc .fc-prev-button,
.groupCalendar_red .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cc4965 !important;
}
.groupCalendar_red .fc .fc-prev-button:hover,
.groupCalendar_red .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_red .fc .fc-prev-button:active,
.groupCalendar_red .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.groupCalendar_red .fc .fc-icon-chevron-left,
.groupCalendar_red .fc .fc-icon-chevron-right {
  color: #cc4965;
}

.groupCalendar_red .fc .fc-today-button {
  background: #cc4965 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_red .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #e74e72;
} */

/* .groupCalendar_red .fc .fc-dayGridMonth-button,
.groupCalendar_red .fc .fc-timeGridWeek-button {
  color: #fa5252 !important;
  background: #ffc9c9 0% 0% no-repeat padding-box !important;
  border-color: #ffc9c9 !important;
}
.groupCalendar_red .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_red .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #e74e72 0% 0% no-repeat padding-box !important;
  border-color: #e74e72 !important;
} */
.groupCalendar_red .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_red .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_red .fc .fc-day-today {
  color: #c63353;
}

.groupCalendar_red .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c63353;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_red
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_red .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c63353 0% 0% no-repeat padding-box;
}
.groupCalendar_red .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_red .fc .fc-col-header-cell,
.groupCalendar_red .fc .fc-timegrid-axis {
  border-bottom: 1px solid #c63353;
}

.fc .fc-daygrid-event.groupEvent_red {
  background-color: #f9eaed;
  color: #c63353;
}

.groupCalendar_red .fc .fc-daygrid-event {
  background-color: #f9eaed;
  color: #c63353;
}

.groupCalendar_red .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_red .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_red .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f9eaed 0% 0% no-repeat padding-box !important;
}

.groupCalendar_red .fc .fc-timegrid-event.fc-event-past {
  background-color: #e5a0af !important;
  border: 1px solid #e5a0af !important;
  color: #f9f9fa !important;
}

/* 
 * 그룹캘린더 - 오렌지
 */
.groupCalendar_orange .fc .fc-prev-button,
.groupCalendar_orange .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c44308 !important;
}
.groupCalendar_orange .fc .fc-prev-button:hover,
.groupCalendar_orange .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_orange .fc .fc-prev-button:active,
.groupCalendar_orange .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_orange .fc .fc-icon-chevron-left,
.groupCalendar_orange .fc .fc-icon-chevron-right {
  color: #c44308;
}

.groupCalendar_orange .fc .fc-today-button {
  background: #c44308 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_orange .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #d78e71;
} */

/* .groupCalendar_orange .fc .fc-dayGridMonth-button,
.groupCalendar_orange .fc .fc-timeGridWeek-button {
  color: #ff922b !important;
  background: #ffd2c1 0% 0% no-repeat padding-box !important;
  border-color: #ffd2c1 !important;
}
.groupCalendar_orange .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_orange .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #f1855b 0% 0% no-repeat padding-box !important;
  border-color: #f1855b !important;
} */
.groupCalendar_orange .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_orange .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_orange .fc .fc-day-today {
  color: #c44308;
}

.groupCalendar_orange .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c44308;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_orange
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_orange .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c44308 0% 0% no-repeat padding-box;
}
.groupCalendar_orange
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_orange .fc .fc-col-header-cell,
.groupCalendar_orange .fc .fc-timegrid-axis {
  border-bottom: 1px solid #c44308;
}

.fc .fc-daygrid-event.groupEvent_orange {
  background-color: #fdebe0;
  color: #9a370d;
}

.groupCalendar_orange .fc .fc-daygrid-event {
  background-color: #fdebe0;
  color: #9a370d;
}

.groupCalendar_orange .fc .fc-timegrid-event.fc-event-past {
  background-color: #e0a68f !important;
  border: 1px solid #e0a68f !important;
  color: #f9f9fa !important;
}

.groupCalendar_orange .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_orange .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_orange .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f8ebe6 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 옐로우
 */
.groupCalendar_yellow .fc .fc-prev-button,
.groupCalendar_yellow .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9a6f14 !important;
}
.groupCalendar_yellow .fc .fc-prev-button:hover,
.groupCalendar_yellow .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_yellow .fc .fc-prev-button:active,
.groupCalendar_yellow .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_yellow .fc .fc-icon-chevron-left,
.groupCalendar_yellow .fc .fc-icon-chevron-right {
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-today-button {
  background: #9a6f14 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_yellow .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #806a1c;
} */

/* .groupCalendar_yellow .fc .fc-dayGridMonth-button,
.groupCalendar_yellow .fc .fc-timeGridWeek-button {
  color: #806a1c !important;
  background: #eedc9d 0% 0% no-repeat padding-box !important;
  border-color: #eedc9d !important;
}
.groupCalendar_yellow .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_yellow .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #bda13c 0% 0% no-repeat padding-box !important;
  border-color: #bda13c !important;
} */
.groupCalendar_yellow .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_yellow .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_yellow .fc .fc-day-today {
  color: #c38d0f;
}

.groupCalendar_yellow .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c38d0f;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_yellow
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_yellow .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c38d0f 0% 0% no-repeat padding-box;
}
.groupCalendar_yellow
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_yellow .fc .fc-col-header-cell,
.groupCalendar_yellow .fc .fc-timegrid-axis {
  border-bottom: 1px solid #efac00;
}

.fc .fc-daygrid-event.groupEvent_yellow {
  background-color: #fff1da;
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-daygrid-event {
  background-color: #fff1da;
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-timegrid-event.fc-event-past {
  background-color: #d9c375 !important;
  border: 1px solid #d9c375 !important;
  color: #f9f9fa !important;
}

.groupCalendar_yellow .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_yellow .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_yellow .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #fff1da 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 빈
 */
.groupCalendar_bean .fc .fc-prev-button,
.groupCalendar_bean .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #749420 !important;
}
.groupCalendar_bean .fc .fc-prev-button:hover,
.groupCalendar_bean .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_bean .fc .fc-prev-button:active,
.groupCalendar_bean .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_bean .fc .fc-icon-chevron-left,
.groupCalendar_bean .fc .fc-icon-chevron-right {
  color: #749420;
}

.groupCalendar_bean .fc .fc-today-button {
  background: #749420 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_bean .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_bean .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_bean .fc .fc-day-today {
  color: #749420;
}

.groupCalendar_bean .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #87ad26;
  border-radius: 20px;
  color: #ffffff;
}

.groupCalendar_bean
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_bean .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #749420 0% 0% no-repeat padding-box;
}
.groupCalendar_bean .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_bean .fc .fc-col-header-cell,
.groupCalendar_bean .fc .fc-timegrid-axis {
  border-bottom: 1px solid #749420;
}

.fc .fc-daygrid-event.groupEvent_bean {
  background-color: #eef5db;
  color: #5b751a;
}

.groupCalendar_bean .fc .fc-daygrid-event {
  background-color: #eef5db;
  color: #5b751a;
}
.groupCalendar_bean .fc .fc-timegrid-event.fc-event-past {
  background-color: #b8d66b !important;
  border: 1px solid #b8d66b !important;
  color: #f8fbf0 !important;
}

.groupCalendar_bean .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_bean .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_bean .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e8f2cf 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 그린
 */
.groupCalendar_green .fc .fc-prev-button,
.groupCalendar_green .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1b7c37 !important;
}
.groupCalendar_green .fc .fc-prev-button:hover,
.groupCalendar_green .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_green .fc .fc-prev-button:active,
.groupCalendar_green .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_green .fc .fc-icon-chevron-left,
.groupCalendar_green .fc .fc-icon-chevron-right {
  color: #1b7c37;
}

.groupCalendar_green .fc .fc-today-button {
  background: #1b7c37 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_green .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_green .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_green .fc .fc-day-today {
  color: #239d45;
}

.groupCalendar_green .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #1b7c37;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_green
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_green .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #239d45 0% 0% no-repeat padding-box;
}
.groupCalendar_green .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_green .fc .fc-col-header-cell,
.groupCalendar_green .fc .fc-timegrid-axis {
  border-bottom: 1px solid #239d45;
}

.fc .fc-daygrid-event.groupEvent_green {
  background-color: #d9f4e0;
  color: #104920;
}

.groupCalendar_green .fc .fc-daygrid-event {
  background-color: #d9f4e0;
  color: #104920;
}
.groupCalendar_green .fc .fc-timegrid-event.fc-event-past {
  background-color: #9be3af !important;
  border: 1px solid #9be3af !important;
  color: #f3fcf5 !important;
}

.groupCalendar_green .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_green .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_green .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e2f7e8 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 민트
 */
.groupCalendar_mint .fc .fc-prev-button,
.groupCalendar_mint .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #229a80 !important;
}
.groupCalendar_mint .fc .fc-prev-button:hover,
.groupCalendar_mint .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_mint .fc .fc-prev-button:active,
.groupCalendar_mint .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_mint .fc .fc-icon-chevron-left,
.groupCalendar_mint .fc .fc-icon-chevron-right {
  color: #229a80;
}

.groupCalendar_mint .fc .fc-today-button {
  background: #229a80 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_mint .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_mint .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_mint .fc .fc-day-today {
  color: #1b7a65;
}

.groupCalendar_mint .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #229a80;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_mint
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_mint .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #1b7a65 0% 0% no-repeat padding-box;
}
.groupCalendar_mint .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_mint .fc .fc-col-header-cell,
.groupCalendar_mint .fc .fc-timegrid-axis {
  border-bottom: 1px solid #229a80;
}

.fc .fc-daygrid-event.groupEvent_mint {
  background-color: #d6f4ed;
  color: #10483b;
}

.groupCalendar_mint .fc .fc-daygrid-event {
  background-color: #d6f4ed;
  color: #10483b;
}
.groupCalendar_mint .fc .fc-timegrid-event.fc-event-past {
  background-color: #95e1d1 !important;
  border: 1px solid #95e1d1 !important;
  color: #f2fbf9 !important;
}

.groupCalendar_mint .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_mint .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_mint .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #d6f4ed 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 블루
 */
.groupCalendar_blue .fc .fc-prev-button,
.groupCalendar_blue .fc .fc-next-button {
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.groupCalendar_blue .fc .fc-prev-button:hover,
.groupCalendar_blue .fc .fc-next-button:hover {
  background: #596ec2 0% 0% no-repeat padding-box;
}
.groupCalendar_blue .fc .fc-prev-button:active,
.groupCalendar_blue .fc .fc-next-button:active {
  background: #596ec2 0% 0% no-repeat padding-box !important;
}
.groupCalendar_blue .fc .fc-icon-chevron-left,
.groupCalendar_blue .fc .fc-icon-chevron-right {
  color: #ffffff;
}

.groupCalendar_blue .fc .fc-today-button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #2b4ac4 !important;
}

.groupCalendar_blue .fc .fc-timegrid-now-indicator-arrow {
  border-color: #5876e4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_blue .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #5876e4 !important;
}
.groupCalendar_blue .fc .fc-day-today {
  color: #5876e4;
}

.groupCalendar_blue .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #5876e4;
  border-radius: 20px;
  color: #ffffff;
}

.groupCalendar_blue .fc .fc-col-header-cell,
.groupCalendar_blue .fc .fc-timegrid-axis {
  border-bottom: 1px solid #5876e4;
}

.fc .fc-daygrid-event.groupEvent_blue {
  background-color: rgba(43, 74, 196, 0.1);
  color: #5876e4;
}

.groupCalendar_blue .fc .fc-daygrid-event {
  background-color: rgba(43, 74, 196, 0.1);
  color: #5876e4;
}
/* 
 * 그룹캘린더 - 파인
 */
.groupCalendar_pine .fc .fc-prev-button,
.groupCalendar_pine .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #257495 !important;
}
.groupCalendar_pine .fc .fc-prev-button:hover,
.groupCalendar_pine .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_pine .fc .fc-prev-button:active,
.groupCalendar_pine .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_pine .fc .fc-icon-chevron-left,
.groupCalendar_pine .fc .fc-icon-chevron-right {
  color: #257495;
}

.groupCalendar_pine .fc .fc-today-button {
  background: #257495 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_pine .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_pine .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_pine .fc .fc-day-today {
  color: #2b88ae;
}

.groupCalendar_pine .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #2688ae;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_pine
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_pine .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #2b88ae 0% 0% no-repeat padding-box;
}
.groupCalendar_pine .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_pine .fc .fc-col-header-cell,
.groupCalendar_pine .fc .fc-timegrid-axis {
  border-bottom: 1px solid #2993bc;
}

.fc .fc-daygrid-event.groupEvent_pine {
  background-color: #e0f0f6;
  color: #1c5a73;
}

.groupCalendar_pine .fc .fc-daygrid-event {
  background-color: #e0f0f6;
  color: #1c5a73;
}
.groupCalendar_pine .fc .fc-timegrid-event.fc-event-past {
  background-color: #84c4dd !important;
  border: 1px solid #84c4dd !important;
  color: #f5fafc !important;
}

.groupCalendar_pine .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_pine .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_pine .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e0f0f6 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 핑크
 */
.groupCalendar_pink .fc .fc-prev-button,
.groupCalendar_pink .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7a2791 !important;
}
.groupCalendar_pink .fc .fc-prev-button:hover,
.groupCalendar_pink .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_pink .fc .fc-prev-button:active,
.groupCalendar_pink .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_pink .fc .fc-icon-chevron-left,
.groupCalendar_pink .fc .fc-icon-chevron-right {
  color: #7a2791;
}

.groupCalendar_pink .fc .fc-today-button {
  background: #7a2791 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_pink .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_pink .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_pink .fc .fc-day-today {
  color: #9931b5;
}

.groupCalendar_pink .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #a836c7;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_pink
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_pink .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #9931b5 0% 0% no-repeat padding-box;
}
.groupCalendar_pink .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_pink .fc .fc-col-header-cell,
.groupCalendar_pink .fc .fc-timegrid-axis {
  border-bottom: 1px solid #a836c7;
}

.fc .fc-daygrid-event.groupEvent_pink {
  background-color: #f5e7f8;
  color: #5c1d6d;
}

.groupCalendar_pink .fc .fc-daygrid-event {
  background-color: #f5e7f8;
  color: #5c1d6d;
}

.groupCalendar_pink .fc .fc-timegrid-event.fc-event-past {
  background-color: #d6a0e5 !important;
  border: 1px solid #d6a0e5 !important;
  color: #fcf8fd !important;
}

.groupCalendar_pink .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_pink .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_pink .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f6eaf9 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 퍼플
 */
.groupCalendar_purple .fc .fc-prev-button,
.groupCalendar_purple .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6f4cc7 !important;
}
.groupCalendar_purple .fc .fc-prev-button:hover,
.groupCalendar_purple .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_purple .fc .fc-prev-button:active,
.groupCalendar_purple .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_purple .fc .fc-icon-chevron-left,
.groupCalendar_purple .fc .fc-icon-chevron-right {
  color: #6f4cc7;
}

.groupCalendar_purple .fc .fc-today-button {
  background: #6f4cc7 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_purple .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_purple .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_purple .fc .fc-day-today {
  color: #5835b1;
}

.groupCalendar_purple .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #613eba;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_purple
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_purple .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #5835b1 0% 0% no-repeat padding-box;
}
.groupCalendar_purple
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_purple .fc .fc-col-header-cell,
.groupCalendar_purple .fc .fc-timegrid-axis {
  border-bottom: 1px solid #7855d0;
}

.fc .fc-daygrid-event.groupEvent_purple {
  background-color: #efebfa;
  color: #391d82;
}

.groupCalendar_purple .fc .fc-daygrid-event {
  background-color: #efebfa;
  color: #391d82;
}
.groupCalendar_purple .fc .fc-timegrid-event.fc-event-past {
  background-color: #bbaae7 !important;
  border: 1px solid #bbaae7 !important;
  color: #faf9fd !important;
}

.groupCalendar_purple .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_purple .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_purple .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #efebfa 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 블랙
 */

.groupCalendar_black .fc .fc-prev-button,
.groupCalendar_black .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3b3f4c !important;
}
.groupCalendar_black .fc .fc-prev-button:hover,
.groupCalendar_black .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_black .fc .fc-prev-button:active,
.groupCalendar_black .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_black .fc .fc-icon-chevron-left,
.groupCalendar_black .fc .fc-icon-chevron-right {
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-today-button {
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_black .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_black .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_black .fc .fc-day-today {
  color: #000000;
}

.groupCalendar_black .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_black
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_black .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #000000 0% 0% no-repeat padding-box;
}
.groupCalendar_black .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_black .fc .fc-col-header-cell,
.groupCalendar_black .fc .fc-timegrid-axis {
  border-bottom: 1px solid #3b3f4c;
}

.fc .fc-daygrid-event.groupEvent_black {
  background-color: #e7e8eb;
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-daygrid-event {
  background-color: #e7e8eb;
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-timegrid-event.fc-event-past {
  background-color: #9b9faf !important;
  border: 1px solid #9b9faf !important;
  color: #f9f9fa !important;
}

.groupCalendar_black .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_black .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_black .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f4f4f5 0% 0% no-repeat padding-box !important;
}
