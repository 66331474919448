/**
 * Version : 1.0
 * 파일명 : address.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 주소록 CSS
 * 수정일자 : 2022-05-03
 * 수정자 : 권도훈
 * 수정내역 : 엑셀업로드 ui 수정
*/
.address_content_Div {
  position: relative;
  left: 40px;
  width: 1404px;
}
.address_header {
  font-size: 28px;
  font-weight: bold;
  color: blue;
  display: inline;
  top: 24px;
  position: relative;
}
.address_viewtype_toggle_btn {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  left: 16px;
  position: relative;
}
.address_viewtype_toggle_btn img {
  width: 16px;
  height: 16px;
}
.address_content {
  margin: 10px 20px;
}
/* .address_div {
  min-height: 48rem;
} */
.address_content_header {
  top: 32px;
  position: relative;
}
.address_content_header div {
  font-size: 16px;
}
.address_rowData_div {
  top: 44px;
  position: relative;
}
.add_address_div {
  background: white;
  position: absolute;
  width: 99rem;
  height: 3.5rem;
  z-index: 200;
}
.address_category_div {
  position: absolute;
  background: white;
  border: 1px solid;
  width: 26.1rem;
  border: none;
  height: 12rem;
  display: none;
}
.address_category_input {
  background: rosybrown;
  color: white;
  border: 1px solid;
  width: 25.8rem;
  height: 2rem;
}
.address_category_input::placeholder {
  color: white;
}
.addressContentDiv {
  position: relative;
  top: 52px;
  height: 836px;
  padding-left: 45px;
  padding-right: 37px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}
.address_ListPopupBackground {
  background: rgba(255, 255, 255, 0.6);
  z-index: 200;
  position: absolute;
  width: 1330px;
  height: 700px;
  margin-top: 10px;
}
.address_ListPopup {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 12px;
  width: 1330px;
  height: 180px;
  z-index: 210;
  /* box-shadow: grey 0px 0px 10px 0px;
  border-radius: 5px; */
}
.address_popupAvartar {
  width: 40px;
  height: 40px;
}
.address_CategorySlider {
  /* width: 20rem; */
  min-width: 29rem;
  min-height: 26px;
  overflow: hidden;
  /* border: 1px solid; */
  /* height: 2rem;
  margin-top: 0.4rem; */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  white-space: nowrap;
  float: left;
}
.address_categotyEditor {
  z-index: 99;
  position: absolute;
  width: 538px;
  height: 344px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 12px;
}
.address_categoryEditor_input {
  width: 522px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #afb3bf;
  margin: 8px 4px 0px 4px;
}
.address_categoryEditor_input:focus {
  outline: none;
}
.address_categoryEditor_item {
  /* width: 538px; */
  width: 500px;
  margin: 12px 0px;
}
.address_categoryContent {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.add_category_btn {
  background-attachment: rosybrown;
  display: none;
  text-align: center;
  color: white;
  font-weight: bold;
}

.cardImg {
  max-height: 12rem;
  min-height: 12rem;
}
.UserImg {
  width: 65%;
  height: 100%;
  margin: 2rem auto 0 auto;
}
.address_searchBtn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #2b4ac4;
  cursor: pointer;
  margin-left: 8px;
  font-weight: bold;
}
.address_searchBtn:active {
  outline: none;
}
.address_searchBtn:disabled {
  background: #afb3bf;
  border: #afb3bf;
}

.groupAddress_searchBtn {
  width: 64px;
  height: 36px;
  background: #666b82;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #666b82;
  cursor: pointer;
  margin-left: 8px;
  font-weight: bold;
}
.groupAddress_searchBtn:active {
  outline: none;
}
.groupAddress_searchBtn:disabled {
  background: #84889b;
  border: #84889b;
}

.addressSearchText {
  width: 280px;
  height: 36px;
  background: #ffffffbe 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}
.addressSearchText:active,
.addressSearchText:focus {
  outline: none;
}
.address_listTable {
  width: 1330px;
  /* height: 700px; */
}
.address_pagingBtn {
  right: 37px;
  position: absolute;
  bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}

.scrollItem {
  border-radius: 6px;
  border: 1px solid;
  width: auto;
  display: inline-block;
  margin: 2px;
  font-size: 12px;
  padding: 2px 10px;
  background: skyblue;
  color: white;
  height: 18px;
  cursor: pointer;
}
.listThead tr {
  width: 1330px;
  height: 56px;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #2b4ac4;
}
.listTbody tr {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  width: 1330px;
  /* height: 52px; */
}
/* .listTbody tr td {
  line-height: 52px;
} */
.listTbody tr:hover {
  background-color: rgba(75, 137, 220, 0.4);
  cursor: pointer;
}

/* .address_popup_innerDiv{
  margin-left: 40px; 
  margin-top: 21px;
  margin-right: 32px;
  margin-bottom: 34px;
} */
.address_checkbox {
  height: 16px;
  width: 16px;
}

.address_header_icon {
  left: 9px;
  top: 3px;
  position: relative;
}

.address_popup_confirmBtn {
  width: 64px;
  height: 36px;
  background: none;
  border: none;
  cursor: pointer;
}
.address_popup_confirmBtn:active {
  outline: none;
}

.address_addBtn {
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #2b4ac4;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_red {
  border: 1px solid #a02741;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #a02741;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_orange {
  border: 1px solid #c44308;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #c44308;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_yellow {
  border: 1px solid #c68a00;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #c68a00;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_bean {
  border: 1px solid #749420;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #749420;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_green {
  border: 1px solid #1b7c37;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #1b7c37;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_mint {
  border: 1px solid #229a80;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #229a80;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_pine {
  border: 1px solid #2993bc;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #2993bc;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_pink {
  border: 1px solid #a836c7;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #a836c7;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_purple {
  border: 1px solid #7855d0;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #7855d0;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_black {
  border: 1px solid #666b82;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #666b82;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn:disabled {
  border: 1px solid #8b9ddf;
  color: #8b9ddf;
}

.adr-excel-download-btn,
.adr-excel-select-btn,
.adr-excel-upload-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.adr-excel-download-btn,
.adr-excel-select-btn {
  width: 152px;
  height: 36px;
}
.adr-excel-download-btn {
  background: #ffffff;
  color: #2b4ac4;
  border: 1px solid #2b4ac4;
  margin-right: 8px;
}
.adr-excel-download-btn:hover {
  background: #2b4ac4;
  color: #ffffff;
}
.adr-excel-select-btn {
  background: #2b4ac4;
  color: #ffffff;
  border: 1px solid #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adr-excel-select-btn:hover,
.adr-excel-upload-btn:hover {
  background: #1f378e;
}
.adr-excel-upload-btn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  border: 1px solid #2b4ac4;
  color: #ffffff;
}

.adr-excel-fileinfo {
  width: 320px;
  height: 36px;
  background: #eaedf9;
  border-radius: 10px;
  margin-right: 8px;
}
.adr-excel-fileinfo-inner {
  margin: 0 0 0 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adr-excel-fileinfo-inner > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adr-excel-fileinfo-inner > div > div {
  margin-left: 8px;
  width: 247px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}

.address_uploadBtn {
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  margin: 5px;
  text-align: center;
}
.address_uploadBtn:active {
  background: #5b70bd;
}

.address_downloadBtn {
  background: #2b4ac4;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  float: right;
  margin-left: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address_downloadBtn:active {
  outline: none;
}
.address_downloadBtn:disabled {
  background: #afb3bf;
  border: #afb3bf;
}

.groupAddress_downloadBtn {
  background: #3b3f4c;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  float: right;
  margin-left: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupAddress_downloadBtn:active {
  outline: none;
}
.groupAddress_downloadBtn:disabled {
  background: #84889b;
  border: #84889b;
}

.adr-excel-upload {
  position: absolute;
  z-index: 400;
  max-width: 424px;
  height: 120px;
  background: white;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 10px;
  right: 0px;
  top: 54px;
}

.adr-excel-upload-close {
  position: absolute;
  right: 8px;
  top: 4px;
}

.adr-excel-upload-inner {
  padding: 16px;
}
.adr-excel-upload-section {
  margin-bottom: 8px;
}
.adr-excel-upload-section > p {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.adr-excel-upload-section > p:first-child {
  color: #2b4ac4;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px !important;
}
.adr-excel-upload-section > p:last-child {
  color: #666b82;
  font-size: 14px;
}

.adr-excel-upload-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_category_item_cover:hover {
  background: #f8f9fd;
}

.address_color_option:hover {
  background-color: #eaedf9;
}

.address_color_default {
  background-color: "#2B4AC4";
}
.address_color_pink {
  background-color: "#DC82C3";
}
.address_color_red {
  background-color: "#D4667E";
}
.address_color_orange {
  background-color: "#CD704B";
}
.address_color_yellow {
  background-color: "#BD9D29";
}
.address_color_lightgreen {
  background-color: "#97C12A";
}
.address_color_green {
  background-color: "#28B750";
}
.address_color_mint {
  background-color: "#27B395";
}
.address_color_blue {
  background-color: "#6E85D7";
}
.address_color_purple {
  background-color: "#9479DA";
}
