/**
 * Version : 1.0
 * 파일명 : profiledirectquestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 1:1문의 css
* 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 1:1문의 버튼 크기조정
 **/
.directquestion_headline{
    text-align: center;
    height: 41px;
    margin-top: 18px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    margin-bottom: 44px;
}
.directquestion_header{
    height: 20px;
    line-height: 20px;
}
.directquestion_header1{
    height: 4px;
}
.directquestion_category_head, .directquestion_name_head,
.directquestion_name_head, .directquestion_phonenumber_head,
.directquestion_email_head, .directquestion_title_head,
.directquestion_content_head, .directquestion_file_head{
    margin-left: 123px;
    display: flex;
}

.directquestion_category, .directquestion_name,
.directquestion_phonenumber, .directquestion_email,
.directquestion_title, .directquestion_content, .directquestion_file{
    display: flex;
    justify-content: space-between;
}

.dq_input_name,
.dq_input_phonenumber, .dq_input_email, .dq_input_title
{
    margin-right: 122px;
    width: 486px;
    height: 14px;
    padding : 23px 33px 25px 33px;
    margin-bottom: 18px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
} 
.directquestion_category_body {
    margin-right: 122px;
    width: 554px;
    height: 64px;
    border-radius: 10px;
    margin-bottom: 18px;
    padding-right: 17px;
    /* padding-bottom: 25px; */

    font-size: 17px;
    padding-left: 27px;
    border: solid 1px #c0c0c0;
    /* padding : 23px 33px 25px 33px;
  
  
    border: solid 1px #c0c0c0;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left; */
}
.dq_fontblack{
    height: 62px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: normal;
    text-align: left;
    color: #111; 
}
.dq_fontorange{
    height: 62px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: normal;
    text-align: left;
    color: #ff734f;
}
.dq_input_content{
    margin-right: 122px;
    width: 486px;
    height: 205px;
    padding : 23px 33px 25px 33px;
    margin-bottom: 18px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    resize: none;
    color: #111;
    font-family: Spoqa Han Sans Neo sans-serif;

}
.dq_input_phonenumber ::placeholder,
.dq_input_name ::placeholder,
.dq_placeholder ::placeholder
{
 color: #aaa;
 font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;

}



.directquestion_content textarea::placeholder{
    color: #afb3bf;
    font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;
}
.dq_underline{
    width: 687px;
    height: 1px;
    margin: 43px 122px 35px 123px;
    background-color: #c0c0c0;
}
.directquestion_footer{
    height: 16px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #555;
}
.directquestion_button{
    width: 160px;
    height: 58px;
    margin: 43px 386px 0px 386px;
    border-radius: 10px;
    background-color: #ff734f;
    text-align: center;
    line-height: 58px;
    font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}


.dq_input_file{
    margin-right: 122px;
    width: 552px;
    height: 62px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    display: flex;
    justify-content: space-between;
}
.dq_input_fileinfo{
    font-family: SpoqaHanSansNeo;
    width: 460px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.dq_inputfilebtn{
    line-height: 62px;
    margin-right: 17px;
}
.dq_inputFileplaceholder{
    margin-left: 33px;
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
    line-height: 62px;
}
.dq_fileListscrollbar{
    overflow-y: scroll;
    height: 52px;
    padding-top: 5px;
    padding-bottom: 5px;

}
.dq_img_test{
    display: flex;
    justify-content: space-between;
}

.directquestion_button_body{
    height: 90px;
}