/**
 * Version : 1.0
 * 파일명 : menu.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 메뉴 CSS
 * 수정일자 :  2022-02-25
 * 수정자 : 권도훈
 * 수정내역 : 메인 UI 수정
*/
.Menu_Div {
  width: 196px;
  height: 1050px;
  border-right: 1px solid rgba(43, 74, 196, 0.2);
  /* float: left; */
  position: relative;
}
.Menu_Logo {
  color: blue;
  font-size: 30px;
  font-weight: bold;
  padding: 25px 0 25px 0;
  text-align: center;
}
/* .Menu_BtnGroup {
  height: 45rem;
  height: 42rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
} */
.Menu_ModeBtn {
  width: 9.5rem;
  height: 4.5rem;
}
.Menu_Btn {
  margin-right: 32px;
  margin-left: 32px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 8px;
  color: #2b4ac4;
  /* border: 1px solid rgba(0, 0, 0, 0); */
  /* display: block;
    width: 8rem;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0); */
}
.Menu_Btn:hover {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: white !important;
  /* outline: none !important;
    border: 1px solid white !important;
    border-radius: 25px 10px !important;
    background-color: white !important; */
}
.Menu_ActiveBtn {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
  /* outline: none !important;
    border: 1px solid white !important;
    border-radius: 25px 10px !important;
    background-color: white !important; */
}

.groupList_Btn {
  margin-right: 32px;
  margin-left: 32px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 16px;
  color: #ffffff;
  border: none !important;
  border-radius: 40px 20px !important;
  background: #8b9ddf 0% 0% no-repeat padding-box !important;
}

.groupMenu_Btn {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 8px;
  color: #4865cc;
  border: none !important;
  border-radius: 40px 20px !important;
}

/*그룹 메뉴 버튼 색상 */

/* 
 * 그룹메뉴 버튼 - 레드
 */
.groupList_Btn_red {
  color: #ffffff !important;
  background: #99243e 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_red {
  color: #913448;
}
.groupMenu_ActiveBtn_red {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_red:hover {
  color: #913448;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 오렌지
 */
.groupList_Btn_orange {
  color: #ffffff !important;
  background: #b24e27 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_orange {
  color: #b24e27;
}
.groupMenu_ActiveBtn_orange {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_orange:hover {
  color: #b24e27;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 옐로우
 */
.groupList_Btn_yellow {
  color: #ffffff !important;
  background: #9a6f14 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_yellow {
  color: #7d5b14;
}
.groupMenu_ActiveBtn_yellow {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_yellow:hover {
  color: #7d5b14;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 빈
 */
.groupList_Btn_bean {
  color: #ffffff !important;
  background: #749420 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_bean {
  color: #5b751a;
}
.groupMenu_ActiveBtn_bean {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_bean:hover {
  color: #5b751a;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 그린
 */
.groupList_Btn_green {
  color: #ffffff !important;
  background: #239d45 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_green {
  color: #135726;
}
.groupMenu_ActiveBtn_green {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_green:hover {
  color: #135726;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 민트
 */
.groupList_Btn_mint {
  color: #ffffff !important;
  background: #1b7a65 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_mint {
  color: #1b7a65;
}
.groupMenu_ActiveBtn_mint {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_mint:hover {
  color: #1b7a65;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

/* 
 * 그룹메뉴 버튼 - 블루
 */
.groupList_Btn_blue {
  color: #ffffff !important;
  background: #8b9ddf 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_blue {
  color: #4865cc;
}
.groupMenu_ActiveBtn_blue {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.groupMenu_Btn_blue:hover {
  color: #4865cc;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 파인
 */
.groupList_Btn_pine {
  color: #ffffff !important;
  background: #257495 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_pine {
  color: #1e5e79;
}
.groupMenu_ActiveBtn_pine {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_pine:hover {
  color: #1e5e79;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 핑크
 */
.groupList_Btn_pink {
  color: #ffffff !important;
  background: #5c1d6d 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_pink {
  color: #5c1d6d;
}
.groupMenu_ActiveBtn_pink {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_pink:hover {
  color: #5c1d6d;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 퍼플
 */
.groupList_Btn_purple {
  color: #ffffff !important;
  background: #5431ac 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_purple {
  color: #46239f;
}
.groupMenu_ActiveBtn_purple {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_purple:hover {
  color: #46239f;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 블랙
 */
.groupList_Btn_black {
  color: #ffffff !important;
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_black {
  color: #3b3f4c;
}
.groupMenu_ActiveBtn_black {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_black:hover {
  color: #3b3f4c;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
