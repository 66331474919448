/**
 * Version : 1.0
 * 파일명 : content.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 컨텐츠 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.ContentHeader_btn {
  padding: 0.75rem 1.25rem;
  margin-right: 1rem;
}
.ContentHeader_btn:hover {
  background-color: #a9a9a9;
}
.ContentHeader_btn:disabled {
  background-color: blue;
}