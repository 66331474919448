@import url(https://fonts.googleapis.com/css?family=Muli);
.alarm_Div {
    width: 360px;
    min-height: 69px;
    max-height: 608px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 12px;
    /* right: 231px; */
    position: absolute;
    /* float: right; */
    z-index: 920;
    overflow-y: auto;
    inset: 0px auto auto 0px;
    overflow-x: hidden;
}

.alarm_Item {
    font-size: 16px;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid #AEC7DE;
    width: 312px;
}

/**
 * Version : 1.0
 * 파일명 : alert.css
 * 작성일자 : 2021-11-01
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 : 2022-05-02
 * 수정자 : 권도훈
 * 수정내역 : 팝업 수정
*/
.Alert {
  /* margin: 20% auto; */
  max-width: 339px;
  /* min-height: 117px; */
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 15px;
  z-index: 999;
  outline: none;
  font-family: Spoqa Han Sans Neo !important;
  letter-spacing: 0px;
}
.AlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 800;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.defaultAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 600;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.NewAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.3);
  z-index: 80;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateAlertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 62, 79, 0.6);
  z-index: 80;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_okBtn,
.modal_cancelBtn {
  padding: 10px 30px;
  color: white;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: bold;
  border-radius: 5px;
}
.modal_okBtn {
  background: blue;
}
.modal_okBtn:hover {
  background: #4169e1;
}
.modal_cancelBtn {
  background: gray;
}
.modal_cancelBtn:hover {
  background: #a9a9a9;
}

.alert-font {
  font-size: 16px;
  text-align: left;
}
.alert-font.title {
  color: #2b4ac4;
  font-weight: bold;
}
.alert-font.content {
  color: #3e3e4f;
}

@media screen and (min-width: 600px) {
  .updatePopup {
    width: 930px;
    /* width: 475px; 1개 */
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
  }

  .updatePopup-1 {
    height: 601px;
    width: 475px;
    border-radius: 10px;
  }
  .updatePopup-1-img-area {
    height: 560px;
  }
  .updatePopup-1-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-1-footer {
    width: 475px;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-1-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-1-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-1-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }

  .updatePopup-2 {
    height: 601px;
    width: 410px;
    border-radius: 10px;
  }
  .updatePopup-2-img-area {
    height: 560px;
    position: relative;
  }
  .updatePopup-2-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-2-img-area > button {
    position: absolute;
    inset: 0px auto auto 0px;
    -webkit-transform: translate(94px, 495px);
            transform: translate(94px, 495px);
    font-family: Spoqa Han Sans Neo;
    width: 206px;
    height: 55px;
    border-radius: 27px;
    background-color: #00ffb9;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    border: none;
    outline: none;
  }
  .updatePopup-2-footer {
    width: 410px;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-2-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-2-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-2-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }
}
@media screen and (max-width: 599px) {
  .updatePopup {
    width: 500px;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
    display: flex !important;
    height: 100%;
    align-items: center !important;
    justify-content: center !important;
  }

  .updatePopup-1 {
    height: 601px;
    width: 90%;
    border-radius: 10px;
    position: absolute;
    z-index: 2;
  }
  .updatePopup-1-img-area {
    height: 560px;
  }
  .updatePopup-1-img-area > img {
    width: 100%;
    height: 100%;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .updatePopup-1-footer {
    width: 100%;
    height: 41px;
    background-color: #282828;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-1-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-1-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-1-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }

  .updatePopup-2 {
    height: 601px;
    width: 80%;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
  }
  .updatePopup-2-img-area {
    height: 560px;
    position: relative;
  }
  .updatePopup-2-img-area > img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .updatePopup-2-img-area > button {
    position: absolute;
    inset: 0px auto auto 0px;
    -webkit-transform: translate(115px, 495px);
            transform: translate(115px, 495px);
    font-family: Spoqa Han Sans Neo;
    width: 206px;
    height: 55px;
    border-radius: 27px;
    background-color: #00ffb9;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    border: none;
    outline: none;
  }
  .updatePopup-2-footer {
    width: 100%;
    height: 41px;
    background-color: #282828;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .updatePopup-2-footer > div {
    margin-left: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.58);
    display: flex;
    align-items: center;
  }
  .updatePopup-2-footer > div > input {
    margin-right: 11px;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #fff;
    /* background: none; */
    background-color: #eee;
  }
  .updatePopup-2-footer > button {
    margin-right: 13px;
    font-family: Spoqa Han Sans Neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }
}

/**
 * Version : 1.0
 * 파일명 : common.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 공통 CSS
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 기본 로딩 추가
*/
/*
 *   ---------------------------------
 *   Pebble CSS 공통양식
 *   찾으려는 CSS 옵션 #을 붙여서 검색
 *   ---------------------------------
 *   None-Drag-Option
 *   #user-selcet
 */
.cursor {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.drag-none {
  -webkit-user-select: none;
  user-select: none;
}
/*
*   #btn
*/
.blue-btn {
  background: blue;
  font-weight: bold;
  color: white;
  border: none;
}
/*
*   #btn:hover
*/
.blue-btn:hover {
  background: #4169e1;
}
/*
*   #background-color
*/
.bg-transparent {
  background-color: transparent;
  background-image: none;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-beige {
  background-color: rgba(245, 245, 220, 0.4);
}
.bg-blue {
  background-color: blue;
}
.bg-gray {
  background-color: gray;
}
.bg-purple {
  background-color: purple;
}
.bg-gradient {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fed6e3; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #a8edea 0%,
    #fed6e3 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/*
*   #float
*/
.float-right {
  float: right;
}
.float-left {
  float: left;
}
/*
*   #position
*/
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
/*
*   #display
*/
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.inherit {
  display: inherit;
}
/*
*   #width
*/
.w-0 {
  width: 0px;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-11 {
  width: 2.75rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-30 {
  width: 7.5rem;
}
.w-32 {
  width: 8rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-80 {
  width: 20rem;
}
.w-90 {
  width: 22.5rem;
}
.w-100 {
  width: 25rem;
}
.w-160 {
  width: 40rem;
}
.w-200 {
  width: 50rem;
}
.w-220 {
  width: 55rem;
}
.w-240 {
  width: 60rem;
}
.w-254 {
  width: 63.5rem;
}
.w-270 {
  width: 67.5rem;
}
.w-274 {
  width: 68.5rem;
}
.w-280 {
  width: 70rem;
}
.w-313 {
  width: 78.3rem;
}
.w-320 {
  width: 80rem;
}
.w-420 {
  width: 105rem;
}
.w-auto {
  width: auto;
}
.w-half {
  width: 50%;
}
.w-full {
  width: 100%;
}
.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/*
*   #height
*/
.h-0 {
  height: 0px;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-23 {
  height: 5.9375rem;
}
.h-24 {
  height: 6rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-56 {
  height: 14rem;
}
.h-80 {
  height: 20rem;
}
.h-100 {
  height: 25rem;
}
.h-120 {
  height: 30rem;
}
.h-160 {
  height: 40rem;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
/*
*   #padding
*/
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-12 {
  padding: 3rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}
.p-28 {
  padding: 7rem;
}
.p-auto {
  padding: auto;
}
/*
*   #padding-top
*/
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-28 {
  padding-top: 7rem;
}
.pt-auto {
  padding-top: auto;
}
/*
*   #padding-bottom
*/
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-28 {
  padding-bottom: 7rem;
}
.pb-auto {
  padding-bottom: auto;
}
/*
*   #padding-left
*/
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-14 {
  padding-left: 3.5rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pl-28 {
  padding-left: 7rem;
}
.pl-auto {
  padding-left: auto;
}
/*
*   #padding-right
*/
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-14 {
  padding-right: 3.5rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pr-28 {
  padding-right: 7rem;
}
.pr-auto {
  padding-right: auto;
}
/*
*   #padding-left, #padding-right
*/
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.px-auto {
  padding-left: auto;
  padding-right: auto;
}
/*
*   #padding-top, #padding-bottom
*/
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
/*
*   #margin
*/
.m-0 {
  margin: 0px;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-28 {
  margin: 7rem;
}
.m-auto {
  margin: auto;
}
.m-center {
  margin: 0 auto;
}
/*
*   #margin-top
*/
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mt-46 {
  margin-top: 14rem;
}
.mt-50 {
  margin-top: 20rem;
}
.mt-64 {
  margin-top: 16rem;
}
.mt-160 {
  margin-top: 20rem;
}
.mt-auto {
  margin-top: auto;
}
/*
*   #margin-bottom
*/
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-14 {
  margin-bottom: 3.5rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-28 {
  margin-bottom: 7rem;
}
.mb-auto {
  margin-bottom: auto;
}
/*
*   #margin-left
*/
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-12 {
  margin-left: 3rem;
}
.ml-14 {
  margin-left: 3.5rem;
}
.ml-16 {
  margin-left: 4rem;
}
.ml-20 {
  margin-left: 5rem;
}
.ml-28 {
  margin-left: 7rem;
}
.ml-auto {
  margin-left: auto;
}
/*
*   #margin-right
*/
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mr-14 {
  margin-right: 3.5rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mr-28 {
  margin-right: 7rem;
}
.mr-auto {
  margin-right: auto;
}
/*
*   #margin-left, #margin-right
*/
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/*
*   #margin-top, #margin-bottom
*/
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
/*
*   #z-index
*/
.zi-0 {
  z-index: 0;
}
.zi-1 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}
.zi-3 {
  z-index: 3;
}
.zi-4 {
  z-index: 4;
}
.zi-5 {
  z-index: 5;
}
.zi-99 {
  z-index: 99;
}
.zi-999 {
  z-index: 999;
}
/*
*   #opacity
*/
.opacity-0 {
  opacity: 0;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
/*
*   #overflow
*/
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
/*
*   #border
*/
.border-black {
  border: 1px solid black;
}
.border-white {
  border: 1px solid white;
}
.border-blue {
  border: 1px solid blue;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
.border-navy {
  border: 1px solid rgba(43, 74, 196, 0.2);
}
.border-none {
  border: none;
}
/*
*   #border-top
*/
.border-t-black {
  border-top: 1px solid black;
}
.border-t-white {
  border-top: 1px solid white;
}
.border-t-blue {
  border-top: 1px solid blue;
}
.border-t-gray {
  border-top: 1px solid gray;
}
.border-t-navy {
  border-top: 1px solid rgba(43, 74, 196, 0.2);
}
.border-t-none {
  border-top: none;
}
/*
*   #border-bottom
*/
.border-b-black {
  border-bottom: 1px solid black;
}
.border-b-white {
  border-bottom: 1px solid white;
}
.border-b-blue {
  border-bottom: 1px solid blue;
}
.border-b-gray {
  border-bottom: 1px solid gray;
}
.border-b-navy {
  border-bottom: 1px solid rgba(43, 74, 196, 0.2);
}
.border-b-none {
  border-bottom: none;
}
/*
*   #border-right
*/
.border-r-black {
  border-right: 1px solid black;
}
.border-r-white {
  border-right: 1px solid white;
}
.border-r-blue {
  border-right: 1px solid blue;
}
.border-r-gray {
  border-right: 1px solid gray;
}
.border-r-navy {
  border-right: 1px solid rgba(43, 74, 196, 0.2);
}
.border-r-none {
  border-right: none;
}
/*
*   #border-left
*/
.border-l-black {
  border-left: 1px solid black;
}
.border-l-white {
  border-left: 1px solid white;
}
.border-l-blue {
  border-left: 1px solid blue;
}
.border-l-navy {
  border-left: 1px solid rgba(43, 74, 196, 0.2);
}
.border-l-none {
  border-left: none;
}
/*
*   #border-right, #border-left
*/
.border-x-black {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
.border-x-white {
  border-right: 1px solid white;
  border-left: 1px solid white;
}
.border-x-blue {
  border-right: 1px solid blue;
  border-left: 1px solid blue;
}
.border-x-none {
  border-right: none;
  border-left: none;
}
/*
*   #border-top, #border-bottom
*/
.border-y-black {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.border-y-white {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.border-y-blue {
  border-top: 1px solid blue;
  border-bottom: 1px solid blue;
}
.border-y-none {
  border-top: none;
  border-bottom: none;
}

/*
*   #border-radius
*/
.border-radius-0 {
  border-radius: 0px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-15 {
  border-radius: 15px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-round {
  border-radius: 9999px;
}
/*
*   #hover
*/
.border-hover-blue:hover {
  border: 1px solid blue;
}
/*
*   #font-weight
*/
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-extrabold {
  font-weight: 800;
}
.font-bold {
  font-weight: bold;
}
/*
*   #font-size
*/
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-24 {
  font-size: 24px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-38 {
  font-size: 38px;
}
/*
*   #color
*/
.font-white {
  color: white;
}
.font-blue {
  color: blue;
}
.font-red {
  color: red;
}
.font-black {
  color: black;
}
/*
*   #top
*/
.top-0 {
  top: 0px;
}
.top-1 {
  top: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.top-3 {
  top: 0.75rem;
}
.top-4 {
  top: 1rem;
}
.top-6 {
  top: 1.5rem;
}
.top-10 {
  top: 2.5rem;
}
.top-12 {
  top: 3rem;
}
.top-14 {
  top: 3.5rem;
}
.top-16 {
  top: 4rem;
}
.top-20 {
  top: 5rem;
}
.top-28 {
  top: 7rem;
}
.top-auto {
  top: auto;
}
.-top-1 {
  top: -0.25rem;
}
.-top-2 {
  top: -0.5rem;
}
.-top-3 {
  top: -0.75rem;
}
.-top-4 {
  top: -1rem;
}
.-top-6 {
  top: -1.5rem;
}
.-top-10 {
  top: -2.5rem;
}
.-top-12 {
  top: -3rem;
}
.-top-14 {
  top: -3.5rem;
}
.-top-16 {
  top: -4rem;
}
.-top-20 {
  top: -5rem;
}
.-top-28 {
  top: -7rem;
}
/*
*   #bottom
*/
.bottom-0 {
  bottom: 0px;
}
.bottom-1 {
  bottom: 0.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.bottom-3 {
  bottom: 0.75rem;
}
.bottom-4 {
  bottom: 1rem;
}
.bottom-6 {
  bottom: 1.5rem;
}
.bottom-10 {
  bottom: 2.5rem;
}
.bottom-12 {
  bottom: 3rem;
}
.bottom-14 {
  bottom: 3.5rem;
}
.bottom-16 {
  bottom: 4rem;
}
.bottom-20 {
  bottom: 5rem;
}
.bottom-28 {
  bottom: 7rem;
}
.bottom-auto {
  bottom: auto;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.-bottom-3 {
  bottom: -0.75rem;
}
.-bottom-4 {
  bottom: -1rem;
}
.-bottom-6 {
  bottom: -1.5rem;
}
.-bottom-10 {
  bottom: -2.5rem;
}
.-bottom-12 {
  bottom: -3rem;
}
.-bottom-14 {
  bottom: -3.5rem;
}
.-bottom-16 {
  bottom: -4rem;
}
.-bottom-20 {
  bottom: -5rem;
}
.-bottom-28 {
  bottom: -7rem;
}
/*
*   #right
*/
.right-0 {
  right: 0px;
}
.right-1 {
  right: 0.25rem;
}
.right-2 {
  right: 0.5rem;
}
.right-3 {
  right: 0.75rem;
}
.right-4 {
  right: 1rem;
}
.right-6 {
  right: 1.5rem;
}
.right-10 {
  right: 2.5rem;
}
.right-12 {
  right: 3rem;
}
.right-14 {
  right: 3.5rem;
}
.right-16 {
  right: 4rem;
}
.right-20 {
  right: 5rem;
}
.right-28 {
  right: 7rem;
}
.right-auto {
  right: auto;
}
.-right-1 {
  right: -0.25rem;
}
.-right-2 {
  right: -0.5rem;
}
.-right-3 {
  right: -0.75rem;
}
.-right-4 {
  right: -1rem;
}
.-right-6 {
  right: -1.5rem;
}
.-right-10 {
  right: -2.5rem;
}
.-right-12 {
  right: -3rem;
}
.-right-14 {
  right: -3.5rem;
}
.-right-16 {
  right: -4rem;
}
.-right-20 {
  right: -5rem;
}
.-right-28 {
  right: -7rem;
}
/*
*   #left
*/
.left-0 {
  left: 0px;
}
.left-1 {
  left: 0.25rem;
}
.left-2 {
  left: 0.5rem;
}
.left-3 {
  left: 0.75rem;
}
.left-4 {
  left: 1rem;
}
.left-6 {
  left: 1.5rem;
}
.left-10 {
  left: 2.5rem;
}
.left-12 {
  left: 3rem;
}
.left-14 {
  left: 3.5rem;
}
.left-16 {
  left: 4rem;
}
.left-20 {
  left: 5rem;
}
.left-28 {
  left: 7rem;
}
.left-auto {
  left: auto;
}
.-left-1 {
  left: -0.25rem;
}
.-left-2 {
  left: -0.5rem;
}
.-left-3 {
  left: -0.75rem;
}
.-left-4 {
  left: -1rem;
}
.-left-6 {
  left: -1.5rem;
}
.-left-10 {
  left: -2.5rem;
}
.-left-12 {
  left: -3rem;
}
.-left-14 {
  left: -3.5rem;
}
.-left-16 {
  left: -4rem;
}
.-left-20 {
  left: -5rem;
}
.-left-28 {
  left: -7rem;
}
/*
*   #top, #bottom
*/
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
/*
*   #right, #left
*/
.inset-x-0 {
  right: 0px;
  left: 0px;
}
/*
*   #top, #bottom, #right, #left
*/
.inset-0 {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
/*
*   #text-decoration
*/
.text-decoration-none {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline;
}
/*
*   #text-align
*/
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
/*
*   #vertical-align
*/
.align-top {
  vertical-align: top;
}
.align-bottom {
  vertical-align: bottom;
}
/*
*   #box-sizing
*/
.box-sizing-border {
  box-sizing: border-box;
}
/*
*  #align-items
*/
.align-items-center {
  align-items: center;
}
/*
*   #justify-content
*/
.jc-center {
  justify-content: center;
}
.jc-end {
  justify-content: flex-end;
}
/*
*   #flex-direction
*/
.flex-direction-column {
  flex-direction: column;
}
/*
*   #resize
*/
.resize-none {
  resize: none;
}
/*
*   #table border none
*/
.table-border-separate-10 {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
/*
*   #max-hieght
*/
.max-height-50 {
  max-height: 50rem;
}
/**
*   Tag
*/
body {
  font-size: 18px;
  margin: 0;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  /* font-family: "Malgun Gothic"; */
  /* font-family: "Roboto"; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* background: #FCF7F8; */
  /* overflow: hidden; */
}
button {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
input {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
code {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  /* font-family: "Malgun Gothic", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace; */
}

.content_Div {
  width: 1483px;
  position: relative;
  right: 0px;
}

.makeAccoutnModal {
  outline: none;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.flex-y-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-datepicker-popper {
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 600;
  width: 288px;
  height: 324px;
}
.react-datepicker__day-names,
.react-datepicker__week,
.react-datepicker__day-name {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #666b82;
}
.react-datepicker__day--outside-month {
  color: #afb3bf !important;
}

.react-datepicker__day,
.react-datepicker__header button {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-datepicker__header button {
  background: none;
  border: none;
}
.react-datepicker__day {
  width: 28px;
  height: 28px;
  line-height: 1.8;
  text-align: center;
  color: black;
  margin: 0 4px;
}

.react-datepicker__week {
  margin: 5px 0;
  color: #3e3e4f !important;
}

.react-datepicker__day:hover {
  color: #2b4ac4;
}

.react-datepicker__day-name {
  width: 24px;
  height: 24px;
  margin: 16px 6px 10px 6px;
}

.react-datepicker__day--selected {
  color: #2b4ac4;
  outline: none;
}
.react-datepicker__day--disabled {
  color: grey;
}

.react-datepicker-custom-selectbox {
  position: absolute;
  width: 82px;
  max-height: 196px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 15px #00000029;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
  overflow-y: auto;
  inset: 0px auto auto 0px;
}

.react-datepicker-custom-selectbox div {
  height: 36px;
  display: flex;
  align-items: center;
}
.react-datepicker-custom-selectbox div:hover {
  background-color: #dbe0f5;
}

.common_ok_btn {
  color: #ffffff;
  background: #2b4ac4;
}

.common_ok_btn:disabled {
  background-color: #a7a7a7;
  color: #6b6969;
}

.common_ok_textBtn {
  color: #2b4ac4;
}

.common_ok_textBtn:disabled {
  color: #707070;
}

.common-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
/**
 * Version : 1.0
 * 파일명 : data.css
 * 작성일자 : 2021-12-29
 * 작성자 : 권도훈
 * 설명 : 데이터 및 통계 CSS
 * 수정일자 : 2022-04-15
 * 수정자 : 강연승
 * 수정내역 : CSS 수정
*/
.data_popup_history,
.data_popup_historyDetail {
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.data_popup_history {
  /* margin: 5% auto; */
  /* width: 768px; */
  height: 768px;
}
.data_popup_historyDetail {
  /* margin: 19% auto; */
  width: 752px;
  height: 212px;
}

.data_content_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}
.data_content_paging div {
  margin-right: 40px;
}
.data_content_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.data_searchType_selectBoxItem {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.data_searchType_selectBoxItem:hover {
  color: #ffffff;
  background: #2b4ac4;
}

.groupData_searchType_selectBoxItem {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.groupData_searchType_selectBoxItem:hover {
  color: #ffffff;
  background: #666b82;
}

.data-statistics-header {
  height: 84px;
  width: 1324px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}

.data-statistics-header.detail {
  height: 85px;
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-header-title {
  color: #3b3f4c;
  font-weight: bold;
  font-size: 20px;
  margin-right: 24px;
  width: 37px;
}

.data-statistics-header-article,
.data-statistics-header-ul,
.data-statistics-header-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-header-options {
  width: 1036px;
  height: 56px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
}

.data-statistics-header-datepicker-area,
.data-statistics-header-search-area,
.data-statistics-sidebar-date-area,
.data-statistics-sidebar-time-area,
.data-statistics-sidebar-host-area {
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-host-area {
  justify-content: space-between;
  width: 100%;
}

.data-statistics-sidebar-host-area-input {
  width: 292px;
  height: 34px;
  border: none;
  outline: 1px solid #dfe1e5;
  border-radius: 8px;
}
.data-statistics-sidebar-host-area-input::-webkit-input-placeholder {
  color: #9b9faf;
}
.data-statistics-sidebar-host-area-input::placeholder {
  color: #9b9faf;
}

.data-statistics-sidebar-host-area-btn {
  width: 60px;
  height: 36px;
  background: #3e3e4f;
  border-radius: 8px;
  border: none;
  outline: 1px solid #dfe1e5;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.data-statistics-header-datepicker-area > font,
.data-statistics-header-search-area > font {
  color: #3b3f4c;
  font-size: 14px;
  font-weight: bold;
}
.data-statistics-header-datepicker-area > font {
  margin-right: 8px;
}
.data-statistics-header-datepicker-area > img {
  margin-left: 16px;
  margin-right: 8px;
}
.data-statistics-header-search-area > font {
  margin-left: 8px;
  margin-right: 17px;
}

.data-statistics-datepicker-range {
  color: #3e3e4f;
  font-size: 14px;
  margin: 0 8px;
}
.data-statistics-datepicker-card {
  width: 178px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #d1d3da;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-datepicker-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 162px;
  height: 20px;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-search-card {
  width: 256px;
  height: 36px;
  background: #ffffffbe;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-search-card > input {
  width: 240px;
  height: 100%;
  background: none;
  outline: none;
  border: none;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-search-card > input::-webkit-input-placeholder {
  color: #9b9faf;
}
.data-statistics-search-card > input::placeholder {
  color: #9b9faf;
}
.data-statistics-searchBtn-card {
  width: 64px;
  height: 36px;
  margin-left: 8px;
  margin-right: 16px;
  background: #666b82;
  box-sizing: border-box;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  border: none;
}
.data-statistics-searchBtn-card:hover {
  background: #243fa5;
}
.data-statistics-searchBtn-card:disabled {
  background: #9b9faf;
  color: #3e3e4f;
}

.data-statistics-card-btn {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}
.data-statistics-card-btn > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.data-statistics-card-btn.og {
  background: #2b4ac4;
}
.data-statistics-card-btn.og:hover {
  background: #2b4ac4;
}
.data-statistics-card-btn.pp {
  background: #2b4ac4;
  margin: 0 8px;
}
.data-statistics-card-btn.pp:hover {
  background: #2b4ac4;
  margin: 0 8px;
}
.data-statistics-card-btn.bl {
  background: #2b4ac4;
}
.data-statistics-card-btn.bl:hover {
  background: #243fa5;
}
.data-statistics-card-btn.gr,
.data-statistics-card-btn.disable,
.data-statistics-card-btn.disable {
  background-color: #ededf0;
}
.data-statistics-card-btn.disable.mg {
  margin: 0 8px;
}
.data-statistics-card-btn.gr:hover {
  background-color: #dfe1e5;
}
.data-statistics-card-toggle {
  display: flex;
  background-color: #ededf0;
  border-radius: 8px;
  position: relative;
}
.data-statistics-card-btn.toggle {
  background: #3b3f4c;
  position: absolute;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease;
  left: 0px;
}
.data-statistics-card-btn.toggle.list {
  left: 37px;
}
.data-statistics-toggle-img {
  z-index: 100;
}

.data-statistics-card-btn.none {
  background: none;
}

.data-statistics-article {
  display: flex;
  justify-content: space-between;
}
.data-statistics {
  background-color: rgba(255, 255, 255, 0.4);
  height: 845px;
  margin-top: 16px;
  border-radius: 12px;
  width: 100%;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}
.data-statistics.detail {
  width: 964px;
  height: 861px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.data-statistics-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.data-statistics-sidebar {
  width: 0px;
  height: 954px;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 96px;
  right: 0;
  transition: width 0.35s ease-out;
  -webkit-transition: width 0.35s ease;
}
.data-statistics-sidebar.show {
  width: 448px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-statistics-sidebar-inner {
  width: 100%;
  justify-content: center;
}

.data-statistics-sidebar-nav {
  width: 90%;
}

.data-statistics-sidebar-title {
  color: #3e3e4f;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
}
.data-statistics-sidebar-title > img {
  margin-right: 8px;
}

.data-statistics-sidebar-container {
  width: 0%;
  float: right;
  height: 861px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  box-sizing: border-box;
  transition: width 0.35s ease-out;
  -webkit-transition: width 0.35s ease;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.data-statistics-sidebar-container.show {
  width: 100%;
}

.data-statistics-sidebar-date,
.data-statistics-sidebar-time,
.data-statistics-sidebar-host,
.data-statistics-sidebar-attendance,
.data-statistics-sidebar-duration {
  width: 362px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.data-statistics-sidebar-date,
.data-statistics-sidebar-time,
.data-statistics-sidebar-host,
.data-statistics-sidebar-attendance {
  margin-bottom: 24px;
}
.data-statistics-sidebar-date > font,
.data-statistics-sidebar-time > font,
.data-statistics-sidebar-host > font {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.data-statistics-sidebar-date {
  margin-top: 32px;
}

.data-statistics-sidebar-attendance-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.data-statistics-sidebar-attendance-title > img {
  margin-left: 8px;
}

.data-statistics-sidebar-attendance-container {
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-statistics-sidebar-attendance-container-header {
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-attendance-title-sub {
  width: 50px;
  height: 18px;
  border-left: 2px solid #2b4ac4;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.data-statistics-sidebar-attendance-box-area {
  height: 36px;
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-attendance-box-area > font {
  margin: 0 8px;
}

.data-statistics-sidebar-attendance-box-item {
  background: #ffffffbe;
  border: 1px solid #ededf0;
  border-radius: 8px;
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-attendance-box-item > font {
  margin-left: 16px;
}

.data-statistics-sidebar-attendance-box-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 8px;
}

.data-statistics-datepicker-side {
  width: 168px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #d1d3da;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-datepicker-side-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 152px;
  height: 20px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-time-div {
  width: 120px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #ededf0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-sidebar-time-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-time-chk {
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-time-chk > input {
  margin: 0 8px;
  width: 18px;
  height: 18px;
}
.data-statistics-sidebar-time-chk > font {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.data-statistics-sidebar-time-popup {
  width: 120px;
  height: 100px;
  overflow-y: auto;
  position: absolute;
  inset: 0 auto auto 0;
  -webkit-transform: translate(0px, 40px);
          transform: translate(0px, 40px);
  background: #ffffff;
  outline: 1px solid #ededf0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-sidebar-time-popup > div {
  margin-left: 10px;
}

.data-statistics-sidebar-time-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 18px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-host-div {
  width: 72px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #3e3e4f;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-statistics-sidebar-host-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56px;
  height: 18px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-container {
  width: 1368px;
  height: calc(100% - 96px);
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}
.data-statistics-container.detail {
  width: 900px;
}

.data-statistics-container-detail {
  /* width: 900px; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 96px); */
}

.data-statistics-container-detail > article {
  width: 100%;
}

.data-statistics-container-card,
.data-statistics-container-list {
  /* width: 1368px; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 96px); */
}

.data-statistics-container-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-container-card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.data-statistics-container-loading,
.data-statistics-container-empty {
  width: 100%;
  height: calc(100% - 96px);
}

.data-statistics-container-empty {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.data-statistics-container-empty > section {
  margin-top: 32px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: #84889b;
}
.data-statistics-container-empty > section > font:first-child {
  font-size: 18px;
}
.data-statistics-container-empty > section > font:last-child {
  font-size: 14px;
}

.data-statistics-card {
  width: 240px;
  height: 352px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px #00000014;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.data-statistics-card-article,
.data-statistics-card-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-card-article {
  width: 240px;
  flex-direction: column;
}
.data-statistics-card-profile {
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
}
.data-statistics-card-name {
  color: #3e3e4f;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}
.data-statistics-card-email {
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
}

.data-statistics-card-ul {
  width: 192px;
  margin: 34px 0;
}
.data-statistics-card-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}
.data-statistics-card-data.margin {
  margin-top: 8px;
}
.data-statistics-card-data > font {
  color: #84889b;
}
.data-statistics-card-data > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.data-statistics-card-data > div > img {
  margin-right: 8px;
}
.data-statistics-card-data > div > font {
  color: #3e3e4f;
}

.data-statistics-card-detailBtn {
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
}
.data-statistics-card-detailBtn:hover {
  color: #243fa5;
}

.data-statistics-header-detail {
  height: 85px;
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-back-btn {
  width: 104px;
  height: 36px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background: #3b3f4c;
}
.data-statistics-back-btn.disable {
  background: #ededf0;
  color: #84889b;
}
.data-statistics-back-btn > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.data-statistics-back-btn:hover {
  background: #666b82;
}
.data-statistics-back-btn > div > img {
  margin-right: 8px;
}

.data-statistics-list {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.data-statistics-list-checkbox {
  width: 20px;
  height: 20px;
}

.data-statistics-list-thead {
  background: #ffffff;
  box-sizing: border-box;
  height: 56px;
  color: #3b3f4c;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: bold;
}

.data-statistics-list-tr.tbody {
  height: 52px;
  letter-spacing: 0px;
  font-size: 14px;
  color: #000000;
  background: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.data-statistics-list-tr th.left,
.data-statistics-list-tr td.left {
  text-align: left;
}

.data-statistics-list-tr th:first-child,
.data-statistics-list-tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.data-statistics-list-tr th:last-child,
.data-statistics-list-tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.data-statistics-list-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-list-flex-left {
  display: flex;
  align-items: center;
}
.data-statistics-list-flex.th {
  height: 52px;
}
.data-statistics-list-flex.td {
  width: 100%;
  height: 52px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-statistics-list-name {
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-statistics-list-sort {
  margin-left: 8px;
}

.data-statistics-list-detail {
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
  font-weight: bold;
}

.data-statistics-list-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.data-statistics-detail-ul {
  height: 56px;
  border: 1px solid #3e3e4f;
  border-radius: 12px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-statistics-detail-li {
  color: #000000;
  font-size: 14px;
}
.data-statistics-detail-li-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-statistics-detail-li-nowrap.name {
  max-width: 90px;
}
.data-statistics-detail-li-nowrap.email {
  max-width: 120px;
}

.data-statistics-detail-li.name {
  margin: 0 8px;
}
.data-statistics-detail-li.div {
  text-align: center;
}
.data-statistics-detail-li.div > div {
  font-size: 14px;
  font-weight: bold;
  color: #6f7384;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-li.div > div > img {
  margin-right: 6px;
}
.data-statistics-detail-li.div > font {
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}
.data-statistics-detail-li.div.total {
  margin-left: 32px;
  width: 130px;
}
.data-statistics-detail-li.div.duration {
  margin: 0 16px;
  width: 123px;
}
.data-statistics-detail-li.div.count {
  margin: 0 16px;
  width: 140px;
}
.data-statistics-detail-li.div.rate {
  width: 100px;
}

.data-statistics-detail-div {
  width: 100%;
  /* height: 680px; */
  min-height: 680px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
}

.data-statistics-detail-table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  border-radius: 16px;
  margin-top: 8px;
  width: 868px;
  height: 100%;
}

.data-statistics-detail-thead {
  background: #ecedf0;
  /* width: 868px; */
  box-sizing: border-box;
  border-spacing: 16px 0 0 0;
  height: 48px;
  color: #3e3e4f;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
}

.data-statistics-detail-tbody {
  max-height: 560px;
}

.data-statistics-detail-tr.tbody {
  height: 48px;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  background: rgba(249, 249, 250, 0.6);
  text-align: center;
}

.data-statistics-detail-tr th:first-child,
.data-statistics-detail-tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.data-statistics-detail-tr th:last-child,
.data-statistics-detail-tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.data-statistics-detail-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
}
.data-statistics-detail-flex.td {
  width: 100%;
  text-align: center;
}

.data-statistics-detail-name,
.data-statistics-detail-email {
  /* width: 65px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-statistics-detail-name {
  max-width: 130px;
}
.data-statistics-detail-email {
  max-width: 170px;
}

.data-statistics-detail-time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.data-statistics-detail-community {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.data-statistics-detail-time-work,
.data-statistics-detail-time-overwork,
.data-statistics-detail-time-week,
.data-statistics-detail-attendance,
.data-statistics-detail-tardy,
.data-statistics-detail-absebce {
  width: 36px;
  height: 22px;
  border-radius: 11px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-time-work {
  background: #8b5fbf;
}
.data-statistics-detail-time-overwork {
  background: #37b392;
  margin: 0 4px;
}
.data-statistics-detail-time-week {
  background: #ee6c4d;
}
.data-statistics-detail-attendance {
  background: #2993bc;
}
.data-statistics-detail-tardy {
  background: #efac00;
  margin: 0 4px;
}
.data-statistics-detail-absebce {
  background: #ee6c4d;
}

.statistics-datepicker-start-popper,
.statistics-datepicker-end-popper,
.statistics-datepicker-detail-start-popper,
.statistics-datepicker-detail-end-popper {
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 600;
  width: 250px;
  height: 310px;
}

.statistics-datepicker-start-popper,
.statistics-datepicker-end-popper,
.statistics-datepicker-detail-start-popper {
  -webkit-transform: translate(0px, 38px) !important;
          transform: translate(0px, 38px) !important;
}

.statistics-datepicker-detail-end-popper {
  -webkit-transform: translate(-80px, 38px) !important;
          transform: translate(-80px, 38px) !important;
}

.data-slider-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-bar-slider-container {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-slider-content {
  position: relative;
  width: 360px;
}

.data-slider-content-track,
.data-slider-content-range,
.data-bar-slider-content-track,
.data-bar-slider-content-range {
  position: absolute;
}

.data-slider-content-track,
.data-slider-content-range,
.data-bar-slider-content-track {
  border-radius: 4px;
  height: 5px;
}

.data-slider-content-track,
.data-bar-slider-content-track {
  background-color: #d1d3da;
  width: 100%;
  z-index: 1;
}
.data-bar-slider-content-track {
  margin-top: 6px;
}

.data-slider-content-range {
  background-color: #2b4ac4;
  z-index: 2;
}
.data-bar-slider-content-range {
  height: 16px;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}
.data-bar-slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 999px;
}
/* .data-bar-slider-dot.purple {
  background-color: #5B407B;
  z-index: 99;
} */
/* .data-bar-slider-content-range > div:first-child {
  padding-left: 4px;
}
.data-bar-slider-content-range > div:last-child {
  padding-right: 4px;
} */

.data-bar-slider-content-range.purple {
  background-color: #8b5fbf;
}
.data-bar-slider-content-range.green {
  background: #37b392;
}
.data-bar-slider-content-range.orange {
  background: #ee6c4d;
}

.data-slider-content-value-left,
.data-slider-content-value-right {
  color: #3e3e4f;
  font-size: 14px;
  font-weight: bold;
}

.data-bar-slider-content-value-left,
.data-bar-slider-content-value-right {
  font-size: 14px;
  font-weight: bold;
}

.data-bar-slider-content-value-left.purple,
.data-bar-slider-content-value-right.purple {
  color: #8b5fbf;
}
.data-bar-slider-content-value-left.green,
.data-bar-slider-content-value-right.green {
  color: #37b392;
}

.data-bar-slider-content-value-left.orange,
.data-bar-slider-content-value-right.orange {
  color: #ee6c4d;
}

.data-slider-thumb,
.data-slider-thumb::-webkit-slider-thumb,
.data-bar-slider-thumb,
.data-bar-slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.data-slider-thumb,
.data-bar-slider-thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 360px;
  outline: none;
}

.data-bar-slider-thumb {
  /* opacity: 0; */
  margin-top: 10px;
}

.data-slider-thumb.left,
.data-bar-slider-thumb.left {
  z-index: 3;
}

.data-slider-thumb.right,
.data-bar-slider-thumb.right {
  z-index: 4;
}

.data-slider-thumb::-webkit-slider-thumb {
  background-color: #2b4ac4;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 0 1px 1px #2B4AC4; */
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.data-slider-thumb::-moz-range-thumb {
  background-color: #2b4ac4;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.data-slider-content-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-bar-slider-thumb::-webkit-slider-thumb {
  border: none;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
  /* border-radius: 10px; */
}
.data-bar-slider-thumb.purple::-webkit-slider-thumb {
  background-color: #8b5fbf;
}
.data-bar-slider-thumb.green::-webkit-slider-thumb {
  background: #37b392;
}
.data-bar-slider-thumb.orange::-webkit-slider-thumb {
  background: #ee6c4d;
}
.data-bar-slider-thumb.left::-webkit-slider-thumb {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.data-bar-slider-thumb.right::-webkit-slider-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-bar-slider-thumb::-moz-range-thumb {
  border: none;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
}
.data-bar-slider-thumb.purple::-moz-range-thumb {
  background-color: #8b5fbf;
}
.data-bar-slider-thumb.green::-moz-range-thumb {
  background: #37b392;
}
.data-bar-slider-thumb.orange::-moz-range-thumb {
  background: #ee6c4d;
}
.data-bar-slider-thumb.left::-moz-range-thumb {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.data-bar-slider-thumb.right::-moz-range-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-statistics-sidebar-duration-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.data-statistics-sidebar-duration-title > img {
  margin-left: 8px;
}

.data-statistics-sidebar-duration-title-sub {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.data-statistics-sidebar-duration-title-sub.purple {
  border-left: 2px solid #8b5fbf;
  color: #8b5fbf;
  width: 62px;
}
.data-statistics-sidebar-duration-title-sub.green {
  border-left: 2px solid #37b392;
  color: #37b392;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.orange {
  border-left: 2px solid #ee6c4d;
  color: #ee6c4d;
  width: 81px;
}
.data-statistics-sidebar-duration-title-sub.deepGreen {
  border-left: 2px solid #2993bc;
  color: #2993bc;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.yellow {
  border-left: 2px solid #efac00;
  color: #efac00;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.orange-2 {
  border-left: 2px solid #ee6c4d;
  color: #ee6c4d;
  width: 36px;
}

.data-statistics-sidebar-duration-container {
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.data-statistics-sidebar-duration-container-header {
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-slider-div {
  width: 360px;
  margin: 8px 0;
}

.data-statistics-sidebar-community {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}

.data-statistics-sidebar-community-item {
  width: 120px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 9px #00000012;
  border-radius: 16px;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
}

.data-statistics-sidebar-community-item.red {
  color: #c63353;
}
.data-statistics-sidebar-community-item.red.selected {
  outline: 1px solid #c63353;
}

.data-statistics-sidebar-community-item.blue {
  color: #1f378e;
  margin: 0 8px;
}
.data-statistics-sidebar-community-item.blue.selected {
  outline: 1px solid #1f378e;
}

.data-statistics-sidebar-community-item.green {
  color: #28b750;
}
.data-statistics-sidebar-community-item.green.selected {
  outline: 1px solid #28b750;
}

.data-statistics-list-paginig {
  width: 1340px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-statistics-detail-paging {
  width: 865px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-statistics-header-date {
  font-size: 14px;
  font-weight: bold;
  color: #3b3f4c;
}
.data_uselog_tooltip {
  position: absolute;
  margin-top: 32px;
  border-radius: 10px;
  padding: 16px;
  min-width: 260px;
  height: 18px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}
.data_uselog_tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 19px;
}

/**
 * Version : 1.0
 * 파일명 : account.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 회원가입 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
 * 수정일자 : 2022-06-08
 * 수정자 : 권도훈
 * 수정내역 : 첫 로그인 팝업 수정
*/
.LoginFailAlert,
.availableDay,
.unavailableDayAlert,
.changePasswordAlert,
.agreeListAlert {
  transition: all 0.5s ease-out;
  border-radius: 15px;
  z-index: 800;
  outline: none;
  border: none;
}
.firstLoginAlert {
  border-radius: 15px;
  z-index: 999;
  outline: none;
  border: none;
}
.agreeListAlert {
  margin: 10% auto;
  width: 392px;
  height: 543px;
  background: #ffffff;
  border: 1px solid #dbe0f5;
  border-radius: 12px;
}
.changePasswordAlert {
  margin: 18% auto;
  width: 520px;
  height: 336px;
  background: #ffffff;
}
.LoginFailAlert {
  margin: 5% auto;
  width: 640px;
  height: 748px;
  background: #fcf7f8;
}
.firstLoginAlert {
  margin: 7% auto;
  width: 560px;
  height: 708px;
  background: #ffffff;
}
.availableDay {
  margin: 10% auto;
  width: 528px;
  height: 564px;
  background: #ffffff;
}
.unavailableDayAlert {
  margin: 10% auto;
  width: 536px;
  height: 593px;
  background: #ffffff;
}
.makeAccountInputGroup {
  border: none;
  height: 100%;
}
.makeAccountInputTitle {
  margin-left: 15px;
  padding-top: 10px;
}

.makeAccountInput {
  text-align: center;
  width: 384px;
  height: 56px;
  border: none;
  outline: none;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  border-radius: 16px;
  background: #ffffff;
}
.makeAccountInput::-webkit-input-placeholder {
  color: #9b9faf;
}
.makeAccountInput::placeholder {
  color: #9b9faf;
}

.makeAccountEmailInput {
  width: 150px !important;
}
.makeAccountSelectBox {
  width: 145px;
  height: 34px;
  border: none;
  border-bottom: 1px solid gray;
}

.makeAccountBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #00000029;
  color: #ffffff;
  font: normal normal bold 14px Spoqa Han Sans Neo;
  outline: none;
  border: none;
  cursor: pointer;
}
.makeAccountBtn:disabled {
  background: #3b3f4c;
  color: #afb3bf;
  cursor: default;
}

.signUpBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 12px;
  font: normal normal bold 14px/57px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
.signUpBtn:disabled {
  background: #3b3f4c;
  color: #afb3bf;
  cursor: default;
}

.loginFail_closeBtn,
.freeInfo_closeBtn,
.firstLogin_closeBtn {
  text-align: right;
}
.loginFail_closeBtn img,
.freeInfo_closeBtn img,
.firstLogin_closeBtn img {
  margin: 32px 32px 0 0;
}

.loginFail_content,
.freeInfo_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.loginFail_title {
  font-size: 32px;
  font-weight: bold;
  color: #3e3e4f;
  margin: 0px;
}

.loginFail_errorIMG {
  margin: 48px 0px;
}

.loginFail_coment {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  text-align: center;
  margin: 0px;
}

.loginFail_makeAccountBtn {
  width: 320px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  margin-top: 48px;
}

.loginFail_balloon {
  position: relative;
  margin: 16px;
  border-radius: 10px;
  width: 240px;
  height: 128px;
  background: #ee6c4d;
  box-shadow: 0px 5px 16px #00000048;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.loginFail_balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ee6c4d;
  content: "";
  position: absolute;
  top: -10px;
  left: 110px;
}

.loginFail_balloon img {
  margin-top: 24px;
}
.loginFail_balloon p {
  margin-top: 9.7px;
}

.freeInfo_title {
  color: #3e3e4f;
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
}
.freeInfo_font_orange {
  color: #ee6c4d;
}
.freeInfo_mainIMG {
  margin: 40px 0px;
}
.freeInfo_coment_main_blue,
.freeInfo_coment_main_orange {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
}
.freeInfo_coment_main_blue {
  color: #2b4ac4;
}
.freeInfo_coment_main_orange {
  color: #af4f39;
}
.freeInfo_coment_sub {
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 24px;
  margin-bottom: 40px;
  text-align: center;
}
.freeInfo_btn_area {
  display: flex;
  justify-content: space-between;
  width: 368px;
  margin-bottom: 40px;
}
.freeInfo_btn_area button {
  width: 176px;
  height: 48px;
  background: #2b4ac4;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: none;
}

.firstLogin_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}

.firstLogin_title {
  color: #3e3e4f;
  font-size: 28px;
  font-weight: bold;
}

.firstLogin_mainIMG {
  margin: 40px 0px;
}

.firstLogin_coment_main {
  font-size: 18px;
  font-weight: bold;
  color: #2b4ac4;
}

.firstLogin_coment_sub,
.firstLogin_coment_sub_red {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.firstLogin_coment_sub {
  margin-top: 41px;
  color: #3e3e4f;
}
.firstLogin_coment_sub_red {
  margin-top: 47px;
  color: #c63353;
}

.firstLogin_btn_area button {
  width: 407px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 55px;
  border: none;
}

.changePW_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.deleteAccount_Alert {
  width: 400px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}

.changePwInput {
  width: 328px;
  height: 48px;
  background: #eaedf9;
  border-radius: 10px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 14px;
  color: #3e3e4f;
}
.changePwInput::-webkit-input-placeholder {
  font-size: 14px;
  letter-spacing: 0px;
  color: #8b9ddf;
}
.changePwInput::placeholder {
  font-size: 14px;
  letter-spacing: 0px;
  color: #8b9ddf;
}

.changePwBtn {
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  border: none;
}

.searchPwInput {
  width: 384px;
  height: 56px;
  background: #eaedf9;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  outline: none;
  border: none;
}
.searchPwInput::-webkit-input-placeholder {
  color: #8b9ddf;
}
.searchPwInput::placeholder {
  color: #8b9ddf;
}

.searchPwBtn {
  width: 384px;
  height: 56px;
  background: #2b4ac4;
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  border: none;
}
.searchPwBtn:disabled {
  background: #8b9ddf;
  color: #cad2f0;
}

.insertInfoInput {
  font: normal normal normal 16px/57px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  width: 384px;
  height: 56px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbe0f5;
  border-radius: 12px;
  outline: none;
  margin-top: 12px;
  margin-bottom: 16px;
}
.insertInfoInput::-webkit-input-placeholder {
  color: #9b9faf;
}
.insertInfoInput::placeholder {
  color: #9b9faf;
}


.account-info-success-btn {
  width: 400px;
  height: 56px;
  background: rgb(139, 95, 191);
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 20px;
  border-radius: 12px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
}
.account-info-success-btn:disabled {
  background: #9b9faf !important;
}
/**
 * Version : 1.0
 * 파일명 : address.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 주소록 CSS
 * 수정일자 : 2022-05-03
 * 수정자 : 권도훈
 * 수정내역 : 엑셀업로드 ui 수정
*/
.address_content_Div {
  position: relative;
  left: 40px;
  width: 1404px;
}
.address_header {
  font-size: 28px;
  font-weight: bold;
  color: blue;
  display: inline;
  top: 24px;
  position: relative;
}
.address_viewtype_toggle_btn {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  left: 16px;
  position: relative;
}
.address_viewtype_toggle_btn img {
  width: 16px;
  height: 16px;
}
.address_content {
  margin: 10px 20px;
}
/* .address_div {
  min-height: 48rem;
} */
.address_content_header {
  top: 32px;
  position: relative;
}
.address_content_header div {
  font-size: 16px;
}
.address_rowData_div {
  top: 44px;
  position: relative;
}
.add_address_div {
  background: white;
  position: absolute;
  width: 99rem;
  height: 3.5rem;
  z-index: 200;
}
.address_category_div {
  position: absolute;
  background: white;
  border: 1px solid;
  width: 26.1rem;
  border: none;
  height: 12rem;
  display: none;
}
.address_category_input {
  background: rosybrown;
  color: white;
  border: 1px solid;
  width: 25.8rem;
  height: 2rem;
}
.address_category_input::-webkit-input-placeholder {
  color: white;
}
.address_category_input::placeholder {
  color: white;
}
.addressContentDiv {
  position: relative;
  top: 52px;
  height: 836px;
  padding-left: 45px;
  padding-right: 37px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}
.address_ListPopupBackground {
  background: rgba(255, 255, 255, 0.6);
  z-index: 200;
  position: absolute;
  width: 1330px;
  height: 700px;
  margin-top: 10px;
}
.address_ListPopup {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 12px;
  width: 1330px;
  height: 180px;
  z-index: 210;
  /* box-shadow: grey 0px 0px 10px 0px;
  border-radius: 5px; */
}
.address_popupAvartar {
  width: 40px;
  height: 40px;
}
.address_CategorySlider {
  /* width: 20rem; */
  min-width: 29rem;
  min-height: 26px;
  overflow: hidden;
  /* border: 1px solid; */
  /* height: 2rem;
  margin-top: 0.4rem; */
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  white-space: nowrap;
  float: left;
}
.address_categotyEditor {
  z-index: 99;
  position: absolute;
  width: 538px;
  height: 344px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 12px;
}
.address_categoryEditor_input {
  width: 522px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #afb3bf;
  margin: 8px 4px 0px 4px;
}
.address_categoryEditor_input:focus {
  outline: none;
}
.address_categoryEditor_item {
  /* width: 538px; */
  width: 500px;
  margin: 12px 0px;
}
.address_categoryContent {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.add_category_btn {
  background-attachment: rosybrown;
  display: none;
  text-align: center;
  color: white;
  font-weight: bold;
}

.cardImg {
  max-height: 12rem;
  min-height: 12rem;
}
.UserImg {
  width: 65%;
  height: 100%;
  margin: 2rem auto 0 auto;
}
.address_searchBtn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #2b4ac4;
  cursor: pointer;
  margin-left: 8px;
  font-weight: bold;
}
.address_searchBtn:active {
  outline: none;
}
.address_searchBtn:disabled {
  background: #afb3bf;
  border: #afb3bf;
}

.groupAddress_searchBtn {
  width: 64px;
  height: 36px;
  background: #666b82;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #666b82;
  cursor: pointer;
  margin-left: 8px;
  font-weight: bold;
}
.groupAddress_searchBtn:active {
  outline: none;
}
.groupAddress_searchBtn:disabled {
  background: #84889b;
  border: #84889b;
}

.addressSearchText {
  width: 280px;
  height: 36px;
  background: #ffffffbe 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}
.addressSearchText:active,
.addressSearchText:focus {
  outline: none;
}
.address_listTable {
  width: 1330px;
  /* height: 700px; */
}
.address_pagingBtn {
  right: 37px;
  position: absolute;
  bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}

.scrollItem {
  border-radius: 6px;
  border: 1px solid;
  width: auto;
  display: inline-block;
  margin: 2px;
  font-size: 12px;
  padding: 2px 10px;
  background: skyblue;
  color: white;
  height: 18px;
  cursor: pointer;
}
.listThead tr {
  width: 1330px;
  height: 56px;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #2b4ac4;
}
.listTbody tr {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  width: 1330px;
  /* height: 52px; */
}
/* .listTbody tr td {
  line-height: 52px;
} */
.listTbody tr:hover {
  background-color: rgba(75, 137, 220, 0.4);
  cursor: pointer;
}

/* .address_popup_innerDiv{
  margin-left: 40px; 
  margin-top: 21px;
  margin-right: 32px;
  margin-bottom: 34px;
} */
.address_checkbox {
  height: 16px;
  width: 16px;
}

.address_header_icon {
  left: 9px;
  top: 3px;
  position: relative;
}

.address_popup_confirmBtn {
  width: 64px;
  height: 36px;
  background: none;
  border: none;
  cursor: pointer;
}
.address_popup_confirmBtn:active {
  outline: none;
}

.address_addBtn {
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #2b4ac4;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_red {
  border: 1px solid #a02741;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #a02741;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_orange {
  border: 1px solid #c44308;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #c44308;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_yellow {
  border: 1px solid #c68a00;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #c68a00;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_bean {
  border: 1px solid #749420;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #749420;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_green {
  border: 1px solid #1b7c37;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #1b7c37;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_mint {
  border: 1px solid #229a80;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #229a80;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_pine {
  border: 1px solid #2993bc;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #2993bc;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_pink {
  border: 1px solid #a836c7;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #a836c7;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_purple {
  border: 1px solid #7855d0;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #7855d0;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn_black {
  border: 1px solid #666b82;
  border-radius: 8px;
  width: 126px;
  height: 36px;
  color: #666b82;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.address_addBtn:disabled {
  border: 1px solid #8b9ddf;
  color: #8b9ddf;
}

.adr-excel-download-btn,
.adr-excel-select-btn,
.adr-excel-upload-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.adr-excel-download-btn,
.adr-excel-select-btn {
  width: 152px;
  height: 36px;
}
.adr-excel-download-btn {
  background: #ffffff;
  color: #2b4ac4;
  border: 1px solid #2b4ac4;
  margin-right: 8px;
}
.adr-excel-download-btn:hover {
  background: #2b4ac4;
  color: #ffffff;
}
.adr-excel-select-btn {
  background: #2b4ac4;
  color: #ffffff;
  border: 1px solid #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adr-excel-select-btn:hover,
.adr-excel-upload-btn:hover {
  background: #1f378e;
}
.adr-excel-upload-btn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  border: 1px solid #2b4ac4;
  color: #ffffff;
}

.adr-excel-fileinfo {
  width: 320px;
  height: 36px;
  background: #eaedf9;
  border-radius: 10px;
  margin-right: 8px;
}
.adr-excel-fileinfo-inner {
  margin: 0 0 0 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adr-excel-fileinfo-inner > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adr-excel-fileinfo-inner > div > div {
  margin-left: 8px;
  width: 247px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}

.address_uploadBtn {
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  margin: 5px;
  text-align: center;
}
.address_uploadBtn:active {
  background: #5b70bd;
}

.address_downloadBtn {
  background: #2b4ac4;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  float: right;
  margin-left: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address_downloadBtn:active {
  outline: none;
}
.address_downloadBtn:disabled {
  background: #afb3bf;
  border: #afb3bf;
}

.groupAddress_downloadBtn {
  background: #3b3f4c;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  float: right;
  margin-left: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupAddress_downloadBtn:active {
  outline: none;
}
.groupAddress_downloadBtn:disabled {
  background: #84889b;
  border: #84889b;
}

.adr-excel-upload {
  position: absolute;
  z-index: 400;
  max-width: 424px;
  height: 120px;
  background: white;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 10px;
  right: 0px;
  top: 54px;
}

.adr-excel-upload-close {
  position: absolute;
  right: 8px;
  top: 4px;
}

.adr-excel-upload-inner {
  padding: 16px;
}
.adr-excel-upload-section {
  margin-bottom: 8px;
}
.adr-excel-upload-section > p {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.adr-excel-upload-section > p:first-child {
  color: #2b4ac4;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px !important;
}
.adr-excel-upload-section > p:last-child {
  color: #666b82;
  font-size: 14px;
}

.adr-excel-upload-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_category_item_cover:hover {
  background: #f8f9fd;
}

.address_color_option:hover {
  background-color: #eaedf9;
}

.address_color_default {
  background-color: "#2B4AC4";
}
.address_color_pink {
  background-color: "#DC82C3";
}
.address_color_red {
  background-color: "#D4667E";
}
.address_color_orange {
  background-color: "#CD704B";
}
.address_color_yellow {
  background-color: "#BD9D29";
}
.address_color_lightgreen {
  background-color: "#97C12A";
}
.address_color_green {
  background-color: "#28B750";
}
.address_color_mint {
  background-color: "#27B395";
}
.address_color_blue {
  background-color: "#6E85D7";
}
.address_color_purple {
  background-color: "#9479DA";
}

/**
 * Version : 1.0
 * 파일명 : calendar.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 캘린더 CSS
 * 수정일자 : 2022-05-24
 * 수정자 : 강연승
 * 수정내역 : 주간 캘린더 헤더 UI 변경 및, 주간 캘린더 선택영역 색상 강조
*/

/* 
 * 풀캘린더
 */
.fc .fc-toolbar.fc-header-toolbar {
  /* margin: 18px 18px 24px 18px !important; */
  margin: 18px 0px 18px 0px !important;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.fc .fc-prev-button,
.fc .fc-next-button {
  width: 36px;
  height: 36px;
  border-radius: 10px !important;
  padding: 0 !important;
}
.fc .fc-icon-chevron-left,
.fc .fc-icon-chevron-right {
  font-size: 20px !important;
  vertical-align: text-bottom !important;
  line-height: 16px;
}
.fc .fc-today-button {
  width: 72px;
  height: 36px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo !important;
  border-radius: 8px !important;
  border: none !important;
}
.fc .fc-dayGridMonth-button {
  border-radius: 8px 0px 0px 8px !important;
}
.fc .fc-timeGridWeek-button {
  border-radius: 0px 8px 8px 0px !important;
  /* margin-right: 8px !important; */
}
.fc .fc-dayGridMonth-button,
.fc .fc-timeGridWeek-button {
  width: 76px;
  height: 36px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo !important;
}
/* .fc .fc-timegrid-now-indicator-arrow {
  border-color: #2b4ac4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.fc .fc-timegrid-now-indicator-line {
  border: 1px solid #2b4ac4 !important;
} */
/* 캘린더 주간 슬롯 높이 */
.fc .fc-timegrid-slot {
  height: 1.7em !important;
}
/* 캘린더 왼쪽 타임 라벨 테두리 */
.fc .fc-timegrid-slot-label {
  border: none !important;
}
/* 캘린더 오른쪽 테두리 */
/* .fc-theme-standard th:last-child {
  border-right: none !important;
} */
/* 캘린더 오른쪽 아래쪽 테두리 */
.fc-theme-standard td:last-child {
  border-right: none !important;
  border-bottom: none !important;
}
.fc .fc-theme-standard th {
  border-right: none !important;
}
/* 캘린더 위쪽 왼쪽 테두리 */
.fc .fc-scrollgrid {
  border: none !important;
}
.fc .fc-daygrid-day-number {
  font-size: 13px;
  margin: 6px 6px 0 0;
  width: 20px;
  height: 20px;
  padding: 1px 0 0 0 !important;
  text-align: center;
}
.fc .fc-timegrid-event {
  margin-right: 10px;
  padding: 6px 6px 6px 6px;
  border-radius: 10px;
  cursor: pointer;
}
.fc .fc-event-title {
  font-size: 12px;
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fc .fc-event-time {
  font-size: 12px !important;
  font-weight: bold;
}
.fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
  padding-bottom: 8px !important;
}
.fc .fc-dayGridMonth-view .fc-col-header-cell-cushion {
  padding-bottom: 16px !important;
}
.fc .fc-dayGridMonth-view .fc-col-header {
  margin-top: 16px;
}
.fc .fc-scrollgrid-section-header .fc-scroller {
  overflow-y: hidden !important;
}
.fc .fc-daygrid-event {
  border-radius: 4px !important;
  margin: 1.5px 4px !important;
  padding-right: 4px;
  cursor: pointer;
}
.fc .fc-scrollgrid th[role="presentation"] {
  border: none;
}
.fc-theme-standard th {
  border-right: none !important;
  border-left: none !important;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px;
}
.fc-scroller::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.fc-scroller::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* 색상별 분리 예정 */
/* 주간 캘린더 과거 배경 */
.fc .fc-timegrid-col.fc-day-past {
  background-color: rgba(132, 136, 155, 0.15);
}
/* 월간 캘린더 오늘 배경 */
.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}
/* 주간 캘린더 오늘 배경 */
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(43, 74, 196, 0.08) !important;
}
/* 드래그 이벤트 색상 */
.fc .fc-event-mirror {
  opacity: 0.8;
  /* background-color: var(
    --fc-event,
    rgba(43, 74, 196, 0.08)
  ) !important; */
}
/* 주간 캘린더 좌측 시간 색상 */
.fc .fc-timegrid-slot-label-cushion {
  font-size: 14px;
  font-weight: bold;
  color: #666b82;
  padding-right: 10px !important;
}
/* 월간 캘린더 더보기 텍스트 */
.fc .fc-daygrid-more-link {
  font-size: 12px;
  color: #84889b;
  font-weight: bold;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: 0px 3px 16px #00000029 !important;
}

.fc .fc-highlight {
  background: none !important;
}

/* 
 * 마이캘린더
 */
.myCalendar .fc .fc-prev-button,
.myCalendar .fc .fc-next-button {
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4 !important;
}
.myCalendar .fc .fc-prev-button:hover,
.myCalendar .fc .fc-next-button:hover {
  background: #6b7cc2 0% 0% no-repeat padding-box;
}
.myCalendar .fc .fc-prev-button:active,
.myCalendar .fc .fc-next-button:active {
  background: #6b7cc2 0% 0% no-repeat padding-box !important;
}
.myCalendar .fc .fc-icon-chevron-left,
.myCalendar .fc .fc-icon-chevron-right {
  color: #ffffff;
}

.myCalendar .fc .fc-today-button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #2b4ac4 !important;
}

.myCalendar .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #2b4ac4;
}

.myCalendar .fc .fc-dayGridMonth-button,
.myCalendar .fc .fc-timeGridWeek-button {
  color: #8b9ddf !important;
  background: #eaedf9 0% 0% no-repeat padding-box !important;
  border-color: #eaedf9 !important;
}
.myCalendar .fc .fc-dayGridMonth-button.fc-button-active,
.myCalendar .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background-color: #2b4ac4 !important;
  border-color: #2b4ac4 !important;
}
.myCalendar .fc .fc-timegrid-now-indicator-arrow {
  border-color: #2b4ac4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.myCalendar .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #2b4ac4 !important;
}
.myCalendar .fc .fc-day-today {
  color: #2b4ac4;
}
.myCalendar .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #2b4ac4;
  border-radius: 20px;
  color: #ffffff;
}
.myCalendar .fc .fc-col-header-cell .fc-col-header-cell-cushion .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.myCalendar .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.myCalendar .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.myCalendar .fc .fc-col-header-cell,
.myCalendar .fc .fc-timegrid-axis {
  border-bottom: 1px solid #2b4ac4;
}
.myCalendar .fc .fc-dayGridMonth-view .fc-col-header-cell {
  border-right: none !important;
  border-left: none !important;
}
/* 마이캘린더 월간 스케줄 배경*/
.myCalendar .fc .fc-daygrid-event {
  background-color: rgba(43, 74, 196, 0.1);
  color: #3b3f4c;
}

.myCalendar .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.myCalendar .fc .fc-timegrid-cols .fc-day-today.selectDate,
.myCalendar .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: rgba(43, 74, 196, 0.08) 0% 0% no-repeat padding-box !important;
}

/*
 * 그룹 캘린더 - 공통
 */
.groupCalendar .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #3b3f4c;
}
.groupCalendar .fc .fc-dayGridMonth-button,
.groupCalendar .fc .fc-timeGridWeek-button {
  color: #afb3bf !important;
  background: #dfe1e5 0% 0% no-repeat padding-box !important;
  border-color: #dfe1e5 !important;
}
.groupCalendar .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
  border-color: #3b3f4c !important;
}

/* 
 * 그룹캘린더 - 레드
 */
.groupCalendar_red .fc .fc-prev-button,
.groupCalendar_red .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cc4965 !important;
}
.groupCalendar_red .fc .fc-prev-button:hover,
.groupCalendar_red .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_red .fc .fc-prev-button:active,
.groupCalendar_red .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.groupCalendar_red .fc .fc-icon-chevron-left,
.groupCalendar_red .fc .fc-icon-chevron-right {
  color: #cc4965;
}

.groupCalendar_red .fc .fc-today-button {
  background: #cc4965 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_red .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #e74e72;
} */

/* .groupCalendar_red .fc .fc-dayGridMonth-button,
.groupCalendar_red .fc .fc-timeGridWeek-button {
  color: #fa5252 !important;
  background: #ffc9c9 0% 0% no-repeat padding-box !important;
  border-color: #ffc9c9 !important;
}
.groupCalendar_red .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_red .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #e74e72 0% 0% no-repeat padding-box !important;
  border-color: #e74e72 !important;
} */
.groupCalendar_red .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_red .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_red .fc .fc-day-today {
  color: #c63353;
}

.groupCalendar_red .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c63353;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_red
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_red .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c63353 0% 0% no-repeat padding-box;
}
.groupCalendar_red .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_red .fc .fc-col-header-cell,
.groupCalendar_red .fc .fc-timegrid-axis {
  border-bottom: 1px solid #c63353;
}

.fc .fc-daygrid-event.groupEvent_red {
  background-color: #f9eaed;
  color: #c63353;
}

.groupCalendar_red .fc .fc-daygrid-event {
  background-color: #f9eaed;
  color: #c63353;
}

.groupCalendar_red .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_red .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_red .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f9eaed 0% 0% no-repeat padding-box !important;
}

.groupCalendar_red .fc .fc-timegrid-event.fc-event-past {
  background-color: #e5a0af !important;
  border: 1px solid #e5a0af !important;
  color: #f9f9fa !important;
}

/* 
 * 그룹캘린더 - 오렌지
 */
.groupCalendar_orange .fc .fc-prev-button,
.groupCalendar_orange .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c44308 !important;
}
.groupCalendar_orange .fc .fc-prev-button:hover,
.groupCalendar_orange .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_orange .fc .fc-prev-button:active,
.groupCalendar_orange .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_orange .fc .fc-icon-chevron-left,
.groupCalendar_orange .fc .fc-icon-chevron-right {
  color: #c44308;
}

.groupCalendar_orange .fc .fc-today-button {
  background: #c44308 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_orange .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #d78e71;
} */

/* .groupCalendar_orange .fc .fc-dayGridMonth-button,
.groupCalendar_orange .fc .fc-timeGridWeek-button {
  color: #ff922b !important;
  background: #ffd2c1 0% 0% no-repeat padding-box !important;
  border-color: #ffd2c1 !important;
}
.groupCalendar_orange .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_orange .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #f1855b 0% 0% no-repeat padding-box !important;
  border-color: #f1855b !important;
} */
.groupCalendar_orange .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_orange .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_orange .fc .fc-day-today {
  color: #c44308;
}

.groupCalendar_orange .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c44308;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_orange
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_orange .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c44308 0% 0% no-repeat padding-box;
}
.groupCalendar_orange
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_orange .fc .fc-col-header-cell,
.groupCalendar_orange .fc .fc-timegrid-axis {
  border-bottom: 1px solid #c44308;
}

.fc .fc-daygrid-event.groupEvent_orange {
  background-color: #fdebe0;
  color: #9a370d;
}

.groupCalendar_orange .fc .fc-daygrid-event {
  background-color: #fdebe0;
  color: #9a370d;
}

.groupCalendar_orange .fc .fc-timegrid-event.fc-event-past {
  background-color: #e0a68f !important;
  border: 1px solid #e0a68f !important;
  color: #f9f9fa !important;
}

.groupCalendar_orange .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_orange .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_orange .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f8ebe6 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 옐로우
 */
.groupCalendar_yellow .fc .fc-prev-button,
.groupCalendar_yellow .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9a6f14 !important;
}
.groupCalendar_yellow .fc .fc-prev-button:hover,
.groupCalendar_yellow .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_yellow .fc .fc-prev-button:active,
.groupCalendar_yellow .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_yellow .fc .fc-icon-chevron-left,
.groupCalendar_yellow .fc .fc-icon-chevron-right {
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-today-button {
  background: #9a6f14 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

/* .groupCalendar_yellow .fc-toolbar-title {
  font: normal normal bold 36px/48px Spoqa Han Sans Neo !important;
  color: #806a1c;
} */

/* .groupCalendar_yellow .fc .fc-dayGridMonth-button,
.groupCalendar_yellow .fc .fc-timeGridWeek-button {
  color: #806a1c !important;
  background: #eedc9d 0% 0% no-repeat padding-box !important;
  border-color: #eedc9d !important;
}
.groupCalendar_yellow .fc .fc-dayGridMonth-button.fc-button-active,
.groupCalendar_yellow .fc .fc-timeGridWeek-button.fc-button-active {
  color: #ffffff !important;
  background: #bda13c 0% 0% no-repeat padding-box !important;
  border-color: #bda13c !important;
} */
.groupCalendar_yellow .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_yellow .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_yellow .fc .fc-day-today {
  color: #c38d0f;
}

.groupCalendar_yellow .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #c38d0f;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_yellow
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_yellow .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #c38d0f 0% 0% no-repeat padding-box;
}
.groupCalendar_yellow
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_yellow .fc .fc-col-header-cell,
.groupCalendar_yellow .fc .fc-timegrid-axis {
  border-bottom: 1px solid #efac00;
}

.fc .fc-daygrid-event.groupEvent_yellow {
  background-color: #fff1da;
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-daygrid-event {
  background-color: #fff1da;
  color: #9a6f14;
}

.groupCalendar_yellow .fc .fc-timegrid-event.fc-event-past {
  background-color: #d9c375 !important;
  border: 1px solid #d9c375 !important;
  color: #f9f9fa !important;
}

.groupCalendar_yellow .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_yellow .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_yellow .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #fff1da 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 빈
 */
.groupCalendar_bean .fc .fc-prev-button,
.groupCalendar_bean .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #749420 !important;
}
.groupCalendar_bean .fc .fc-prev-button:hover,
.groupCalendar_bean .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_bean .fc .fc-prev-button:active,
.groupCalendar_bean .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_bean .fc .fc-icon-chevron-left,
.groupCalendar_bean .fc .fc-icon-chevron-right {
  color: #749420;
}

.groupCalendar_bean .fc .fc-today-button {
  background: #749420 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_bean .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_bean .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_bean .fc .fc-day-today {
  color: #749420;
}

.groupCalendar_bean .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #87ad26;
  border-radius: 20px;
  color: #ffffff;
}

.groupCalendar_bean
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_bean .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #749420 0% 0% no-repeat padding-box;
}
.groupCalendar_bean .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_bean .fc .fc-col-header-cell,
.groupCalendar_bean .fc .fc-timegrid-axis {
  border-bottom: 1px solid #749420;
}

.fc .fc-daygrid-event.groupEvent_bean {
  background-color: #eef5db;
  color: #5b751a;
}

.groupCalendar_bean .fc .fc-daygrid-event {
  background-color: #eef5db;
  color: #5b751a;
}
.groupCalendar_bean .fc .fc-timegrid-event.fc-event-past {
  background-color: #b8d66b !important;
  border: 1px solid #b8d66b !important;
  color: #f8fbf0 !important;
}

.groupCalendar_bean .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_bean .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_bean .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e8f2cf 0% 0% no-repeat padding-box !important;
}

/* 
 * 그룹캘린더 - 그린
 */
.groupCalendar_green .fc .fc-prev-button,
.groupCalendar_green .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1b7c37 !important;
}
.groupCalendar_green .fc .fc-prev-button:hover,
.groupCalendar_green .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_green .fc .fc-prev-button:active,
.groupCalendar_green .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_green .fc .fc-icon-chevron-left,
.groupCalendar_green .fc .fc-icon-chevron-right {
  color: #1b7c37;
}

.groupCalendar_green .fc .fc-today-button {
  background: #1b7c37 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_green .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_green .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_green .fc .fc-day-today {
  color: #239d45;
}

.groupCalendar_green .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #1b7c37;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_green
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_green .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #239d45 0% 0% no-repeat padding-box;
}
.groupCalendar_green .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_green .fc .fc-col-header-cell,
.groupCalendar_green .fc .fc-timegrid-axis {
  border-bottom: 1px solid #239d45;
}

.fc .fc-daygrid-event.groupEvent_green {
  background-color: #d9f4e0;
  color: #104920;
}

.groupCalendar_green .fc .fc-daygrid-event {
  background-color: #d9f4e0;
  color: #104920;
}
.groupCalendar_green .fc .fc-timegrid-event.fc-event-past {
  background-color: #9be3af !important;
  border: 1px solid #9be3af !important;
  color: #f3fcf5 !important;
}

.groupCalendar_green .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_green .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_green .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e2f7e8 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 민트
 */
.groupCalendar_mint .fc .fc-prev-button,
.groupCalendar_mint .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #229a80 !important;
}
.groupCalendar_mint .fc .fc-prev-button:hover,
.groupCalendar_mint .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_mint .fc .fc-prev-button:active,
.groupCalendar_mint .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_mint .fc .fc-icon-chevron-left,
.groupCalendar_mint .fc .fc-icon-chevron-right {
  color: #229a80;
}

.groupCalendar_mint .fc .fc-today-button {
  background: #229a80 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_mint .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_mint .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_mint .fc .fc-day-today {
  color: #1b7a65;
}

.groupCalendar_mint .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #229a80;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_mint
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_mint .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #1b7a65 0% 0% no-repeat padding-box;
}
.groupCalendar_mint .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_mint .fc .fc-col-header-cell,
.groupCalendar_mint .fc .fc-timegrid-axis {
  border-bottom: 1px solid #229a80;
}

.fc .fc-daygrid-event.groupEvent_mint {
  background-color: #d6f4ed;
  color: #10483b;
}

.groupCalendar_mint .fc .fc-daygrid-event {
  background-color: #d6f4ed;
  color: #10483b;
}
.groupCalendar_mint .fc .fc-timegrid-event.fc-event-past {
  background-color: #95e1d1 !important;
  border: 1px solid #95e1d1 !important;
  color: #f2fbf9 !important;
}

.groupCalendar_mint .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_mint .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_mint .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #d6f4ed 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 블루
 */
.groupCalendar_blue .fc .fc-prev-button,
.groupCalendar_blue .fc .fc-next-button {
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.groupCalendar_blue .fc .fc-prev-button:hover,
.groupCalendar_blue .fc .fc-next-button:hover {
  background: #596ec2 0% 0% no-repeat padding-box;
}
.groupCalendar_blue .fc .fc-prev-button:active,
.groupCalendar_blue .fc .fc-next-button:active {
  background: #596ec2 0% 0% no-repeat padding-box !important;
}
.groupCalendar_blue .fc .fc-icon-chevron-left,
.groupCalendar_blue .fc .fc-icon-chevron-right {
  color: #ffffff;
}

.groupCalendar_blue .fc .fc-today-button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #2b4ac4 !important;
}

.groupCalendar_blue .fc .fc-timegrid-now-indicator-arrow {
  border-color: #5876e4 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_blue .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #5876e4 !important;
}
.groupCalendar_blue .fc .fc-day-today {
  color: #5876e4;
}

.groupCalendar_blue .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #5876e4;
  border-radius: 20px;
  color: #ffffff;
}

.groupCalendar_blue .fc .fc-col-header-cell,
.groupCalendar_blue .fc .fc-timegrid-axis {
  border-bottom: 1px solid #5876e4;
}

.fc .fc-daygrid-event.groupEvent_blue {
  background-color: rgba(43, 74, 196, 0.1);
  color: #5876e4;
}

.groupCalendar_blue .fc .fc-daygrid-event {
  background-color: rgba(43, 74, 196, 0.1);
  color: #5876e4;
}
/* 
 * 그룹캘린더 - 파인
 */
.groupCalendar_pine .fc .fc-prev-button,
.groupCalendar_pine .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #257495 !important;
}
.groupCalendar_pine .fc .fc-prev-button:hover,
.groupCalendar_pine .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_pine .fc .fc-prev-button:active,
.groupCalendar_pine .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_pine .fc .fc-icon-chevron-left,
.groupCalendar_pine .fc .fc-icon-chevron-right {
  color: #257495;
}

.groupCalendar_pine .fc .fc-today-button {
  background: #257495 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_pine .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_pine .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_pine .fc .fc-day-today {
  color: #2b88ae;
}

.groupCalendar_pine .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #2688ae;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_pine
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_pine .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #2b88ae 0% 0% no-repeat padding-box;
}
.groupCalendar_pine .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_pine .fc .fc-col-header-cell,
.groupCalendar_pine .fc .fc-timegrid-axis {
  border-bottom: 1px solid #2993bc;
}

.fc .fc-daygrid-event.groupEvent_pine {
  background-color: #e0f0f6;
  color: #1c5a73;
}

.groupCalendar_pine .fc .fc-daygrid-event {
  background-color: #e0f0f6;
  color: #1c5a73;
}
.groupCalendar_pine .fc .fc-timegrid-event.fc-event-past {
  background-color: #84c4dd !important;
  border: 1px solid #84c4dd !important;
  color: #f5fafc !important;
}

.groupCalendar_pine .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_pine .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_pine .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #e0f0f6 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 핑크
 */
.groupCalendar_pink .fc .fc-prev-button,
.groupCalendar_pink .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7a2791 !important;
}
.groupCalendar_pink .fc .fc-prev-button:hover,
.groupCalendar_pink .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_pink .fc .fc-prev-button:active,
.groupCalendar_pink .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_pink .fc .fc-icon-chevron-left,
.groupCalendar_pink .fc .fc-icon-chevron-right {
  color: #7a2791;
}

.groupCalendar_pink .fc .fc-today-button {
  background: #7a2791 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_pink .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_pink .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_pink .fc .fc-day-today {
  color: #9931b5;
}

.groupCalendar_pink .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #a836c7;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_pink
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_pink .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #9931b5 0% 0% no-repeat padding-box;
}
.groupCalendar_pink .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_pink .fc .fc-col-header-cell,
.groupCalendar_pink .fc .fc-timegrid-axis {
  border-bottom: 1px solid #a836c7;
}

.fc .fc-daygrid-event.groupEvent_pink {
  background-color: #f5e7f8;
  color: #5c1d6d;
}

.groupCalendar_pink .fc .fc-daygrid-event {
  background-color: #f5e7f8;
  color: #5c1d6d;
}

.groupCalendar_pink .fc .fc-timegrid-event.fc-event-past {
  background-color: #d6a0e5 !important;
  border: 1px solid #d6a0e5 !important;
  color: #fcf8fd !important;
}

.groupCalendar_pink .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_pink .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_pink .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f6eaf9 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 퍼플
 */
.groupCalendar_purple .fc .fc-prev-button,
.groupCalendar_purple .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6f4cc7 !important;
}
.groupCalendar_purple .fc .fc-prev-button:hover,
.groupCalendar_purple .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_purple .fc .fc-prev-button:active,
.groupCalendar_purple .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_purple .fc .fc-icon-chevron-left,
.groupCalendar_purple .fc .fc-icon-chevron-right {
  color: #6f4cc7;
}

.groupCalendar_purple .fc .fc-today-button {
  background: #6f4cc7 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_purple .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_purple .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_purple .fc .fc-day-today {
  color: #5835b1;
}

.groupCalendar_purple .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #613eba;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_purple
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_purple .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #5835b1 0% 0% no-repeat padding-box;
}
.groupCalendar_purple
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_purple .fc .fc-col-header-cell,
.groupCalendar_purple .fc .fc-timegrid-axis {
  border-bottom: 1px solid #7855d0;
}

.fc .fc-daygrid-event.groupEvent_purple {
  background-color: #efebfa;
  color: #391d82;
}

.groupCalendar_purple .fc .fc-daygrid-event {
  background-color: #efebfa;
  color: #391d82;
}
.groupCalendar_purple .fc .fc-timegrid-event.fc-event-past {
  background-color: #bbaae7 !important;
  border: 1px solid #bbaae7 !important;
  color: #faf9fd !important;
}

.groupCalendar_purple .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_purple .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_purple .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #efebfa 0% 0% no-repeat padding-box !important;
}
/* 
 * 그룹캘린더 - 블랙
 */

.groupCalendar_black .fc .fc-prev-button,
.groupCalendar_black .fc .fc-next-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3b3f4c !important;
}
.groupCalendar_black .fc .fc-prev-button:hover,
.groupCalendar_black .fc .fc-next-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.groupCalendar_black .fc .fc-prev-button:active,
.groupCalendar_black .fc .fc-next-button:active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.groupCalendar_black .fc .fc-icon-chevron-left,
.groupCalendar_black .fc .fc-icon-chevron-right {
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-today-button {
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.groupCalendar_black .fc .fc-timegrid-now-indicator-arrow {
  border-color: #c63353 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.groupCalendar_black .fc .fc-timegrid-now-indicator-line {
  border: 1px solid #c63353 !important;
}
.groupCalendar_black .fc .fc-day-today {
  color: #000000;
}

.groupCalendar_black .fc .fc-day-today .fc-daygrid-day-number {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
}

.groupCalendar_black
  .fc
  .fc-col-header-cell
  .fc-col-header-cell-cushion
  .weekday {
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
}
.groupCalendar_black .fc .fc-day-today .fc-col-header-cell-cushion .weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 23px;
  border-radius: 50%;
  font: normal normal bold 12px/23px Spoqa Han Sans Neo;
  color: #ffffff;
  background: #000000 0% 0% no-repeat padding-box;
}
.groupCalendar_black .fc .fc-col-header-cell .fc-col-header-cell-cushion .date {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.groupCalendar_black .fc .fc-col-header-cell,
.groupCalendar_black .fc .fc-timegrid-axis {
  border-bottom: 1px solid #3b3f4c;
}

.fc .fc-daygrid-event.groupEvent_black {
  background-color: #e7e8eb;
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-daygrid-event {
  background-color: #e7e8eb;
  color: #3b3f4c;
}

.groupCalendar_black .fc .fc-timegrid-event.fc-event-past {
  background-color: #9b9faf !important;
  border: 1px solid #9b9faf !important;
  color: #f9f9fa !important;
}

.groupCalendar_black .fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.groupCalendar_black .fc .fc-timegrid-cols .fc-day-today.selectDate,
.groupCalendar_black .fc .fc-timegrid-cols .fc-day-future.selectDate {
  background: #f4f4f5 0% 0% no-repeat padding-box !important;
}

/**
 * Version : 1.0
 * 파일명 : clause.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 이용약관 CSS - 삭제예정
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.clauseDiv {
  width: 800px;
  height: 600px;
  margin-left: 560px;
  margin-top: 150px;
  position: absolute;
}
.clauseLogo {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: blue;
}
.clauseLeftDiv {
  background: white;
  border: none;
  border-radius: 20px;
  height: 100%;
  width: 390px;
  float: left;
}
.clauseRightDiv {
  background: white;
  border: none;
  border-radius: 20px;
  height: 100%;
  width: 390px;
  float: right;
}
.cancleBtn {
  background: gray;
  margin-left: 50px;
}
.agreeBtn {
  background: blue;
  margin-right: 50px;
}

.clauseBtn {
  margin-top: 10px;
  width: 310px;
  height: 40px;
  border: none;
  border-radius: 25px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.clauseTextarea {
  overflow: auto;
  resize: none;
  border: none;
  width: 90%;
  margin-left: 15px;
  margin-top: 10px;
  height: 90%;
}

.clauseAgreeDiv {
  width: 90%;
  margin-left: 15px;
  margin-top: 10px;
}
/**
 * Version : 1.0
 * 파일명 : content.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 컨텐츠 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.ContentHeader_btn {
  padding: 0.75rem 1.25rem;
  margin-right: 1rem;
}
.ContentHeader_btn:hover {
  background-color: #a9a9a9;
}
.ContentHeader_btn:disabled {
  background-color: blue;
}
/**
 * Version : 1.0
 * 파일명 : header.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 헤더 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
*/
.Header_MsgBtn {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.75rem;
}
.Header_UserBtn {
  height: 60px;
}
/* .Header_UserNM {
  margin-top: 1rem !important;
  font-size: 16px;
} */
.Header_groupProfileBtnArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header_groupProfileBtn {
  height: 60px;
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
}
.Header_groupLicenseBtn {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: rgba(139, 157, 223, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.Header_groupMeetingHostBtn {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: rgba(139, 157, 223, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.Header_groupLicenseBtn_red {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f9eaed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #791a2f;
}
.Header_groupMeetingHostBtn_red {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f9eaed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #791a2f;
}
.Header_groupLicenseBtn_orange {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f8ebe6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7d371b;
}
.Header_groupMeetingHostBtn_orange {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f8ebe6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7d371b;
}
.Header_groupLicenseBtn_yellow {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #fff1da 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #725314;
}
.Header_groupMeetingHostBtn_yellow {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #fff1da 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #725314;
}
.Header_groupLicenseBtn_bean {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #eef5db 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #35450f;
}
.Header_groupMeetingHostBtn_bean {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #eef5db 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #35450f;
}
.Header_groupLicenseBtn_green {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #e5f8ea 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #104920;
}
.Header_groupMeetingHostBtn_green {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #e5f8ea 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #104920;
}
.Header_groupLicenseBtn_mint {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #d6f4ed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #10483b;
}
.Header_groupMeetingHostBtn_mint {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #d6f4ed 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #10483b;
}
.Header_groupLicenseBtn_pine {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #e0f0f6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #134457;
}
.Header_groupMeetingHostBtn_pine {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #e0f0f6 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #134457;
}
.Header_groupLicenseBtn_pink {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #f6eaf9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2e0f36;
}
.Header_groupMeetingHostBtn_pink {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #f6eaf9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2e0f36;
}
.Header_groupLicenseBtn_purple {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #efebfa 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #331974;
}
.Header_groupMeetingHostBtn_purple {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #efebfa 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #331974;
}
.Header_groupLicenseBtn_black {
  padding: 0 16px;
  max-width: 200px;
  height: 36px;
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 24px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #000000;
}
.Header_groupMeetingHostBtn_black {
  padding: 0 16px;
  max-width: 250px;
  height: 36px;
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 29px;
  border: none;
  margin-left: 16px;
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #000000;
}
.licenseDday {
  clear: both;
  position: absolute;
  margin: 16px 0 0 105px;
  width: 36px;
  height: 17px;
  background: #d7263d 0% 0% no-repeat padding-box;
  border-radius: 9px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  color: #ffffff;
}

/**
 * Version : 1.0
 * 파일명 : login.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 로그인 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Login_Div {
  width: 25rem;
  height: 20rem;
  margin: 18rem auto;
}
.Login_Input {
  height: 3rem;
  background: #eaeaea;
  border-radius: 10px;
  outline: none;
}
.Login_Btn {
  height: 2.5rem;
}
.Login_Text {
  font-size: 14px;
}
/**
 * Version : 1.0
 * 파일명 : menu.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 메뉴 CSS
 * 수정일자 :  2022-02-25
 * 수정자 : 권도훈
 * 수정내역 : 메인 UI 수정
*/
.Menu_Div {
  width: 196px;
  height: 1050px;
  border-right: 1px solid rgba(43, 74, 196, 0.2);
  /* float: left; */
  position: relative;
}
.Menu_Logo {
  color: blue;
  font-size: 30px;
  font-weight: bold;
  padding: 25px 0 25px 0;
  text-align: center;
}
/* .Menu_BtnGroup {
  height: 45rem;
  height: 42rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
} */
.Menu_ModeBtn {
  width: 9.5rem;
  height: 4.5rem;
}
.Menu_Btn {
  margin-right: 32px;
  margin-left: 32px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 8px;
  color: #2b4ac4;
  /* border: 1px solid rgba(0, 0, 0, 0); */
  /* display: block;
    width: 8rem;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0); */
}
.Menu_Btn:hover {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: white !important;
  /* outline: none !important;
    border: 1px solid white !important;
    border-radius: 25px 10px !important;
    background-color: white !important; */
}
.Menu_ActiveBtn {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
  /* outline: none !important;
    border: 1px solid white !important;
    border-radius: 25px 10px !important;
    background-color: white !important; */
}

.groupList_Btn {
  margin-right: 32px;
  margin-left: 32px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 16px;
  color: #ffffff;
  border: none !important;
  border-radius: 40px 20px !important;
  background: #8b9ddf 0% 0% no-repeat padding-box !important;
}

.groupMenu_Btn {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 14px;
  padding-bottom: 13px;
  margin-bottom: 8px;
  color: #4865cc;
  border: none !important;
  border-radius: 40px 20px !important;
}

/*그룹 메뉴 버튼 색상 */

/* 
 * 그룹메뉴 버튼 - 레드
 */
.groupList_Btn_red {
  color: #ffffff !important;
  background: #99243e 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_red {
  color: #913448;
}
.groupMenu_ActiveBtn_red {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_red:hover {
  color: #913448;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 오렌지
 */
.groupList_Btn_orange {
  color: #ffffff !important;
  background: #b24e27 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_orange {
  color: #b24e27;
}
.groupMenu_ActiveBtn_orange {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_orange:hover {
  color: #b24e27;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 옐로우
 */
.groupList_Btn_yellow {
  color: #ffffff !important;
  background: #9a6f14 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_yellow {
  color: #7d5b14;
}
.groupMenu_ActiveBtn_yellow {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_yellow:hover {
  color: #7d5b14;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 빈
 */
.groupList_Btn_bean {
  color: #ffffff !important;
  background: #749420 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_bean {
  color: #5b751a;
}
.groupMenu_ActiveBtn_bean {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_bean:hover {
  color: #5b751a;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 그린
 */
.groupList_Btn_green {
  color: #ffffff !important;
  background: #239d45 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_green {
  color: #135726;
}
.groupMenu_ActiveBtn_green {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_green:hover {
  color: #135726;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 민트
 */
.groupList_Btn_mint {
  color: #ffffff !important;
  background: #1b7a65 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_mint {
  color: #1b7a65;
}
.groupMenu_ActiveBtn_mint {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_mint:hover {
  color: #1b7a65;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

/* 
 * 그룹메뉴 버튼 - 블루
 */
.groupList_Btn_blue {
  color: #ffffff !important;
  background: #8b9ddf 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_blue {
  color: #4865cc;
}
.groupMenu_ActiveBtn_blue {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.groupMenu_Btn_blue:hover {
  color: #4865cc;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 파인
 */
.groupList_Btn_pine {
  color: #ffffff !important;
  background: #257495 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_pine {
  color: #1e5e79;
}
.groupMenu_ActiveBtn_pine {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_pine:hover {
  color: #1e5e79;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 핑크
 */
.groupList_Btn_pink {
  color: #ffffff !important;
  background: #5c1d6d 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_pink {
  color: #5c1d6d;
}
.groupMenu_ActiveBtn_pink {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_pink:hover {
  color: #5c1d6d;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 퍼플
 */
.groupList_Btn_purple {
  color: #ffffff !important;
  background: #5431ac 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_purple {
  color: #46239f;
}
.groupMenu_ActiveBtn_purple {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_purple:hover {
  color: #46239f;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
/* 
 * 그룹메뉴 버튼 - 블랙
 */
.groupList_Btn_black {
  color: #ffffff !important;
  background: #3b3f4c 0% 0% no-repeat padding-box !important;
}
.groupMenu_Btn_black {
  color: #3b3f4c;
}
.groupMenu_ActiveBtn_black {
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.groupMenu_Btn_black:hover {
  color: #3b3f4c;
  border: none !important;
  border-radius: 40px 20px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

/**
 * Version : 1.0
 * 파일명 : mobile_auth.css
 * 작성일자 : 2022-03-25
 * 작성자 : 권도훈
 * 설명 : 핸드폰 인증 CSS
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.py-auth-popup {
    width: 440px;
    height: 545px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px #00000029;
    transition: all 0.5s ease-out;
    border-radius: 16px;
    z-index: 100;
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.py-auth-btn-close {
    position: absolute;
    right: 16px;
    top: 8px;
}

.py-auth-btn {
    width: 304px;
    height: 40px;
    border-radius: 8px;
    background: #2B4AC4;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    margin-bottom: 24px;
    transition: all 0.1s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.py-auth-btn.loading,
.py-auth-btn:disabled {
    background: #8B9DDF;
    color: #DBE0F5;
}
.py-auth-btn:hover {
    background: #7489d4;
}

.py-auth-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2B4AC4;
    margin-top: 24px;
}
.py-auth-title.term {
    margin-bottom: 8px;
}
.py-auth-title.mobile {
    margin-bottom: 37px;
}

.py-auth-description {
    width: 234px;
    height: 39px;
    text-align: center;
    margin-bottom: 24px;
}
.py-auth-description > p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    color: #3E3E4F;
}

.py-auth-sub-title {
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
}

.py-auth-container {
    width: 304px;
    height: calc(100% - 119px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.py-auth-content-term,
.py-auth-content-phone,
.py-auth-content-success {
    width: 100%;
}

.py-auth-content-term {
    height: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.py-auth-term-item {
    width: 304px;
    height: 36px;
    background: #FFFFFF;
    outline: 1px solid #C1C3CD;
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #3E3E4F;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-out;
}
.py-auth-term-item > div {
    margin-left: 16px;
}
.py-auth-term-item:hover {
    background: #2B4AC4;
    color: #FFFFFF;
}

.py-auth-content-phone {
    height: 338px;
    background: #EAEDF9;
    border-radius: 12px;
}

.py-auth-content-phone-inner {
    margin: 24px;
    height: calc(100% - 48px);
}

.py-auth-phone-item {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}
.py-auth-phone-item:last-child {
    margin-bottom: 0;
}

.py-auth-phone-item-title {
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
}
.py-auth-phone-item-textArea {
    width: 100%;
    height: 32px;
}
.py-auth-phone-input {
    background: none;
    width: 100%;
    height: 32px;
    border: none;
    outline: none;
}

/* .py-auth-phone-input.birth, */
.py-auth-phone-input.gender {
    text-align: center;
}

.py-auth-phone-item-line {
    position: relative;
}

.py-auth-phone-item-textArea.birth,
.py-auth-phone-item-line.birth {
    width: 72px !important;
}

.py-auth-phone-item-textArea.gender,
.py-auth-phone-item-line.gender {
    width: 24px !important;
}


.py-auth-phone-birth {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.py-auth-phone-item-min {
    margin: 0 4px;
    letter-spacing: 0px;
    color: #3E3E4F;
    font-size: 14px;
    font-weight: bold;
}

.py-auth-phone-btn-carrier-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.py-auth-phone-btn-carrier {
    height: 32px;
    background: #AFB3BF;
    border-radius: 8px;
    color: #FFFFFF;
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    outline: none;
    margin-right: 8px;
    margin-top: 8px;
    transition: all 0.1s ease-out;
}

.py-auth-phone-btn-carrier.active {
    background: #3E3E4F;
}

.py-auth-phone-item-auth {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.py-auth-phone-item-num-area {
    width: 75%;
    height: 32px;
}

.py-auth-btn-num {
    width: 64px;
    height: 32px;
    background: #3E3E4F;
    border-radius: 8px;
    border: none;
    outline: none;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    /* transition: all 0.2s ease-out; */
    transition: 0.2s;
}
.py-auth-btn-num:hover {
    background: #707072;
}

.py-authphone-item-line-default {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    transition: 0.4s;
    background-color: #9b9b9c;
}

.py-authphone-item-line-default.none {
    display: none;
}

.py-auth-success-header {
    margin-top: 232px;
}

.py-auth-content-success {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #3E3E4F;
    text-align: center;
}










.payment_auth_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    font-weight: bold;
    color: #2B4AC4;
    margin: 0 15px;
    height: 70px;
}
.payment_auth_content {
    height: 400px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment_auth_item:hover {
    color: #2B4AC4;
}

.payment_auth_btn, .payment_auth_success_btn, .payment_auth_btn_loading {
    border: none;
    outline: none;
    height: 45px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 12px;
}
.payment_auth_btn, .payment_auth_success_btn {
    background: #2B4AC4;
    color: #FFFFFF;
}
.payment_auth_success_btn {
    width: 90%;
}
.payment_auth_btn_loading {
    background: #e2e2e2;
}

.payment_mobile_article {
    position: relative;
    margin-bottom: 10px;
}
.payment_mobile_article > p {
    margin: 0;
}
.payment_mobile_article > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}

.payment_mobile_non_focus {
    position: absolute;
    width: 0;
    left: 50%;
    height: 2px;
	transition: 0.4s;
    /* background-color: #e2e2e2; */
}
.payment_mobile_focus {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
	transition: 0.4s;
    background-color: #84889B;
}

.payment_mobile_rrn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.payment_mobile_birthDate {
    position: relative;
    width: 16%;
}
.payment_mobile_genderBirth {
    position: relative;
    width: 45%;
}
.payment_mobile_rrn > div > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}
.payment_mobile_rrn > p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    padding: 0px 12px;
}

.payment_mobile_genderBirth {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.payment_mobile_genderBirthCode {
    position: relative;
    width: 10%;
}
.payment_mobile_genderBirthCode > input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
}
.payment_mobile_genderBirthCode > div {
    width: 80%;
    margin: 0;
}

.payment_mobile_genderBirth_star {
    width: 80%;
    padding: 0 3px;
    height: 15px;
}

.payment_mobile_carrier_area, .payment_mobile_carrier_mvno_area {
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_mobile_carrier_mvno_area {
    margin-top: 20px;
}
.payment_mobile_carrier_btn, .payment_mobile_carrier_btn_active {
    border: none;
    outline: none;
    height: 35px;
    width: 60px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
    margin: 0 6px;
    cursor: pointer;
}
.payment_mobile_carrier_btn {
    background: #e2e2e2;
    color: #000000;
}
.payment_mobile_carrier_btn_active {
    background: #2B4AC4;
    color: #FFFFFF;
}

.payment_auth_success_content {
    height: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.payment_auth_success_content > article {
    height: 400px;
    display: flex;
    align-items: center;
}
/**
 * Version : 1.0
 * 파일명 : modal.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Modal {
    margin: 18% auto;
    width: 339px;
    height: 117px;
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;

    /* margin: 18% auto;
    width: 400px;
    height: 200px;
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 9999; */
}
.Overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.1);
}
.modal_okBtn,
.modal_cancelBtn {
padding: 10px 30px;
color: white;
border: none;
margin-left: 2px;
margin-right: 2px;
font-weight: bold;
border-radius: 5px;
}
.modal_okBtn {
background: blue;
}
.modal_okBtn:hover {
background: #4169e1;
}
.modal_cancelBtn {
background: gray;
}
.modal_cancelBtn:hover {
background: #a9a9a9;
}
/**
 * Version : 1.0
 * 파일명 : record.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 녹화파일 CSS
 * 수정일자 :  2022-01-13
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.record_Div {
  position: relative;
  left: 40px;
  width: 1404px;
}
.record_header_Div {
  font-size: 28px;
  font-weight: bold;
  color: blue;
  margin-top: 24px;
  width: 1404px;
  height: 56px;
}

.record_header_title {
  color: #2b4ac4;
}

.record_content_Div {
  width: 1404px;
  height: 844px;
  max-height: 844px;
  margin-top: 14px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}
.fileItem_Timeline {
  float: left;
  width: 312px;
  height: 176px;
  /* margin-right: 24px; */
  margin-top: 20px;
  border-radius: 16px;
}

.dateLine_Timeline {
  width: 100%;
  float: left;
  margin-top: 15px;
}

.record_date_timeline {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-size: 18px;
  margin: 15px 0px;
}
.groupRecord_date_timeline {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-size: 18px;
  margin: 15px 0px;
}
.record_date_timeline::after {
  content: "";
  flex-grow: 1;
  margin: 0px 16px 0px 16px;
  background: #dbe0f5;
  height: 2px;
  font-size: 0px;
  line-height: 0px;
}
.groupRecord_date_timeline::after {
  content: "";
  flex-grow: 1;
  margin: 0px 16px 0px 16px;
  background: #dfe1e5;
  height: 2px;
  font-size: 0px;
  line-height: 0px;
}

.record_table {
  /* width: 1404px; */
  width: 1330px;
  max-height: 736px;
  /* margin: 0px 37px;
  padding-top: 32px; */
  overflow: hidden;
}

.record_thead_tr {
  font-size: 16px;
  font-weight: bold;
  height: 56px;
  background: white;
  color: #3b3f4c;
}
.record_thead_tr th {
  text-align: left;
}
.record_tbody_tr {
  font-size: 14px;
  height: 56px;
  background: rgba(255, 255, 255, 0.6);
}

.record_table_input:focus {
  outline: none;
}

.record_table_leftItem {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.record_table_rightItem {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.record_searchText {
  border: none;
  border-radius: 8px;
  width: 280px;
  height: 36px;
  background: #ffffffbe;
}
.record_searchText:active,
.record_searchText:focus {
  outline: none;
}
.record_searchBtn {
  color: white;
  border-radius: 8px;
  background: #2b4ac4;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  width: 64px;
  height: 36px;
  font-weight: bold;
}
.groupRecord_searchBtn {
  color: white;
  border-radius: 8px;
  background: #666b82;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  width: 64px;
  height: 36px;
  font-weight: bold;
}
.record_searchBtn:active,
.groupRecord_searchBtn:active,
.record_searchBtn:focus,
.groupRecord_searchBtn:focus {
  outline: none;
}

.record_LoadingBackground {
  background: rgba(255, 255, 255, 0.6);
  z-index: 900;
  position: absolute;
  width: 1404px;
  height: 845px;
  margin-top: 50px;
  border-radius: 15px;
}

.record_groupName {
  border-radius: 6px;
  border: 1px solid;
  width: auto;
  display: inline-block;
  margin: 2px;
  font-size: 12px;
  padding: 2px 10px;
  color: white;
  height: 18px;
}

.record_card_undefined {
  border: 1px solid #6e85d7;
  background: #f8f9fd;
}
.record_card_span_undefined {
  border: 1px solid #6e85d7;
}
.groupRecord_card_span {
  border: 1px solid #9b9faf;
}
.record_card_title_undefined {
  color: #2b4ac4;
}
.record_card_groupNM_undefined {
  color: #2b4ac4;
}

.record_card_red {
  border: 1px solid #de8799;
  background: #fbf0f2;
}
.record_card_span_red {
  border: 1px solid #de8799;
}
.record_card_title_red {
  color: #c63353;
}
.record_card_groupNM_red {
  color: #c63353;
}

.record_card_orange {
  border: 1px solid #d78e71;
  background: #fbf3ef;
}
.record_card_span_orange {
  border: 1px solid #d78e71;
}
.record_card_title_orange {
  color: #b24e27;
}
.record_card_groupNM_orange {
  color: #b24e27;
}

.record_card_yellow {
  border: 1px solid #cdb14c;
  background: #fbf9f2;
}
.record_card_span_yellow {
  border: 1px solid #cdb14c;
}
.record_card_title_yellow {
  color: #806a1c;
}
.record_card_groupNM_yellow {
  color: #806a1c;
}

.record_card_bean {
  border: 1px solid #44cb6a;
  background: #f0fbf3;
}
.record_card_span_bean {
  border: 1px solid #44cb6a;
}
.record_card_title_bean {
  color: #104920;
}
.record_card_groupNM_bean {
  color: #239d45;
}

.record_card_green {
  border: 1px solid #44cb6a;
  background: #f0fbf3;
}
.record_card_span_green {
  border: 1px solid #44cb6a;
}
.record_card_title_green {
  color: #104920;
}
.record_card_groupNM_green {
  color: #239d45;
}

.record_card_mint {
  border: 1px solid #39c8a9;
  background: #f2fbf9;
}
.record_card_span_mint {
  border: 1px solid #39c8a9;
}
.record_card_title_mint {
  color: #1b7a65;
}
.record_card_groupNM_mint {
  color: #229a80;
}

.record_card_pine {
  border: 1px solid #4faace;
  background: #f3f9fb;
}
.record_card_span_pine {
  border: 1px solid #4faace;
}
.record_card_title_pine {
  color: #217495;
}
.record_card_groupNM_pine {
  color: #2993bc;
}

.record_card_blue {
  border: 1px solid #6e85d7;
  background: #f8f9fd;
}
.record_card_span_blue {
  border: 1px solid #6e85d7;
}
.record_card_title_blue {
  color: #2b4ac4;
}
.record_card_groupNM_blue {
  color: #2b4ac4;
}

.record_card_pink {
  border: 1px solid #cb86dd;
  background: #fbf5fc;
}
.record_card_span_pink {
  border: 1px solid #cb86dd;
}
.record_card_title_pink {
  color: #661b7a;
}
.record_card_groupNM_pink {
  color: #a836c7;
}

.record_card_purple {
  border: 1px solid #9479da;
  background: #faf9fd;
}
.record_card_span_purple {
  border: 1px solid #9479da;
}
.record_card_title_purple {
  color: #46239f;
}
.record_card_groupNM_purple {
  color: #7855d0;
}

.record_card_black {
  border: 1px solid #9b9faf;
  background: #f6f6f8;
}
.record_card_span_black {
  border: 1px solid #9b9faf;
}
.record_card_title_black {
  color: #3e3e4f;
}
.record_card_groupNM_black {
  color: #3b3f4c;
}

/* .release + label span {
  background-image: url("../check.png");
  background: #848caf;
  border: 1px solid #2B4AC4;
  background-size: cover;
}
.checked + label span {
  background-image: url("../check.png");
  background: #2B4AC4;
  border: 1px solid #2B4AC4;
  background-size: cover;
} */

.Rec_Chk:checked + label span,
.Rec_Chk_ALL:checked + label span {
  background: #2b4ac4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAARRJREFUOBFjVHA4L8DF9bWfkeF/AAMDowADkeA/A8OCb9+4C1mAmhcwMjD4AzUTqRWiDKg6gZvzKyMTRDNJehGKGf/7MyF45LAYBUgyoDxNkWFijSYDLzcz3DaiDWgtVGWI9ZdicLYUYtBQ4iHNAJBmfxcxsKaNe14xnL78EdMAkLPmteswlKUqwiVBDHTN1f23UeRZYDyQs8z0+MGYj4eFoQaokJBmkF5Gba8jwDQBAcganr78ySAtzg6WADkb3WaYHpRABCkCKQYBYjSD1KEYABJANgSfzSC1IIDiBYgQhJQSY2d49uonshBWNoYLYKqI0czwn+EjEzAEN8I0kUFvYAJmyQSwIUDTSDLgP8PCr9+5CwBc3l2Mw0Gk2wAAAABJRU5ErkJggg==);
  border: 1px solid #2b4ac4;
  background-size: cover;
}
.Rec_Chk + label,
Rec_Chk_ALL + label {
  width: 20px;
  height: 20px;
}
.Rec_Chk + label span,
.Rec_Chk_ALL + label span {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: none;
  border-radius: 3px;
  background-size: cover;
}

.record_tooltip {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 343px;
  height: 62px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  inset: 0px auto auto 0px;
  -webkit-transform: translate(134px, 155px);
          transform: translate(134px, 155px);
  align-items: center;
  justify-content: center;
}
.record_tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 48px;
}

/**
 * Version : 1.0
 * 파일명 : schedule.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 스케줄 CSS
 * 수정일자 : 2022-06-14
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 선택 area 수정
*/
.scheduleModalBtn {
  padding: 8px 16px 10px;
  border-radius: 4px;
  background-color: #2b4ac4;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleModalLabel {
  width: 70px;
  font-weight: bold;
  padding-right: 16px;
  display: inline-block;
  text-align: right;
  color: #1f378e;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}
.scheduleModalLabel_History {
  width: 70px;
  font-weight: bold;
  padding-right: 16px;
  display: inline-block;
  text-align: right;
  color: #3e3e4f;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}

.scheduleModalContent {
  display: inline-block;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.scheduleModalDiv {
  margin: 1rem auto;
}
.scheduleModalInput {
  font-size: 18px;
  width: 17rem;
}
/* .scheduleDetail .MuiDialog-paperWidthXs {
  max-width: 368px;
} */
/*
  *   &initial
  */
.initialCalendar {
  width: 70%;
  /* height: 100vh; */
  float: left;
  padding: 25px;
  /* padding-left: 30px; */
  border-right: 1px solid #000;
  /* display: inline-block; */
}
.initialSidebar {
  width: 25%;
  /* float: right; */
  padding: 10px;
  padding-top: 60px;
  /* height: 500px; */
  display: inline-block;
}
.initial_btnArea {
  text-align: center;
  padding-top: 35vh;
}
.initial_btn {
  width: 25vh;
  height: 13vh;
  background-color: aquamarine;
  margin: 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
}
#initialResSchedule {
  /* float: right; */
  /* position:absolute !important; */
  left: unset !important;
  bottom: unset !important;
  right: 0px !important;
  top: 0px !important;
  margin-top: 8.7vh;
}
#initialResSchedule .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0) !important;
}
#initialResSchedule .MuiDialog-paper {
  margin: 0 !important;
}
#initialResSchedule .MuiDialog-paperWidthXl {
  width: 183.5vh !important;
}
#initialResSchedule .MuiDialog-scrollPaper {
  /* display: block; */
  float: right;
}
#initialMeetingNow .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
  inset: 77px 0px 0px 300px !important;
}
.initial_btnArea {
  text-align: center;
  padding-top: 35vh;
}
.initial_btn {
  width: 25vh;
  height: 13vh;
  background-color: aquamarine;
  margin: 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
}
.input_participant {
  margin-top: 30px;
  flex: 1 1;
  text-align: center;
  width: 320px;
  font-size: 1.25rem;
  outline: none;
  border: none;
  border-bottom: 1px solid #0602d7;
}
.participant_List {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin: 0 36px;
  max-height: 96px;
  overflow-y: auto;
}
.participant_List > ul {
  padding: 0;
}
.participant_List > ul > li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  padding: 5px 0px 9px;
}
/* .participant_List > ul > li > button {
  float: right;
  line-height: 20px;
  padding: 0;
} */
.border_bottom {
  border-bottom: 2px solid blue;
}
.meetingNow .btnArea {
  display: flex;
  justify-content: center;
}
.meetingNow .btnArea .startBtn {
  width: 312px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin: 24px 0px;
  cursor: pointer;
}
.btnArea .startBtn {
  width: 312px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin: 24px 0px;
  cursor: pointer;
}
.res_schedule button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.res_schedule > section {
  width: 90%;
  /* max-width: 450px; */
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  /* animation: modal-show .3s; */
  overflow: hidden;
}
.res_schedule > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  /* background-color: #f1f1f1; */
  font-weight: 700;
}
.res_schedule > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.res_schedule > section > main {
  padding: 16px;
  /* border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6; */
}
.res_schedule > section > main > div > div {
  clear: both;
  margin: 20px auto;
  text-align: center;
  /* height:50px; */
  /* width:350px; */
}
.res_schedule .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.res_schedule .inputValue {
  width: 250px;
  height: 30px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}
.res_schedule .inputDate {
  width: 250px;
  height: 30px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}
.res_schedule .areaValue {
  width: 252px;
  height: 50px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}

.res_schedule > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.res_schedule > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}

.res_schedule > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #3985c9;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  height: 40px;
}
.resIcon {
  margin-right: 12px;
  vertical-align: middle;
}
.resIcon_content {
  margin-right: 12px;
  vertical-align: top;
}

.durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 315px;
}
.durationItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: center;
  height: 36px;
  width: 120px;
  color: #3e3e4f;
  line-height: 36px;
}
.bookmark_btn_meeting {
  width: 88px;
  height: 36px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px 0px 0px 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b9ddf;
  border: none;
  cursor: pointer;
}

.bookmark_btn_group {
  width: 74px;
  height: 36px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 0px 8px 8px 0px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b9ddf;
  border: none;
  cursor: pointer;
}

.bookmark_btn_active {
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
}

.myCalendar .exchange_btn,
.mybookmark.exchange_btn {
  background-color: #3b3f4c;
  border-radius: 10px;
  border: none;
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
.groupCalendar .exchange_btn,
.groupBookmark.exchange_btn {
  background-color: #666b82;
  border-radius: 10px;
  border: none;
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
.bookmark_header {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.group_bookmark_header {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3b3f4c;
}

.bookmark_item {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #2b4ac4;
}

.group_bookmark_item_red {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d4667e;
}
.group_bookmark_item_orange {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #c44308;
}
.group_bookmark_item_yellow {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d49500;
}
.group_bookmark_item_bean {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #87ad26;
}
.group_bookmark_item_green {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #28b750;
}
.group_bookmark_item_mint {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #6cd6bf;
}
.group_bookmark_item_pine {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #75bdd9;
}
.group_bookmark_item_pink {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d6a0e5;
}
.group_bookmark_item_purple {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #a994e1;
}
.group_bookmark_item_black {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #84889b;
}

.bookmark_item_title {
  color: #2b4ac4;
}
.group_bookmark_item_title_red {
  color: #861e35;
}
.group_bookmark_item_title_orange {
  color: #732c0e;
}
.group_bookmark_item_title_yellow {
  color: #7d5b14;
}
.group_bookmark_item_title_bean {
  color: #4a5f15;
}
.group_bookmark_item_title_green {
  color: #104920;
}
.group_bookmark_item_title_mint {
  color: #10483b;
}
.group_bookmark_item_title_pine {
  color: #134457;
}
.group_bookmark_item_title_pink {
  color: #3d1448;
}
.group_bookmark_item_title_purple {
  color: #2d1665;
}
.group_bookmark_item_title_black {
  color: #000000;
}

.bookmark_item_content {
  color: #2b4ac4;
}
.group_bookmark_item_content_red {
  color: #d0778a;
}
.group_bookmark_item_content_orange {
  color: #d78e71;
}
.group_bookmark_item_content_yellow {
  color: #cdb14c;
}
.group_bookmark_item_content_bean {
  color: #aed057;
}
.group_bookmark_item_content_green {
  color: #28b750;
}
.group_bookmark_item_content_mint {
  color: #27b395;
}
.group_bookmark_item_content_pine {
  color: #4faace;
}
.group_bookmark_item_content_pink {
  color: #bd66d4;
}
.group_bookmark_item_content_purple {
  color: #7855d0;
}
.group_bookmark_item_content_black {
  color: #84889b;
}

.bookmarkResultPopup {
  margin: 14% auto;
  padding: 32px 0 40px;
  width: 320px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.bookmarkResultPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.bookmarkDeletePopup {
  margin: 14% auto;
  /* padding: 32px 0 40px; */
  width: 480px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: -5px 3px 20px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.bookmarkDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.meetingNowAddressPopup {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 10px;
  top: 340px;
  overflow-y: auto;
}
.meetingNowAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.meetingNowAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.noLicensePopup {
  margin: 13% auto;
  padding: 32px 32px 40px;
  width: 576px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.noLicensePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.noLicensePopup .popupTitle {
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.noLicensePopup .popupContents {
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin-top: 32px;
}
.noLicensePopup .btnArea {
  margin-top: 40px;
}
.noLicensePopup .btnOK {
  width: 136px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
}
.noLicensePopup .btnGoLicense {
  width: 208px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
  margin-left: 16px;
}
/* .meetingNow {
  text-align: center;
  margin: 4rem;
} */
.meetingNow {
  /* transition: all 0.5s ease-out; */
  width: 384px;
  margin-top: 137px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 30px;
}
.meetingNowResult {
  margin-top: 90px;
  /* transition: all 0.8s ease-in 0s; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 24px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 30px;
  width: 490px;
}
.meetingNow .meetingNow_header {
  display: flex;
  justify-content: space-between;
}
.meetingNow .meetingNow_header_title {
  display: flex;
  text-align: left;
  color: #2b4ac4;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  margin-top: 24px;
  margin-left: 32px;
}
.meetingNow .meetingNow_header_closeIcon {
  display: flex;
  margin-top: 8px;
  margin-right: 12px;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
.meetingNow .meetingNow_durationArea {
  display: flex;
  flex-direction: column;
  margin: 25px 32px 0 32px;
}
.meetingNow .meetingNow_durationArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_durationArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_durationArea_contents {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.meetingNow .meetingNow_durationArea_selectBox_label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.meetingNow .meetingNow_durationArea_selectBox {
  display: flex;
  justify-content: center;
  margin-left: 8px;
}
.meetingNow .meetingNow_recordArea {
  display: flex;
  flex-direction: column;
  margin: 25px 32px 0 32px;
}
.meetingNow .meetingNow_recordArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_recordArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 6px;
}
.meetingNow .meetingNow_recordArea_contents {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.meetingNow .meetingNow-record-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 36px;
  margin-left: 20px;
  border-radius: 9px;
  border: solid 1px #afb3bf;
  background-color: #dfe1e5;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9faf;
}
.meetingNow .meetingNow-record-btn-active {
  border: solid 1px #2b4ac4;
  background-color: #2b4ac4;
  color: #fff;
}
/* 즉시 미팅 */
.meetingNow .selectHour {
  height: 34px;
  width: 94px;
  padding: 0 8px 0 16px;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meetingNow_durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 36px;
}
.meetingNow_durationItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: left;
  padding-left: 16px;
  height: 32px;
  width: 120px;
  color: #3e3e4f;
  line-height: 32px;
}
.meetingNow .meetingNow_platformArea {
  display: flex;
  flex-direction: column;
  margin: 16px 32px 0 32px;
}
.meetingNow .meetingNow_platformArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_platformArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_platformArea_contents {
  margin-top: 8px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-evenly;
}
.meetingNow .meetingNow_platformArea_webexBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_webexBtn {
  border: 1px solid #3672ff;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_zoomBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_zoomBtn {
  border: 1px solid #2d8cff;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_chimeBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_chimeBtn {
  border: 1px solid #3ab5bb;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_pexipBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_pexipBtn {
  border: 1px solid #0a2136;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_selectBox {
  width: 240px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 8px;
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a3b1e5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.meetingNow .meetingNow_platformArea_contents .platformSelectBox {
  margin-top: 165px;
  width: 184px;
  height: 80px;
  padding: 16px 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  position: absolute;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(88px, 0fr));
}

.meetingNow .meetingNow_guestArea {
  display: flex;
  flex-direction: column;
  margin: 22px 32px 0 32px;
}
.meetingNow .meetingNow_guestArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_guestArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_guestArea_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.meetingNow .meetingNow_guestArea_input {
  width: 296px;
  height: 40px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetingNow .meetingNow_guestArea_input input {
  width: 242px;
  height: 18px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  outline: none;
  border: none;
  border-radius: 8px;
  text-align: center;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
}
.meetingNow .meetingNow_guestArea_input input::-webkit-input-placeholder {
  text-align: center;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #b8c3eb;
}
.meetingNow .meetingNow_guestArea_input input::placeholder {
  text-align: center;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #b8c3eb;
}
.meetingNow .participant_List .participantItem {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meetingNowResult-webex-meetingInfo {
  margin: 16px 0 0 69px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}
.meetingNowResult-webex-meetingInfo-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.meetingNowResult .link-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
}
.meetingNowResult .link-copy > img {
  width: 18px;
  height: 18px;
}
.meetingNowResult-webex-meetingInfo-label {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b5fbf;
  width: 55px;
  margin-right: 18px;
}
.meetingNowResult-webex-meetingInfo-value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #111;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 280px;
}

/**
 * Version : 1.0
 * 파일명 : sidebar.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 사이드바 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 라디오 버튼 영역 크기 수정
*/
.sidebar_adress_input {
  width: 100%;
  height: 20px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  font-size: 18px;
}
.sidebar_adress_btn {
  font-weight: bold;
  color: white;
  background-color: blue;
  width: 280px;
  height: 60px;
  border: none;
  border-radius: 15px !important;
  cursor: pointer;
  margin-top: 10px;
  margin: 0 auto;
  display: block;
}
.sidebar_adress_div {
  height: 740px;
}
.Sidebar_MeetingBtn {
  width: 392px;
  height: 126px;
  background-color: #2b4ac4;
  border-radius: 32px;
  margin: 24px 0;
  padding: 0 32px;
  /* width: 280px;
    height: 60px;
    margin-top: 30px !important;
    margin-bottom: 30px !important; */
}
.meetingNowBtn {
  width: 392px;
  height: 126px;
  background-color: #2b4ac4;
  border: none;
  border-radius: 32px;
  margin: 24px 0;
  padding: 0 32px;
  cursor: pointer;
}
.meetingNowBtn_red {
  background-color: #d4667e;
}
.meetingNowBtn_orange {
  background-color: #c44308;
}
.meetingNowBtn_yellow {
  background-color: #d49500;
}
.meetingNowBtn_bean {
  background-color: #87ad26;
}
.meetingNowBtn_green {
  background-color: #1b7c37;
}
.meetingNowBtn_mint {
  background-color: #229a80;
}
.meetingNowBtn_blue {
  background-color: #8b9ddf;
}
.meetingNowBtn_pine {
  background-color: #2688ae;
}
.meetingNowBtn_pink {
  background-color: #a836c7;
}
.meetingNowBtn_purple {
  background-color: #613eba;
}
.meetingNowBtn_black {
  background-color: #000000;
}

.sidebar_schedule {
  border-radius: 32px 32px 0 0;
  background-color: rgba(255, 255, 255, 0.32);
  /* margin-left: 20px;
    width: 360px;
    background: rgba(152, 215, 2216, 0.4);
    height: 75vh;
    overflow-y: auto;
    border-radius: 15px; */
}
.sidebar_schedule ::-webkit-scrollbar {
  width: 8px;
}
.sidebar_schedule ::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.sidebar_schedule ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.sidebar_toggleBtn {
  float: right;
  text-align: center;
  border: 1px solid;
  width: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  background: blue;
  border: none;
  color: white;
}
.sidebar_header {
  margin-left: 32px;
  margin-right: 32px;
  padding: 14px 0;
}
.sidebar_title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  display: inline;
  line-height: 40px;
}
.sidebar_title_timeline {
  color: #2b4ac4;
}
.groupSidebar_title_timeline {
  color: #3b3f4c;
}
.sidebar_title_history {
  color: #3e3e4f;
}
.sidebar_scheduleList {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.5);
  clear: both;
}
.timelineNoSchedule {
  width: 175px;
  height: 21px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 20px 7px 20px 24px;
  border-radius: 16px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  text-align: center;
  padding: 17px 84px 18px 85px;
}

.resSidebar {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: -5px 3px 20px #00000029;
  height: 954px;
  /* background: rgba(152, 215, 2216, 0.4);
    height: 100%; */
}
.resSidebar_margin {
  margin: 0 50px;
}
.resSidebar_top {
  padding: 22px 0px;
  font-weight: bold;
}
.resSidebar_title {
  display: inline;
  font: normal normal bold 20px/28px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.resSidebar_closeBtn {
  display: inline;
  float: right;
}
.resSidebar .resIcon {
  margin-right: 10px;
  vertical-align: middle;
}
.resSidebar .resIcon_content {
  margin-right: 10px;
  vertical-align: top;
}
.resSidebar .inputFileBtn {
  padding: 8px 16px 9px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  vertical-align: middle;
  line-height: 44px;
}
.resSidebar_input {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 47px;
  width: 344px;
  padding: 0 12px;
  border-bottom: 1px solid #ffffff;
}
.resSidebar_input:disabled {
  border: none;
  border-radius: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 344px;
  padding: 0 12px;
}
.resSidebar_input_participant {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 47px;
  width: 232px;
  padding: 0 12px;
  border-bottom: 1px solid #ffffff;
}
.resSidebar_btn_addParticipant {
  border: 1px solid #afb3bf;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  /* padding: 11px; */
  background: #dfe1e5 0% 0% no-repeat padding-box;
}
.resSidebar_btn_addParticipant_active {
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  color: #2b4ac4;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  /* padding: 11px; */
  background-color: #ffffff;
}
.resSidebar_startDate,
.resSidebar_endDate {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 208px;
  padding: 0 12px;
}
.resSidebar .startArea .react-datepicker-wrapper,
.resSidebar .endArea .react-datepicker-wrapper,
.resSidebar .startArea .react-datepicker__tab-loop,
.resSidebar .endArea .react-datepicker__tab-loop {
  display: inline-block;
}
.resSidebar .startArea .selectTimeActive,
.resSidebar .endArea .selectTimeActive {
  margin-left: 8px;
  width: 108px;
  padding: 10px 0 10px 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid #2b4ac4;
  display: inline-block;
}
.resSidebar .startArea .selectTime,
.resSidebar .endArea .selectTime {
  margin-left: 8px;
  width: 108px;
  padding: 10px 0 10px 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: inline-block;
}
.resSidebar .startArea .durationPopup,
.resSidebar .endArea .durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 4px;
  margin-left: 280px;
  z-index: 2;
}
.resSidebar .startArea .MuiInputBase-formControl,
.resSidebar .endArea .MuiInputBase-formControl {
  height: 48px;
  width: 124px;
}
.resSidebar .startArea .MuiInputBase-formControl div,
.resSidebar .endArea .MuiInputBase-formControl div {
  padding: 12px 15px;
}

/* 사이드바 데이트 피커 아이콘 */
.resSidebar .startArea .MuiIconButton-edgeEnd {
  position: absolute !important;
  right: 184px;
  top: 245px;
}
.resSidebar .endArea .MuiIconButton-edgeEnd {
  position: absolute !important;
  right: 184px;
  top: 301px;
}

.resSidebar_textArea {
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 10px;
  display: inline-block;
  padding: 12px;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
  min-height: 47px;
  width: 344px;
  resize: none;
}
.resSidebar .platformChkboxAreaActive {
  margin-top: 7px;
  margin-left: 40px;
  width: 252px;
  /* height: 56px; */
  height: 28px;
  display: grid;
  padding: 8px 58px;
  grid-gap: 0px 32px;
  /* align-content: center;
  justify-content: center; */
  grid-template-columns: repeat(auto-fill, minmax(110px, 0fr));
  /* background: #f8f9fd 0% 0% no-repeat padding-box; */
  background-color: #ffffff;
  border-radius: 10px;
}
.resSidebar .platformChkboxArea {
  display: none;
}
.recButtonOn {
  display: inline-block;
  width: 86px;
  height: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/24px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-right: 8px;
  padding: 8px 0 10px;
}
.recButtonOff {
  display: inline-block;
  width: 86px;
  height: 16px;
  background: #dfe1e5 0% 0% no-repeat padding-box;
  border: 1px solid #afb3bf;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/24px Spoqa Han Sans Neo;
  color: #9b9faf;
  margin-right: 8px;
  padding: 8px 0 10px;
}
.resSidebar input::-webkit-input-placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.resSidebar input::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.resSidebar input:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.resSidebar input:focus::-webkit-input-placeholder {
  color: #2b4ac4;
}
.resSidebar input:focus::placeholder {
  color: #2b4ac4;
}

.resSidebar textarea::-webkit-input-placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}

.resSidebar textarea::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.resSidebar textarea:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.resSidebar textarea:focus::-webkit-input-placeholder {
  color: #2b4ac4;
}
.resSidebar textarea:focus::placeholder {
  color: #2b4ac4;
}

.resSidebar_saveBtnActive {
  width: 360px;
  border: 1px solid #2b4ac4;
  background: #2b4ac4;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 24px 56px;
  box-shadow: 0px 6px 15px #00000029;
  cursor: pointer;
  /* width: 300px;
    border: 1px solid blue;
    background: blue;
    height: 35px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-top: 15px; */
}
.resSidebar_saveBtn {
  width: 360px;
  border: none;
  background: #84889b;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 32px 56px;
  box-shadow: none;
  cursor: default;
}
.resSidebar .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.sidebarAddressPopup {
  /* width: 296px; */
  width: 368px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  /* border-radius: 24px; */
  border-radius: 16px;
  margin: 8px 40px;
  overflow-y: auto;
  /* right: 64px; */
}
.sidebarAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 96px;
  margin-right: 10px;
}
.sidebarAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.favoriteAddressPopup {
  /* width: 296px; */
  width: 330px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  /* border-radius: 24px; */
  border-radius: 16px;
  margin: 8px 40px;
  overflow-y: auto;
  /* right: 64px; */
}
.favoriteAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 96px;
  margin-right: 10px;
}
.favoriteAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
/*
   *    &participantSidebar
   */
.participantSidebar {
  height: 954px;
  display: flex;
  clear: both;
  flex-direction: column;
  border: none;
  border-radius: 0 32px 32px 0;
  box-shadow: 12px 12px 30px #0000001a;
  background-color: rgb(255, 255, 255);
  transition: 0.8s ease;
}
.participantTab {
  width: 114px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-weight: bold;
  color: #d1d3da;
  border-bottom: 2px solid #d1d3da;
}
.activeParticipantTab {
  color: #2b4ac4;
  border-bottom: 2px solid #2b4ac4;
}
.participantList {
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 296px;
  padding-left: 16px;
  margin-top: 10px;
  margin-left: 44px;
  max-height: 150px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}
.resSidebar .participantList {
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 344px;
  padding-left: 24px;
  padding-right: 0px;
  margin-top: 8px;
  margin-left: 40px;
  max-height: 104px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}

.resSidebar .participantList li,
.resSidebar .fileList li {
  height: 32px;
}
.resSidebar .participantList .participantEmail {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}
.bookmarkSidebar .participantList .participantEmail {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
/* .resSidebar .participantList button {
  width: 10px;
  height: 10px;
  float: right;
} */

.resSidebar .fileList {
  border-radius: 10px;
  width: 344px;
  padding-left: 16px;
  padding-right: 0px;
  margin-top: 8px;
  margin-left: 40px;
  max-height: 80px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}

/* .resSidebar .fileList button {
  width: 10px;
  height: 10px;
  float: right;
} */

.bookmarkSidebar {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: -5px 3px 20px #00000029;
  height: 954px;
}
.bookmarkSidebar_margin {
  margin: 0 40px;
}
.bookmarkSidebar_top {
  padding: 32px 0px 24px;
}
.bookmarkSidebar_title {
  display: inline;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.bookmarkSidebar_expln {
  display: inline;
  font: normal normal normal 14px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.bookmarkSidebar_closeBtn {
  display: inline;
  float: right;
  cursor: pointer;
  /* position: absolute;
  top: 24px;
  right: 24px; */
}
.bookmarkSidebar_input {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 308px;
  padding: 0 10px;
}
.bookmarkSidebar_input_participant {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 199px;
  padding: 0 10px;
}
.bookmarkSidebar_btn_addParticipant {
  border: 1px solid #afb3bf;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  background: #dfe1e5 0% 0% no-repeat padding-box;
}
.bookmarkSidebar_btn_addParticipant_active {
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  color: #2b4ac4;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  background-color: #ffffff;
}
.bookmarkSidebar_textArea {
  border: none;
  border-radius: 10px;
  display: inline-block;
  padding: 12px 24px 16px 16px;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
  min-height: 69px;
  width: 288px;
  resize: none;
}
.bookmarkSidebar input::-webkit-input-placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.bookmarkSidebar input::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.bookmarkSidebar input:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.bookmarkSidebar input:focus::-webkit-input-placeholder {
  color: #2b4ac4;
}
.bookmarkSidebar input:focus::placeholder {
  color: #2b4ac4;
}

.bookmarkSidebar textarea::-webkit-input-placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}

.bookmarkSidebar textarea::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.bookmarkSidebar textarea:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.bookmarkSidebar textarea:focus::-webkit-input-placeholder {
  color: #2b4ac4;
}
.bookmarkSidebar textarea:focus::placeholder {
  color: #2b4ac4;
}

.bookmarkSidebar_saveBtnActive {
  width: 360px;
  border: 1px solid #2b4ac4;
  background: #2b4ac4;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 24px 56px;
  box-shadow: 0px 6px 15px #00000029;
  cursor: pointer;
}
.bookmarkSidebar_saveBtn {
  width: 360px;
  border: none;
  background: #84889b;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 32px 56px;
  box-shadow: none;
  cursor: default;
}
.bookmarkSidebar .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.recTooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 376px;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  margin-top: 10px;
  right: 240px;
}
.recTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 40px;
}
.fileTooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  margin-top: 10px;
  right: 240px;
}
.fileTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 15px;
}

/**
 * Version : 1.0
 * 파일명 : alert.css
 * 작성일자 : 2021-11-01
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 : 2022-03-25
 * 수정자 : 권도훈
 * 수정내역 : 사용자 인증 팝업 분리
*/
.payment {
    margin: 20% auto;
    width: 339px;
    /* min-height: 117px; */
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
}
.paymentOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 800;
}
.modal_okBtn,
.modal_cancelBtn {
padding: 10px 30px;
color: white;
border: none;
margin-left: 2px;
margin-right: 2px;
font-weight: bold;
border-radius: 5px;
}
.modal_okBtn {
background: blue;
}
.modal_okBtn:hover {
background: #4169e1;
}
.modal_cancelBtn {
background: gray;
}
.modal_cancelBtn:hover {
background: #a9a9a9;
}

.product_card {
    width: 320px;
    height: 232px;
    background: #FFFFFF;
    box-shadow: 5px 5px 16px #00000029;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_card_title {
    width: 294px;
    height: 72px;
    border-bottom: 1px solid #AFB3BF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #3E3E4F;
}
.product_card_amount {
    margin-top: 16px;
    font-size: 32px;
    /* font-weight: bold; */
    color: #2B4AC4;
}
.product_card_coment {
    margin-top: 13px;
    font-size: 20px;
    font-weight: bold;
    color: #3E3E4F;
}
.product_card_vat {
    margin-top: 7px;
    font-size: 14px;
    font-weight: bold;
    color: #AFB3BF;
}
.product_card_count {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.product_card_counter{
    width: 104px;
    height: 49px;
    border: 1px solid #9B9FAF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product_card_count_num {
    text-align: center;
    width: 69px;
}
.product_card_count_btn {
    display: flex;
    flex-direction: column;
}
.product_card_count_btn div {
    width: 35px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #9B9FAF;
}
.product_card_count_label {
    font-size: 16px;
    font-weight: bold;
    color: #3E3E4F;
    margin-left: 8px;
}
.product_card_payBtn {
    margin-top: 24px;
    width: 146px;
    height: 48px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    outline: none;
    background: #2B4AC4;
    border-radius: 12px;
}

.profile_popup_pay_body {
    width: 816px;
    /* max-height: 648px; */
    height: 648px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile_popup_pay_title {
    width: 816px;
    height: 40px;
    background: #F8F9FD;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #8B5FBF;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.profile_popup_pay_title font {
    margin-left: 16px;
}

.profile_popup_pay_centerBorder {
    /* height: 648px; */
    max-height: 648px;
    border: 2px dashed #DFE1E5;
}

.profile_popup_payment_Title,
.profile_popup_selectedProduct_Title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
}

.profile_popup_payment_Title {
    color: #433D4A;
}

.profile_popup_selectedProduct_Title {
    color: #2B4AC4;
}

.profile_popup_selectBox_option {
    font-size: 14px;
    padding: 4px 0;
    color: #3E3E4F;
    outline: none;
    border: none;
}
/**
 * Version : 1.0
 * 파일명 : profile.css
 * 작성일자 : 2021-11-16
 * 작성자 : 권도훈
 * 설명 : 프로필 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 타임존, 언어 툴팁 위치 수정
*/
.profile_addCardPopup,
.profile_editCardPopup {
  margin: 11% auto;
  width: 488px;
  height: 575px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 800;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.profile_addCardPopup_header {
  margin: 0 24px 32px 24px;
  padding-top: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_addCardPopup_header img {
  width: 24px;
  height: 24px;
}
.profile_addCardPopup_body {
  margin: 10px 24px;
  font-size: 14px;
}
.profile_addCardPopup_text {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-bottom: 16px;
}
.profile_addCardPopup_input {
  font-size: 18px;
  color: #3e3e4f;
  border: none;
  background: none;
  text-align: center;
}
.profile_cardNum_input {
  width: 56px;
  height: 30px;
  margin-bottom: 19px;
}
.profile_date_input {
  width: 48px;
  height: 30px;
  margin-bottom: 19px;
}
.profile_password_input {
  width: 149px;
  height: 24px;
  margin-bottom: 19px;
  text-align: right !important;
}
.profile_registrationNum_input {
  width: 80%;
  height: 24px;
  margin-bottom: 19px;
  text-align: right !important;
}
.profile_cardName_input {
  height: 24px;
  width: 300px;
  text-align: right;
  margin-bottom: 19px;
}
.profile_addCardPopup_cardNum {
  height: 78px;
}
.profile_addCardPopup_cardNumDiv {
  float: right;
}
.profile_cardNum_input::-webkit-input-placeholder, .profile_date_input::-webkit-input-placeholder, .profile_cardName_input::-webkit-input-placeholder {
  color: #d2d2d2;
}
.profile_cardNum_input::placeholder,
.profile_date_input::placeholder,
.profile_cardName_input::placeholder {
  color: #d2d2d2;
}
.profile_cardNum_input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.profile_addCardPopup_cardLogo {
  margin: 16px 0px 15px 24px;
}
/* .profile_addCardPopup_cardNum_title {
  font-weight: bold;
  padding: 5px 0px;
}
.profile_addCardPopup_cardNum_title img {
  width: 68px;
  height: 15px;
} */

.profile_addCardPopup_cardInfo {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
}

.profile_addCardPopup_div {
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  /* margin: 0px 4px;
  border: none;
  font-size: 16px; */
}
.profile_addCardPopup_dateDiv,
.profile_addCardPopup_passowrdDiv {
  width: 216px;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile_addCardPopup_cardType {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  padding: 16px;
  margin-bottom: 8px;
}
.profile_addCardPopup_checkbox {
  display: flex;
  align-items: center;
}
/* .proife_addCardPopup_cardNum {
  width: 55px;
  text-align: center;
}
.proife_addCardPopup_date {
  width: 25px;
  text-align: center;
}
.proife_addCardPopup_cardName {
  width: 100%;
  text-align: right;
  padding: 2px 0px;
} */
.profile_addCardPopup_input:focus {
  outline: none;
}

.profile_addCardPopup_infoTitle {
  margin: 16px 0px 15px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
}
.profile_addCardPopup_infoInput {
  text-align: right;
}

.profile_addCardPopup_btnArea {
  display: flex;
  margin-top: 32px;
}

.profile_addCardPopup_Btn {
  text-align: center;
  width: 216px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 8px;
}
.profile_addCardPopup_ok {
  background: #2b4ac4;
}
.profile_addCardPopup_cancle {
  background: #d7263d;
}
.profile_addCardPopup_ok:hover {
  background: #3654cc;
}
.profile_addCardPopup_cancle:hover {
  background: #df3f54;
}

.profile_addCardPopup_Btn:disabled {
  color: #000000;
  background: #afb3bf;
  cursor: default;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 40px;
  height: 954px;
}
.profile_content_header {
  font-size: 24px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 24px;
  margin-bottom: 32px;
}
.profile_content_body {
  /* margin: 32px 40px; */
  width: 932px;
  height: 824px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 32px;
  position: relative;
}
.porfile_support_body {
  background: rgba(255, 255, 255, 0.5);
  margin: 0px 0px 32px 0px;
  width: 932px;
  height: 824px;
  border: none;
  bottom: 0px;
  /* border-top-left-radius: 32px;
   border-top-right-radius: 32px; */
  border-radius: 32px;
  position: relative;
}
.profile_profile_content_child {
  position: relative;
  height: 100%;
  margin: 0 32px;
}
.profile_loading {
  position: absolute;
}
/* .profile_profile_content_child,
.profile_loading {
  width: 93%;
} */

.profile_content_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 16px; */
  margin-bottom: 4px;
  height: 36px;
}
.profile_content_title.pay {
  margin-bottom: 16px;
}

.pf-content-title-txt {
  font-family: Spoqa Han Sans Neo;
  font-weight: bold;
  letter-spacing: 0px;
}
.pf-content-title-txt.title {
  font-size: 16px;
  color: #3e3e4f;
}
.pf-content-title-txt.comment {
  font-size: 14px;
  color: #666b82;
  margin-left: 8px;
}

.profile_content_whiteBtn {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_red {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #a02741;
  border-radius: 8px;
  color: #a02741;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_orange {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #c44308;
  border-radius: 8px;
  color: #c44308;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_yellow {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #c68a00;
  border-radius: 8px;
  color: #c68a00;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_bean {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #749420;
  border-radius: 8px;
  color: #749420;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_green {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #1b7c37;
  border-radius: 8px;
  color: #1b7c37;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_mint {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #229a80;
  border-radius: 8px;
  color: #229a80;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_pine {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2993bc;
  border-radius: 8px;
  color: #2993bc;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_pink {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #a836c7;
  border-radius: 8px;
  color: #a836c7;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_purple {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #7855d0;
  border-radius: 8px;
  color: #7855d0;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_black {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #666b82;
  border-radius: 8px;
  color: #666b82;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}

.profile_content_purpleBtn {
  width: 64px;
  height: 36px;
  background: #ecedf7;
  color: #bfc1e5;
  font-size: 14px;
  font-weight: bold;
  border: none;
}
.profile_content_purpleBtn:active {
  outline: none;
}
.profile_content_purpleBtn:disabled {
  background: #8b5fbf;
  color: #ffffff;
}

.profile_content_cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 740px;
}

.profile_content_card {
  width: 272px;
  height: 160px;
  border-radius: 16px;
  /* background: #a3b1e5; */
  margin-right: 26px;
  margin-bottom: 26px;
}

.profile_content_card section {
  height: 113px;
  padding: 18px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 안에 내용이 없어서 그런지 경고 떠서 커밋이 안되가지고 잠시 주석처리했습니다. */
/* .profile_content_cardTitle {
} */
.profile_content_cardTitle,
.profile_content_cardInput {
  /* color: #3e3e4f; */
  font-size: 22px;
  background: none;
  border: none;
  outline: none;
}
.profile_content_cardTitle {
  width: 216px;
}
.profile_content_cardInput {
  width: 200px;
}
.profile_content_cardTitleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_content_cardEditDiv {
  display: flex;
  flex-direction: row;
  width: 240px;
  align-items: center;
  border-bottom: 1px solid #2b4ac4;
}
.profile_content_cardEditBtn {
  font-size: 14px;
  font-weight: bold;
  color: #b8c3eb;
  margin-left: 8px;
}

.profile_content_cardLogo {
  width: 108px;
  height: 14px;
}
.profile_content_cardInfo {
  height: 18px;
  /* color: #5b74d2; */
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile_content_addCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  color: #4865cc;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile_content_addCard div {
  margin-top: 24px;
}

.profile_content_payTable,
.profile_content_licenseTable {
  max-height: 696px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}
.profile_content_payTable_header,
.profile_content_licenseTable_header {
  height: 56px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  text-align: center;
  background: #f1f2fa;
  font-size: 16px;
}
.profile_content_payTable_header {
  background: #f8f9fd;
  font-size: 14px;
}
/* .profile_content_licenseTable_header {
  
  
} */

.profile_content_payTable_body,
.profile_content_licenseTable_body {
  height: 52px;
  background: #f8f9fd;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  color: #3e3e4f;
  text-align: center;
}

.profile_content_FreeLicense {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  margin-left: 8px;
}

.profile_content_payTable_body button {
  color: #cad2f0;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
}

.profile_content_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 6px;
  margin-bottom: 22px;
}
.profile_content_paging div {
  margin-right: 40px;
}
.profile_content_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.profile_licenseSettingPopup_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  position: absolute;
  bottom: 0;
}
.profile_licenseSettingPopup_paging div {
  margin-right: 40px;
}
.profile_licenseSettingPopup_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.profile_content_license,
.profile_content_paylog {
  height: 758px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_sidebar {
  width: 472px;
  height: 954px;
  background: rgba(255, 255, 255, 0.5);
  padding: 0 36px;
}

.profile_sidebar_header {
  margin-top: 24px;
  color: #2b4ac4;
  font-size: 24px;
  font-weight: bold;
}

.profile_sidebar_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile_sidebar_avatar {
  width: 104px;
  height: 104px;
  border-radius: 999px;
  margin-top: 32px;
}

.profile_sidebar_info {
  margin-top: 40px;
  margin-bottom: 48px;
  color: #3e3e4f;
  font-weight: bold;
  text-align: center;
}
.profile_sidebar_name {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  font-size: 20px;
}
.profile_sidebar_nameEditor {
  width: 250px;
  height: 24px;
  background: none;
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b8c3eb;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
}
.profile_sidebar_email {
  margin-top: 16px;
  font-size: 16px;
}

.profile_sidebar_btn_side,
.profile_sidebar_btn_center {
  width: 344px;
  height: 64px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  margin-bottom: 16px;
}

.profile_sidebar_btn_side {
  color: #8b5fbf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
}

.profile_sidebar_btn_side_support {
  width: 344px;
  height: 64px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  margin-bottom: 16px;

  color: #8b5fbf;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.profile_sidebar_btn_support {
  margin-left: 24px;
}
.profile_sidebar_btn_support_sub {
  margin-left: 23px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b94a3;
}

.profile_sidebar_btn_left {
  margin-left: 24px;
}
.profile_sidebar_btn_right {
  margin-right: 32px;
}

.profile_sidebar_btn_center {
  background: rgb(43, 74, 196, 0.2);
  color: #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_sidebar_img {
  bottom: 51px;
}

.profile_popup_product {
  width: 880px;
  /* height: 858px;
  margin: 2% auto; */
  background: white;
  border-radius: 32px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.profile_popup_product_header {
  margin: 32px 24px 0 40px;
  width: 824px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #8b5fbf;
}
.profile_popup_product_body {
  margin-bottom: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile_popup_product_coment {
  height: 352px;
  width: 688px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.profile_popup_product_list {
  width: 688px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.pf-paydetail-popup {
  width: 354px;
  height: 554px;
  background: #ffffff;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf-paydetail-container {
  margin: 8px 16px 12px 16px;
  width: calc(100% - 32px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
  position: relative;
}

.pf-paydetail-loading {
  height: 488px;
  width: 306px;
}

.pf-paydetail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  width: 306px;
}
.pf-paydetail-header > p {
  margin: 0;
}

.pf-paydetail-section {
  width: 306px;
  height: 72px;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-bottom: 8px;
}

.pf-paydetail-section-inner {
  margin: 14px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 28px);
}

.pf-paydetail-section-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
}
.pf-paydetail-section-item:first-child {
  margin-bottom: 8px;
}

.pf-paydetail-section-item > p,
.pf-paydetail-section-item > div > p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #2b4ac4;
  margin: 0;
}
.pf-paydetail-section-item > div > p {
  font-weight: bold;
  margin-left: 4px;
}

.pf-paydetail-section-item > div {
  display: flex;
  align-items: center;
}

.pf-paydetail-section-line {
  background: #2b4ac4;
  width: 1px;
  height: 13px;
}

.pf-paydetail-list {
  width: 306px;
}

.pf-paydetail-list-header,
.pf-paydetail-list-content-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pf-paydetail-list-header {
  background: #dfe1e5;
  font-weight: bold;
  margin-bottom: 8px;
  border-radius: 6px;
}
.pf-paydetail-list-content {
  background: #f9f9fa;
  border-radius: 6px;
}

.pf-paydetail-list-content-item {
  position: relative;
}
.pf-paydetail-list-content-item.line {
  text-decoration: line-through;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
  color: #9b9faf;
}

.pf-paydetail-list-content-comment {
  color: #d7263d;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  right: 20px;
}

.pf-paydetail-paging {
  width: 306px;
  height: 32px;
  position: absolute;
  bottom: 0;
}

.pf-paydetail-paging > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paydetail-paging > div > font {
  letter-spacing: 0px;
  color: #666b82;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
}

.pf-paydetail-paging > div > div > img {
  width: 24px;
  height: 24px;
}
.pf-paydetail-paging > div > div > img {
  margin: 0 4px 0 0;
}
.pf-paydetail-paging > div > div > img:last-child {
  margin: 0 !important;
}

.profile_popup_pay_info {
  text-align: center;
  /* width: 688px; */
  width: 350px;
  text-align: left;
}

.profile_popup_addPaymentBtn,
.profile_popup_selected_productInfo {
  width: 350px;
  height: 206px;
  display: flex;
  flex-direction: column;
}

.profile_popup_addPaymentBtn {
  background: #ededf0;
  border: none;
  border-radius: 32px;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_popup_addPaymentBtn div {
  margin-top: 32px;
}

.profile_popup_selected_productInfo {
  background: #eaedf9;
  border: 1px solid #2b4ac4;
  border-radius: 24px;
  box-shadow: 5px 5px 16px #00000029;
  align-items: flex-start;
}

/* .profile_popup_pay_info div {
  padding: 15px 0 5px 0;
} */

.profile_popup_pay_selectedCard {
  height: 100px;
}

.profile_popup_pay_cardItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile_popup_pay_cardName {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile_popup_pay_sdkBtn {
  background-color: #ffffff;
  border: 1px solid #9b9faf;
  color: #666b82;
}

.profile_popup_payBtn {
  background: #2b4ac4;
  color: #ffffff;
  border: none;
}
.profile_popup_pay_sdkBtn,
.profile_popup_payBtn {
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  height: 56px;
  outline: none;
}
.profile_popup_payBtn:disabled {
  cursor: default;
  background-color: #dfe1e5;
  font-size: 16px;
  font-weight: bold;
  color: #666b82;
}
.profile_popup_pay_sdkBtn:hover {
  background: #2b4ac4;
  color: #ffffff;
}

.profile_popup_btnArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_loading {
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 그룹 프로필 */
.profile_sidebar_btn_imgUpload {
  padding: 7px 76px 11px;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.group_profile_sidebar_groupname {
  font: normal normal bold 20px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.group_profile_sidebar_createDate {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #84889b;
  margin-top: 16px;
  margin-bottom: 16px;
}
.group_profile_sidebar_leader_icon {
  margin-right: 8px;
  vertical-align: top;
}
.group_profile_sidebar_leader {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.group_profile_sidebar_colorName {
  display: inline-block;
  text-transform: uppercase;
}
.group_profile_sidebar_groupcolor {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-left: 8px;
}
.group_profile_thead {
  background: #f1f2fa 0% 0% no-repeat padding-box;
  border-radius: 12px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3b3f4c;
  /* width: 868px; */
  height: 56px;
}
.group_profile_tbody {
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 12px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  height: 56px;
}

.group_profile_invite_btn_red {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a02741;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a02741;
}
.group_profile_invite_btn_orange {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c44308;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c44308;
}
.group_profile_invite_btn_yellow {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c68a00;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c68a00;
}
.group_profile_invite_btn_bean {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #749420;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #749420;
}
.group_profile_invite_btn_green {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1b7c37;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #1b7c37;
}
.group_profile_invite_btn_mint {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #229a80;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #229a80;
}
.group_profile_invite_btn_pine {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2993bc;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
}
.group_profile_invite_btn_pink {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a836c7;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a836c7;
}
.group_profile_invite_btn_purple {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7855d0;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7855d0;
}
.group_profile_invite_btn_black {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666b82;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #666b82;
}

.group_profile_delete_member {
  text-align: right;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c1c3cd;
}

.groupCategoryEditPopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 286px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.groupCategoryEditPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupColorEditPopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 286px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #36252529;
  outline: none;
  display: flex;
  flex-direction: column;
}
.groupColorEditPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostInvitePopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 480px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.groupMeetingHostInvitePopup_background {
  width: 480px;
  /* height: 444px; */
}
.groupMeetingHostInvitePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostInvitePopup_header {
  margin-top: 32px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  align-items: center;
  justify-content: center;
}
.groupMeetingHostDeletePopup {
  text-align: center;
  margin: 14% auto;
  width: 400px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  outline: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 16px #00000029;
}

.groupMeetingHostDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupDeletePopup {
  text-align: center;
  margin: 14% auto;
  width: 530px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  outline: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 16px #00000029;
}

.groupDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostDeletePopup_header {
  margin-top: 32px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  align-items: center;
  justify-content: center;
}
.LicenseAlert {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 429px;
  background: white;
  /* transition: all 0.5s ease-out; */
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.LicenseAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.LicenseAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.LicenseAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.setLicenseAlert {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 330px;
  background: white;
  /* transition: all 0.5s ease-out; */
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.setLicenseAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.setLicenseAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.setLicenseAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.assignSchedule {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 320px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.assignSchedule .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.assignSchedule .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.assignSchedule .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.EditThemaAlert {
  margin: 18% auto;
  padding: 32px 24px 16px 40px;
  width: 460px;
  background: white;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.EditThemaAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.EditThemaAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.EditThemaAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.licenseSettingPopup {
  padding: 16px 24px;
  width: 664px;
  height: 546px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.licenseSettingPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.licenseSettingPopup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.licenseSettingPopup .licenseSettingPopupTitle {
  color: #3b3f4c;
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.licenseSettingPopup .licenseInfo,
.licenseSettingPopup .licenseInfoActive {
  margin-top: 6px;
  width: 664px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.licenseSettingPopup .licenseInfo {
  background: #dfe1e5 0% 0% no-repeat padding-box;
  outline: 1px solid #afb3bf;
}
.licenseSettingPopup .licenseInfoActive {
  background: #eaedf9 0% 0% no-repeat padding-box;
  outline: 1px solid #8b9ddf;
}

.licenseInfo-inner,
.licenseInfo-text-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.licenseInfo-inner {
  /* margin: 12px 16px 12px 24px; */
  height: calc(100% - 24px);
  width: calc(100% - 40px);
}
.licenseInfo-text-area > div {
  margin-bottom: 1px;
}
.licenseInfo-text-area > div:first-child {
  margin-right: 24px;
}

.lc-setting-popup-header-font {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.lc-setting-popup-header-font.type {
  color: #8b9ddf;
  font-weight: bold;
  margin-right: 8px;
}
.lc-setting-popup-header-font.type.active {
  color: #2b4ac4 !important;
}
.lc-setting-popup-header-font.value {
  color: #9b9faf;
}
.lc-setting-popup-header-font.value.active {
  color: #3b3f4c !important;
}

.lc-content-header {
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lc-content-header > div {
  display: flex;
  align-items: center;
}
.lc-content-header > div > font {
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  letter-spacing: 0px;
  color: #666b82;
  margin-left: 8px;
}

.lc-content-inner {
  position: absolute;
  margin: 0px 8px 11px 8px;
  height: calc(100% - 11px);
}

.lc-content-relative {
  height: 100%;
  position: relative;
}

.lc-content-table {
  border-spacing: 0 2px;
}

.lc-content-table-tr {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  height: 32px;
}

.lc-content-table-tr.thead {
  background: #d1d3da 0% 0% no-repeat padding-box;
}

.lc-content-table-tr > th:first-child,
.lc-content-table-tr > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.lc-content-table-tr > th:last-child,
.lc-content-table-tr > td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.lc-content-table-tr > th,
.lc-content-table-tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lc-content-table-left {
  text-align: left;
}
.lc-content-table-center {
  text-align: center;
}

.licenseSettingPopup .releaseLicenseBtn {
  width: 72px;
  height: 36px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  float: right;
  border: none;
  margin: 10px 16px;
}
.licenseSettingContent {
  margin-top: 8px;
  width: 664px;
  height: 456px;
  background: #f1f1f3 0% 0% no-repeat padding-box;
  border-radius: 12px;
  position: relative;
}
.licenseSettingContent.disable {
  height: 388px !important;
}

.scheduleListTitle {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.licenseSettingPopup .licenseScheduleList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.licenseSettingPopup .licenseScheduleList table {
  border-spacing: 0 5px;
  margin: 0 auto;
}
/* .licenseSettingPopup .licenseStateBtn {
  float: right;
} */
.licenseSettingPopup .licenseStateBtn .MuiSwitch-root {
  width: 40px;
  height: 24px;
  margin-left: 8px;
  padding: 0px;
}
.licenseSettingPopup .licenseStateBtn .MuiFormControlLabel-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-switchBase {
  color: #6e85d7;
  padding: 0;
  margin: 3px 2px;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-colorPrimary.Mui-checked {
  color: #2b4ac4;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-track {
  background-color: #b3bfe9;
  opacity: 1;
  width: 40px;
  height: 24px;
  border-radius: 12px;
}
.licenseSettingPopup
  .licenseStateBtn
  .MuiSwitch-colorPrimary.Mui-checked
  + .MuiSwitch-track {
  background-color: #ffffff;
}

.licenseSettingPopup .scheduleReassignBtn {
  border: none;
  background: #8b9ddf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #e5e9f8;
  opacity: 0.62;
  width: 91px;
  height: 36px;
}
.licenseSettingPopup .scheduleReassignBtnActive {
  cursor: pointer;
  border: none;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #e5e9f8;
  width: 91px;
  height: 36px;
}
.licenseSettingPopup .licenseScheduleList_thead {
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  height: 40px;
}

.licenseSettingPopup .licenseScheduleList_tbody td {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  text-align: center;
  height: 40px;
  color: #3e3e4f;
}
.licenseSettingPopup .licenseScheduleList_thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.licenseSettingPopup .licenseScheduleList_thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.licenseSettingPopup .licenseScheduleList .noSchedule {
  padding: 32px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
}
.licenseSettingPopup .balloon {
  margin-top: 8px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 283px;
  border-radius: 10px;
  padding: 12px 15px 14px 16px;
  position: absolute;
  right: 0px;
  text-align: left;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.licenseSettingPopup .balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 260px;
}
.licenseSettingPopup .guideTooltip {
  margin-top: 8px;
  margin: 172px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 384px;
  border-radius: 10px;
  padding: 12px 20px 16px;
  position: absolute;
  /* right: 40px; */
  text-align: center;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.profile_licenseSettingPopup_footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_licenseSettingPopup_allocateBtn {
  background: #3b3f4c 0% 0% no-repeat padding-box;
}
.profile_licenseSettingPopup_otherGroupBtn {
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.profile_licenseSettingPopup_unsubscribeBtn {
  background: #c63353 0% 0% no-repeat padding-box;
}

.profile_licenseSettingPopup_allocateBtn,
.profile_licenseSettingPopup_otherGroupBtn,
.profile_licenseSettingPopup_unsubscribeBtn {
  width: 216px;
  height: 48px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.profile_licenseSettingPopup_setGroupBtn {
  width: 216px;
  height: 48px;
  /* margin: 0 196px; */
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.setGroupLicensePopup {
  margin: 14% auto;
  padding: 16px 24px 24px;
  width: 472px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.setGroupLicensePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.setGroupLicensePopup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.setGroupLicensePopup .setGroupLicensePopupTitle {
  text-align: left;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  margin-top: 8px;
  margin-left: 8px;
  color: #2b4ac4;
}

.setGroupLicensePopup .groupSearchInput {
  width: 360px;
  height: 48px;
  text-align: center;
  margin: 0px auto;
  display: block;
  background-color: #eaedf9;
  border: none;
  border-radius: 10px;
}

.setGroupLicensePopup .groupSearchInput::-webkit-input-placeholder {
  font: normal normal normal 14px/21px Spoqa Han Sans Neo;
  color: #a3b1e5;
}

.setGroupLicensePopup .groupSearchInput::placeholder {
  font: normal normal normal 14px/21px Spoqa Han Sans Neo;
  color: #a3b1e5;
}

.setGroupLicensePopup .groupSearchInput:focus {
  outline: none;
}

.setGroupLicensePopup .licenseGroupSettingBtn {
  border: none;
  width: 360px;
  height: 40px;
  background: #6e85d7 0% 0% no-repeat padding-box;
  margin-top: 16px;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #cad2f0;
}

.setGroupLicensePopup .licenseGroupSettingBtnActive {
  border: none;
  width: 360px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  margin-top: 16px;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  cursor: pointer;
}

.setGroupLicensePopup .groupListPopup {
  width: 360px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 10px;
  margin: 8px 56px;
  overflow-y: auto;
}

.setGroupLicensePopup .groupListPopup .groupListPopupItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}

.group_profile_tbody input {
  max-width: 76px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  border-radius: 8px;
  border: none;
  outline: none;
  text-align: center;
  /* background: #ecedf7 0% 0% no-repeat padding-box; */
}
.group_profile_tbody input:disabled {
  background: none;
}
.group_profile_tbody input:focus {
  /* min-width: 96px; */
  height: 36px;
  background: #ecedf7 0% 0% no-repeat padding-box;
}
.meetingHostAuthTooltip {
  z-index: 80;
  margin-top: 18px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 328px;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  right: -175px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.meetingHostAuthTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 30px;
}
/* .profile_content_div {
  margin: 20px 40px;
} */

/* .profile_content_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_content_title_text {
  font-size: 18px;
  font-weight: bold;
  color: #2b4ac4;
}
.profile_content_title_btn {
  background-color: #2b4ac4;
  color: #ffffff;
  font-size: 14px;
  border: none;
  font-weight: bold;
  height: 30px;
}
.profile_content_title_btn:active {
  outline: none;
} */

/* .profile_sidebar_avatar {
  width: 104px;
  height: 104px;
  border-radius: 999px;
  margin-top: 64px;
}
.profile_sidebar_name {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 40px;
}
.profile_sidebar_email {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 16px;
  margin-bottom: 32px;
}

.profile_sidebar_groupname {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 40px;
}
.profile_sidebar_createDate {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 16px;
}
.profile_sidebar_leader_icon {
  width: 16px;
  height: 16px;
}
.profile_sidebar_leader {
  font-size: 16px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 16px;
}

.profile_sidebar_btn_side {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  width: 344px;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 16px;
}
.profile_sidebar_btn_center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  width: 344px;
  height: 64px;
  color: #2b4ac4;
  background: rgba(43, 74, 196, 0.2);
  margin-top: 16px;
}
.profile_sidebar_point {
  color: #ffffff;
  background: #2b4ac4;
}
.profile_sidebar_bluetext {
  color: #2b4ac4;
}
.profile_sidebar_leftdiv {
  margin-left: 24px;
}
.profile_sidebar_rightdiv {
  margin-right: 32px;
} */

.comment_timezone_balloon,
.comment_language_balloon {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 150px;
  height: 30px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  inset: 80px auto auto 0px;
  /* transform: translate(1025px, 646px); */
  -webkit-transform: translate(1265px, 492px);
          transform: translate(1265px, 492px);
  align-items: center;
  justify-content: center;
}
.comment_excel_balloon {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 150px;
  height: 30px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  inset: 80px auto auto 0px;
  /* transform: translate(1025px, 646px); */
  -webkit-transform: translate(1025px, 552px);
          transform: translate(1025px, 552px);
  align-items: center;
  justify-content: center;
}
.comment_timezone_balloon:after,
.comment_language_balloon:after,
.comment_excel_balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 48px;
}

.comment_timezone_balloon:after {
  left: 48px;
}

.comment_language_balloon:after {
  left: 104px;
}
.SupcontentAll {
  height: 824px;
  overflow-y: scroll;
  /* -ms-overflow-style: none; 
 scrollbar-width: 1px;  */
  width: 950px;
}
/* .SupcontentAll::-webkit-scrollbar {
  display: none;
}  */
.SupcontentAll::-webkit-scrollbar {
  width: 8px;
}
.SupcontentAll::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.SupcontentAll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.support_header_title {
  display: inline;
  margin-bottom: 20px;
}

.py-product-popup {
  width: 624px;
  height: 560px;
  background: white;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-product-popup-inner {
  width: 600px;
  height: 100%;
}

.py-product-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
.py-product-popup-header > font {
  color: #2b4ac4;
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  letter-spacing: 0px;
}

.py-product-popup-container-loading,
.py-product-popup-container {
  height: calc(100% - 56px);
  width: 600px;
}
.py-product-popup-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.py-product-popup-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.py-product-list {
  width: 576px;
  height: 216px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-top: 10px;
  /* justify-content: space-between; */
}
/* .py-product-list.selected {
  border-bottom: 2px dashed #D1D3DA;
} */

.py-product-list-item {
  width: 576px;
  height: 216px;
  /* background: #DBE0F5;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; */
}
/* .py-product-list-item.selected {
  background: #2B4AC4 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 16px #0000001A;
} */

.py-product-list-item-inner {
  /* height: 118px;
  width: 232px; */
  margin: 26px 16px 16px 48px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-item-info {
  width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.py-product-list-item-info-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.py-product-list-item-info-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 24px;
  font-weight: bold;
}
.py-product-list-item-info-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-product-list-item-info-amount > font {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 30px;
}
.py-product-list-item-info-amount-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.py-product-list-item-info-amount-comment > font:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #ee6c4d;
}
.py-product-list-item-info-amount-comment > font:last-child {
  font-size: 15px;
  color: #3b3f4c;
  text-decoration: line-through;
  -webkit-text-decoration-line: 2px solid #ee6c4d;
          text-decoration-line: 2px solid #ee6c4d;
  -webkit-text-decoration-color: #ee6c4d;
          text-decoration-color: #ee6c4d;
}
/* .py-product-list-item-info-amount > font:last-child {
  letter-spacing: 0px;
  color: #6E85D7;
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
} */

.py-product-list-item-info-bot {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.py-product-list-item-info-coment {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: 0px;
  color: #666b82;
  font-size: 14px;
  font-weight: bold;
}

.py-product-list-item-info-paydt {
  margin-top: 5px;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 12px;
  font-weight: bold;
}

.py-product-list-item-title {
  height: 37px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-list-item-title > font {
  font: normal normal bold 20px/72px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #8b9ddf;
}
.py-product-list-item-title.selected > font {
  color: #ffffff;
}

.py-product-list-item-amount {
  margin-top: 24px;
  margin-bottom: 4px;
  height: 37px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.py-product-list-item-amount-date,
.py-product-list-item-amount-value {
  letter-spacing: 0px;
  color: #8b9ddf;
}
.py-product-list-item-amount-date.selected,
.py-product-list-item-amount-value.selected {
  color: #ffffff;
}
.py-product-list-item-amount-date {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  margin-bottom: 5px;
  margin-right: 6px;
}
.py-product-list-item-amount-value {
  font-size: 28px;
  font-family: Spoqa Han Sans Neo;
}

.py-product-list-item-comment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  letter-spacing: 0px;
  color: #8b9ddf;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-item-comment.selected {
  color: #a3b1e5;
}

.py-product-list-btn-area {
  height: 174px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
.py-product-list-btn-area-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.py-product-list-counter {
  width: 128px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.py-product-list-counter-input {
  width: 104px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9b9faf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-list-counter-input > input {
  width: 65px;
  outline: none;
  border: none;
  text-align: center;
}
.py-product-list-counter-text {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-counter-input-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #9b9faf;
}
.py-product-list-counter-input-btn > div {
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-list-counter-input-btn > div:first-child {
  border-bottom: 1px solid #9b9faf;
}
.py-product-list-seleted-btn {
  width: 160px;
  height: 56px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-seleted-btn > img {
  margin-left: 6px;
}

.py-product-list-non-selected-comment {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-weight: bold;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  text-align: center;
}

.py-product-list-counter-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0px;
  color: #666b82;
  font-size: 12px;
  font-weight: bold;
  margin-top: 14px;
}

.py-product-payment {
  width: 576px;
  height: 472px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.py-product-selected-info {
  width: 184px;
  margin-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.py-product-selected-info-container {
  height: 274px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.py-product-selected-info-title,
.py-product-payment-info-title {
  height: 36px;
  background: #eaedf9;
  display: flex;
  border-radius: 8px;
  align-items: center;
}
.py-product-selected-info-title {
  width: 184px;
}
.py-product-payment-info-title {
  width: 344px;
}
.py-product-selected-info-title > font,
.py-product-payment-info-title > font {
  margin-left: 16px;
  color: #2b4ac4;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-product-selected-info-content {
  width: 184px;
  height: 222px;
  background: #2b4ac4;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.py-product-selected-info-content-top {
  height: 85px;
  width: 160px;
  border-bottom: 2px dashed #6e85d7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.py-product-selected-info-content-top > font,
.py-product-selected-info-content-bot > font {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
}
.py-product-selected-info-content-top > font:first-child {
  font-size: 20px;
  font-weight: bold;
}
.py-product-selected-info-content-top > font:last-child {
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.py-product-selected-info-content-bot {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.py-product-selected-info-content-bot > font {
  font-size: 14px;
  font-weight: bold;
}
.py-product-selected-info-content-bot > font:nth-child(2) {
  margin-top: 16px;
  margin-bottom: 4px;
}

.py-product-selected-info-btn {
  width: 184px;
  height: 40px;
  border: 1px solid #4865cc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4865cc;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-selected-info-btn > img {
  margin-right: 8px;
}

.py-product-payment-info {
  width: 368px;
  height: 100%;
  border-left: 1px solid #dbe0f5;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.py-product-payment-info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-product-payment-info-card {
  background: #dbe0f5;
  width: 344px;
  height: 222px;
  border-radius: 20px;
  margin: 16px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.py-product-payment-info-card.none {
  margin: 16px 0;
}
.py-product-payment-info-card.none > font {
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 24px;
}

.py-product-payment-info-card-item {
  width: 272px;
  height: 152px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #00000029;
  border-radius: 12px;
}
.py-product-payment-info-card-item-inner {
  margin: 16px;
}

.py-product-payment-info-del-btn {
  width: 344px;
  height: 40px;
  background: #ffffff;
  outline: 1px solid #4865cc;
  border-radius: 10px;
  border: none;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  margin-bottom: 8px;
}

.py-product-payment-info-comment {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
  margin-bottom: 23px;
}

.py-product-payment-info-terms {
  text-decoration: underline;
  -webkit-text-decoration-color: #666b82;
          text-decoration-color: #666b82;
  color: #666b82;
  font-weight: bold;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
}

.py-product-payment-info-btn {
  width: 344px;
  height: 38px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border: none;
  outline: none;
  background: #2b4ac4;
  color: #ffffff;
}
.py-product-payment-info-btn:disabled {
  background: #eaedf9;
  color: #cad2f0;
}

.py-product-auth {
  width: 576px;
  height: 816px;
}

.py-product-auth-header {
  width: 576px;
  height: 36px;
  border-radius: 8px;
  background: #eaedf9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-auth-header > font {
  margin-left: 16px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-product-auth-container {
  height: 436px;
  width: 576px;
  background: #ededf0;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Spoqa Han Sans Neo;
}

.py-product-auth-container-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 26px);
}
.py-product-auth-container-comment-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-auth-container-comment-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.py-product-auth-container-comment-title {
  font-size: 14px;
}
.py-product-auth-container-comment-detail {
  font-size: 12px;
  margin-top: 8px;
}
.py-product-auth-container-comment-cancle {
  margin-bottom: 25px;
  width: 184px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  outline: 1px solid #c63353;
  border-radius: 10px;
  border: none;
  letter-spacing: 0px;
  color: #c63353;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.1s ease-out;
}
.py-product-auth-container-comment-cancle:hover {
  background: #c63353;
  color: #ffffff;
}

.py-product-auth-container-content {
  width: 336px;
  height: 416px;
  background: #dfe1e5;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-container-content-inner {
  margin: 16px 16px 0;
  width: 304px;
}

.py-product-auth-content-title {
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.py-product-auth-content-body {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-btn:disabled {
  background: #6e85d7 !important;
}
.py-product-auth-btn:hover {
  background: #7489d4;
}
.py-product-auth-btn {
  width: 304px;
  height: 40px;
  border-radius: 8px;
  background: #2b4ac4;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  outline: none;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.py-product-auth-term-list {
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-phone-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 237px;
  width: 265px;
}
.py-product-auth-num-list {
  width: 265px;
}

.py-product-auth-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 427px;
  width: 100%;
}

.py-product-auth-success-comment {
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  margin-bottom: 24px;
}

.py-product-add {
  width: 576px;
  height: 816px;
}

.py-product-add-header {
  width: 576px;
  height: 36px;
  border-radius: 8px;
  background: #eaedf9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-add-header > font {
  margin-left: 16px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-product-add-container {
  height: 428px;
  width: 576px;
  background: #eaedf9;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.py-product-add-item {
  width: 224px;
  height: 264px;
  background: #ffffff;
  box-shadow: 5px 5px 16px #00000029;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-add-item:first-child {
  margin-right: 16px;
}
.py-product-add-item-inner {
  width: 182px;
  height: 193px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.py-product-add-item-section {
  width: 100%;
  text-align: right;
}
.py-product-add-item-section-title,
.py-product-add-item-section-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-weight: bold;
}
.py-product-add-item-section-title {
  font-size: 20px;
  margin-bottom: 8px;
}
.py-product-add-item-section-comment {
  font-size: 14px;
}

.py-product-transfer,
.py-product-card {
  width: 448px;
  height: 412px;
  background: #dbe0f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-card {
  align-items: flex-start;
}
.py-product-transfer-inner,
.py-product-card-inner {
  margin: 12px 16px 16px 16px;
}

.py-product-transfer-section {
  margin-bottom: 8px;
}
.py-product-card-section {
  margin-bottom: 4px;
}
.py-product-transfer-section > p,
.py-product-card-section > p,
.py-product-card-type-inner {
  margin: 0px;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
}
.py-product-transfer-title,
.py-product-card-title {
  font-weight: bold;
  margin-bottom: 4px;
}
.py-product-card-comment {
  margin-bottom: 8px !important;
  margin-top: 4px !important;
}

.py-product-card-type {
  width: 384px;
  height: 48px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
}
.py-product-card-type-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
  margin-left: 16px;
  font-weight: bold;
}

.py-product-card-type-item:first-child {
  margin-right: 28px;
}

.py-product-card-type-item {
  display: flex;
  align-items: center;
}
.py-product-card-type-item > img {
  margin-right: 8px;
}

.py-product-transfer-bank {
  width: 416px;
  height: 184px;
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border: 1px solid #eaedf9;
  border-radius: 10px;
}
.py-product-transfer-bank-inner {
  margin-left: 16px;
}
.py-product-transfer-bank-header {
  height: 34px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-transfer-bank-list {
  height: 134px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 1px;
  padding-top: 1px;
}

.py-product-transfer-input {
  width: 416px;
  height: 46px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.py-product-transfer-input-inner {
  width: 382px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-transfer-input-inner > span {
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-transfer-input-inner > input {
  border: none;
  outline: none;
  background: none;
  text-align: right;
  width: 260px;
  font-size: 14px;
  color: #3e3e4f;
}

.py-product-transfer-btn-area {
  margin-top: 8px;
}
.py-product-transfer-btn-area.confirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-transfer-btn-area > button {
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  color: #ffffff;
  outline: none;
  border: none;
  transition: all 0.1s ease-out;
}
.py-product-transfer-btn-cancle,
.py-product-transfer-btn-auth {
  width: 204px;
  height: 36px;
}
.py-product-transfer-btn-ok {
  width: 416px;
  height: 36px;
}
.py-product-transfer-btn-cancle {
  background: #d7263d;
}
.py-product-transfer-btn-cancle:hover {
  background: #df4b5e;
}
.py-product-transfer-btn-auth,
.py-product-transfer-btn-ok {
  background: #2b4ac4;
}
.py-product-transfer-btn-auth:hover,
.py-product-transfer-btn-ok:hover {
  background: #4f67c5;
}
.py-product-transfer-btn-auth:disabled {
  background: #84889b;
  color: #dfe1e5;
}

.py-product-transfer-bank-item {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  background: #ffffff;
  height: 36px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.py-product-transfer-bank-item.selected {
  background: #3e3e4f;
}
.py-product-transfer-bank-item.selected > div > div {
  color: #ffffff;
}

.py-product-transfer-bank-item-inner {
  height: 100%;
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-transfer-bank-item-inner > div {
  margin-left: 4px;
  font-size: 12px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.py-product-transfer-bank-list-loading {
  width: 100%;
  height: 100%;
}

.py-product-transfer-ars {
  width: 416px;
  height: 292px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.py-product-transfer-ars-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.py-product-transfer-ars-num {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-family: Spoqa Han Sans Neo;
  font-size: 36px;
  font-weight: bold;
  margin: 60px 0 70px 0;
}

.py-product-payment-info-loading {
  width: 344px;
  height: 222px;
  background: #dbe0f5;
  border-radius: 20px;
  margin: 16px 0;
}

.py-product-payment-info-card-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-product-payment-info-card-item-top > section > p {
  margin: 0;
  text-align: right;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-product-payment-info-card-item-bot {
  margin-top: 47px;
}
.py-product-payment-info-card-item-bot.transfer {
  margin-top: 63px;
}

.py-product-payment-info-card-item-bot > section > p {
  margin: 0;
  text-align: right;
  letter-spacing: 0px;
  color: #2b4ac4;

  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-payment-info-card-item-bot > section > p:first-child {
  font-size: 12px;
  color: #9b9faf;
}
.py-product-payment-info-card-item-bot > section > p:last-child {
  font-size: 16px;
  color: #3e3e4f;
}

.py-product-card-input {
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  margin-bottom: 4px;
}
.py-product-card-input.num {
  width: 384px;
  height: 88px;
}
.py-product-card-input.expire {
  width: 384px;
  height: 46px;
}
.py-product-card-input.cvc,
.py-product-card-input.password {
  width: 188px;
  height: 80px;
}

.py-product-card-input-inner.num {
  margin: 12px 16px;
}
.py-product-card-input-inner.expire {
  margin: 7px 16px;
}
.py-product-card-input-inner.cvc,
.py-product-card-input-inner.password {
  margin: 12px 16px;
}

.py-product-card-input-comment {
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-card-input-num-area {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.py-product-card-input-cvc-area,
.py-product-card-input-password-area {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.py-product-card-input-num-area > input,
.py-product-card-input-expire-area > input,
.py-product-card-input-cvc-area > input,
.py-product-card-input-password-area > input {
  background: none;
  border: none;
  outline: none;
}
.py-product-card-input-num-area > input,
.py-product-card-input-expire-area > input {
  text-align: center;
}
.py-product-card-input-cvc-area > input,
.py-product-card-input-password-area > input {
  text-align: right;
}
.py-product-card-input-num-area > input {
  width: 48px;
  height: 30px;
}
.py-product-card-input-expire-area > input {
  width: 40px;
  height: 30px;
}
.py-product-card-input-cvc-area > input {
  width: 100%;
  height: 21px;
}
.py-product-card-input-password-area > input {
  width: 132px;
  height: 21px;
}
.py-product-card-input-num-area > input::-webkit-input-placeholder, .py-product-card-input-expire-area > input::-webkit-input-placeholder, .py-product-card-input-cvc-area > input::-webkit-input-placeholder, .py-product-card-input-password-area > input::-webkit-input-placeholder {
  color: #d1d3da;
}
.py-product-card-input-num-area > input::placeholder,
.py-product-card-input-expire-area > input::placeholder,
.py-product-card-input-cvc-area > input::placeholder,
.py-product-card-input-password-area > input::placeholder {
  color: #d1d3da;
}

.py-product-card-input-expire-area,
.py-product-card-input-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-product-card-btn-area {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-card-btn {
  width: 188px;
  height: 36px;
  border-radius: 8px;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  outline: none;
}
.py-product-card-btn.cancle {
  background: #d7263d;
}
.py-product-card-btn.ok {
  background: #3e3e4f;
}
.py-product-card-btn:disabled {
  background: #84889b;
  color: #dfe1e5;
}

.py-popup-success {
  width: 437px;
  height: 494px;
  background: white;
  border-radius: 16px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-popup-success-title {
  margin-top: 24px;
  margin-bottom: 16px;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 20px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-popup-success-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-popup-success-info.top {
  margin-top: 24px;
  margin-bottom: 16px;
}
.py-popup-success-info.bot {
  margin-bottom: 24px;
}

.py-popup-success-section {
  text-align: center;
  margin-top: 4px;
}
.py-popup-success-section-font {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.py-popup-success-section-font.blue {
  color: #1240b8;
}
.py-popup-success-section-font.purple {
  color: #7300e9;
}

.py-popup-success-comment {
  letter-spacing: 0px;
  color: #84889b;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 16px;
}

.py-popup-success-btn {
  background: #2b4ac4;
  width: 320px;
  height: 48px;
  border-radius: 10px;
  color: #ffffff;
  font-family: Spoqa Han Sans Neo;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  border: none;
  outline: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.py-popup-success-btn:hover {
  background: #4865cc;
}

.pf-paylog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-header-title {
  color: #8b5fbf;
  font-weight: bold;
  margin: 16px 0;
}

.pf-paylog-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dbe0f5;
  outline: 1px solid #a3b1e5;
  border-radius: 12px;
  height: 64px;
}

.pf-paylog-payment-content {
  margin: 14px 24px 14px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pf-paylog-payment-info > p,
.pf-paylog-payment-nm > p {
  margin: 0;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
  color: #3e3e4f;
}
.pf-paylog-payment-info > p:first-child {
  color: #2b4ac4;
  font-weight: bold;
}

.pf-paylog-payment-line {
  height: 21px;
  border: 1px solid #2b4ac4;
  margin: 0 23px;
}

.pf-paylog-payment-nm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
}

.pf-paylog-payment-change {
  letter-spacing: 0px;
  color: #6e85d7;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 150px;
}

.pf-paylog-payment-add {
  display: flex;
  align-items: center;
}
.pf-paylog-payment-add > font {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #2b4ac4;
  margin-bottom: 1px;
}

.pf-paylog-payment-outstanding {
  width: 152px;
  height: 36px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
  font-weight: bold;
  transition: 0.2 ease;
  transition: all 0.1s ease-out;
}
.pf-paylog-payment-outstanding:hover {
  background: #3654cc;
}
.pf-paylog-payment-outstanding:disabled {
  background: #8b9ddf;
}

.pf-paylog-container {
  width: 100%;
  height: calc(100% - 120px);
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-content-table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  height: 624px;
  display: block;
}
.pf-paylog-content-table > tbody {
  position: relative;
}
.pf-paylog-content-loading {
  height: 100%;
}
.pf-paylog-content-loading,
.pf-paylog-content-table-loading {
  width: 100%;
  position: absolute;
}

.pf-paylog-content-table-tr {
  height: 48px;
  background: #f9f9fa;
  font-size: 14px;
  text-align: center;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.pf-paylog-content-table-tr.thead {
  font-weight: bold;
  background: #ededf0;
}

.pf-paylog-content-table-tr > th:first-child,
.pf-paylog-content-table-tr > td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.pf-paylog-content-table-tr > th:last-child,
.pf-paylog-content-table-tr > td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pf-paylog-content-table-th.left,
.pf-paylog-content-table-td.left {
  text-align: left;
}

.pf-paylog-content-table-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
}
.pf-paylog-content-table-btn > img {
  margin-right: 8px;
}

.pf-paylog-payment-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 64px;
  outline: 1px solid #a3b1e5;
  border: none;
  background: #dbe0f5;
}

.pf-paylog-content-table-empty {
  /* height: 100%; */
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b3b3;
  font-weight: bold;
}

.pf-paylog-content-paging {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: calc(100% - 624px);
}

.py-outstanding-popup {
  width: 400px;
  height: 392px;
  background: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-outstanding-popup-inner {
  width: calc(100% - 32px);
  height: 100%;
  margin: 8px 16px;
  position: relative;
}

.py-outstanding-popup-close {
  position: absolute;
  right: 0;
}

.py-outstanding-header {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-outstanding-container {
  /* height: calc(100% - 36px); */
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0px;
  font-family: Spoqa Han Sans Neo;
}

.py-outstanding-container-loading,
.pf-license-container-loading {
  height: 100%;
  width: 100%;
  position: absolute;
}

.py-outstanding-container-section {
  color: #3e3e4f;
  font-weight: bold;
  margin: 16px 0;
  text-align: center;
}
.py-outstanding-container-section > p {
  margin: 0;
}
.py-outstanding-container-section > p:first-child {
  font-size: 20px;
  margin-bottom: 8px;
}
.py-outstanding-container-section > p:last-child {
  font-size: 14px;
}

.py-outstanding-container-content {
  width: 280px;
  height: 136px;
  background: #eaedf9;
  border-radius: 10px;
  font-family: Spoqa Han Sans Neo;
}

.py-outstanding-container-content-inner {
  margin: 18px 23px 15px 23px;
}

.py-outstanding-content-item {
  color: #3e3e4f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-outstanding-content-item > font:first-child {
  font-size: 14px;
  font-weight: bold;
}
.py-outstanding-content-item > font:last-child {
  font-size: 16px;
}

.py-outstanding-content-comment {
  margin-top: 16px;
  text-align: center;
}
.py-outstanding-content-comment > p {
  margin: 0;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-outstanding-container-btn {
  width: 280px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  border: none;
  outline: none;
  margin-top: 8px;
  transition: all 0.1s ease-out;
}
.py-outstanding-container-btn:hover {
  background: #7489d4;
}
.py-outstanding-container-btn:active {
  background: #2b4ac4;
}
.py-outstanding-container-btn:disabled {
  background: #eaedf9;
}

.py-outstanding-container-comment {
  margin-top: 12px;
  font-size: 12px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #d7263d;
}

.pf-paylog-detail-list {
  color: #000000;
}
.pf-paylog-detail-list.line {
  text-decoration: line-through;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
}

.pf-common-container {
  margin-top: 16px;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
}

.py-outstanding-result-popup {
  width: 360px;
  height: 304px;
  background: #ffffff;
  /* box-shadow: 0px 5px 16px #00000029; */
  border-radius: 12px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-outstanding-result-popup.success {
  width: 320px;
  height: 240px;
}

.py-outstanding-result-container {
  position: relative;
  width: calc(100% - 32px);
  height: calc(100% - 16px);
  margin: 8px 16px;
}

.py-outstanding-result-close {
  position: absolute;
  right: 0;
}

.py-outstanding-result-content {
  margin: 8px 9px 14px 9px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.py-outstanding-result-section {
  margin: 16px 0;
  text-align: center;
}

.py-outstanding-result-section > p {
  margin: 0;
  font-size: 14px;
  color: #3e3e4f;
}
.py-outstanding-result-section-bold {
  font-weight: bold !important;
}
.py-outstanding-result-section-title {
  font-size: 20px !important;
  font-weight: bold;
  text-transform: lowercase;
  margin-bottom: 8px !important;
}
.py-outstanding-result-section-title.success {
  color: #2b4ac4 !important;
}

.py-outstanding-result-section-msg.err {
  color: #c63353 !important;
  font-weight: bold;
  margin: 16px 0 !important;
}

.py-outstanding-result-section-msg.comment {
  color: #666b82 !important;
  font-size: 12px !important;
}

.py-outstanding-result-ok {
  width: 264px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  outline: none;
  transition: all 0.1s ease-out;
}
.py-outstanding-result-ok.success {
  width: 256px !important;
}
.py-outstanding-result-ok:hover {
  background: #7489d4;
}
.py-outstanding-result-ok:active {
  background: #2b4ac4;
}

.pf-license-content {
  position: relative;
  height: calc(100% - 40px);
}

.pf-license-table {
  /* height: 696px; */
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 12px;
}

.pf-license-table-tr {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.pf-license-table-tr.thead {
  font-size: 16px;
  height: 56px;
  background: #f1f2fa;
}
.pf-license-table-tr.tbody {
  font-size: 14px;
  height: 52px;
  background: #f8f9fd;
}
.pf-license-table-tr.tbody.new {
  background: linear-gradient(
      93deg,
      rgba(26, 255, 212, 0.2) 0%,
      rgba(139, 95, 191, 0.2) 100%
    )
    transparent !important;
}

.pf-license-table-tr > th:first-child,
.pf-license-table-tr > td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pf-license-table-tr > th:last-child,
.pf-license-table-tr > td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pf-license-table-font.bold {
  font-weight: bold;
}

.pf-license-table-font.left {
  text-align: left;
}
.pf-license-table-font.center {
  text-align: center;
}
.pf-license-table-font.unsubscribe,
.pf-license-table-font.free,
.pf-license-table-font.new {
  font-family: Spoqa Han Sans Neo !important;
  font-size: 12px;
  font-weight: bold;
}
.pf-license-table-font.unsubscribe,
.pf-license-table-font.red {
  color: #c63353 !important;
}
.pf-license-table-font.free {
  color: #d7263d;
}
.pf-license-table-font.new {
  color: #16ddb5;
}

.pf-license-table-unsubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pf-license-table-unsubscribe > img {
  margin-right: 4px;
}

.pf-license-paging {
  position: absolute;
  height: 38px;
  width: 276px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
/* .pf-license-content-inner {
    height: 758px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.py-unsubscribe-popup {
  width: 286px;
  height: 240px;
  background: #ffffff;
  box-shadow: -5px 3px 20px #00000029;
  border-radius: 16px;
  z-index: 910;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-popup-inner {
  margin: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3b3f4c;
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 48px);
}

.py-unsubscribe-popup-loading {
  position: absolute;
  width: 100%;
  height: 100%;
}

.py-unsubscribe-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
}
.py-unsubscribe-comment-1 {
  font-size: 14px;
  margin: 10px 0;
}

.py-unsubscribe-comment-2 {
  color: #c63353;
  font-size: 10px;
  margin-bottom: 25px;
  text-align: center;
}

.py-unsubscribe-btn-area {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.py-unsubscribe-btn-ok {
  color: #2b4ac4;
  margin-right: 30px;
}

.py-unsubscribe-btn-cancle {
  color: #c63353;
}

.py-unsubscribe-result-popup {
  width: 408px;
  height: 328px;
  background: #ffffff;
  box-shadow: -5px 3px 20px #00000029;
  border-radius: 16px;
  z-index: 910;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-result-popup-inner {
  margin: 24px 33px;
  width: calc(100% - 66px);
  height: calc(100% - 48px);
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-result-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.py-unsubscribe-result-comment {
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.py-unsubscribe-result-expire {
  color: #1240b8;
}

.py-unsubscribe-result-btn {
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 320px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
}

.pf-pay-fail-popup {
  width: 320px;
  height: 288px;
  background: #ffffff;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.pf-pay-fail-close {
  position: absolute;
  margin-top: 8px;
  margin-right: 16px;
  right: 0;
}

.pf-pay-fail-popup-inner {
  margin: 16px 38px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf-pay-fail-popup-title {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin: 16px 0 8px;
}

.pf-pay-fail-popup-comment {
  font-size: 14px;
  color: #3e3e4f;
  text-align: center;
}

.pf-pay-fail-popup-comment > div > font {
  color: #2b4ac4 !important;
  font-weight: bold !important;
}

.pf-pay-fail-popup-comment.red {
  font-size: 12px !important;
  color: #d7263d !important;
  margin-top: 12px;
  margin-bottom: 20px;
}

.pf-pay-fail-popup-btn {
  width: 240px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  border: none;
}

.host-excel-upload {
  position: absolute;
  z-index: 400;
  max-width: 424px;
  height: 120px;
  background: white;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 10px;
  right: 64px;
  top: 650px;
}

.host-excel-upload-close {
  position: absolute;
  right: 8px;
  top: 4px;
}

.host-excel-upload-inner {
  padding: 16px;
}
.host-excel-upload-section {
  margin-bottom: 8px;
}
.host-excel-upload-section > p {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.host-excel-upload-section > p:first-child {
  color: #2b4ac4;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px !important;
}
.host-excel-upload-section > p:last-child {
  color: #666b82;
  font-size: 14px;
}

.host-excel-upload-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.host-excel-select-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.host-excel-select-btn {
  width: 152px;
  height: 36px;
}
.host-excel-select-btn {
  background: #2b4ac4;
  color: #ffffff;
  border: 1px solid #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.host-excel-select-btn:hover {
  background: #1f378e;
}
.host-excel-fileinfo {
  width: 240px;
  height: 36px;
  background: #eaedf9;
  border-radius: 10px;
  margin-right: 8px;
}
.host-excel-fileinfo-inner {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.host-excel-fileinfo-inner > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.host-excel-fileinfo-inner > div > div {
  margin-left: 8px;
  width: 167px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}
.host-excel-upload-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.host-excel-upload-btn:hover {
  background: #1f378e;
}
.host-excel-upload-btn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  border: 1px solid #2b4ac4;
  color: #ffffff;
}

/**
 * Version : 1.0
 * 파일명 : popup.css
 * 작성일자 : 2021-12-29
 * 작성자 : 강연승
 * 설명 : 팝업 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 팝업 위치 수정
*/
.platformPopup {
  /* margin: 5% auto; */
  margin: 10% auto;
  padding: 16px 16px 21px 16px;
  width: 656px;
  background: white;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.platformPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.platformPopup .popup_title {
  text-align: left;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 8px;
}
.platformPopup .popup_expln {
  margin-bottom: 23px;
  text-align: center;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.platformPopup .closeBtn {
  cursor: pointer;
  float: right;
}
.platformPopup .popup_contents_background {
  padding: 24px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 16px;
  margin-top: 16px;
}
.platformPopup .popup_contents {
  /* background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 24px;
  margin-top: 16px;
  padding: 0px 100px; */
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(296px, 0fr));
}
.platformPopup .platformBox {
  width: 248px;
  height: 116px;
  padding: 20px 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000026;
  border-radius: 16px;
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.platformPopup .selectPlatform {
  border: 1px solid #6e85d7;
  width: 246px;
  height: 114px;
  padding: 20px 24px;
}
.platformPopup .platformBoxText {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
.platformPopup .platformBoxImg {
  display: flex;
  justify-content: flex-end;
}
.platformPopup .platformBox.platformBox_webex.selectPlatform {
  background: #f7f9ff 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_zoom.selectPlatform {
  background: #f6faff 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_chime.selectPlatform {
  background: #f2fbfb 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_pexip.selectPlatform {
  background: #fff8f9 0% 0% no-repeat padding-box;
}
/* .platformPopup .platformBox.platformBox_webex .platformImg {
  margin-top: 32px;
  margin-bottom: 16px;
}
.platformPopup .platformBox.platformBox_zoom .platformImg {
  margin-top: 27px;
  margin-bottom: 19px;
}
.platformPopup .platformBox.platformBox_chime .platformImg {
  margin-top: 56px;
  margin-bottom: 49px;
}
.platformPopup .platformBox.platformBox_pexip .platformImg {
  margin-top: 60px;
  margin-bottom: 47px;
} */

.platformPopup .platformBox .platformNm {
  font: normal normal bold 16px/24px Spoqa Han Sans Neo;
  color: #9b9faf;
  /* margin-top: 8px;
  margin-bottom: 16px; */
}
.platformPopup .platformBox .platformExpln {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #9b9faf;
  /* margin-bottom: 8px; */
}
.platformPopup .platformBox .platformNm.webexActive {
  color: #1bc4ff;
}
.platformPopup .platformBox .platformExpln.webexActive {
  color: #2f65fb;
}
.platformPopup .platformBox .platformNm.zoomActive {
  color: #2c8cfb;
}
.platformPopup .platformBox .platformExpln.zoomActive {
  color: #0c4cba;
}
.platformPopup .platformBox .platformNm.chimeActive {
  color: #1abbc0;
}
.platformPopup .platformBox .platformExpln.chimeActive {
  color: #00868c;
}
.platformPopup .platformBox .platformNm.pexipActive {
  color: #3e3e4f;
}
.platformPopup .platformBox .platformExpln.pexipActive {
  color: #fa2a45;
}
.platformPopup .popup_footer {
  margin-top: 16px;
  text-align: right;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.platformPopup .popup_footer .chkLabel {
  margin-left: 8px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.platformPopup .popup_footer .confirmBtn {
  cursor: pointer;
  margin-left: 16px;
  border: none;
  width: 64px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
}

.resSuccessPopup {
  margin: 6% auto;
  /* padding: 8px 16px 24px 16px; */
  width: 416px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.resSuccessPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.resSuccessPopup .popup_title {
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-align: center;
}
.resSuccessPopup .closeBtn {
  cursor: pointer;
  float: right;
  margin: 8px 16px 0 0;
  /* position: absolute;
  top: 210px;
  right: 720px; */
}
.resSuccessPopup .platformImg {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}
.resSuccessPopup .popup_contents_background {
  padding: 0px 24px 24px 16px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 16px 16px;
}
.resSuccessPopup .popup_contents {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  margin-bottom: 16px;
  margin-left: 32px;
}
.resSuccessPopup .popup_contents .infoLabel {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  width: 61px;
  margin-right: 10px;
  text-align: right;
  color: #2b4ac4;
  display: inline-block;
}

.resSuccessPopup .div_scheduleURL {
  margin: 24px 20px 0px 20px;
  padding: 14px 21px 9px 24px;
  text-align: center;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.resSuccessPopup .scheduleURL {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  margin-right: 20px;
  display: inline-block;
}
.resSuccessPopup .scheduleURLText {
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
}

.resSuccessPopup .copyIcon {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.resSuccessPopup .popup_contents .scheduleTitle {
  text-align: left;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  vertical-align: bottom;
  margin-top: 24px;
}
.resSuccessPopup .popup_contents .scheduleRecording {
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
  margin-top: 16px;
}

.resSuccessPopup .popup_contents .scheduleInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  display: inline-block;
}
.resSuccessPopup .popup_contents .scheduleContents {
  word-break: break-all;
  width: 273px;
  max-height: 72px;
  overflow-y: auto;
  white-space: pre-line;
}
.resSuccessPopup .popup_contents .participantsList {
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.resSuccessPopup .popup_contents .scheduleFileList {
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.resSuccessPopup .popup_contents .scheduleFileInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  cursor: pointer;
  display: block;
}
.resSuccessPopup .popup_footer {
  text-align: center;
  margin-top: 28px;
}
.resSuccessPopup .confirmBtn {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
}
.makeGroupPopup {
  text-align: center;
  width: 480px;
  /* padding: 29px 80px 40px; */
  /* min-height: 444px; */
  margin: 13% auto;
  background: white;
  border-radius: 32px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 16px #00000029;
}
.makeGroupPopupOverlay {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.makeGroupPopup_background {
  width: 480px;
  /* height: 444px; */
}

.makeGroupPopup_header {
  margin-top: 29px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.makeGroupPopup_body {
  width: 480px;
  margin: 0px 17px 0px 17px;
}

.inviteGroupAgreePopup {
  text-align: center;
  width: 536px;
  /* padding: 29px 80px 40px; */
  /* min-height: 444px; */
  margin: 10% auto;
  background: white;
  border-radius: 32px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 16px #00000029;
}
.inviteGroupAgreePopup_Overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

/* 그룹 거절 확인 alert */
.denyAlert .MuiBackdrop-root {
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.denyAlert .MuiPaper-elevation24 {
  box-shadow: -5px 3px 20px #00000029;
}
.denyAlert .MuiDialog-paperWidthXs {
  width: 255px;
  padding: 24px 8px 8px 24px;
  border-radius: 16px;
}
.denyAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
.denyAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.denyAlert .btnYes {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}

.scheduleDetail {
  margin: 5% auto;
  padding: 10px 16px 24px 16px;
  width: 374px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.scheduleDetail_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.scheduleDetail .closeBtn {
  cursor: pointer;
  float: right;
  /* margin: 8px 16px 0 0; */
  /* position: absolute;
  top: 210px;
  right: 720px; */
}
.scheduleDetail .popup_header,
.scheduleDetail .popup_contents {
  margin-left: 24px;
}
.scheduleDetail .scheduleTitle {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  /* color: #2b4ac4; */
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  vertical-align: bottom;
}
.scheduleDetail .scheduleDetail_PlatFormDiv {
  margin-top: 32px;
}
.scheduleDetail .scheduleDetail_HostKeyDiv {
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-top: 8px;
  width: 170px;
  height: 18px;
  padding: 7px 16px 7px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3068fe;
}
.scheduleDetail .scheduleDetail_HostKeyExpln {
  margin-top: 8px;
  font: normal normal bold 12px/18px Spoqa Han Sans Neo;
  color: #c63353;
}

.scheduleDetail .scheduleDetail_GroupNm {
  margin-top: 14px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.scheduleDetail .scheduleDetail_div {
  display: flex;
  margin-top: 16px;
}
.scheduleDetail .scheduleDetail_label {
  width: 61px;
  margin-right: 10px;
  display: inline-block;
  text-align: right;
  color: #2b4ac4;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.scheduleDetail .scheduleDetail_label.timeline {
  color: #2b4ac4;
}
.scheduleDetail .scheduleDetail_label.history {
  color: #3e3e4f;
}
.scheduleDetail .scheduleDetail_recYN.history {
  color: #3e3e4f;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-top: 10px;
}
.scheduleDetail .scheduleDetail_recYN.timeline {
  width: 35px;
  height: 16px;
  padding: 4px 0;
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 6px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  /* margin-top: 10px; */
  display: inline-block;
}
.scheduleDetail .scheduleDetail_Contents {
  text-align: left;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  display: inline-block;
}
.scheduleDetail .scheduleDetail_Contents.participantsList {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 72px;
  overflow-y: auto;
}
.scheduleDetail .scheduleDetail_Contents.scheduleContents {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
  white-space: pre-line;
}
.scheduleDetail .scheduleDetail_Contents.scheduleFileList {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.scheduleDetail .scheduleDetail_Contents .scheduleFileList .scheduleFileInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  cursor: pointer;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}
.scheduleDetail .scheduleDetail_Contents .meetingLink {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}

.scheduleDetail .div_scheduleURL {
  margin: 24px 20px 0 20px;
  padding: 14px 21px 9px 24px;
  text-align: center;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.scheduleDetail .scheduleURL {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  margin-right: 20px;
  display: inline-block;
}
.scheduleDetail .scheduleURLText {
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
}

.scheduleDetail .copyIcon {
  display: inline-block;
  cursor: pointer;
}

.scheduleDetail .footer {
  margin-top: 24px;
  padding-top: 20px;
  text-align: center;
}
.scheduleDetail .footer .editBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #2b4ac4;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleDetail .footer .delBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #c63353;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleDetail .footer .enterBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #3e3e4f;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.workTimePopup {
  margin: 18% auto;
  padding: 36px 38px 30px;
  width: 498px;
  background: white;
  border-radius: 23px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.workTimePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.workTimePopup .popupTitle {
  font: normal normal bold 21px/20px Spoqa Han Sans Neo;
  text-align: left;
  color: #111;
}
.workTimePopup .popupText {
  font: normal normal 500 17px/16px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
  margin-top: 19px;
}
.workTimePopup .timeArea {
  display: flex;
  justify-content: center;
  margin-top: 43px;
  margin-bottom: 39px;
}
.workTimePopup .selectArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workTimePopup .selectBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49px;
  height: 15px;
  padding: 15px 13px 15px 21px;
  border-radius: 10px;
  background-color: #ebedf5;
  font: normal normal normal 18px/15px Spoqa Han Sans Neo;
  color: #111;
}
.workTimePopup .selectIcon {
  width: 13px;
  height: 9px;
}
.workTimePopup .timeText {
  margin-top: 7px;
  font: normal normal 500 15px/15px Spoqa Han Sans Neo;
  color: #909090;
}
.workTimePopup .timeColon {
  margin: 17px 4px 0;
  font: normal normal normal 21px/12px Spoqa Han Sans Neo;
  text-align: center;
  color: #111;
}
.workTimePopup .timeWave {
  margin: 19px 12px 0;
  font: normal normal 500 30px/6px Spoqa Han Sans Neo;
  text-align: center;
  color: #111;
}
.workTimePopup .btnConfirm {
  width: 127px;
  height: 45px;
  font: normal normal normal 18px/17px Spoqa Han Sans Neo;
  text-align: left;
  color: #fff;
  background-color: #436bff;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: none;
}
.workTimePopup .selectPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 83px;
  height: 100px;
  margin-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}
.workTimePopup .selectPopup .selectItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: center;
  height: 30px;
  width: 83px;
  color: #3e3e4f;
  line-height: 30px;
}

/* 통계 상세 팝업 */
.data-statistics-detail-popup {
  margin: 8% auto;
  padding: 12px 16px 20px 24px;
  width: 694px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.data-statistics-detail-popup-header {
  display: flex;
  height: 36px;
  align-items: flex-end;
  justify-content: space-between;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-title {
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  color: #2b4ac4;
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-btnPDF {
  width: 138px;
  height: 36px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 16px;
  border: 1px solid #3e3e4f;
  border-radius: 8px;
  padding: 0 15px 0 12px;
  cursor: pointer;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-btnPDF
  .data-statistics-detail-popup-header-btnPDF-text {
  margin-left: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.data-statistics-detail-popup-info {
  margin-top: 12px;
  margin-right: 8px;
  padding: 12px 24px 20px;
  height: 62px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}
.data-statistics-detail-popup-groupParticipantInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-dateTimeInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-meetingCntInfo {
  display: flex;
  align-items: flex-end;
}
.data-statistics-detail-popup-eduAttendInfo {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}
.data-statistics-detail-popup-groupInfo {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-groupProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}
.data-statistics-detail-popup-groupNm {
  margin-left: 8px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-participant-info {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.data-statistics-detail-popup-participant-name {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-participant-email {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-dateRange {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.data-statistics-detail-popup-useTime {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
}
.data-statistics-detail-popup-edu-total {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.data-statistics-detail-popup-edu-attend {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
}
.data-statistics-detail-popup-meetingCnt {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-data {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-duration {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-cnt {
  margin-left: 24px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-attendance-percent {
  margin-top: 8px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-percent-header {
  display: flex;
  align-items: center;
  height: 32px;
  background: #cad2f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0 16px 0 24px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-percent-body {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-popup-attendance-percent-emptyBody {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
}
.data-statistics-detail-popup-attendance-percent-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 56px;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  text-align: center;
  color: #3e3e4f;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo-value {
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo,
.data-statistics-detail-popup-attendance-eduInfo,
.data-statistics-detail-popup-attendance-socialInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-box {
  width: 14px;
  height: 14px;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #37b392;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-box {
  width: 14px;
  height: 14px;
  background: #37b392 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #37b392;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-box {
  width: 14px;
  height: 14px;
  background: #ee6c4d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-eduInfo-attendance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-box {
  width: 14px;
  height: 14px;
  background: #2993bc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #efac00;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-box {
  width: 14px;
  height: 14px;
  background: #efac00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #efac00;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-box {
  width: 14px;
  height: 14px;
  background: #ee6c4d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-socialInfo-attend {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-box {
  width: 14px;
  height: 14px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-box {
  width: 14px;
  height: 14px;
  background: #c63353 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-log {
  margin-top: 16px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-log-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  background: #cad2f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0 12px 0 24px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-log-paging {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-attendance-log-paging img {
  cursor: pointer;
  margin: 0 4px;
}
.data-statistics-detail-popup-attendance-log-body {
  margin-top: 12px;
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  height: 178px;
}
.data-statistics-detail-popup-attendance-log-table th {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  height: 26px;
  color: #3e3e4f;
}
.data-statistics-detail-popup-attendance-log-table tbody tr {
  height: 28px;
  margin-top: 4px;
}
.data-statistics-detail-popup-attendance-log-table td {
  font-size: 14px;
  line-height: 18px;
  /* font: normal normal normal 14px/18px Spoqa Han Sans Neo; */
}
.data-statistics-detail-popup-attendance-log-table-left {
  padding-left: 8px;
  text-align: left;
}
.data-statistics-detail-popup-attendance-log-table-center {
  text-align: center;
}
.data-statistics-detail-popup-attendance-log-table-title {
  padding-left: 8px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 184px;
}
.data-statistics-detail-popup-attendance-type-WORK_TIME_IN {
  font-weight: bold;
  color: #8b5fbf;
}
.data-statistics-detail-popup-attendance-type-WORK_TIME_OUT {
  font-weight: bold;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-type-WORK_WEEKEND {
  font-weight: bold;
  color: #37b392;
}
.data-statistics-detail-popup-attendance-type-ATTENDANCE {
  font-weight: bold;
  color: #2993bc;
}
.data-statistics-detail-popup-attendance-type-TARDY {
  font-weight: bold;
  color: #efac00;
}
.data-statistics-detail-popup-attendance-type-ABSENT {
  font-weight: bold;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-type-ATTEND {
  font-weight: bold;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-type-NO_ATTEND {
  font-weight: bold;
  color: #c63353;
}
.data-statistics-detail-popup-attendance-log-body-empty {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  color: #8b8e99;
  height: 178px;
}

/**
 * Version : 1.0
 * 파일명 : group.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 그룹 CSS
 * 수정일자 : 2022-03-29
 * 수정자 : 강연승
 * 수정내역 : 주소록 팝업 css 수정
*/
.groupInputValue {
  width: 296px;
  height: 40px;
  text-align: center;
  margin: 16px auto 0px;
  display: block;
  background-color: #f1f3fb;
  border: none;
  border-radius: 10px;
}
.groupInputValue::-webkit-input-placeholder {
  color: #b8c3eb;
}
.groupInputValue::placeholder {
  color: #b8c3eb;
}
.groupInputValue:focus {
  outline: none;
}
.groupInputValue:active {
  outline: none;
}
.groupCreateBtnActive {
  width: 296px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  height: 48px;
  border: none;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: white;
  cursor: pointer;
}
.makeGroupAddressPopup {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 24px;
  margin: 8px 92px;
  overflow-y: auto;
}
.makeGroupAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.makeGroupAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.inviteMeetingHost {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 24px;
  margin: 8px 92px;
  overflow-y: auto;
}
.inviteMeetingHost .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.inviteMeetingHost .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.addressPopupItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: left;
  padding: 0 16px;
  display: flex;
  /* justify-content: space-between; */
}
.addressPopupItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.groupCreateBtn {
  width: 296px;
  background: #8b9ddf 0% 0% no-repeat padding-box;
  height: 48px;
  border: none;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: white;
}
.groupSelectCtgry {
  /* appearance: none; */
  border: 1px solid #2b4ac4;
  background-color: #ffffff;
  width: 298px;
  height: 42px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
}
.groupSelectThemaPopup {
  width: 296px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  margin: 0 90px;
  position: absolute;
}
.groupSelectThemaPopupItem {
  width: 296px;
  height: 30px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
}
.groupSelectCtgry::-ms-expand {
  display: none;
}
.groupListScroll::-webkit-scrollbar {
  width: 8px;
}
.groupListScroll::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.groupListScroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* .inviteGroupAgreePopup .MuiBackdrop-root {
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.inviteGroupAgreePopup .MuiPaper-elevation24 {
  box-shadow: 0px 5px 16px #00000029;
}
.inviteGroupAgreePopup .MuiDialog-paperWidthXs {
  min-width: 536px;
  border-radius: 24px;
} */

/* 그룹 배경색상 */
.groupBgColor_red {
  background: #de8799;
}
.groupBgColor_orange {
  background: #d78e71;
}
.groupBgColor_yellow {
  background: #cdb14c;
}
.groupBgColor_bean {
  background: #aed057;
}
.groupBgColor_green {
  background: #64d483;
}
.groupBgColor_mint {
  background: #6cd6bf;
}
.groupBgColor_blue {
  background: #8b9ddf;
}
.groupBgColor_pine {
  background: #75bdd9;
}
.groupBgColor_pink {
  background: #e08ec9;
}
.groupBgColor_pink {
  background: #cb86dd;
}
.groupBgColor_purple {
  background: #a994e1;
}
.groupBgColor_black {
  background: #3b3f4c;
}

/* 그룹설명 색상 */
.groupExplnColor_red {
  color: #f0c9d2;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_orange {
  color: #edccbf;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_yellow {
  color: #ece1b9;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_bean {
  color: #e8f2cf;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_green {
  color: #d9f4e0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_mint {
  color: #d6f4ed;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_blue {
  color: #cad2f0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pine {
  color: #bfe3f1;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pink {
  color: #f4d9ed;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_pink {
  color: #e7c9f0;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_purple {
  color: #d8cef1;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}
.groupExplnColor_black {
  color: #84889b;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
}

/* 그룹멤버 색상 */
.groupMemberColor_red {
  color: #c63353;
}
.groupMemberColor_orange {
  color: #b24e27;
}
.groupMemberColor_yellow {
  color: #806a1c;
}
.groupMemberColor_bean {
  color: #aed057;
}
.groupMemberColor_green {
  color: #28b750;
}
.groupMemberColor_mint {
  color: #229a80;
}
.groupMemberColor_blue {
  color: #4865cc;
}
.groupMemberColor_pine {
  color: #1e6e8d;
}
.groupMemberColor_pink {
  color: #721959;
}
.groupMemberColor_pink {
  color: #bd66d4;
}
.groupMemberColor_purple {
  color: #7855d0;
}
.groupMemberColor_black {
  color: #3b3f4c;
}

/* 그룹리더 색상 */
.groupLeaderColor_red {
  color: #c63353;
}
/* 누락됨 */
.groupLeaderColor_orange {
  color: #b24e27;
}
.groupLeaderColor_yellow {
  color: #806a1c;
}
.groupLeaderColor_bean {
  color: #749420;
}
/* 누락됨 */
.groupLeaderColor_green {
  color: #28b750;
}
.groupLeaderColor_mint {
  color: #229a80;
}
.groupLeaderColor_blue {
  color: #4865cc;
}
.groupLeaderColor_pine {
  color: #2993bc;
}
.groupLeaderColor_pink {
  color: #c23099;
}
/* 누락됨 */
.groupLeaderColor_pink {
  color: #bd66d4;
}
/* 누락됨 */
.groupLeaderColor_purple {
  color: #7855d0;
}
.groupLeaderColor_black {
  color: #3b3f4c;
}

/* 그룹미팅 색상 */
/* 누락됨 */
.groupMeetingColor_red {
  color: #c63353;
}
.groupMeetingColor_orange {
  color: #692e17;
}
/* 누락됨 */
.groupMeetingColor_yellow {
  color: #806a1c;
}
/* 누락됨 */
.groupMeetingColor_bean {
  color: #35450f;
}
.groupMeetingColor_green {
  color: #1b7c37;
}
/* 누락됨 */
.groupMeetingColor_mint {
  color: #229a80;
}
/* 누락됨 */
.groupMeetingColor_blue {
  color: #4865cc;
}
.groupMeetingColor_pine {
  color: #2993bc;
}
/* 누락됨 */
.groupMeetingColor_pink {
  color: #721959;
}
.groupMeetingColor_pink {
  color: #661b7a;
}
.groupMeetingColor_purple {
  color: #46239f;
}
.groupMeetingColor_black {
  color: #000000;
}
/* 그룹테두리 색상 */
.groupBorderColor_red {
  border: 1px solid #ebb6c1;
  border-top: none;
}
.groupBorderColor_orange {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_yellow {
  border: 1px solid #d9c375;
  border-top: none;
}
.groupBorderColor_bean {
  border: 1px solid #d7e8ac;
  border-top: none;
}
.groupBorderColor_green {
  border: 1px solid #9be3af;
  border-top: none;
}
.groupBorderColor_mint {
  border: 1px solid #95e1d1;
  border-top: none;
}
.groupBorderColor_blue {
  border: 1px solid #b8c3eb;
  border-top: none;
}
.groupBorderColor_pine {
  border: 1px solid #adcfdd;
  border-top: none;
}
.groupBorderColor_pink {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_pink {
  border: 1px solid #f2adf1;
  border-top: none;
}
.groupBorderColor_purple {
  border: 1px solid #a994e1;
  border-top: none;
}
.groupBorderColor_black {
  border: 1px solid #b8c3eb;
  border-top: none;
}

/* 그룹테두리 색상 */
.groupBorderColor_red_bottom_none {
  border: 1px solid #ebb6c1;
  border-bottom: none;
}
.groupBorderColor_orange_bottom_none {
  border: 1px solid #e7baa9;
  border-bottom: none;
}
.groupBorderColor_yellow_bottom_none {
  border: 1px solid #d9c375;
  border-bottom: none;
}
.groupBorderColor_bean_bottom_none {
  border: 1px solid #d7e8ac;
  border-bottom: none;
}
.groupBorderColor_green_bottom_none {
  border: 1px solid #9be3af;
  border-bottom: none;
}
.groupBorderColor_mint_bottom_none {
  border: 1px solid #95e1d1;
  border-bottom: none;
}
.groupBorderColor_blue_bottom_none {
  border: 1px solid #b8c3eb;
  border-bottom: none;
}
.groupBorderColor_pine_bottom_none {
  border: 1px solid #adcfdd;
  border-bottom: none;
}
.groupBorderColor_pink_bottom_none {
  border: 1px solid #e7baa9;
  border-bottom: none;
}
.groupBorderColor_pink_bottom_none {
  border: 1px solid #f2adf1;
  border-bottom: none;
}
.groupBorderColor_purple_bottom_none {
  border: 1px solid #a994e1;
  border-bottom: none;
}
.groupBorderColor_black_bottom_none {
  border: 1px solid #b8c3eb;
  border-bottom: none;
}

/* 그룹테두리 색상 */
.groupBorderColor_red_top_none {
  border: 1px solid #ebb6c1;
  border-top: none;
}
.groupBorderColor_orange_top_none {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_yellow_top_none {
  border: 1px solid #d9c375;
  border-top: none;
}
.groupBorderColor_bean_top_none {
  border: 1px solid #d7e8ac;
  border-top: none;
}
.groupBorderColor_green_top_none {
  border: 1px solid #9be3af;
  border-top: none;
}
.groupBorderColor_mint_top_none {
  border: 1px solid #95e1d1;
  border-top: none;
}
.groupBorderColor_blue_top_none {
  border: 1px solid #b8c3eb;
  border-top: none;
}
.groupBorderColor_pine_top_none {
  border: 1px solid #adcfdd;
  border-top: none;
}
.groupBorderColor_pink_top_none {
  border: 1px solid #e7baa9;
  border-top: none;
}
.groupBorderColor_pink_top_none {
  border: 1px solid #f2adf1;
  border-top: none;
}
.groupBorderColor_purple_top_none {
  border: 1px solid #a994e1;
  border-top: none;
}
.groupBorderColor_black_top_none {
  border: 1px solid #b8c3eb;
  border-top: none;
}

/* 그룹멤버 색상 */
.groupPebble_title_red {
  color: #96394d;
}
.groupPebble_title_orange {
  color: #7d371b;
}
.groupPebble_title_yellow {
  color: #725314;
}
.groupPebble_title_bean {
  color: #465b14;
}
.groupPebble_title_green {
  color: #104920;
}
.groupPebble_title_mint {
  color: #10483b;
}
.groupPebble_title_pine {
  color: #134457;
}
.groupPebble_title_pink {
  color: #5c1d6d;
}
.groupPebble_title_purple {
  color: #391d82;
}
.groupPebble_title_black {
  color: #000000;
}

/**
 * Version : 1.0
 * 파일명 : dashboard.css
 * 작성일자 : 2022-02-22
 * 작성자 : 강연승
 * 설명 : 대시보드 CSS
 * 수정일자 : 2022-02-22
 * 수정자 : 강연승
 * 수정내역 : 
*/
.dashboardBackground {
  width: 1362px;
  height: 803px;
  overflow-y: auto;
  padding: 23px 21px;
  border-radius: 16px;
}
.dashboardMeetingItemGrid {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardMeetingItem {
  width: 225px;
  height: 61px;
  padding: 21px 17px 22px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}

.dashboardMeetingItem .title {
  margin-left: 7px;
  font: normal normal normal 15px/19px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #555;
}
.dashboardMeetingItem .tooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  text-align: left;
  margin-top: 15px;
}
.dashboardMeetingItem .tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 35px;
}
.dashboardMeetingItem .reserveTotal {
  display: flex;
  flex-direction: column;
  padding-right: 14px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #ffb423;
}
.dashboardMeetingItem .reserveToday {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #c0c0c0;
  padding-left: 13px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
}
.dashboardMeetingItem .meetingEndBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 6px 9px 6px 0px;
  border-radius: 2px;
  background-color: #12e0c4;
}

.dashboardMeetingItem .meetingNotOverBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 6px 10px;
  border-radius: 2px;
  background-color: #ff734f;
}
.dashboardMeetingItem .meetingEndBorder {
  border-left: 1px solid #c0c0c0;
  height: 22px;
}
.dashboardMeetingItem .meetingEnd {
  display: inline-block;
  padding-right: 13px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
  vertical-align: top;
}
.dashboardMeetingItem .meetingNotOver {
  display: inline-block;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
  vertical-align: top;
}

.dashboardMeetingItem .reserveTime {
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
}
.dashboardMeetingItem .realMeetingTime {
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #ffb423;
}
.dashboardMeetingItem .avgMeetingDuration {
  font: normal normal bold 24px/20px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
}
.dashboardMeetingItem .durationMax,
.dashboardMeetingItem .durationMin {
  font: normal normal bold 21px/17px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
}
.dashboardMeetingItem .durationMaxText,
.dashboardMeetingItem .durationMinText {
  margin-top: 5px;
  font: normal normal bold 14px/14px Spoqa Han Sans Neo;
  text-align: left;
  color: #acacac;
}
.dashboardMeetingItem .hourText {
  margin-left: 10px;
  font: normal normal bold 19px/18px Spoqa Han Sans Neo;
  text-align: left;
  color: #acacac;
  vertical-align: bottom;
}
.dashboardDoughnutArea {
  margin-top: 26px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardAccCntArea {
  width: 635px;
  padding: 18px 17px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardAccCntArea .title {
  margin-left: 7px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardAccCntArea .accValue {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
}
.dashboardAccCntArea .accValue .currency {
  font: normal normal 500 37px/28px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accCost {
  margin-left: 14px;
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .time {
  margin-left: 17px;
  font: normal normal 500 37px/28px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accTime {
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accMinute {
  margin-left: 16px;
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .myAcc {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 531px;
}
.dashboardAccCntArea .myAcc .res {
  font: normal normal bold 60px/48px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
  margin: 0 auto;
}
.dashboardAccCntArea .myAcc .borderLine {
  border-right: 1px solid #c0c0c0;
  height: 48px;
}
.dashboardAccCntArea .myAcc .parti {
  font: normal normal bold 60px/48px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
  margin: 0 auto;
}
.dashboardAccCntArea .buttonArea {
  padding-top: 1px;
  display: flex;
  margin-right: 56px;
  flex-direction: column;
  align-items: center;
}
.dashboardAccCntArea .buttonArea .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  opacity: 0.68;
  border-radius: 50%;
  background-color: #e8e8e8;
}
.dashboardAccCntArea .buttonArea .button .arrowIcon {
  width: 13px;
  height: 9px;
}
.dashboardAccCntArea .buttonArea .buttonMenu {
  margin: 24px 0px 23px 0px;
  font: normal normal 500 23px/21px Spoqa Han Sans Neo;
  color: #6c6c6c;
}
.dashboardAccCntArea .buttonArea .tooltipUp {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  text-align: center;
  margin-bottom: 7px;
}
.dashboardAccCntArea .buttonArea .tooltipDown {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  text-align: center;
  margin-top: 7px;
}
.dashboardAccCntArea .buttonArea .tooltipDown:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -5px;
  right: 9px;
}
.dashboardAccCntArea .buttonArea .tooltipUp:after {
  border-top: 10px solid #3e3e4f;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0px solid transparent;
  content: "";
  position: absolute;
  bottom: -5px;
  right: 9px;
}
.dashboardDoughnutChartArea {
  width: 633px;
  height: 227px;
  padding: 12px 18px 11px 18px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardDoughnutChartArea .dashboardIcon {
  margin-top: 8px;
}
.dashboardDoughnutChartArea .title {
  margin-top: 10px;
  margin-left: 8px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardDoughnutChartArea .btnTimeSetting {
  height: 14px;
  cursor: pointer;
  float: right;
  margin-right: 6px;
  padding: 11px 12px 10px 12px;
  border-radius: 10px;
  background-color: #436bff;
  font: normal normal normal 14px/14px Spoqa Han Sans Neo;
  text-align: center;
  color: #fff;
}
.dashboardDoughnutChartArea .chartItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .socialItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .nodataItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .nodataItem canvas {
  display: block;
  box-sizing: border-box;
  width: 135px;
  height: 135px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.dashboardDoughnutChartArea .nodataItem .nodataValue {
  position: absolute;
  margin-top: 50px;
  font: normal normal 500 19px Spoqa Han Sans Neo;
  color: #111111;
}
.dashboardDoughnutChartArea .socialItem .socialDiv {
  padding-top: 59px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.dashboardDoughnutChartArea .socialItem .socialValue {
  max-width: 150px;
  height: 35px;
  font: normal normal bold 40px/35px Spoqa Han Sans Neo;
  text-align: center;
  color: #436bff;
}
.dashboardDoughnutChartArea .socialItem .social {
  margin-left: 13px;
  font: normal normal 500 20px/19px Spoqa Han Sans Neo;
  text-align: center;
  color: #436bff;
}
.dashboardDoughnutChartArea .chartItem .chartTitle,
.dashboardDoughnutChartArea .socialItem .chartTitle,
.dashboardDoughnutChartArea .nodataItem .chartTitle {
  margin-top: 19px;
  width: 107px;
  height: 15px;
  font: normal normal normal 15px/15px Spoqa Han Sans Neo;
  text-align: center;
  color: #666;
}
.dashbaordBarArea {
  margin-top: 27px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardMeetingCntBarArea {
  width: 632px;
  height: 255px;
  padding: 20px 20px 13px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardMeetingCntBarArea .title {
  margin-left: 11px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardMeetingCntBarArea .resMeetingDiv {
  display: flex;
  align-items: center;
  padding-right: 14px;
  border-right: 1px solid #c0c0c0;
  margin-right: 13px;
}
.dashboardMeetingCntBarArea .endMeetingDiv {
  display: flex;
  align-items: center;
}
.dashboardMeetingCntBarArea .resMeeting,
.dashboardMeetingCntBarArea .endMeeting {
  font: normal normal normal 17px/16px Spoqa Han Sans Neo;
  text-align: left;
  color: #111;
  vertical-align: top;
  margin-right: 10px;
}
.dashboardMeetingCntBarArea .resMeetingBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #ffb423;
}
.dashboardMeetingCntBarArea .endMeetingBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #7f84fb;
}
.dashboardHostResMeetingCnt {
  width: 631px;
  height: 256px;
  padding: 19px 20px 13px 18px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardHostResMeetingCnt .dashboard_item_header {
  display: flex;
  justify-content: space-between;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea {
  display: flex;
  align-items: center;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea .sortText {
  margin-right: 10px;
  font: normal normal normal 17px/14px Spoqa Han Sans Neo;
  text-align: left;
  color: #555;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea .sortIcon {
  margin-right: 8px;
  cursor: pointer;
}
.dashboardHostResMeetingCnt .title {
  margin-left: 10px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashbaordListArea {
  margin-top: 26px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardResMeetingList {
  width: 632px;
  height: 306px;
  padding: 21px 20px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardEndMeetingList {
  width: 637px;
  height: 305px;
  padding: 21px 20px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardResMeetingList .title {
  margin-left: 10px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardEndMeetingList .title {
  margin-left: 11px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardResMeetingList .dashboard_item_header,
.dashboardEndMeetingList .dashboard_item_header {
  display: flex;
  justify-content: space-between;
  height: 22px;
}

.dashboardResMeetingList .totalCnt,
.dashboardEndMeetingList .totalCnt {
  font: normal normal normal 15px/15px Spoqa Han Sans Neo;
  color: #111;
}
.dashboardResMeetingList .noMeetingList,
.dashboardEndMeetingList .noMeetingList {
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  color: #8b8e99;
  height: 267px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardResMeetingList .meetingList,
.dashboardEndMeetingList .meetingList {
  margin: 16px 0 12px 31px;
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboardResMeetingList .meetingList_table,
.dashboardEndMeetingList .meetingList_table {
  border-spacing: 0;
}
.dashboardResMeetingList .meetingList_table th,
.dashboardEndMeetingList .meetingList_table th {
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  text-align: left;
  padding: 13px 0;
  color: #646464;
  border-bottom: 1px solid #436bff;
}
.dashboardResMeetingList .meetingList_table td,
.dashboardEndMeetingList .meetingList_table td {
  font: normal normal normal 14px/14px Spoqa Han Sans Neo;
  text-align: left;
  padding: 12px 12px 12px 0;
  color: #646464;
  border-bottom: 1px solid #c0c0c0;
}
.dashboardResMeetingList .meetingList_table .td_title,
.dashboardEndMeetingList .meetingList_table .td_title {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.dashboardResMeetingList .meetingList_table .td_hostNm,
.dashboardEndMeetingList .meetingList_table .td_hostNm {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60px;
}

.dashboard_list_paging {
  width: 100%;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard_list_paging .pageNumDiv {
  margin: 0 20px;
  display: flex;
}
.dashboard_list_paging .pageNumDiv .pageText {
  margin: 0 10px;
  cursor: pointer;
  font: normal normal normal 14px/12px Spoqa Han Sans Neo;
  color: #8b8e99;
}
.dashboard_list_paging .pageNumDiv .pageText.pageNow {
  font-weight: bold;
  color: #436bff;
}
.dashboard_list_paging .first_btn {
  width: 15px;
  height: 15px;
  margin-right: 12px;
}
.dashboard_list_paging .end_btn {
  width: 15px;
  height: 15px;
  margin-left: 12px;
}
.dashboard_list_paging .prev_btn {
  width: 12px;
  height: 15px;
}
.dashboard_list_paging .next_btn {
  width: 12px;
  height: 15px;
}

.DormantPopup {
    margin: 10% auto;
    width: 432px;
    height: 520px;
    background: white;
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
 .DormantPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  .dormant_end{
     margin-left: auto;
     margin-right: auto;
    text-align: center;
    letter-spacing: 0px;
    color: #84889B;
    opacity: 1;
    background-color: #EDEDF0;
    width: 384px;
    height: 88px;
    padding-top: 16px;
    border-radius: 8px;
  }
  .dormant_end_text{
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
  }
  .dormant_Check_ID{
      width: 296px;
      height: 29px;
      padding-top: 11px;
      background-color:#3B3F4C ;
      text-align: center;
      font-weight: bold;
      color: #AFB3BF;
      font-size: 14px;
      border-radius: 8px;
      opacity: 1;
      margin-bottom: 24px;

  }
  .dormant_input_mail{
    width: 290px;
    height: 18px;
    padding-top: 11px;
    border: 1px solid #AFB3BF;
    border-radius: 8px;

    margin-bottom: 10px;
    padding-bottom: 11px;
    text-align: center;
  }
  .dormant_input_mail ::-webkit-input-placeholder{
    font-size: 14px;
    color: #AFB3BF;
    font-weight: 500;
    height: 18px;
  }
  .dormant_input_mail ::placeholder{
    font-size: 14px;
    color: #AFB3BF;
    font-weight: 500;
    height: 18px;
  }
  .dormant_input_mail:focus {
    border: 1px solid #3B3F4C;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    outline: none;
  }
  .dormant_middle_text{
      text-align: center;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 16px;
      color: #3B3F4C;
      font-family: Spoqa Han Sans Neo;
  }
  .dormant_head_text{
      text-align: center;
      color: #2B4AC4;
      font-weight: bold ;
      margin-bottom: 20px;
  }
  .dormant_activeline{
    margin-left: auto;
    margin-right: auto;
  }

  .dormant_correct_input{
    width: 290px;
    height: 18px;
    padding-top: 11px;
    border: 1px solid #2B4AC4;
    border-radius: 8px;
    margin-bottom: 10px;
    padding-bottom: 11px;
    text-align: center;
  }
 .dormant_correct_input:focus{
    border: 1px solid #2B4AC4;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    outline: none;
 }
  .dormant_correct_ID{
    width: 296px;
    height: 29px;
    padding-top: 11px;
    background-color:#2B4AC4  ;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 24px;
  }





  .DormantsuccessPopup {
  margin: 15% auto auto auto;
    vertical-align: middle;
    width: 400px;
    height: 256px;
    background: white;
    border-radius: 16px;
    z-index: 99;
    box-shadow: 0px 5px 20px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
 .DormantsuccessPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .Dormantsuccess_bottom{
        margin-bottom: 31px;
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #666B82;
  }
  .Dormanttime{
      color: #2B4AC4;;
  }
  .Dormantsuccess_mid{
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #3B3F4C;
  }
  .Dormantsuccess_top{
    margin-bottom: 22px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2B4AC4;  
  }
/**
 * Version : 1.0
 * 파일명 : empty.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 잘못된 페이지 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Empty_Div {
  text-align: center;
}
/**
 * Version : 1.0
 * 파일명 : landing.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 랜딩페이지 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
*/
.Landing_menu {
  font-family: "Spoqa Han Sans Neo", sans-serif;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 1em;
  margin: 15px 40px;
  text-transform: uppercase;
}
.Landing_menu li {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 0.6em;
  padding: 12px 15px;
  position: relative;
}
.Landing_menu li div {
  color: blue;
  font-weight: bold;
  text-decoration: none;
}
.Landing_menu li:before,
.Landing_menu li:after {
  transition: all 0.35s ease;
  height: 2px;
  width: 35%;
  background-color: #528cb3;
  position: absolute;
  content: "";
  opacity: 0;
}
.Landing_menu li:before {
  top: 0;
  left: 0;
}
.Landing_menu li:after {
  bottom: 0;
  right: 0;
}
.Landing_menu li:hover:before,
.Landing_menu li.hover:before,
.Landing_menu li:hover:after,
.Landing_menu li.hover:after {
  opacity: 1;
}
.Landing_menu li:hover:before,
.Landing_menu li.hover:before {
  left: 65%;
}
.Landing_menu li:hover:after,
.Landing_menu li.hover:after {
  right: 65%;
}
.Landing_Header {
  margin-top: 34px;
  margin-left: 200px;
  margin-right: 140px;
}
.Landing_LoginBtn {
  width: 5rem;
  height: 2.5rem;
}
.Landing_Body {
  height: 45rem;
}
.header_sample {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  /* color: white; */
  background: white;
}
.main_sample {
  padding: 7rem 7rem 0 7rem;
  height: 100%;
}
.footer_sample {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.slider-div {
  position: absolute;
}

.Landing_Header_Btn {
  min-width: 120px;
  height: 48px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 39px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border: none;
}
.Landing_Header_Btn:active {
  outline: none;
}

/* .maintainance {
  height: 100%;
  background: #f2f4fb;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
} */
.maintainance{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (min-width:600px) {
    /**
    HEADER
    **/
    header {
        z-index: 999;
        position: fixed;
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(255, 255, 255);
    }
    .hd_content {
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hd_content > section {
        width: 500px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hd_txt_btn_area {
        display: flex;
        width: 280px;
        justify-content: space-between;
    }
    .hd_txt_btn_area > button {
        background: none;
        outline: none;
        border: none;
        color: rgb(139, 95, 191);
        font-weight: bold;
    }
    .hd_btn {
        background: rgb(43, 74, 196);
        border-radius: 20px;
        width: 88px;
        height: 40px;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: bold;
        border: none;
    }
    /**
    BODY
    **/
    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        font-family: "Spoqa Han Sans Neo";
    }
    /* 우측 상단 이동 버튼 */
    .bd_top_btn {
        z-index: 999;
        position: fixed;
        right: 5%;
        bottom: 5%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        cursor: pointer;
        width: 66px;
        height: 66px;
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px;
        border-radius: 150px 70px;
        background: rgb(139, 95, 191);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 최상단 로고 섹션 */
    .bd_title_section {
        width: 100%;
        height: 921px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: rgb(242, 244, 251);
    }
    .bd_title_section > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1200px;
    }
    .bd_title_logo {
        margin-bottom: 24px;
    }
    .bd_title_logo_article {
        font-size: 32px;
        font-weight: bold;
        color: rgb(43, 74, 196);
        text-align: center;
        margin-bottom: 32px;
    }
    .bd_title_logo_article > p {
        margin: 0px;
    }
    .bd_title_btn {
        background: rgb(43, 74, 196);
        border: none;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 16px;
        height: 48px;
        width: 240px;
        font-family: "Spoqa Han Sans Neo";
        margin-bottom: 72px;
    }
    .bd_title_pebble_article {
        text-align: center;
        margin-bottom: 152px;
    }
    .bd_title_pebble_article > p {
        font-size: 28px;
        font-weight: bold;
        color: rgb(62, 62, 79);
    }
    /* 캘린더 이미지 섹션 */
    .bd_calendar_section {
        position: absolute;
        inset: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bd_calendar_section > img {
        width: 1124px;
        height: 702px;
    }
    /* Pebble 홍보 문구 섹션 */
    .bd_pr_section {
        width: 100%;
        height: 3176px;
        background: rgb(43, 74, 196);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .bd_pr_content {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .bd_pr_title_article {
        text-align: center;
        color: rgb(255, 255, 255);
        font-weight: bold;
    }
    .bd_pr_title {
        font-size: 32px;
    }
    .bd_pr_description {
        font-size: 20px;
    }
    .bd_pr_content > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        height: 180px;
        margin-bottom: 100px;
    }
    .bd_pr_content > ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 200px;
        text-align: center;
    }
    .bd_pr_content > ul > li.center {
        margin: 0px 65px;
    }
    .bd_pr_content > ul > li > title {
        height: 126px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
    }
    .bd_pr_feature {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 72px;
    }
    .bd_pr_feature > p {
        color: rgb(255, 255, 255);
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
    .bd_pr_feature > img {
        position: absolute;
        bottom: 10px;
    }
    .bd_pr_feature_item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 72px;
    }
    .bd_pr_feature_item.last {
        margin-bottom: 136px;
    }
    .bd_pr_feature_item > article.left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        margin-left: 70px;
        margin-bottom: 70px;
    }
    .bd_pr_feature_item > article.right {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        margin-left: 70px;
        margin-bottom: 70px;
    }
    .bd_pr_feature_item_title {
        width: 224px;
        height: 56px;
        background: rgb(31, 55, 142);
        border-radius: 72px 28px;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }
    .bd_pr_feature_item_description {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: bold;
    }
    .bd_pr_feature_item_description.right {
        text-align: right;
    }
    /* 사용가이드 섹션 */
    .bd_guide_section {
        width: 100%;
        height: 875px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .bd_guide_title {
        text-align: center;
        margin-top: 128px;
        font-size: 32px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        margin-bottom: 64px;
    }
    .bd_guide_video {
        width: 1024px;
        height: 600px;
        background: rgb(223, 225, 229);
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 10px;
        border-radius: 32px;
    }
    /* 제품 설명 섹션 */
    .bd_product_section {
        width: 100%;
        height: 736px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }
    /* 무료 체험 섹션 */
    .bd_ep_section {
        width: 100%;
        height: 1300px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 120px;
    }
    .bd_ep_item_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_item_list > .center {
        margin: 0 75px;
    }
    .bd_ep_item_title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_item_title.blue {
        color: rgb(43, 74, 196);
    }
    .bd_ep_item_title.orange {
        color: #EE6C4D;
    }
    .bd_ep_item_title.purple {
        color: #8B5FBF;
    }
    .bd_ep_item_description {
        margin-top: 32px;
        margin-bottom: 61px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        text-align: center;
    }
    .bd_ep_fuction_area {
        margin-bottom: 136px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 1000px;
    }
    .bd_ep_fuction_title {
        color: rgb(43, 74, 196);
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_logo {
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .bd_ep_fuction_area > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_area > ul > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_fuction_area > ul > li > div {
        z-index: 99;
    }
    .bd_ep_fuction_area > ul > li > img {
        position: absolute;
    }
    .bd_ep_btn_area {
        margin-top: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bd_ep_btn {
        background: rgb(238, 108, 77);
        border-radius: 10px;
        width: 240px;
        height: 56px;
        outline: none;
        border: none;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: bold;
    }
    /**
    FOOTER
    **/
    footer {
        width: 100%;
        height: 200px;
        background-color: rgb(62, 62, 79);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ft_content {
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .ft_btn_area {
        display: flex;
        width: 100%;
        justify-content: center;
        color: rgb(255, 255, 255);
        margin-top: 24px;
    }
    .ft_btn {
        font-size: 18px;
    }
    .ft_btn.center1 {
        margin: 0px 18px 0px 36px;
    }
    .ft_btn.center2 {
        margin: 0px 36px 0px 18px;
    }
    .ft_info {
        font-size: 14px;
        margin-top: 24px;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}

@media screen and (max-width:599px) {
    /**
    HEADER
    **/
    header {
        z-index: 999;
        position: fixed;
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgb(255, 255, 255);
        justify-content: flex-start;
    }
    .hd_content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    .hd_content > section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hd_content > div > img {
        width: 70%;
        margin-left: 40px;
    }
    .hd_txt_btn_area {
        display: flex;
        width: 200px;
        justify-content: space-between;
    }
    .hd_txt_btn_area > button {
        background: none;
        outline: none;
        border: none;
        color: rgb(139, 95, 191);
        font-weight: bold;
        font-size: 10px;
    }
    .hd_btn {
        background: rgb(43, 74, 196);
        border-radius: 20px;
        width: 60px;
        height: 30px;
        color: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: bold;
        border: none;
        margin: 0 5px;
    }
    /**
    BODY
    **/
    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        font-family: "Spoqa Han Sans Neo";
    }
    /* 우측 상단 이동 버튼 */
    .bd_top_btn {
        z-index: 999;
        position: fixed;
        right: 5%;
        bottom: 5%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        cursor: pointer;
        width: 66px;
        height: 66px;
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px;
        border-radius: 150px 70px;
        background: rgb(139, 95, 191);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 최상단 로고 섹션 */
    .bd_title_section {
        width: 100%;
        height: 670px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: rgb(242, 244, 251);
    }
    .bd_title_section > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1200px;
    }
    .bd_title_logo {
        margin-bottom: 24px;
        width: 150px;
    }
    .bd_title_logo_article {
        font-size: 24px;
        font-weight: bold;
        color: rgb(43, 74, 196);
        text-align: center;
        margin-bottom: 32px;
    }
    .bd_title_logo_article > p {
        margin: 0px;
    }
    .bd_title_btn {
        background: rgb(43, 74, 196);
        border: none;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 16px;
        height: 48px;
        width: 240px;
        font-family: "Spoqa Han Sans Neo";
        margin-bottom: 40px;
    }
    .bd_title_pebble_article {
        text-align: center;
        margin-bottom: 90px;
    }
    .bd_title_pebble_article > p {
        font-size: 20px;
        font-weight: bold;
        color: rgb(62, 62, 79);
    }
    .bd_title_pebble_article > img {
        width: 80%;
    }
    /* 캘린더 이미지 섹션 */
    .bd_calendar_section {
        position: absolute;
        inset: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-transform: translate(0, 600px) !important;
                transform: translate(0, 600px) !important;
    }
    .bd_calendar_section > img {
        width: 350px;
        height: 230px;
    }
    /* Pebble 홍보 문구 섹션 */
    .bd_pr_section {
        width: 100%;
        height: 2355px;
        background: rgb(43, 74, 196);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .bd_pr_content {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .bd_pr_title_article {
        text-align: center;
        color: rgb(255, 255, 255);
        font-weight: bold;
    }
    .bd_pr_title {
        font-size: 24px;
    }
    .bd_pr_description {
        font-size: 14px;
        max-width: 200px;
    }
    .bd_pr_content > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 50px;
        flex-direction: column;
    }
    .bd_pr_content > ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 200px;
        text-align: center;
    }
    .bd_pr_content > ul > li.center {
        margin: 0px 65px;
    }
    .bd_pr_content > ul > li > title {
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
    }
    .bd_pr_feature {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
    }
    .bd_pr_feature > p {
        color: rgb(255, 255, 255);
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_pr_feature > img {
        position: absolute;
        bottom: 12px;
        width: 390px;
    }
    .bd_pr_feature_item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
    }
    .bd_pr_feature_item.center {
        flex-direction: column-reverse;
    }
    .bd_pr_feature_item > img {
        width: 50%;
        margin-bottom: 20px;
    }
    .bd_pr_feature_item.last {
        margin-bottom: 136px;
    }
    .bd_pr_feature_item > article.left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
    .bd_pr_feature_item > article.right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
    .bd_pr_feature_item_title {
        width: 200px;
        height: 56px;
        background: rgb(31, 55, 142);
        border-radius: 72px 28px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }
    .bd_pr_feature_item_description {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: bold;
    }
    .bd_pr_feature_item_description.right {
        text-align: left;
    }
    /* 사용가이드 섹션 */
    .bd_guide_section {
        width: 100%;
        height: 560px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bd_guide_section > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bd_guide_title {
        text-align: center;
        margin-top: 80px;
        font-size: 22px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        margin-bottom: 64px;
    }
    .bd_guide_video {
        width: 400px;
        height: 250px;
        background: rgb(223, 225, 229);
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 10px;
        border-radius: 32px;
    }
    /* 제품 설명 섹션 */
    .bd_product_section {
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }
    .bd_product_section > div > img {
        width: 98%;
    }
    /* 무료 체험 섹션 */
    .bd_ep_section {
        width: 100%;
        height: 1832px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-top: 120px;
        margin-bottom: 100px;
    }
    .bd_ep_item_list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bd_ep_item_list > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .bd_ep_item_list > li > img {
        width: 60%;
    }
    .bd_ep_item_list > .center {
        margin: 0 75px;
    }
    .bd_ep_item_title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_item_title.blue {
        color: rgb(43, 74, 196);
    }
    .bd_ep_item_title.orange {
        color: #EE6C4D;
    }
    .bd_ep_item_title.purple {
        color: #8B5FBF;
    }
    .bd_ep_item_description {
        margin-top: 32px;
        margin-bottom: 61px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        text-align: center;
    }
    .bd_ep_fuction_area {
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bd_ep_fuction_title {
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_logo {
        margin-top: 50px;
        margin-bottom: 60px;
        width: 50%;
    }
    .bd_ep_fuction_area > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        flex-direction: column;
        height: 250px;
    }
    .bd_ep_fuction_area > ul > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_fuction_area > ul > li > div {
        z-index: 99;
        font-size: 18px;
    }
    .bd_ep_fuction_area > ul > li > img {
        position: absolute;
    }
    .bd_ep_btn_area {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bd_ep_btn {
        background: rgb(238, 108, 77);
        border-radius: 10px;
        width: 200px;
        height: 48px;
        outline: none;
        border: none;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: bold;
    }
    /**
    FOOTER
    **/
    footer {
        width: 100%;
        height: 200px;
        background-color: rgb(62, 62, 79);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ft_content {
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .ft_content > img {
        width: 20%;
    }
    .ft_btn_area {
        display: flex;
        width: 100%;
        justify-content: center;
        color: rgb(255, 255, 255);
        margin-top: 24px;
    }
    .ft_btn {
        font-size: 14px;
    }
    .ft_btn.center1 {
        margin: 0px 10px 0px 10px;
    }
    .ft_btn.center2 {
        margin: 0px 0px 0px 10px;
    }
    .ft_info {
        font-size: 10px;
        margin-top: 18px;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}
/**
 * Version : 1.0
 * 파일명 : alertsup.css
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : support모달 창 CSS
*/
.supportPopup {
    margin: 5% auto;
    width: 530px;
    height: 550px;
    background: white;
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .supportPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .checkimage{
    margin : 34px 238px 22px 238px;
    width: 54px;
    height: 52px;
}
 .tt-1{
     display: flex;
     flex-direction: column;
 }

 .texthead{
     display: flex;
     margin: 0 154px 0 154px;
     padding-left: 15px;
     padding-right: 15px;
     height: 35px;
     line-height: 28px;
     background-color: white;
     position: absolute;
     top: 108px;

 }
 .texthead .textblack{
     font-size: 21px;
     color: #222222;
 }
 .texthead .textblue{
     font-size: 21px;
     color: #2b4ac4;
     width: 80px;
     height: 28px;
     line-height: 28px;
     margin-left: 10px;     
 }
 .outline{
    width: 438px;
    height: 319px;
    margin: 28px 46px 28px 46px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
    border-image-slice: 1;
    background-image: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
}
.outline .innertext{
    width: 438px;
    height: 319px;
    background-color: white;
}
.innertext .textblack{
    text-align: center;
    font-size: 21px;
    color: #222222;
    background-color: white;
    margin-bottom: 43px;
}
.textmid{
    text-align: center;
    margin-left: 87px;
    width: 267px;
    height: 43px;
}
.textmid1{
font-size: 15px;
}
.textmid2{
    display: flex;
    height: 20px;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 42px;
}
.textmid2 .textblack{
    font-size: 15px;
}
.textmid2 .textorange{

    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    color: #f3512b;
    font-weight: bold;
    
}
.textunder{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
.textlast{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
.gohomebutton{
    width: 123px;
    height: 43px;
    margin: 0 204px 0 203px;
    border-radius: 5px;
    background-color: #2b4ac4;
    text-align: center;
}
.buttontext{
    width: 28px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 43px;
    color: #fff;
    margin-top: 12px;
    padding-top : 12px
}
/**
 * Version : 1.0
 * 파일명 : paging.css
 * 작성일자 : 2022-04-22
 * 작성자 : 권도훈
 * 설명 : 페이징 CSS
*/
.pg-common-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pg-common-font {
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.pg-common-div > div {
  display: flex;
  align-items: center;
}

.pg-common-img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.pg-common-img:last-child {
  margin-right: 0;
}

/**
 * Version : 1.0
 * 파일명 : profilesupport.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 기본페이지 css
 **/
.profile_content_support{
    margin: 34px 68px 33px 66px;
    height: 757px;
}

.first_card{
    display: flex;
    margin-bottom: 13px;
    text-align: center;
}
.profile_content_faq{
    width: 392px;
    height: 216px;
    margin-right: 14px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;

}
.sup_faqimg{
    margin-top: 43px; 
    margin-bottom: 16px;
}
.sup_faqimg2{

    margin-top: 43px; 
    margin-bottom: 16px;
}
.sup_faq{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_faq_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}
.hover_blue:hover .profile_content_faq{
    background-color: #f4f5fb;
}
.hover_blue:hover .text_hover_blue{
    font-weight: bold;
    color:  #2b4ac4 ; 
}
.hover_blue:hover { 
    color:
    #2b4ac4 ; 
}


/* 첫번쨰 FAQ 카드 끝*/

.profile_content_directquestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
}
.sup_dqimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_directquestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_dq_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}

.hover_orange:hover .profile_content_directquestion{
    background-color: #fcf5f3;
}
.hover_orange:hover .text_hover_orange{
    font-weight: bold;
    color: #ff734f ; 
}
.hover_orange:hover { 
    color: #ff734f; 
}

/*두번쨰 카드 끝*/

.second_card{
    display: flex;
    margin-bottom: 13px;
    text-align: center;
}

.profile_content_suggestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    margin-right: 14px;
}
.sup_suggestionimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_suggestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_suggestion_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}
.hover_purple:hover .profile_content_suggestion{
    background-color: 
    #f3f3fb;
}
.hover_purple:hover .text_hover_purple1{
    font-weight: bold;
    color: #7875f6 ; 
}
.hover_purple:hover .text_hover_purple2{
    font-weight: bold;
    color: #7875f6 ; 
}
.hover_purple:hover { 
    color: #7875f6;
}


/* 세번째 카드 끝*/

.profile_content_myquestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
}
.sup_myquestionimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_myquestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_myquestion_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}

.hover_mint:hover .profile_content_myquestion{
    background-color: 
    #f4fcfb;
}
.hover_mint:hover .text_hover_mint{
    font-weight: bold;
    color: #23dec4 ; 
}
.hover_mint:hover { 
    color: #23dec4;
}

/* 네번 째 카드 끝*/

.third_card{
    display: flex;

}
.profile_content_tel{
    margin-right: 14px;
    width: 392px;
    height: 298px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
.sup_tel_title{
    height: 18px;
    margin: 47px 229px 24px 50px;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
    letter-spacing: normal;
    color: #111;
}
.sup_tel_num{
    height: 32px;
    margin: 0 0 53px 50px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #111;
}
.sup_tel_time{
    height: 82px;
    margin: 0px 78px 0 74px; 
}
.sup_tel_time_font{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color:   #555;
    line-height:161%;
}

.profile_content_announcement{
    margin-top : 47px;
    margin-left : 23px;
}
.sup_announcement_title{
    margin-bottom: 35px;
    width: 70px;
    height: 18px;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    
}
.announcementbody{
    width: 354px;
    height: 47px;
    display: flex;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: left;
    color: #868e96;

    border-top: solid 1px #c0c0c0;
    
}
.announcement_index{
    margin: 0 13px 0 13px;
}
.announcement_title{
    margin: 0 0 0 25px;
}

.announcementbodyfinish {
    width: 354px;
    height: 1px;
    background-color: #d2d2d2;
  }
.announcement_more{
    margin: 19px 0 0 289px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #4f575f;
}
/**
 * Version : 1.0
 * 파일명 : profilesupsuggestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 고객의 소리 css
  * 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 고객센터 고객의 소리 크기 조정
 **/ 
.suggestionHeader{
    display: flex;
    font-size: 21px;
    justify-content: center;
    padding: 11px 0 30px 0;
    color: 
    #555555;
}
.suggestionHeaderbold{
    text-align: center;
    font-weight: bold;
}
.suggestionsHeader{
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    height: 40px;
    margin-bottom: 30px;

}
.suggestionsHeader-2{
    margin-top: 2px;
}





.suggestioninputtext{
    display: flex;
    margin-bottom: 12px;
    height: 205px;
}
.suggestioninputtexttitle{
    margin: 22px 68px 0 122px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    }

.suggestioninputtextmain{
    width: 484px;
    height :158px;
    padding: 22px 33px 22px 33px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    background-color: #fff;
    resize: none;
    font-family: SpoqaHanSansNeo;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.suggestioninputtext textarea::-webkit-input-placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
}
.suggestioninputtext textarea::placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
}

.suggestioninputfile{
    display : flex;
    margin-bottom: 25px;
}

.suggestioninputfiletitle{
  margin:  22px 68px 0 122px;

  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
}

.supsuggestionline{
    width: 687px;
    height: 1px;
    margin: 0 122px 22px 123px;
    background-color: #c0c0c0;
}
.suggestionfooter{
    margin-bottom: 22px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-align: center;
}
.suggestionfooter-2{
    display: flex;
    justify-content: center;
}
.suggestionbutton{
    width: 160px;
    height: 53px;
    border-radius: 10px;
    background-color: #7875f6;
    text-align: center;
    letter-spacing: normal;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}
.suggestionimagebtn{
    width: 60px;
    height: 14px;
    padding: 10px 15px 11px 15px;
    border-radius: 5px;
    background-color: #555;
    font-family: SpoqaHanSansNeo-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.suggestion_inputFileholder{
  width: 502px;
  height: 54px;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  padding-right: 17px;
  padding-left: 33px;
  display: flex;
}
.suggestion_inputFileinfo{
    margin-left: 1px;
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 54px;
}
.inputfilebtn{
    line-height: 54px;
}
.suggestion_inputFileholder{
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 52px;
    color: #aaa;
    justify-content: space-between;
}
.fileListscrollbar{
    overflow-y: scroll;
    height: 52px;
    width: 420px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.suggestion_img{
    display: flex;
    justify-content: space-between;
}
/**
 * Version : 1.0
 * 파일명 : alertsup.css
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : support모달 창 CSS
*/
.supportPopupmain {
    margin: 5% auto;
    width: 530px;
    height: 490px;
    background: white;
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
 .supportPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
   .checkimage{
    margin : 34px 238px 22px 238px;
    width: 54px;
    height: 52px;
}
 .tt-1{
     display: flex;
     flex-direction: column;
 }

  .texthead{
     display: flex;
     margin: 0 154px 0 154px;
     padding-left: 15px;
     padding-right: 15px;
     height: 35px;
     line-height: 28px;
     background-color: white;
     position: absolute;
     top: 108px;

 }
  .texthead .textblack{
     font-size: 21px;
     color: #222222;
 }
  .texthead .textblue{
     font-size: 21px;
     color: #2b4ac4;
     width: 80px;
     height: 28px;
     line-height: 28px;
     margin-left: 10px;     
 }
  .outlinemain{
    width: 438px;
    height: 250px;
    margin: 28px 46px 28px 46px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
    border-image-slice: 1;
    background-image: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
}
 .outlinemain .innertextmain{
    width: 438px;
    height: 250px;
    background-color: white;
}
 .innertextmain .textblack{
    text-align: center;
    font-size: 21px;
    color: #222222;
    background-color: white;
    margin-bottom: 43px;
}
 .textmid{
    text-align: center;
    margin-left: 87px;
    width: 267px;
    height: 43px;
}
 .textmid1{
font-size: 15px;
}
 .textmid2{
    display: flex;
    height: 20px;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 42px;
}
 .textmid2 .textblack{
    font-size: 15px;
}
 .textmid2 .textorange{

    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    color: #f3512b;
    font-weight: bold;
    
}
 .textunder{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
 .textlast{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
 .gohomebutton{
    width: 123px;
    height: 43px;
    margin: 0 204px 0 203px;
    border-radius: 5px;
    background-color: #2b4ac4;
    text-align: center;
}
 .buttontext{
    width: 28px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 43px;
    color: #fff;
    margin-top: 12px;
    padding-top : 12px
}
/**
 * Version : 1.0
 * 파일명 : profilefaq.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 FAQ css
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 :사이즈 변경
 **/
.faq_main{
    margin-top:18px;
}
.faq_body_title_noclick{
    margin: 0px 36px 17px 37px;

    height: auto;
    width: 788px;
    border-radius: 10px;
    height: 34px;
    border: solid 1px rgba(144, 144, 144, 0.3);
    padding: 12px 44px 12px 27px;
    line-height: 18px;

    max-height: 100px;
    min-height : 34px;
    transition: max-height 1s ease-out, min-height 0.3s ease-out;
    -webkit-transition: max-height 1s ease, min-height 0.3s ease;

}
.opensrr{
    width: 788px;
    height: auto;
    margin: 0px 36px 17px 37px;
    padding: 12px 44px 34px 26px;
    border-radius: 10px;
    border: solid 1px #2b4ac4;
    font-size: 17px;
    max-height: 1000px;
    min-height: 100px;
}
.faq_body_title_noclick_header{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    
}
.faq_body_title_text1{
    line-height: 34px;
    margin-left: 47px;
}
.faq_body_title_text{
    line-height: 34px;
    margin-left: 48px;
}
.faq_body_title_onclick{
    width: 788px;
    max-height: 600px;
    margin: 0px 36px 17px 37px;
    padding: 12px 44px 34px 26px;
    border-radius: 10px;
    border: solid 1px 
    #2b4ac4;
    font-size: 17px;
    /* transition: all 0.1s cubic-bezier(0.22, 0.01, 0.71, 1.2); */

}

.faq_body_title_onclick_header{
    justify-content: space-between;
    display: flex;
}
.faq_body_line{
    width: 788px;
    height: 1px;
    margin: 12px 0 14px;
    background-color: #ddd;
}
.dttm_img{
    display: flex;
   
}
.imgcursorfaq{
    margin-top: 10px;
}
.faq_body_content{
    display: flex;
}
.faq_body_content_text{
    margin-left: 47px;
    line-height: 2;
    font-size: 16px;
}
.faq_body_files{
    margin-left: 78px;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.faq_body_filedownload{
    margin-left: 20px;
}
.FAQ_Search{
    display: flex;
    margin-left: 256px;
 
}
.FAQ_Searchbar{
    height: 30px;
    width: 364px;
    padding: 13px 26px 12px 30px;
    border-radius: 10px;
    border-color: rgba(217, 217, 217, 0.3) ;
    background-color: rgba(217, 217, 217, 0.3);
    color: #909090;
    display: flex;
    justify-content: space-between;

}
.FAQ_Searchbar_input{
    width: 300px;
    background-color: rgba(217, 217, 217, 0.05);
    border: 0px;
    outline: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #111;
}
.FAQ_Searchbar ::-webkit-input-placeholder{
    
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Searchbar ::placeholder{
    
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Searchbar_input ::-webkit-input-placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Searchbar_input ::placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Select_Category{
    height: 55px;
    border-radius: 10px;
    margin-right: 20px;
    background-color: rgba(217, 217, 217, 0.3);
    color: #909090;
    border: 0px;
    outline: none;
    text-align: center;
}
.FAQ_answermark{
    height: 35px;
    width: 35px;
}
.faq_body_content_text p{
    margin: 0px;
}

.faq_file_title{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
color: #2b4ac4;
}
.FAQpaging{
    margin-top: 6px;
    padding-right: 320px;
    padding-left: 320px;
    padding-bottom: 6px;
}
/**
 * Version : 1.0
 * 파일명 : profiledirectquestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 1:1문의 css
* 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 1:1문의 버튼 크기조정
 **/
.directquestion_headline{
    text-align: center;
    height: 41px;
    margin-top: 18px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    margin-bottom: 44px;
}
.directquestion_header{
    height: 20px;
    line-height: 20px;
}
.directquestion_header1{
    height: 4px;
}
.directquestion_category_head, .directquestion_name_head,
.directquestion_name_head, .directquestion_phonenumber_head,
.directquestion_email_head, .directquestion_title_head,
.directquestion_content_head, .directquestion_file_head{
    margin-left: 123px;
    display: flex;
}

.directquestion_category, .directquestion_name,
.directquestion_phonenumber, .directquestion_email,
.directquestion_title, .directquestion_content, .directquestion_file{
    display: flex;
    justify-content: space-between;
}

.dq_input_name,
.dq_input_phonenumber, .dq_input_email, .dq_input_title
{
    margin-right: 122px;
    width: 486px;
    height: 14px;
    padding : 23px 33px 25px 33px;
    margin-bottom: 18px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
} 
.directquestion_category_body {
    margin-right: 122px;
    width: 554px;
    height: 64px;
    border-radius: 10px;
    margin-bottom: 18px;
    padding-right: 17px;
    /* padding-bottom: 25px; */

    font-size: 17px;
    padding-left: 27px;
    border: solid 1px #c0c0c0;
    /* padding : 23px 33px 25px 33px;
  
  
    border: solid 1px #c0c0c0;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left; */
}
.dq_fontblack{
    height: 62px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: normal;
    text-align: left;
    color: #111; 
}
.dq_fontorange{
    height: 62px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: normal;
    text-align: left;
    color: #ff734f;
}
.dq_input_content{
    margin-right: 122px;
    width: 486px;
    height: 205px;
    padding : 23px 33px 25px 33px;
    margin-bottom: 18px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    resize: none;
    color: #111;
    font-family: Spoqa Han Sans Neo sans-serif;

}
.dq_input_phonenumber ::-webkit-input-placeholder, .dq_input_name ::-webkit-input-placeholder, .dq_placeholder ::-webkit-input-placeholder
{
 color: #aaa;
 font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;

}
.dq_input_phonenumber ::placeholder,
.dq_input_name ::placeholder,
.dq_placeholder ::placeholder
{
 color: #aaa;
 font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;

}



.directquestion_content textarea::-webkit-input-placeholder{
    color: #afb3bf;
    font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;
}



.directquestion_content textarea::placeholder{
    color: #afb3bf;
    font: normal normal normal 17px Spoqa Han Sans Neo sans-serif;
}
.dq_underline{
    width: 687px;
    height: 1px;
    margin: 43px 122px 35px 123px;
    background-color: #c0c0c0;
}
.directquestion_footer{
    height: 16px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #555;
}
.directquestion_button{
    width: 160px;
    height: 58px;
    margin: 43px 386px 0px 386px;
    border-radius: 10px;
    background-color: #ff734f;
    text-align: center;
    line-height: 58px;
    font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}


.dq_input_file{
    margin-right: 122px;
    width: 552px;
    height: 62px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    display: flex;
    justify-content: space-between;
}
.dq_input_fileinfo{
    font-family: SpoqaHanSansNeo;
    width: 460px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.dq_inputfilebtn{
    line-height: 62px;
    margin-right: 17px;
}
.dq_inputFileplaceholder{
    margin-left: 33px;
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
    line-height: 62px;
}
.dq_fileListscrollbar{
    overflow-y: scroll;
    height: 52px;
    padding-top: 5px;
    padding-bottom: 5px;

}
.dq_img_test{
    display: flex;
    justify-content: space-between;
}

.directquestion_button_body{
    height: 90px;
}
/**
 * Version : 1.0
 * 파일명 : profilesupmyquestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 내 문의사항 css
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : p태그 수정
 **/
.myquestion_main_c{
    margin: 18px 36px 0px 37px;
    padding: 12px 24px 12px 27px;
    border-radius: 10px;
    border: solid 1px rgba(144,144,144, 0.3);
    transition: max-height 1s ease-out, min-height 0.35s ease-out;
    -webkit-transition: max-height 1s ease,
    min-height 0.35s ease;
    min-height: 34px;
    max-height: 34px;
    
    
}
.openmyquestion{
    margin: 18px 36px 0px 37px;
    padding: 12px 24px 12px 27px;
    border-radius: 10px;
    border: solid 1px #23dec4;
    min-height: 80px;
    max-height: 1000px;
}



.myquestion_main .insertimage{
    height: 50px;
    width : 50px;
    margin-left: 19px;
}

.myquestion_closed_title{
    display: flex;
    justify-content: space-between;

}
.myquestion_closed_title .c_title_right{
    display: flex;

}
.c_title_left{
    font-size: 17px;
    line-height: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

}

.myquestion_answer_unfinished{
    padding: 7px 13px 7px 12px;
    border-radius: 5px;
    border: solid 1px #23dec4;
    margin : 0px 29px 0px 15px;
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #23dec4;
}

.myquestion_answer_complete{
    padding: 7px 19px 7px 18px;
    border-radius: 5px;
    border: solid 1px #23dec4;
    margin : 0px 29px 0px 15px;
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #23dec4;
  color: #fff;
}
.myquestion_writedate{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #909090;
    line-height: 35px;
}
.myquestion_small_header{
    margin: 33px 0 21px 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #23dec4;
}
.myquestion_content{
    margin-left: 19px;
    font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  white-space:pre;
}
.myquestion_content p{
    margin: 0px;
}
.myquestion_line{
    width: 788px;
    height: 1px;
    margin: 19px 0 35px;
    background-color: #ddd;
}
.myquestion_line2{
    width: 788px;
    height: 1px;
    margin: 33px 0 30px;
    background-color: #ddd;
}
.myquestion_file{
    display: flex;
    margin-top: 19px;
    margin-bottom: 31px;
}
.myquestion_file_title{
    margin: 20px 15px 0px 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #23dec4;
}
.myquestion_end{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.myquestion_end .myquestion_email_address{
    margin-left: 19px;
    font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color:  #909090;
}
.myquestion_end_button{
    display: flex;
    margin-right: 19px;
}
.myquestion_update{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
    margin-right: 13px;
}
.myquestion_delete{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
    margin-left: 12px;
}
.myquestion_line3{
    width: 1px;
  height: 16px;
  margin-top: 3px;
  background-color: #909090;  
}
.img_dttm_cursor{
    margin-right: 21px;
}
.supportpaging{
    margin-top: 6px;
    padding-right: 320px;
    padding-left: 320px;
    padding-bottom: 6px;
   
}
.myquestion_file_txt{
    font-size: 16px;
    margin-bottom : 5px

}
.myquestion_file_body{
    margin-top: 20px;
    
}

/**
 * Version : 1.0
 * 파일명 : alertsupimage.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : alert모달창 CSS
 **/
.supportPopupimage {
    margin-left: 10% ;
    margin-top: 5%;
    width: 80%;
    height: 80%;
    background: rgba(120, 120, 120, 0.8);
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .supportPopupimage_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .modal_image_background{
      text-align: center;
      width: 100%;
      height: 100%;
    
  }
  .modal_image_center{
    line-height: 725px;
  }
/**
 * Version : 1.0
 * 파일명 : profileannouncement.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 공지사항 css
* 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 공지사항 강조 css 작업
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : 사이즈 조정
 **/
.announcement_main{
    margin-top:18px;
}
.announcement_body_title_noclick{
    margin: 0px 36px 17px 37px;
    width: 788px;
    border-radius: 10px;
    border: solid 1px rgba(144, 144, 144, 0.3);
    padding: 12px 44px 12px 26px;
    line-height: 18px;

    max-height: 100px;
    min-height : 34px;
    transition: max-height 0.7s ease-out, min-height 0.6s ease-out;
    -webkit-transition: max-height 0.7s ease, min-height 0.6s ease;

}

.openannouncement{
 
    margin: 0px 36px 17px 37px;
    padding: 17px 44px 33px 26px;
    border-radius: 10px;
    border: solid 1px #00205b;

    max-height: 1000px;
    min-height: 100px;
}
.announcement_body_title_noclick_header{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    line-height: 30px;
}

.announcement_body_title_noclick_header .noclick_dttm{
    color : rgba(144, 144, 144, 1); font-size: 15px;
}
.announcement_body_title_onclick_header .onclick_dttm{
    color : rgba(144, 144, 144, 1); font-size: 15px;
}


.announcement_body_title_onclick_header{
    justify-content: space-between;
    display: flex;
    font-size: 17px;
}
.announcement_body_line{
    width: 788px;
    height: 1px;
    margin: 19px 0 35px;
    background-color: #ddd;
}
.announcement_body_content p{
    margin: 0px;
}
.announcement_body_content{
    margin-left: 10px;
    font-size:  16px;
  
}
.announcement_files{
    margin-bottom : 15px;
    margin-top : 35px;

}
.announcement_file{
    margin-left: 10px;
}
.announcement_right .img_d_cursor{
 margin-left: 16px;
}
.announcement_right{
    display: flex;
}
.announcement_hold{
    display: flex;
}
.announcement_hold_text{
    width: 26px;
    height: 14px;
    margin: 0 10px 0 0;
    padding: 8px 11px 9px 11px;
    border-radius: 15.4px;
    background-color: #00205b;
    font-size: 14px;
    color: #fff;
    line-height: 14px;
}

.announcement_hold{
    height: 31px;
    line-height: 31px;
}
.announcement_file{
    margin: 0px 0px 0px 0px;
    display: flex;
}
.announcement_file_txt{
    margin-left: 10px;
    font-size: 16px;
    display: flex;
}
.announcement_file_title{
    margin-right: 10px;
    color : #00205b;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-left: 10px;
}
/**
 * Version : 1.0
 * 파일명 : profilesupportdetail.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 작동페이지 css
 **/
.Supmenu0, .Supmenu1,.Supmenu2,.Supmenu3,.Supmenu4{
    display : flex;
}

.titleFAQ , .titleDQ, .titleSuggest, .titlemyDQ, .titleannouncement{
    width: 932px;
    height: 195px;

    border-radius: 32px;
    text-align: center;

}
.titleFAQ{
    background-color: #f4f5fb;
}
.titleDQ{
    background-color: #fbf6f4;
}

.titleSuggest{
    background-color: #f6f6fc;
}
.titlemyDQ{
    background-color: #f4fcfb;
}
.titleannouncement{
    background-color: #f6f8fd;
}

.SubtitleFAQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #2b4ac4;
}
.SubtitleDQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #ff734f;
}

.SubtitleSuggest{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #7875f6;
}

.SubtitlemyDQ{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color: #23dec4;
}

.Subtitleannouncement{
    padding-top: 36px;
    padding-bottom:50px;
    height: 31px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 31px;
    letter-spacing: normal;
    text-align: center;
    color:#00205b;
}

.Supmenu0,.Supmenu1,.Supmenu2,.Supmenu3,.Supmenu4{
    margin-left: 97px;
}

.Supfaq, .Supdirectquestion, .Supsuggestion, .Supmyquestion, .Supannouncement{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    border: solid 1px #9da1b2;

    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #9da1b2;
}

.Supmenu0 .Supfaq{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #2b4ac4;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu1 .Supdirectquestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #ff734f;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu2 .Supsuggestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color:#7875f6;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}

.Supmenu3 .Supmyquestion{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #23dec4;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}
.Supmenu4 .Supannouncement{
    margin-right: 15px;
    width:136px;
    height:50px;
    border-radius: 24px;
    background-color: #00205b;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0px;
}
.SupContentArea{
 padding-top: 30px;
height: 195px;
}


/**
 * Version : 1.0
 * 파일명 : coachmark.css
 * 작성일자 : 2022-06-08
 * 작성자 : 권도훈
 * 설명 : 코치마크 CSS
*/
.ReactModal__Body--open {
    position: relative;
    width: 100%;
    height: 100%;
}
.coachmark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.73);
    z-index: 80;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 100%;
    min-height: 100%;
}

.coachmark {
    height: 100%;
    min-width: 1680px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    border: none;
}

.cm-slide-btn-area {
    position: absolute;
    width: calc(100% - 82px);
    margin: 0 41px;
    display: flex;
}
.cm-slide-btn {
    position: absolute;
    width: 34px;
    height: 65px;
}
.cm-slide-btn.none {
    display: none;
}
.cm-slide-btn:first-child {
    left: 0;
}
.cm-slide-btn:last-child {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 0;
}

.cm-bot-area {
    position: absolute;
    top: 45px;
    right: 42px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.cm-close-btn {
    width: 55px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm-close-btn-img {
    transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
    transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.cm-close-btn-img.hover {
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.cm-pagecount-area {
    margin-right: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cm-pagecount-current,
.cm-pagecount-total {
    font-family: SpoqaHanSansNeo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.cm-pagecount-current {
    color: #FFFFFF;
}
.cm-pagecount-total {
    color: rgba(255, 255, 255, 0.68);
}
.cm-pagecount-line {
    border: 1px solid rgba(255, 255, 255, 0.68);
    margin: 0 14px;
    font-size: 10px;
    height: 12px;
}
/**
 * Version : 1.0
 * 파일명 : support.css
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : 고객센터 Landing 페이지 CSS
*/
.supportpebblemark{
    margin: 33px 0 39px 360px;
    height: 48px;
    width: 164px ;
    
}

.supportheader{
    height: 120px;

}

.supportbody{
    margin-left: 277px;
    width: 1363;
    height: 890;
    display: flex;
    z-index: 1;
 
}
.imageintroduce{
    color: #ffffff;
}
.spttitle{
    height: 42px;
    margin: 115px 22px 350px 114px;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #f9f9f9;
}
.sptmid-1{
    margin: 0px 0px 0px 114px;
    display: flex;
}

.tel-textbox{
    width: 152px;
    height: 54px;
    margin: 0px 29px 17px 0px;
    border-radius: 27px;
    background-color: rgba(255, 255, 255, 0.24);
}
.tel-text{
    width: 75px;
    height: 20px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left: 38px;
}
.telnumber{
    height: 33px;
    margin: 0px 0px 28px 0px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.sptmid-2{
    margin-left: 297px;
    margin-bottom: 83px;
}
.---1000-1800-1200-1300 {
    width: 250px;
    height: 44px;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.32px;
    text-align: left;
    color: #fff;
  }
  .---1000-1800-1200-1300 .text-style-1 {
    letter-spacing: normal;
  }
  .---1000-1800-1200-1300 .text-style-2 {
    font-weight: bold;
  }
  .Shape-1 {
    width: 493px;
    height: 1px;
    margin: 0px 0px 51px 117px;
    opacity: 0.48;
    background-color: #fff;
  }
 
.link-three{
    display: flex;
    margin-left: 115px;
    
}
.iglink{
    display: none;
}
.ytlink, .nblink, .iglink{
    width: 79px;
    height: 79px;
    margin: 0px 18px 0 0px;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    text-align: center;    
}
.sociallink1{
    padding-top: 28px;
}

.sociallink{
    padding-top: 25px;
}
.texttitle{
    width: 500px;
    height: 19px;
    margin: 111px 80px 18px 106px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #555;
}
.shape-2{
    width: 99px;
    height: 3px;
    margin: 21px 149px 92px 106px;
    background-color: #2b4ac4;
}
.textcategory{
    margin: 0px 0px 0px 106px;
    height: 17px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #111;
    display: flex;
}
.textblue{
    height: 17px;
    margin: 0px 0px 0px 11px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #2b4ac4;
}


.textinput1{
width: 482px;
height: 62px;
margin: 15px 0 36px 106px;
padding: 0px 0 0 33px;
border-radius: 10px;
border: solid 1px #c0c0c0;
font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
color: #aaa;
}

.textinput2{
    width: 482px;
    height: 146px;
    margin: 15px 0 30px 106px;
    padding: 22px 0 0 33px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #aaa;
    font-family:"Spoqa Han Sans Neo", "sans-serif";
    resize: none;    
}
.checkboxbox{
    display: flex;
    margin: 0 0 0 106px;
}
.checkboxtext{
    padding-left: 13px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
}
.checkbox{
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin: 0 0 0 0;
}

.buttonbox{
    margin: 28px 0 30px 199px;
    display: flex;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    
    
}
.cancelbutton{
    width: 160px;
    height: 20px;
    padding: 15px 0 23px;
    border-radius: 10px;
    background-color: #dcdcdc;
}
.sendbutton{
    width: 160px;
    margin-left: 15px;
    height: 20px;
    padding: 15px 0 23px;
    border-radius: 10px;
    background-color: #2b4ac4;
    color: #fff;
}

.supportbody input::-webkit-input-placeholder {
    font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
color: #aaa;
  }

.supportbody input::placeholder {
    font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
letter-spacing: normal;
color: #aaa;
  }
  
.supportbody  textarea::-webkit-input-placeholder{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #aaa;
    font-family : Spoqa Han Sans Neo;
  }
  
.supportbody  textarea::placeholder{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #aaa;
    font-family : Spoqa Han Sans Neo;
  }
/**
 * Version : 1.0
 * 파일명 : recipt.css
 * 작성일자 : 2022-04-15
 * 작성자 : 권도훈
 * 설명 : 전표 css
 * 수정일자 : 2022-04-21
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.rp-container {
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f4f6;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
}

.rp-content {
    padding: 24px;
    width: 352px;
    background-color: rgb(255, 255, 255);
}
.rp-table {
    width: 100%;
}

.rp-table-tr.empty {
    height: 30px;
}

.rp-table-title {
    font-size: 24px;
    font-weight: bold;
    color: #3E3E4F;
}
.rp-table-text {
    font-weight: bold;
    font-size: 14px;
}
.rp-table-text.key {
    color: #84889B;
}
.rp-table-text.value {
    color: #666B82;
}
.rp-table-text.blue {
    color: #2F82F6 !important;
}

.rp-table-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rp-table-div.margin {
    margin-top: 8px;
}
.rp-table-div.line {
    border-bottom: 1px solid #e2e2e2;
    margin: 16px 0;
}

.rp-table-footer {
    font-size: 14px;
    color: #84889B;
    display: flex;
    flex-direction: column;
}
.rp-table-footer.title {
    font-weight: bold;
}

.rp-table-btn {
    width: 36px;
    height: 36px;
    background: #AFB3BF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-out;
}
.rp-table-btn:first-child {
    margin-right: 6px;
}
.rp-table-btn:hover {
    background: #c2c2c2;
}
.rp-table-btn:active {
    background: #AFB3BF;
}

.rp-table-btn > img {
    width: 20px;
    height: 20px;
}
