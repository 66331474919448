.DormantPopup {
    margin: 10% auto;
    width: 432px;
    height: 520px;
    background: white;
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
 .DormantPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  .dormant_end{
     margin-left: auto;
     margin-right: auto;
    text-align: center;
    letter-spacing: 0px;
    color: #84889B;
    opacity: 1;
    background-color: #EDEDF0;
    width: 384px;
    height: 88px;
    padding-top: 16px;
    border-radius: 8px;
  }
  .dormant_end_text{
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-variant: normal;
  }
  .dormant_Check_ID{
      width: 296px;
      height: 29px;
      padding-top: 11px;
      background-color:#3B3F4C ;
      text-align: center;
      font-weight: bold;
      color: #AFB3BF;
      font-size: 14px;
      border-radius: 8px;
      opacity: 1;
      margin-bottom: 24px;

  }
  .dormant_input_mail{
    width: 290px;
    height: 18px;
    padding-top: 11px;
    border: 1px solid #AFB3BF;
    border-radius: 8px;

    margin-bottom: 10px;
    padding-bottom: 11px;
    text-align: center;
  }
  .dormant_input_mail ::placeholder{
    font-size: 14px;
    color: #AFB3BF;
    font-weight: 500;
    height: 18px;
  }
  .dormant_input_mail:focus {
    border: 1px solid #3B3F4C;
    backdrop-filter: blur(2px);
    outline: none;
  }
  .dormant_middle_text{
      text-align: center;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 16px;
      color: #3B3F4C;
      font-family: Spoqa Han Sans Neo;
  }
  .dormant_head_text{
      text-align: center;
      color: #2B4AC4;
      font-weight: bold ;
      margin-bottom: 20px;
  }
  .dormant_activeline{
    margin-left: auto;
    margin-right: auto;
  }

  .dormant_correct_input{
    width: 290px;
    height: 18px;
    padding-top: 11px;
    border: 1px solid #2B4AC4;
    border-radius: 8px;
    margin-bottom: 10px;
    padding-bottom: 11px;
    text-align: center;
  }
 .dormant_correct_input:focus{
    border: 1px solid #2B4AC4;
    backdrop-filter: blur(2px);
    outline: none;
 }
  .dormant_correct_ID{
    width: 296px;
    height: 29px;
    padding-top: 11px;
    background-color:#2B4AC4  ;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 24px;
  }





  .DormantsuccessPopup {
  margin: 15% auto auto auto;
    vertical-align: middle;
    width: 400px;
    height: 256px;
    background: white;
    border-radius: 16px;
    z-index: 99;
    box-shadow: 0px 5px 20px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
 .DormantsuccessPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .Dormantsuccess_bottom{
        margin-bottom: 31px;
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #666B82;
  }
  .Dormanttime{
      color: #2B4AC4;;
  }
  .Dormantsuccess_mid{
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #3B3F4C;
  }
  .Dormantsuccess_top{
    margin-bottom: 22px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #2B4AC4;  
  }