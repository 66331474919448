/**
 * Version : 1.0
 * 파일명 : data.css
 * 작성일자 : 2021-12-29
 * 작성자 : 권도훈
 * 설명 : 데이터 및 통계 CSS
 * 수정일자 : 2022-04-15
 * 수정자 : 강연승
 * 수정내역 : CSS 수정
*/
.data_popup_history,
.data_popup_historyDetail {
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.data_popup_history {
  /* margin: 5% auto; */
  /* width: 768px; */
  height: 768px;
}
.data_popup_historyDetail {
  /* margin: 19% auto; */
  width: 752px;
  height: 212px;
}

.data_content_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}
.data_content_paging div {
  margin-right: 40px;
}
.data_content_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.data_searchType_selectBoxItem {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.data_searchType_selectBoxItem:hover {
  color: #ffffff;
  background: #2b4ac4;
}

.groupData_searchType_selectBoxItem {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.groupData_searchType_selectBoxItem:hover {
  color: #ffffff;
  background: #666b82;
}

.data-statistics-header {
  height: 84px;
  width: 1324px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}

.data-statistics-header.detail {
  height: 85px;
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-header-title {
  color: #3b3f4c;
  font-weight: bold;
  font-size: 20px;
  margin-right: 24px;
  width: 37px;
}

.data-statistics-header-article,
.data-statistics-header-ul,
.data-statistics-header-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-header-options {
  width: 1036px;
  height: 56px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
}

.data-statistics-header-datepicker-area,
.data-statistics-header-search-area,
.data-statistics-sidebar-date-area,
.data-statistics-sidebar-time-area,
.data-statistics-sidebar-host-area {
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-host-area {
  justify-content: space-between;
  width: 100%;
}

.data-statistics-sidebar-host-area-input {
  width: 292px;
  height: 34px;
  border: none;
  outline: 1px solid #dfe1e5;
  border-radius: 8px;
}
.data-statistics-sidebar-host-area-input::placeholder {
  color: #9b9faf;
}

.data-statistics-sidebar-host-area-btn {
  width: 60px;
  height: 36px;
  background: #3e3e4f;
  border-radius: 8px;
  border: none;
  outline: 1px solid #dfe1e5;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}
.data-statistics-header-datepicker-area > font,
.data-statistics-header-search-area > font {
  color: #3b3f4c;
  font-size: 14px;
  font-weight: bold;
}
.data-statistics-header-datepicker-area > font {
  margin-right: 8px;
}
.data-statistics-header-datepicker-area > img {
  margin-left: 16px;
  margin-right: 8px;
}
.data-statistics-header-search-area > font {
  margin-left: 8px;
  margin-right: 17px;
}

.data-statistics-datepicker-range {
  color: #3e3e4f;
  font-size: 14px;
  margin: 0 8px;
}
.data-statistics-datepicker-card {
  width: 178px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #d1d3da;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-datepicker-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 162px;
  height: 20px;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-search-card {
  width: 256px;
  height: 36px;
  background: #ffffffbe;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-search-card > input {
  width: 240px;
  height: 100%;
  background: none;
  outline: none;
  border: none;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-search-card > input::placeholder {
  color: #9b9faf;
}
.data-statistics-searchBtn-card {
  width: 64px;
  height: 36px;
  margin-left: 8px;
  margin-right: 16px;
  background: #666b82;
  box-sizing: border-box;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  border: none;
}
.data-statistics-searchBtn-card:hover {
  background: #243fa5;
}
.data-statistics-searchBtn-card:disabled {
  background: #9b9faf;
  color: #3e3e4f;
}

.data-statistics-card-btn {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}
.data-statistics-card-btn > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.data-statistics-card-btn.og {
  background: #2b4ac4;
}
.data-statistics-card-btn.og:hover {
  background: #2b4ac4;
}
.data-statistics-card-btn.pp {
  background: #2b4ac4;
  margin: 0 8px;
}
.data-statistics-card-btn.pp:hover {
  background: #2b4ac4;
  margin: 0 8px;
}
.data-statistics-card-btn.bl {
  background: #2b4ac4;
}
.data-statistics-card-btn.bl:hover {
  background: #243fa5;
}
.data-statistics-card-btn.gr,
.data-statistics-card-btn.disable,
.data-statistics-card-btn.disable {
  background-color: #ededf0;
}
.data-statistics-card-btn.disable.mg {
  margin: 0 8px;
}
.data-statistics-card-btn.gr:hover {
  background-color: #dfe1e5;
}
.data-statistics-card-toggle {
  display: flex;
  background-color: #ededf0;
  border-radius: 8px;
  position: relative;
}
.data-statistics-card-btn.toggle {
  background: #3b3f4c;
  position: absolute;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease;
  left: 0px;
}
.data-statistics-card-btn.toggle.list {
  left: 37px;
}
.data-statistics-toggle-img {
  z-index: 100;
}

.data-statistics-card-btn.none {
  background: none;
}

.data-statistics-article {
  display: flex;
  justify-content: space-between;
}
.data-statistics {
  background-color: rgba(255, 255, 255, 0.4);
  height: 845px;
  margin-top: 16px;
  border-radius: 12px;
  width: 100%;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}
.data-statistics.detail {
  width: 964px;
  height: 861px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.data-statistics-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.data-statistics-sidebar {
  width: 0px;
  height: 954px;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 96px;
  right: 0;
  transition: width 0.35s ease-out;
  -webkit-transition: width 0.35s ease;
}
.data-statistics-sidebar.show {
  width: 448px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-statistics-sidebar-inner {
  width: 100%;
  justify-content: center;
}

.data-statistics-sidebar-nav {
  width: 90%;
}

.data-statistics-sidebar-title {
  color: #3e3e4f;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
}
.data-statistics-sidebar-title > img {
  margin-right: 8px;
}

.data-statistics-sidebar-container {
  width: 0%;
  float: right;
  height: 861px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  box-sizing: border-box;
  transition: width 0.35s ease-out;
  -webkit-transition: width 0.35s ease;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.data-statistics-sidebar-container.show {
  width: 100%;
}

.data-statistics-sidebar-date,
.data-statistics-sidebar-time,
.data-statistics-sidebar-host,
.data-statistics-sidebar-attendance,
.data-statistics-sidebar-duration {
  width: 362px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.data-statistics-sidebar-date,
.data-statistics-sidebar-time,
.data-statistics-sidebar-host,
.data-statistics-sidebar-attendance {
  margin-bottom: 24px;
}
.data-statistics-sidebar-date > font,
.data-statistics-sidebar-time > font,
.data-statistics-sidebar-host > font {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.data-statistics-sidebar-date {
  margin-top: 32px;
}

.data-statistics-sidebar-attendance-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.data-statistics-sidebar-attendance-title > img {
  margin-left: 8px;
}

.data-statistics-sidebar-attendance-container {
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-statistics-sidebar-attendance-container-header {
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-attendance-title-sub {
  width: 50px;
  height: 18px;
  border-left: 2px solid #2b4ac4;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.data-statistics-sidebar-attendance-box-area {
  height: 36px;
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-attendance-box-area > font {
  margin: 0 8px;
}

.data-statistics-sidebar-attendance-box-item {
  background: #ffffffbe;
  border: 1px solid #ededf0;
  border-radius: 8px;
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-attendance-box-item > font {
  margin-left: 16px;
}

.data-statistics-sidebar-attendance-box-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 8px;
}

.data-statistics-datepicker-side {
  width: 168px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #d1d3da;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-datepicker-side-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 152px;
  height: 20px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-time-div {
  width: 120px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #ededf0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.data-statistics-sidebar-time-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-time-chk {
  display: flex;
  align-items: center;
}

.data-statistics-sidebar-time-chk > input {
  margin: 0 8px;
  width: 18px;
  height: 18px;
}
.data-statistics-sidebar-time-chk > font {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.data-statistics-sidebar-time-popup {
  width: 120px;
  height: 100px;
  overflow-y: auto;
  position: absolute;
  inset: 0 auto auto 0;
  transform: translate(0px, 40px);
  background: #ffffff;
  outline: 1px solid #ededf0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #3e3e4f;
  font-size: 14px;
}
.data-statistics-sidebar-time-popup > div {
  margin-left: 10px;
}

.data-statistics-sidebar-time-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 18px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-sidebar-host-div {
  width: 72px;
  height: 36px;
  background: #ffffffbe;
  outline: 1px solid #3e3e4f;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-statistics-sidebar-host-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56px;
  height: 18px;
  color: #3e3e4f;
  font-size: 14px;
}

.data-statistics-container {
  width: 1368px;
  height: calc(100% - 96px);
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease;
}
.data-statistics-container.detail {
  width: 900px;
}

.data-statistics-container-detail {
  /* width: 900px; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 96px); */
}

.data-statistics-container-detail > article {
  width: 100%;
}

.data-statistics-container-card,
.data-statistics-container-list {
  /* width: 1368px; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 96px); */
}

.data-statistics-container-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-container-card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.data-statistics-container-loading,
.data-statistics-container-empty {
  width: 100%;
  height: calc(100% - 96px);
}

.data-statistics-container-empty {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.data-statistics-container-empty > section {
  margin-top: 32px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: #84889b;
}
.data-statistics-container-empty > section > font:first-child {
  font-size: 18px;
}
.data-statistics-container-empty > section > font:last-child {
  font-size: 14px;
}

.data-statistics-card {
  width: 240px;
  height: 352px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px #00000014;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.data-statistics-card-article,
.data-statistics-card-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-card-article {
  width: 240px;
  flex-direction: column;
}
.data-statistics-card-profile {
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
}
.data-statistics-card-name {
  color: #3e3e4f;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}
.data-statistics-card-email {
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
}

.data-statistics-card-ul {
  width: 192px;
  margin: 34px 0;
}
.data-statistics-card-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}
.data-statistics-card-data.margin {
  margin-top: 8px;
}
.data-statistics-card-data > font {
  color: #84889b;
}
.data-statistics-card-data > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.data-statistics-card-data > div > img {
  margin-right: 8px;
}
.data-statistics-card-data > div > font {
  color: #3e3e4f;
}

.data-statistics-card-detailBtn {
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
}
.data-statistics-card-detailBtn:hover {
  color: #243fa5;
}

.data-statistics-header-detail {
  height: 85px;
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-back-btn {
  width: 104px;
  height: 36px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background: #3b3f4c;
}
.data-statistics-back-btn.disable {
  background: #ededf0;
  color: #84889b;
}
.data-statistics-back-btn > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.data-statistics-back-btn:hover {
  background: #666b82;
}
.data-statistics-back-btn > div > img {
  margin-right: 8px;
}

.data-statistics-list {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.data-statistics-list-checkbox {
  width: 20px;
  height: 20px;
}

.data-statistics-list-thead {
  background: #ffffff;
  box-sizing: border-box;
  height: 56px;
  color: #3b3f4c;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: bold;
}

.data-statistics-list-tr.tbody {
  height: 52px;
  letter-spacing: 0px;
  font-size: 14px;
  color: #000000;
  background: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.data-statistics-list-tr th.left,
.data-statistics-list-tr td.left {
  text-align: left;
}

.data-statistics-list-tr th:first-child,
.data-statistics-list-tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.data-statistics-list-tr th:last-child,
.data-statistics-list-tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.data-statistics-list-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-list-flex-left {
  display: flex;
  align-items: center;
}
.data-statistics-list-flex.th {
  height: 52px;
}
.data-statistics-list-flex.td {
  width: 100%;
  height: 52px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-statistics-list-name {
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-statistics-list-sort {
  margin-left: 8px;
}

.data-statistics-list-detail {
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
  font-weight: bold;
}

.data-statistics-list-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.data-statistics-detail-ul {
  height: 56px;
  border: 1px solid #3e3e4f;
  border-radius: 12px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-statistics-detail-li {
  color: #000000;
  font-size: 14px;
}
.data-statistics-detail-li-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-statistics-detail-li-nowrap.name {
  max-width: 90px;
}
.data-statistics-detail-li-nowrap.email {
  max-width: 120px;
}

.data-statistics-detail-li.name {
  margin: 0 8px;
}
.data-statistics-detail-li.div {
  text-align: center;
}
.data-statistics-detail-li.div > div {
  font-size: 14px;
  font-weight: bold;
  color: #6f7384;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-li.div > div > img {
  margin-right: 6px;
}
.data-statistics-detail-li.div > font {
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
}
.data-statistics-detail-li.div.total {
  margin-left: 32px;
  width: 130px;
}
.data-statistics-detail-li.div.duration {
  margin: 0 16px;
  width: 123px;
}
.data-statistics-detail-li.div.count {
  margin: 0 16px;
  width: 140px;
}
.data-statistics-detail-li.div.rate {
  width: 100px;
}

.data-statistics-detail-div {
  width: 100%;
  /* height: 680px; */
  min-height: 680px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
}

.data-statistics-detail-table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  border-radius: 16px;
  margin-top: 8px;
  width: 868px;
  height: 100%;
}

.data-statistics-detail-thead {
  background: #ecedf0;
  /* width: 868px; */
  box-sizing: border-box;
  border-spacing: 16px 0 0 0;
  height: 48px;
  color: #3e3e4f;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
}

.data-statistics-detail-tbody {
  max-height: 560px;
}

.data-statistics-detail-tr.tbody {
  height: 48px;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  background: rgba(249, 249, 250, 0.6);
  text-align: center;
}

.data-statistics-detail-tr th:first-child,
.data-statistics-detail-tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.data-statistics-detail-tr th:last-child,
.data-statistics-detail-tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.data-statistics-detail-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
}
.data-statistics-detail-flex.td {
  width: 100%;
  text-align: center;
}

.data-statistics-detail-name,
.data-statistics-detail-email {
  /* width: 65px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-statistics-detail-name {
  max-width: 130px;
}
.data-statistics-detail-email {
  max-width: 170px;
}

.data-statistics-detail-time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.data-statistics-detail-community {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.data-statistics-detail-time-work,
.data-statistics-detail-time-overwork,
.data-statistics-detail-time-week,
.data-statistics-detail-attendance,
.data-statistics-detail-tardy,
.data-statistics-detail-absebce {
  width: 36px;
  height: 22px;
  border-radius: 11px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-time-work {
  background: #8b5fbf;
}
.data-statistics-detail-time-overwork {
  background: #37b392;
  margin: 0 4px;
}
.data-statistics-detail-time-week {
  background: #ee6c4d;
}
.data-statistics-detail-attendance {
  background: #2993bc;
}
.data-statistics-detail-tardy {
  background: #efac00;
  margin: 0 4px;
}
.data-statistics-detail-absebce {
  background: #ee6c4d;
}

.statistics-datepicker-start-popper,
.statistics-datepicker-end-popper,
.statistics-datepicker-detail-start-popper,
.statistics-datepicker-detail-end-popper {
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 600;
  width: 250px;
  height: 310px;
}

.statistics-datepicker-start-popper,
.statistics-datepicker-end-popper,
.statistics-datepicker-detail-start-popper {
  transform: translate(0px, 38px) !important;
}

.statistics-datepicker-detail-end-popper {
  transform: translate(-80px, 38px) !important;
}

.data-slider-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-bar-slider-container {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-slider-content {
  position: relative;
  width: 360px;
}

.data-slider-content-track,
.data-slider-content-range,
.data-bar-slider-content-track,
.data-bar-slider-content-range {
  position: absolute;
}

.data-slider-content-track,
.data-slider-content-range,
.data-bar-slider-content-track {
  border-radius: 4px;
  height: 5px;
}

.data-slider-content-track,
.data-bar-slider-content-track {
  background-color: #d1d3da;
  width: 100%;
  z-index: 1;
}
.data-bar-slider-content-track {
  margin-top: 6px;
}

.data-slider-content-range {
  background-color: #2b4ac4;
  z-index: 2;
}
.data-bar-slider-content-range {
  height: 16px;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}
.data-bar-slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 999px;
}
/* .data-bar-slider-dot.purple {
  background-color: #5B407B;
  z-index: 99;
} */
/* .data-bar-slider-content-range > div:first-child {
  padding-left: 4px;
}
.data-bar-slider-content-range > div:last-child {
  padding-right: 4px;
} */

.data-bar-slider-content-range.purple {
  background-color: #8b5fbf;
}
.data-bar-slider-content-range.green {
  background: #37b392;
}
.data-bar-slider-content-range.orange {
  background: #ee6c4d;
}

.data-slider-content-value-left,
.data-slider-content-value-right {
  color: #3e3e4f;
  font-size: 14px;
  font-weight: bold;
}

.data-bar-slider-content-value-left,
.data-bar-slider-content-value-right {
  font-size: 14px;
  font-weight: bold;
}

.data-bar-slider-content-value-left.purple,
.data-bar-slider-content-value-right.purple {
  color: #8b5fbf;
}
.data-bar-slider-content-value-left.green,
.data-bar-slider-content-value-right.green {
  color: #37b392;
}

.data-bar-slider-content-value-left.orange,
.data-bar-slider-content-value-right.orange {
  color: #ee6c4d;
}

.data-slider-thumb,
.data-slider-thumb::-webkit-slider-thumb,
.data-bar-slider-thumb,
.data-bar-slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.data-slider-thumb,
.data-bar-slider-thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 360px;
  outline: none;
}

.data-bar-slider-thumb {
  /* opacity: 0; */
  margin-top: 10px;
}

.data-slider-thumb.left,
.data-bar-slider-thumb.left {
  z-index: 3;
}

.data-slider-thumb.right,
.data-bar-slider-thumb.right {
  z-index: 4;
}

.data-slider-thumb::-webkit-slider-thumb {
  background-color: #2b4ac4;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 0 1px 1px #2B4AC4; */
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.data-slider-thumb::-moz-range-thumb {
  background-color: #2b4ac4;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.data-slider-content-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-bar-slider-thumb::-webkit-slider-thumb {
  border: none;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
  /* border-radius: 10px; */
}
.data-bar-slider-thumb.purple::-webkit-slider-thumb {
  background-color: #8b5fbf;
}
.data-bar-slider-thumb.green::-webkit-slider-thumb {
  background: #37b392;
}
.data-bar-slider-thumb.orange::-webkit-slider-thumb {
  background: #ee6c4d;
}
.data-bar-slider-thumb.left::-webkit-slider-thumb {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.data-bar-slider-thumb.right::-webkit-slider-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-bar-slider-thumb::-moz-range-thumb {
  border: none;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
}
.data-bar-slider-thumb.purple::-moz-range-thumb {
  background-color: #8b5fbf;
}
.data-bar-slider-thumb.green::-moz-range-thumb {
  background: #37b392;
}
.data-bar-slider-thumb.orange::-moz-range-thumb {
  background: #ee6c4d;
}
.data-bar-slider-thumb.left::-moz-range-thumb {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.data-bar-slider-thumb.right::-moz-range-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-statistics-sidebar-duration-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.data-statistics-sidebar-duration-title > img {
  margin-left: 8px;
}

.data-statistics-sidebar-duration-title-sub {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.data-statistics-sidebar-duration-title-sub.purple {
  border-left: 2px solid #8b5fbf;
  color: #8b5fbf;
  width: 62px;
}
.data-statistics-sidebar-duration-title-sub.green {
  border-left: 2px solid #37b392;
  color: #37b392;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.orange {
  border-left: 2px solid #ee6c4d;
  color: #ee6c4d;
  width: 81px;
}
.data-statistics-sidebar-duration-title-sub.deepGreen {
  border-left: 2px solid #2993bc;
  color: #2993bc;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.yellow {
  border-left: 2px solid #efac00;
  color: #efac00;
  width: 36px;
}
.data-statistics-sidebar-duration-title-sub.orange-2 {
  border-left: 2px solid #ee6c4d;
  color: #ee6c4d;
  width: 36px;
}

.data-statistics-sidebar-duration-container {
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.data-statistics-sidebar-duration-container-header {
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-statistics-sidebar-slider-div {
  width: 360px;
  margin: 8px 0;
}

.data-statistics-sidebar-community {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}

.data-statistics-sidebar-community-item {
  width: 120px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 9px #00000012;
  border-radius: 16px;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
}

.data-statistics-sidebar-community-item.red {
  color: #c63353;
}
.data-statistics-sidebar-community-item.red.selected {
  outline: 1px solid #c63353;
}

.data-statistics-sidebar-community-item.blue {
  color: #1f378e;
  margin: 0 8px;
}
.data-statistics-sidebar-community-item.blue.selected {
  outline: 1px solid #1f378e;
}

.data-statistics-sidebar-community-item.green {
  color: #28b750;
}
.data-statistics-sidebar-community-item.green.selected {
  outline: 1px solid #28b750;
}

.data-statistics-list-paginig {
  width: 1340px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-statistics-detail-paging {
  width: 865px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.data-statistics-header-date {
  font-size: 14px;
  font-weight: bold;
  color: #3b3f4c;
}
.data_uselog_tooltip {
  position: absolute;
  margin-top: 32px;
  border-radius: 10px;
  padding: 16px;
  min-width: 260px;
  height: 18px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}
.data_uselog_tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 19px;
}
