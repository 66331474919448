/**
 * Version : 1.0
 * 파일명 : profilesupport.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 기본페이지 css
 **/
.profile_content_support{
    margin: 34px 68px 33px 66px;
    height: 757px;
}

.first_card{
    display: flex;
    margin-bottom: 13px;
    text-align: center;
}
.profile_content_faq{
    width: 392px;
    height: 216px;
    margin-right: 14px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;

}
.sup_faqimg{
    margin-top: 43px; 
    margin-bottom: 16px;
}
.sup_faqimg2{

    margin-top: 43px; 
    margin-bottom: 16px;
}
.sup_faq{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_faq_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}
.hover_blue:hover .profile_content_faq{
    background-color: #f4f5fb;
}
.hover_blue:hover .text_hover_blue{
    font-weight: bold;
    color:  #2b4ac4 ; 
}
.hover_blue:hover { 
    color:
    #2b4ac4 ; 
}


/* 첫번쨰 FAQ 카드 끝*/

.profile_content_directquestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
}
.sup_dqimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_directquestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_dq_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}

.hover_orange:hover .profile_content_directquestion{
    background-color: #fcf5f3;
}
.hover_orange:hover .text_hover_orange{
    font-weight: bold;
    color: #ff734f ; 
}
.hover_orange:hover { 
    color: #ff734f; 
}

/*두번쨰 카드 끝*/

.second_card{
    display: flex;
    margin-bottom: 13px;
    text-align: center;
}

.profile_content_suggestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    margin-right: 14px;
}
.sup_suggestionimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_suggestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_suggestion_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}
.hover_purple:hover .profile_content_suggestion{
    background-color: 
    #f3f3fb;
}
.hover_purple:hover .text_hover_purple1{
    font-weight: bold;
    color: #7875f6 ; 
}
.hover_purple:hover .text_hover_purple2{
    font-weight: bold;
    color: #7875f6 ; 
}
.hover_purple:hover { 
    color: #7875f6;
}


/* 세번째 카드 끝*/

.profile_content_myquestion{
    width: 392px;
    height: 216px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
}
.sup_myquestionimg{
    margin-top: 43px; 
    margin-bottom: 16px; 
}

.sup_myquestion{
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    height: 20px;
}
.sup_myquestion_text{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #777;
    margin-top: 24px;
    margin-bottom: 37px;
    height: 14px;
    line-height: 14px;
}

.hover_mint:hover .profile_content_myquestion{
    background-color: 
    #f4fcfb;
}
.hover_mint:hover .text_hover_mint{
    font-weight: bold;
    color: #23dec4 ; 
}
.hover_mint:hover { 
    color: #23dec4;
}

/* 네번 째 카드 끝*/

.third_card{
    display: flex;

}
.profile_content_tel{
    margin-right: 14px;
    width: 392px;
    height: 298px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
.sup_tel_title{
    height: 18px;
    margin: 47px 229px 24px 50px;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
    letter-spacing: normal;
    color: #111;
}
.sup_tel_num{
    height: 32px;
    margin: 0 0 53px 50px;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #111;
}
.sup_tel_time{
    height: 82px;
    margin: 0px 78px 0 74px; 
}
.sup_tel_time_font{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color:   #555;
    line-height:161%;
}

.profile_content_announcement{
    margin-top : 47px;
    margin-left : 23px;
}
.sup_announcement_title{
    margin-bottom: 35px;
    width: 70px;
    height: 18px;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    
}
.announcementbody{
    width: 354px;
    height: 47px;
    display: flex;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: left;
    color: #868e96;

    border-top: solid 1px #c0c0c0;
    
}
.announcement_index{
    margin: 0 13px 0 13px;
}
.announcement_title{
    margin: 0 0 0 25px;
}

.announcementbodyfinish {
    width: 354px;
    height: 1px;
    background-color: #d2d2d2;
  }
.announcement_more{
    margin: 19px 0 0 289px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #4f575f;
}