/**
 * Version : 1.0
 * 파일명 : profileannouncement.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 공지사항 css
* 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 공지사항 강조 css 작업
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : 사이즈 조정
 **/
.announcement_main{
    margin-top:18px;
}
.announcement_body_title_noclick{
    margin: 0px 36px 17px 37px;
    width: 788px;
    border-radius: 10px;
    border: solid 1px rgba(144, 144, 144, 0.3);
    padding: 12px 44px 12px 26px;
    line-height: 18px;

    max-height: 100px;
    min-height : 34px;
    transition: max-height 0.7s ease-out, min-height 0.6s ease-out;
    -webkit-transition: max-height 0.7s ease, min-height 0.6s ease;

}

.openannouncement{
 
    margin: 0px 36px 17px 37px;
    padding: 17px 44px 33px 26px;
    border-radius: 10px;
    border: solid 1px #00205b;

    max-height: 1000px;
    min-height: 100px;
}
.announcement_body_title_noclick_header{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    line-height: 30px;
}

.announcement_body_title_noclick_header .noclick_dttm{
    color : rgba(144, 144, 144, 1); font-size: 15px;
}
.announcement_body_title_onclick_header .onclick_dttm{
    color : rgba(144, 144, 144, 1); font-size: 15px;
}


.announcement_body_title_onclick_header{
    justify-content: space-between;
    display: flex;
    font-size: 17px;
}
.announcement_body_line{
    width: 788px;
    height: 1px;
    margin: 19px 0 35px;
    background-color: #ddd;
}
.announcement_body_content p{
    margin: 0px;
}
.announcement_body_content{
    margin-left: 10px;
    font-size:  16px;
  
}
.announcement_files{
    margin-bottom : 15px;
    margin-top : 35px;

}
.announcement_file{
    margin-left: 10px;
}
.announcement_right .img_d_cursor{
 margin-left: 16px;
}
.announcement_right{
    display: flex;
}
.announcement_hold{
    display: flex;
}
.announcement_hold_text{
    width: 26px;
    height: 14px;
    margin: 0 10px 0 0;
    padding: 8px 11px 9px 11px;
    border-radius: 15.4px;
    background-color: #00205b;
    font-size: 14px;
    color: #fff;
    line-height: 14px;
}

.announcement_hold{
    height: 31px;
    line-height: 31px;
}
.announcement_file{
    margin: 0px 0px 0px 0px;
    display: flex;
}
.announcement_file_txt{
    margin-left: 10px;
    font-size: 16px;
    display: flex;
}
.announcement_file_title{
    margin-right: 10px;
    color : #00205b;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-left: 10px;
}