/**
 * Version : 1.0
 * 파일명 : clause.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 이용약관 CSS - 삭제예정
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.clauseDiv {
  width: 800px;
  height: 600px;
  margin-left: 560px;
  margin-top: 150px;
  position: absolute;
}
.clauseLogo {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: blue;
}
.clauseLeftDiv {
  background: white;
  border: none;
  border-radius: 20px;
  height: 100%;
  width: 390px;
  float: left;
}
.clauseRightDiv {
  background: white;
  border: none;
  border-radius: 20px;
  height: 100%;
  width: 390px;
  float: right;
}
.cancleBtn {
  background: gray;
  margin-left: 50px;
}
.agreeBtn {
  background: blue;
  margin-right: 50px;
}

.clauseBtn {
  margin-top: 10px;
  width: 310px;
  height: 40px;
  border: none;
  border-radius: 25px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.clauseTextarea {
  overflow: auto;
  resize: none;
  border: none;
  width: 90%;
  margin-left: 15px;
  margin-top: 10px;
  height: 90%;
}

.clauseAgreeDiv {
  width: 90%;
  margin-left: 15px;
  margin-top: 10px;
}