/**
 * Version : 1.0
 * 파일명 : profilesupsuggestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 고객의 소리 css
  * 수정일자 : 2022-03-31
 * 수정자 : 정지윤
 * 수정내역 : 고객센터 고객의 소리 크기 조정
 **/ 
.suggestionHeader{
    display: flex;
    font-size: 21px;
    justify-content: center;
    padding: 11px 0 30px 0;
    color: 
    #555555;
}
.suggestionHeaderbold{
    text-align: center;
    font-weight: bold;
}
.suggestionsHeader{
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    height: 40px;
    margin-bottom: 30px;

}
.suggestionsHeader-2{
    margin-top: 2px;
}





.suggestioninputtext{
    display: flex;
    margin-bottom: 12px;
    height: 205px;
}
.suggestioninputtexttitle{
    margin: 22px 68px 0 122px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #111;
    }

.suggestioninputtextmain{
    width: 484px;
    height :158px;
    padding: 22px 33px 22px 33px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    background-color: #fff;
    resize: none;
    font-family: SpoqaHanSansNeo;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.suggestioninputtext textarea::placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
}

.suggestioninputfile{
    display : flex;
    margin-bottom: 25px;
}

.suggestioninputfiletitle{
  margin:  22px 68px 0 122px;

  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
}

.supsuggestionline{
    width: 687px;
    height: 1px;
    margin: 0 122px 22px 123px;
    background-color: #c0c0c0;
}
.suggestionfooter{
    margin-bottom: 22px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-align: center;
}
.suggestionfooter-2{
    display: flex;
    justify-content: center;
}
.suggestionbutton{
    width: 160px;
    height: 53px;
    border-radius: 10px;
    background-color: #7875f6;
    text-align: center;
    letter-spacing: normal;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}
.suggestionimagebtn{
    width: 60px;
    height: 14px;
    padding: 10px 15px 11px 15px;
    border-radius: 5px;
    background-color: #555;
    font-family: SpoqaHanSansNeo-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.suggestion_inputFileholder{
  width: 502px;
  height: 54px;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  padding-right: 17px;
  padding-left: 33px;
  display: flex;
}
.suggestion_inputFileinfo{
    margin-left: 1px;
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 54px;
}
.inputfilebtn{
    line-height: 54px;
}
.suggestion_inputFileholder{
    font-family: SpoqaHanSansNeo;
    padding-right: 18px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 52px;
    color: #aaa;
    justify-content: space-between;
}
.fileListscrollbar{
    overflow-y: scroll;
    height: 52px;
    width: 420px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.suggestion_img{
    display: flex;
    justify-content: space-between;
}