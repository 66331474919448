/**
 * Version : 1.0
 * 파일명 : login.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 로그인 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Login_Div {
  width: 25rem;
  height: 20rem;
  margin: 18rem auto;
}
.Login_Input {
  height: 3rem;
  background: #eaeaea;
  border-radius: 10px;
  outline: none;
}
.Login_Btn {
  height: 2.5rem;
}
.Login_Text {
  font-size: 14px;
}