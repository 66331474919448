/**
 * Version : 1.0
 * 파일명 : record.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 녹화파일 CSS
 * 수정일자 :  2022-01-13
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.record_Div {
  position: relative;
  left: 40px;
  width: 1404px;
}
.record_header_Div {
  font-size: 28px;
  font-weight: bold;
  color: blue;
  margin-top: 24px;
  width: 1404px;
  height: 56px;
}

.record_header_title {
  color: #2b4ac4;
}

.record_content_Div {
  width: 1404px;
  height: 844px;
  max-height: 844px;
  margin-top: 14px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}
.fileItem_Timeline {
  float: left;
  width: 312px;
  height: 176px;
  /* margin-right: 24px; */
  margin-top: 20px;
  border-radius: 16px;
}

.dateLine_Timeline {
  width: 100%;
  float: left;
  margin-top: 15px;
}

.record_date_timeline {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-size: 18px;
  margin: 15px 0px;
}
.groupRecord_date_timeline {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-size: 18px;
  margin: 15px 0px;
}
.record_date_timeline::after {
  content: "";
  flex-grow: 1;
  margin: 0px 16px 0px 16px;
  background: #dbe0f5;
  height: 2px;
  font-size: 0px;
  line-height: 0px;
}
.groupRecord_date_timeline::after {
  content: "";
  flex-grow: 1;
  margin: 0px 16px 0px 16px;
  background: #dfe1e5;
  height: 2px;
  font-size: 0px;
  line-height: 0px;
}

.record_table {
  /* width: 1404px; */
  width: 1330px;
  max-height: 736px;
  /* margin: 0px 37px;
  padding-top: 32px; */
  overflow: hidden;
}

.record_thead_tr {
  font-size: 16px;
  font-weight: bold;
  height: 56px;
  background: white;
  color: #3b3f4c;
}
.record_thead_tr th {
  text-align: left;
}
.record_tbody_tr {
  font-size: 14px;
  height: 56px;
  background: rgba(255, 255, 255, 0.6);
}

.record_table_input:focus {
  outline: none;
}

.record_table_leftItem {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.record_table_rightItem {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.record_searchText {
  border: none;
  border-radius: 8px;
  width: 280px;
  height: 36px;
  background: #ffffffbe;
}
.record_searchText:active,
.record_searchText:focus {
  outline: none;
}
.record_searchBtn {
  color: white;
  border-radius: 8px;
  background: #2b4ac4;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  width: 64px;
  height: 36px;
  font-weight: bold;
}
.groupRecord_searchBtn {
  color: white;
  border-radius: 8px;
  background: #666b82;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  width: 64px;
  height: 36px;
  font-weight: bold;
}
.record_searchBtn:active,
.groupRecord_searchBtn:active,
.record_searchBtn:focus,
.groupRecord_searchBtn:focus {
  outline: none;
}

.record_LoadingBackground {
  background: rgba(255, 255, 255, 0.6);
  z-index: 900;
  position: absolute;
  width: 1404px;
  height: 845px;
  margin-top: 50px;
  border-radius: 15px;
}

.record_groupName {
  border-radius: 6px;
  border: 1px solid;
  width: auto;
  display: inline-block;
  margin: 2px;
  font-size: 12px;
  padding: 2px 10px;
  color: white;
  height: 18px;
}

.record_card_undefined {
  border: 1px solid #6e85d7;
  background: #f8f9fd;
}
.record_card_span_undefined {
  border: 1px solid #6e85d7;
}
.groupRecord_card_span {
  border: 1px solid #9b9faf;
}
.record_card_title_undefined {
  color: #2b4ac4;
}
.record_card_groupNM_undefined {
  color: #2b4ac4;
}

.record_card_red {
  border: 1px solid #de8799;
  background: #fbf0f2;
}
.record_card_span_red {
  border: 1px solid #de8799;
}
.record_card_title_red {
  color: #c63353;
}
.record_card_groupNM_red {
  color: #c63353;
}

.record_card_orange {
  border: 1px solid #d78e71;
  background: #fbf3ef;
}
.record_card_span_orange {
  border: 1px solid #d78e71;
}
.record_card_title_orange {
  color: #b24e27;
}
.record_card_groupNM_orange {
  color: #b24e27;
}

.record_card_yellow {
  border: 1px solid #cdb14c;
  background: #fbf9f2;
}
.record_card_span_yellow {
  border: 1px solid #cdb14c;
}
.record_card_title_yellow {
  color: #806a1c;
}
.record_card_groupNM_yellow {
  color: #806a1c;
}

.record_card_bean {
  border: 1px solid #44cb6a;
  background: #f0fbf3;
}
.record_card_span_bean {
  border: 1px solid #44cb6a;
}
.record_card_title_bean {
  color: #104920;
}
.record_card_groupNM_bean {
  color: #239d45;
}

.record_card_green {
  border: 1px solid #44cb6a;
  background: #f0fbf3;
}
.record_card_span_green {
  border: 1px solid #44cb6a;
}
.record_card_title_green {
  color: #104920;
}
.record_card_groupNM_green {
  color: #239d45;
}

.record_card_mint {
  border: 1px solid #39c8a9;
  background: #f2fbf9;
}
.record_card_span_mint {
  border: 1px solid #39c8a9;
}
.record_card_title_mint {
  color: #1b7a65;
}
.record_card_groupNM_mint {
  color: #229a80;
}

.record_card_pine {
  border: 1px solid #4faace;
  background: #f3f9fb;
}
.record_card_span_pine {
  border: 1px solid #4faace;
}
.record_card_title_pine {
  color: #217495;
}
.record_card_groupNM_pine {
  color: #2993bc;
}

.record_card_blue {
  border: 1px solid #6e85d7;
  background: #f8f9fd;
}
.record_card_span_blue {
  border: 1px solid #6e85d7;
}
.record_card_title_blue {
  color: #2b4ac4;
}
.record_card_groupNM_blue {
  color: #2b4ac4;
}

.record_card_pink {
  border: 1px solid #cb86dd;
  background: #fbf5fc;
}
.record_card_span_pink {
  border: 1px solid #cb86dd;
}
.record_card_title_pink {
  color: #661b7a;
}
.record_card_groupNM_pink {
  color: #a836c7;
}

.record_card_purple {
  border: 1px solid #9479da;
  background: #faf9fd;
}
.record_card_span_purple {
  border: 1px solid #9479da;
}
.record_card_title_purple {
  color: #46239f;
}
.record_card_groupNM_purple {
  color: #7855d0;
}

.record_card_black {
  border: 1px solid #9b9faf;
  background: #f6f6f8;
}
.record_card_span_black {
  border: 1px solid #9b9faf;
}
.record_card_title_black {
  color: #3e3e4f;
}
.record_card_groupNM_black {
  color: #3b3f4c;
}

/* .release + label span {
  background-image: url("../check.png");
  background: #848caf;
  border: 1px solid #2B4AC4;
  background-size: cover;
}
.checked + label span {
  background-image: url("../check.png");
  background: #2B4AC4;
  border: 1px solid #2B4AC4;
  background-size: cover;
} */

.Rec_Chk:checked + label span,
.Rec_Chk_ALL:checked + label span {
  background: #2b4ac4;
  background-image: url("../check.png");
  border: 1px solid #2b4ac4;
  background-size: cover;
}
.Rec_Chk + label,
Rec_Chk_ALL + label {
  width: 20px;
  height: 20px;
}
.Rec_Chk + label span,
.Rec_Chk_ALL + label span {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background: none;
  border-radius: 3px;
  background-size: cover;
}

.record_tooltip {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 343px;
  height: 62px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  inset: 0px auto auto 0px;
  transform: translate(134px, 155px);
  align-items: center;
  justify-content: center;
}
.record_tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 48px;
}
