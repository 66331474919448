/**
 * Version : 1.0
 * 파일명 : common.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 공통 CSS
 * 수정일자 : 2022-04-19
 * 수정자 : 권도훈
 * 수정내역 : 기본 로딩 추가
*/
/*
 *   ---------------------------------
 *   Pebble CSS 공통양식
 *   찾으려는 CSS 옵션 #을 붙여서 검색
 *   ---------------------------------
 *   None-Drag-Option
 *   #user-selcet
 */
.cursor {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.drag-none {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*
*   #btn
*/
.blue-btn {
  background: blue;
  font-weight: bold;
  color: white;
  border: none;
}
/*
*   #btn:hover
*/
.blue-btn:hover {
  background: #4169e1;
}
/*
*   #background-color
*/
.bg-transparent {
  background-color: transparent;
  background-image: none;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-beige {
  background-color: rgba(245, 245, 220, 0.4);
}
.bg-blue {
  background-color: blue;
}
.bg-gray {
  background-color: gray;
}
.bg-purple {
  background-color: purple;
}
.bg-gradient {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fed6e3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #a8edea 0%,
    #fed6e3 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #a8edea 0%,
    #fed6e3 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/*
*   #float
*/
.float-right {
  float: right;
}
.float-left {
  float: left;
}
/*
*   #position
*/
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
/*
*   #display
*/
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.inherit {
  display: inherit;
}
/*
*   #width
*/
.w-0 {
  width: 0px;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-11 {
  width: 2.75rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-30 {
  width: 7.5rem;
}
.w-32 {
  width: 8rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-80 {
  width: 20rem;
}
.w-90 {
  width: 22.5rem;
}
.w-100 {
  width: 25rem;
}
.w-160 {
  width: 40rem;
}
.w-200 {
  width: 50rem;
}
.w-220 {
  width: 55rem;
}
.w-240 {
  width: 60rem;
}
.w-254 {
  width: 63.5rem;
}
.w-270 {
  width: 67.5rem;
}
.w-274 {
  width: 68.5rem;
}
.w-280 {
  width: 70rem;
}
.w-313 {
  width: 78.3rem;
}
.w-320 {
  width: 80rem;
}
.w-420 {
  width: 105rem;
}
.w-auto {
  width: auto;
}
.w-half {
  width: 50%;
}
.w-full {
  width: 100%;
}
.w-fit-content {
  width: fit-content;
}
/*
*   #height
*/
.h-0 {
  height: 0px;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-23 {
  height: 5.9375rem;
}
.h-24 {
  height: 6rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-56 {
  height: 14rem;
}
.h-80 {
  height: 20rem;
}
.h-100 {
  height: 25rem;
}
.h-120 {
  height: 30rem;
}
.h-160 {
  height: 40rem;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
/*
*   #padding
*/
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-12 {
  padding: 3rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}
.p-28 {
  padding: 7rem;
}
.p-auto {
  padding: auto;
}
/*
*   #padding-top
*/
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-28 {
  padding-top: 7rem;
}
.pt-auto {
  padding-top: auto;
}
/*
*   #padding-bottom
*/
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-28 {
  padding-bottom: 7rem;
}
.pb-auto {
  padding-bottom: auto;
}
/*
*   #padding-left
*/
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-14 {
  padding-left: 3.5rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pl-28 {
  padding-left: 7rem;
}
.pl-auto {
  padding-left: auto;
}
/*
*   #padding-right
*/
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-14 {
  padding-right: 3.5rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pr-28 {
  padding-right: 7rem;
}
.pr-auto {
  padding-right: auto;
}
/*
*   #padding-left, #padding-right
*/
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.px-auto {
  padding-left: auto;
  padding-right: auto;
}
/*
*   #padding-top, #padding-bottom
*/
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
/*
*   #margin
*/
.m-0 {
  margin: 0px;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-28 {
  margin: 7rem;
}
.m-auto {
  margin: auto;
}
.m-center {
  margin: 0 auto;
}
/*
*   #margin-top
*/
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mt-46 {
  margin-top: 14rem;
}
.mt-50 {
  margin-top: 20rem;
}
.mt-64 {
  margin-top: 16rem;
}
.mt-160 {
  margin-top: 20rem;
}
.mt-auto {
  margin-top: auto;
}
/*
*   #margin-bottom
*/
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-14 {
  margin-bottom: 3.5rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-28 {
  margin-bottom: 7rem;
}
.mb-auto {
  margin-bottom: auto;
}
/*
*   #margin-left
*/
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-12 {
  margin-left: 3rem;
}
.ml-14 {
  margin-left: 3.5rem;
}
.ml-16 {
  margin-left: 4rem;
}
.ml-20 {
  margin-left: 5rem;
}
.ml-28 {
  margin-left: 7rem;
}
.ml-auto {
  margin-left: auto;
}
/*
*   #margin-right
*/
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mr-14 {
  margin-right: 3.5rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mr-28 {
  margin-right: 7rem;
}
.mr-auto {
  margin-right: auto;
}
/*
*   #margin-left, #margin-right
*/
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/*
*   #margin-top, #margin-bottom
*/
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
/*
*   #z-index
*/
.zi-0 {
  z-index: 0;
}
.zi-1 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}
.zi-3 {
  z-index: 3;
}
.zi-4 {
  z-index: 4;
}
.zi-5 {
  z-index: 5;
}
.zi-99 {
  z-index: 99;
}
.zi-999 {
  z-index: 999;
}
/*
*   #opacity
*/
.opacity-0 {
  opacity: 0;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
/*
*   #overflow
*/
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
/*
*   #border
*/
.border-black {
  border: 1px solid black;
}
.border-white {
  border: 1px solid white;
}
.border-blue {
  border: 1px solid blue;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
.border-navy {
  border: 1px solid rgba(43, 74, 196, 0.2);
}
.border-none {
  border: none;
}
/*
*   #border-top
*/
.border-t-black {
  border-top: 1px solid black;
}
.border-t-white {
  border-top: 1px solid white;
}
.border-t-blue {
  border-top: 1px solid blue;
}
.border-t-gray {
  border-top: 1px solid gray;
}
.border-t-navy {
  border-top: 1px solid rgba(43, 74, 196, 0.2);
}
.border-t-none {
  border-top: none;
}
/*
*   #border-bottom
*/
.border-b-black {
  border-bottom: 1px solid black;
}
.border-b-white {
  border-bottom: 1px solid white;
}
.border-b-blue {
  border-bottom: 1px solid blue;
}
.border-b-gray {
  border-bottom: 1px solid gray;
}
.border-b-navy {
  border-bottom: 1px solid rgba(43, 74, 196, 0.2);
}
.border-b-none {
  border-bottom: none;
}
/*
*   #border-right
*/
.border-r-black {
  border-right: 1px solid black;
}
.border-r-white {
  border-right: 1px solid white;
}
.border-r-blue {
  border-right: 1px solid blue;
}
.border-r-gray {
  border-right: 1px solid gray;
}
.border-r-navy {
  border-right: 1px solid rgba(43, 74, 196, 0.2);
}
.border-r-none {
  border-right: none;
}
/*
*   #border-left
*/
.border-l-black {
  border-left: 1px solid black;
}
.border-l-white {
  border-left: 1px solid white;
}
.border-l-blue {
  border-left: 1px solid blue;
}
.border-l-navy {
  border-left: 1px solid rgba(43, 74, 196, 0.2);
}
.border-l-none {
  border-left: none;
}
/*
*   #border-right, #border-left
*/
.border-x-black {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
.border-x-white {
  border-right: 1px solid white;
  border-left: 1px solid white;
}
.border-x-blue {
  border-right: 1px solid blue;
  border-left: 1px solid blue;
}
.border-x-none {
  border-right: none;
  border-left: none;
}
/*
*   #border-top, #border-bottom
*/
.border-y-black {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.border-y-white {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.border-y-blue {
  border-top: 1px solid blue;
  border-bottom: 1px solid blue;
}
.border-y-none {
  border-top: none;
  border-bottom: none;
}

/*
*   #border-radius
*/
.border-radius-0 {
  border-radius: 0px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-15 {
  border-radius: 15px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-round {
  border-radius: 9999px;
}
/*
*   #hover
*/
.border-hover-blue:hover {
  border: 1px solid blue;
}
/*
*   #font-weight
*/
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-extrabold {
  font-weight: 800;
}
.font-bold {
  font-weight: bold;
}
/*
*   #font-size
*/
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-24 {
  font-size: 24px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-38 {
  font-size: 38px;
}
/*
*   #color
*/
.font-white {
  color: white;
}
.font-blue {
  color: blue;
}
.font-red {
  color: red;
}
.font-black {
  color: black;
}
/*
*   #top
*/
.top-0 {
  top: 0px;
}
.top-1 {
  top: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.top-3 {
  top: 0.75rem;
}
.top-4 {
  top: 1rem;
}
.top-6 {
  top: 1.5rem;
}
.top-10 {
  top: 2.5rem;
}
.top-12 {
  top: 3rem;
}
.top-14 {
  top: 3.5rem;
}
.top-16 {
  top: 4rem;
}
.top-20 {
  top: 5rem;
}
.top-28 {
  top: 7rem;
}
.top-auto {
  top: auto;
}
.-top-1 {
  top: -0.25rem;
}
.-top-2 {
  top: -0.5rem;
}
.-top-3 {
  top: -0.75rem;
}
.-top-4 {
  top: -1rem;
}
.-top-6 {
  top: -1.5rem;
}
.-top-10 {
  top: -2.5rem;
}
.-top-12 {
  top: -3rem;
}
.-top-14 {
  top: -3.5rem;
}
.-top-16 {
  top: -4rem;
}
.-top-20 {
  top: -5rem;
}
.-top-28 {
  top: -7rem;
}
/*
*   #bottom
*/
.bottom-0 {
  bottom: 0px;
}
.bottom-1 {
  bottom: 0.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.bottom-3 {
  bottom: 0.75rem;
}
.bottom-4 {
  bottom: 1rem;
}
.bottom-6 {
  bottom: 1.5rem;
}
.bottom-10 {
  bottom: 2.5rem;
}
.bottom-12 {
  bottom: 3rem;
}
.bottom-14 {
  bottom: 3.5rem;
}
.bottom-16 {
  bottom: 4rem;
}
.bottom-20 {
  bottom: 5rem;
}
.bottom-28 {
  bottom: 7rem;
}
.bottom-auto {
  bottom: auto;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.-bottom-3 {
  bottom: -0.75rem;
}
.-bottom-4 {
  bottom: -1rem;
}
.-bottom-6 {
  bottom: -1.5rem;
}
.-bottom-10 {
  bottom: -2.5rem;
}
.-bottom-12 {
  bottom: -3rem;
}
.-bottom-14 {
  bottom: -3.5rem;
}
.-bottom-16 {
  bottom: -4rem;
}
.-bottom-20 {
  bottom: -5rem;
}
.-bottom-28 {
  bottom: -7rem;
}
/*
*   #right
*/
.right-0 {
  right: 0px;
}
.right-1 {
  right: 0.25rem;
}
.right-2 {
  right: 0.5rem;
}
.right-3 {
  right: 0.75rem;
}
.right-4 {
  right: 1rem;
}
.right-6 {
  right: 1.5rem;
}
.right-10 {
  right: 2.5rem;
}
.right-12 {
  right: 3rem;
}
.right-14 {
  right: 3.5rem;
}
.right-16 {
  right: 4rem;
}
.right-20 {
  right: 5rem;
}
.right-28 {
  right: 7rem;
}
.right-auto {
  right: auto;
}
.-right-1 {
  right: -0.25rem;
}
.-right-2 {
  right: -0.5rem;
}
.-right-3 {
  right: -0.75rem;
}
.-right-4 {
  right: -1rem;
}
.-right-6 {
  right: -1.5rem;
}
.-right-10 {
  right: -2.5rem;
}
.-right-12 {
  right: -3rem;
}
.-right-14 {
  right: -3.5rem;
}
.-right-16 {
  right: -4rem;
}
.-right-20 {
  right: -5rem;
}
.-right-28 {
  right: -7rem;
}
/*
*   #left
*/
.left-0 {
  left: 0px;
}
.left-1 {
  left: 0.25rem;
}
.left-2 {
  left: 0.5rem;
}
.left-3 {
  left: 0.75rem;
}
.left-4 {
  left: 1rem;
}
.left-6 {
  left: 1.5rem;
}
.left-10 {
  left: 2.5rem;
}
.left-12 {
  left: 3rem;
}
.left-14 {
  left: 3.5rem;
}
.left-16 {
  left: 4rem;
}
.left-20 {
  left: 5rem;
}
.left-28 {
  left: 7rem;
}
.left-auto {
  left: auto;
}
.-left-1 {
  left: -0.25rem;
}
.-left-2 {
  left: -0.5rem;
}
.-left-3 {
  left: -0.75rem;
}
.-left-4 {
  left: -1rem;
}
.-left-6 {
  left: -1.5rem;
}
.-left-10 {
  left: -2.5rem;
}
.-left-12 {
  left: -3rem;
}
.-left-14 {
  left: -3.5rem;
}
.-left-16 {
  left: -4rem;
}
.-left-20 {
  left: -5rem;
}
.-left-28 {
  left: -7rem;
}
/*
*   #top, #bottom
*/
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
/*
*   #right, #left
*/
.inset-x-0 {
  right: 0px;
  left: 0px;
}
/*
*   #top, #bottom, #right, #left
*/
.inset-0 {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
/*
*   #text-decoration
*/
.text-decoration-none {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline;
}
/*
*   #text-align
*/
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
/*
*   #vertical-align
*/
.align-top {
  vertical-align: top;
}
.align-bottom {
  vertical-align: bottom;
}
/*
*   #box-sizing
*/
.box-sizing-border {
  box-sizing: border-box;
}
/*
*  #align-items
*/
.align-items-center {
  align-items: center;
}
/*
*   #justify-content
*/
.jc-center {
  justify-content: center;
}
.jc-end {
  justify-content: flex-end;
}
/*
*   #flex-direction
*/
.flex-direction-column {
  flex-direction: column;
}
/*
*   #resize
*/
.resize-none {
  resize: none;
}
/*
*   #table border none
*/
.table-border-separate-10 {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
/*
*   #max-hieght
*/
.max-height-50 {
  max-height: 50rem;
}
/**
*   Tag
*/
body {
  font-size: 18px;
  margin: 0;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  /* font-family: "Malgun Gothic"; */
  /* font-family: "Roboto"; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* background: #FCF7F8; */
  /* overflow: hidden; */
}
button {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
input {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
code {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  /* font-family: "Malgun Gothic", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace; */
}

.content_Div {
  width: 1483px;
  position: relative;
  right: 0px;
}

.makeAccoutnModal {
  outline: none;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.flex-y-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-datepicker-popper {
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 600;
  width: 288px;
  height: 324px;
}
.react-datepicker__day-names,
.react-datepicker__week,
.react-datepicker__day-name {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #666b82;
}
.react-datepicker__day--outside-month {
  color: #afb3bf !important;
}

.react-datepicker__day,
.react-datepicker__header button {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-datepicker__header button {
  background: none;
  border: none;
}
.react-datepicker__day {
  width: 28px;
  height: 28px;
  line-height: 1.8;
  text-align: center;
  color: black;
  margin: 0 4px;
}

.react-datepicker__week {
  margin: 5px 0;
  color: #3e3e4f !important;
}

.react-datepicker__day:hover {
  color: #2b4ac4;
}

.react-datepicker__day-name {
  width: 24px;
  height: 24px;
  margin: 16px 6px 10px 6px;
}

.react-datepicker__day--selected {
  color: #2b4ac4;
  outline: none;
}
.react-datepicker__day--disabled {
  color: grey;
}

.react-datepicker-custom-selectbox {
  position: absolute;
  width: 82px;
  max-height: 196px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 15px #00000029;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #2b4ac4;
  overflow-y: auto;
  inset: 0px auto auto 0px;
}

.react-datepicker-custom-selectbox div {
  height: 36px;
  display: flex;
  align-items: center;
}
.react-datepicker-custom-selectbox div:hover {
  background-color: #dbe0f5;
}

.common_ok_btn {
  color: #ffffff;
  background: #2b4ac4;
}

.common_ok_btn:disabled {
  background-color: #a7a7a7;
  color: #6b6969;
}

.common_ok_textBtn {
  color: #2b4ac4;
}

.common_ok_textBtn:disabled {
  color: #707070;
}

.common-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}