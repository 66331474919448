/**
 * Version : 1.0
 * 파일명 : dashboard.css
 * 작성일자 : 2022-02-22
 * 작성자 : 강연승
 * 설명 : 대시보드 CSS
 * 수정일자 : 2022-02-22
 * 수정자 : 강연승
 * 수정내역 : 
*/
.dashboardBackground {
  width: 1362px;
  height: 803px;
  overflow-y: auto;
  padding: 23px 21px;
  border-radius: 16px;
}
.dashboardMeetingItemGrid {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardMeetingItem {
  width: 225px;
  height: 61px;
  padding: 21px 17px 22px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}

.dashboardMeetingItem .title {
  margin-left: 7px;
  font: normal normal normal 15px/19px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #555;
}
.dashboardMeetingItem .tooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  text-align: left;
  margin-top: 15px;
}
.dashboardMeetingItem .tooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 35px;
}
.dashboardMeetingItem .reserveTotal {
  display: flex;
  flex-direction: column;
  padding-right: 14px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #ffb423;
}
.dashboardMeetingItem .reserveToday {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #c0c0c0;
  padding-left: 13px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
}
.dashboardMeetingItem .meetingEndBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 6px 9px 6px 0px;
  border-radius: 2px;
  background-color: #12e0c4;
}

.dashboardMeetingItem .meetingNotOverBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 6px 10px;
  border-radius: 2px;
  background-color: #ff734f;
}
.dashboardMeetingItem .meetingEndBorder {
  border-left: 1px solid #c0c0c0;
  height: 22px;
}
.dashboardMeetingItem .meetingEnd {
  display: inline-block;
  padding-right: 13px;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
  vertical-align: top;
}
.dashboardMeetingItem .meetingNotOver {
  display: inline-block;
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
  vertical-align: top;
}

.dashboardMeetingItem .reserveTime {
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
}
.dashboardMeetingItem .realMeetingTime {
  font: normal normal bold 24px/22px Spoqa Han Sans Neo;
  text-align: left;
  color: #ffb423;
}
.dashboardMeetingItem .avgMeetingDuration {
  font: normal normal bold 24px/20px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
}
.dashboardMeetingItem .durationMax,
.dashboardMeetingItem .durationMin {
  font: normal normal bold 21px/17px Spoqa Han Sans Neo;
  text-align: left;
  color: #6d6d6d;
}
.dashboardMeetingItem .durationMaxText,
.dashboardMeetingItem .durationMinText {
  margin-top: 5px;
  font: normal normal bold 14px/14px Spoqa Han Sans Neo;
  text-align: left;
  color: #acacac;
}
.dashboardMeetingItem .hourText {
  margin-left: 10px;
  font: normal normal bold 19px/18px Spoqa Han Sans Neo;
  text-align: left;
  color: #acacac;
  vertical-align: bottom;
}
.dashboardDoughnutArea {
  margin-top: 26px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardAccCntArea {
  width: 635px;
  padding: 18px 17px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardAccCntArea .title {
  margin-left: 7px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardAccCntArea .accValue {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
}
.dashboardAccCntArea .accValue .currency {
  font: normal normal 500 37px/28px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accCost {
  margin-left: 14px;
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .time {
  margin-left: 17px;
  font: normal normal 500 37px/28px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accTime {
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .accValue .accMinute {
  margin-left: 16px;
  font: normal normal bold 60px/51px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
}
.dashboardAccCntArea .myAcc {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 531px;
}
.dashboardAccCntArea .myAcc .res {
  font: normal normal bold 60px/48px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
  margin: 0 auto;
}
.dashboardAccCntArea .myAcc .borderLine {
  border-right: 1px solid #c0c0c0;
  height: 48px;
}
.dashboardAccCntArea .myAcc .parti {
  font: normal normal bold 60px/48px Spoqa Han Sans Neo;
  text-align: center;
  color: #12e0c4;
  margin: 0 auto;
}
.dashboardAccCntArea .buttonArea {
  padding-top: 1px;
  display: flex;
  margin-right: 56px;
  flex-direction: column;
  align-items: center;
}
.dashboardAccCntArea .buttonArea .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  opacity: 0.68;
  border-radius: 50%;
  background-color: #e8e8e8;
}
.dashboardAccCntArea .buttonArea .button .arrowIcon {
  width: 13px;
  height: 9px;
}
.dashboardAccCntArea .buttonArea .buttonMenu {
  margin: 24px 0px 23px 0px;
  font: normal normal 500 23px/21px Spoqa Han Sans Neo;
  color: #6c6c6c;
}
.dashboardAccCntArea .buttonArea .tooltipUp {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  text-align: center;
  margin-bottom: 7px;
}
.dashboardAccCntArea .buttonArea .tooltipDown {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  text-align: center;
  margin-top: 7px;
}
.dashboardAccCntArea .buttonArea .tooltipDown:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -5px;
  right: 9px;
}
.dashboardAccCntArea .buttonArea .tooltipUp:after {
  border-top: 10px solid #3e3e4f;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0px solid transparent;
  content: "";
  position: absolute;
  bottom: -5px;
  right: 9px;
}
.dashboardDoughnutChartArea {
  width: 633px;
  height: 227px;
  padding: 12px 18px 11px 18px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardDoughnutChartArea .dashboardIcon {
  margin-top: 8px;
}
.dashboardDoughnutChartArea .title {
  margin-top: 10px;
  margin-left: 8px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardDoughnutChartArea .btnTimeSetting {
  height: 14px;
  cursor: pointer;
  float: right;
  margin-right: 6px;
  padding: 11px 12px 10px 12px;
  border-radius: 10px;
  background-color: #436bff;
  font: normal normal normal 14px/14px Spoqa Han Sans Neo;
  text-align: center;
  color: #fff;
}
.dashboardDoughnutChartArea .chartItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .socialItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .nodataItem {
  width: 137px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboardDoughnutChartArea .nodataItem canvas {
  display: block;
  box-sizing: border-box;
  width: 135px;
  height: 135px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.dashboardDoughnutChartArea .nodataItem .nodataValue {
  position: absolute;
  margin-top: 50px;
  font: normal normal 500 19px Spoqa Han Sans Neo;
  color: #111111;
}
.dashboardDoughnutChartArea .socialItem .socialDiv {
  padding-top: 59px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.dashboardDoughnutChartArea .socialItem .socialValue {
  max-width: 150px;
  height: 35px;
  font: normal normal bold 40px/35px Spoqa Han Sans Neo;
  text-align: center;
  color: #436bff;
}
.dashboardDoughnutChartArea .socialItem .social {
  margin-left: 13px;
  font: normal normal 500 20px/19px Spoqa Han Sans Neo;
  text-align: center;
  color: #436bff;
}
.dashboardDoughnutChartArea .chartItem .chartTitle,
.dashboardDoughnutChartArea .socialItem .chartTitle,
.dashboardDoughnutChartArea .nodataItem .chartTitle {
  margin-top: 19px;
  width: 107px;
  height: 15px;
  font: normal normal normal 15px/15px Spoqa Han Sans Neo;
  text-align: center;
  color: #666;
}
.dashbaordBarArea {
  margin-top: 27px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardMeetingCntBarArea {
  width: 632px;
  height: 255px;
  padding: 20px 20px 13px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardMeetingCntBarArea .title {
  margin-left: 11px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardMeetingCntBarArea .resMeetingDiv {
  display: flex;
  align-items: center;
  padding-right: 14px;
  border-right: 1px solid #c0c0c0;
  margin-right: 13px;
}
.dashboardMeetingCntBarArea .endMeetingDiv {
  display: flex;
  align-items: center;
}
.dashboardMeetingCntBarArea .resMeeting,
.dashboardMeetingCntBarArea .endMeeting {
  font: normal normal normal 17px/16px Spoqa Han Sans Neo;
  text-align: left;
  color: #111;
  vertical-align: top;
  margin-right: 10px;
}
.dashboardMeetingCntBarArea .resMeetingBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #ffb423;
}
.dashboardMeetingCntBarArea .endMeetingBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #7f84fb;
}
.dashboardHostResMeetingCnt {
  width: 631px;
  height: 256px;
  padding: 19px 20px 13px 18px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardHostResMeetingCnt .dashboard_item_header {
  display: flex;
  justify-content: space-between;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea {
  display: flex;
  align-items: center;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea .sortText {
  margin-right: 10px;
  font: normal normal normal 17px/14px Spoqa Han Sans Neo;
  text-align: left;
  color: #555;
}
.dashboardHostResMeetingCnt .dashboard_item_header .sortArea .sortIcon {
  margin-right: 8px;
  cursor: pointer;
}
.dashboardHostResMeetingCnt .title {
  margin-left: 10px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashbaordListArea {
  margin-top: 26px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(669px, 0fr));
}
.dashboardResMeetingList {
  width: 632px;
  height: 306px;
  padding: 21px 20px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardEndMeetingList {
  width: 637px;
  height: 305px;
  padding: 21px 20px 18px 17px;
  border-radius: 19px;
  box-shadow: 0px 1px 5px 0 rgba(198, 198, 198, 0.35);
  background-color: #fff;
}
.dashboardResMeetingList .title {
  margin-left: 10px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardEndMeetingList .title {
  margin-left: 11px;
  font: normal normal normal 17px/18px Spoqa Han Sans Neo;
  text-align: left;
  vertical-align: text-top;
  color: #111;
}
.dashboardResMeetingList .dashboard_item_header,
.dashboardEndMeetingList .dashboard_item_header {
  display: flex;
  justify-content: space-between;
  height: 22px;
}

.dashboardResMeetingList .totalCnt,
.dashboardEndMeetingList .totalCnt {
  font: normal normal normal 15px/15px Spoqa Han Sans Neo;
  color: #111;
}
.dashboardResMeetingList .noMeetingList,
.dashboardEndMeetingList .noMeetingList {
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  color: #8b8e99;
  height: 267px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardResMeetingList .meetingList,
.dashboardEndMeetingList .meetingList {
  margin: 16px 0 12px 31px;
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboardResMeetingList .meetingList_table,
.dashboardEndMeetingList .meetingList_table {
  border-spacing: 0;
}
.dashboardResMeetingList .meetingList_table th,
.dashboardEndMeetingList .meetingList_table th {
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  text-align: left;
  padding: 13px 0;
  color: #646464;
  border-bottom: 1px solid #436bff;
}
.dashboardResMeetingList .meetingList_table td,
.dashboardEndMeetingList .meetingList_table td {
  font: normal normal normal 14px/14px Spoqa Han Sans Neo;
  text-align: left;
  padding: 12px 12px 12px 0;
  color: #646464;
  border-bottom: 1px solid #c0c0c0;
}
.dashboardResMeetingList .meetingList_table .td_title,
.dashboardEndMeetingList .meetingList_table .td_title {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.dashboardResMeetingList .meetingList_table .td_hostNm,
.dashboardEndMeetingList .meetingList_table .td_hostNm {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60px;
}

.dashboard_list_paging {
  width: 100%;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard_list_paging .pageNumDiv {
  margin: 0 20px;
  display: flex;
}
.dashboard_list_paging .pageNumDiv .pageText {
  margin: 0 10px;
  cursor: pointer;
  font: normal normal normal 14px/12px Spoqa Han Sans Neo;
  color: #8b8e99;
}
.dashboard_list_paging .pageNumDiv .pageText.pageNow {
  font-weight: bold;
  color: #436bff;
}
.dashboard_list_paging .first_btn {
  width: 15px;
  height: 15px;
  margin-right: 12px;
}
.dashboard_list_paging .end_btn {
  width: 15px;
  height: 15px;
  margin-left: 12px;
}
.dashboard_list_paging .prev_btn {
  width: 12px;
  height: 15px;
}
.dashboard_list_paging .next_btn {
  width: 12px;
  height: 15px;
}
