/**
 * Version : 1.0
 * 파일명 : profilesupmyquestion.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 내 문의사항 css
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 : p태그 수정
 **/
.myquestion_main_c{
    margin: 18px 36px 0px 37px;
    padding: 12px 24px 12px 27px;
    border-radius: 10px;
    border: solid 1px rgba(144,144,144, 0.3);
    transition: max-height 1s ease-out, min-height 0.35s ease-out;
    -webkit-transition: max-height 1s ease,
    min-height 0.35s ease;
    min-height: 34px;
    max-height: 34px;
    
    
}
.openmyquestion{
    margin: 18px 36px 0px 37px;
    padding: 12px 24px 12px 27px;
    border-radius: 10px;
    border: solid 1px #23dec4;
    min-height: 80px;
    max-height: 1000px;
}



.myquestion_main .insertimage{
    height: 50px;
    width : 50px;
    margin-left: 19px;
}

.myquestion_closed_title{
    display: flex;
    justify-content: space-between;

}
.myquestion_closed_title .c_title_right{
    display: flex;

}
.c_title_left{
    font-size: 17px;
    line-height: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;

}

.myquestion_answer_unfinished{
    padding: 7px 13px 7px 12px;
    border-radius: 5px;
    border: solid 1px #23dec4;
    margin : 0px 29px 0px 15px;
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #23dec4;
}

.myquestion_answer_complete{
    padding: 7px 19px 7px 18px;
    border-radius: 5px;
    border: solid 1px #23dec4;
    margin : 0px 29px 0px 15px;
    font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: #23dec4;
  color: #fff;
}
.myquestion_writedate{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #909090;
    line-height: 35px;
}
.myquestion_small_header{
    margin: 33px 0 21px 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #23dec4;
}
.myquestion_content{
    margin-left: 19px;
    font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111;
  white-space:pre;
}
.myquestion_content p{
    margin: 0px;
}
.myquestion_line{
    width: 788px;
    height: 1px;
    margin: 19px 0 35px;
    background-color: #ddd;
}
.myquestion_line2{
    width: 788px;
    height: 1px;
    margin: 33px 0 30px;
    background-color: #ddd;
}
.myquestion_file{
    display: flex;
    margin-top: 19px;
    margin-bottom: 31px;
}
.myquestion_file_title{
    margin: 20px 15px 0px 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #23dec4;
}
.myquestion_end{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.myquestion_end .myquestion_email_address{
    margin-left: 19px;
    font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color:  #909090;
}
.myquestion_end_button{
    display: flex;
    margin-right: 19px;
}
.myquestion_update{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
    margin-right: 13px;
}
.myquestion_delete{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
    margin-left: 12px;
}
.myquestion_line3{
    width: 1px;
  height: 16px;
  margin-top: 3px;
  background-color: #909090;  
}
.img_dttm_cursor{
    margin-right: 21px;
}
.supportpaging{
    margin-top: 6px;
    padding-right: 320px;
    padding-left: 320px;
    padding-bottom: 6px;
   
}
.myquestion_file_txt{
    font-size: 16px;
    margin-bottom : 5px

}
.myquestion_file_body{
    margin-top: 20px;
    
}
