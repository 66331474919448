/**
 * Version : 1.0
 * 파일명 : alertsup.css
 * 작성일자 : 2022-03-15
 * 작성자 : 정지윤
 * 설명 : support모달 창 CSS
*/
.supportPopup {
    margin: 5% auto;
    width: 530px;
    height: 550px;
    background: white;
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .supportPopup_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .checkimage{
    margin : 34px 238px 22px 238px;
    width: 54px;
    height: 52px;
}
 .tt-1{
     display: flex;
     flex-direction: column;
 }

 .texthead{
     display: flex;
     margin: 0 154px 0 154px;
     padding-left: 15px;
     padding-right: 15px;
     height: 35px;
     line-height: 28px;
     background-color: white;
     position: absolute;
     top: 108px;

 }
 .texthead .textblack{
     font-size: 21px;
     color: #222222;
 }
 .texthead .textblue{
     font-size: 21px;
     color: #2b4ac4;
     width: 80px;
     height: 28px;
     line-height: 28px;
     margin-left: 10px;     
 }
 .outline{
    width: 438px;
    height: 319px;
    margin: 28px 46px 28px 46px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
    border-image-slice: 1;
    background-image: linear-gradient(to right, #1e51d8, #6c4596, #d6605a, #39c6c6);
}
.outline .innertext{
    width: 438px;
    height: 319px;
    background-color: white;
}
.innertext .textblack{
    text-align: center;
    font-size: 21px;
    color: #222222;
    background-color: white;
    margin-bottom: 43px;
}
.textmid{
    text-align: center;
    margin-left: 87px;
    width: 267px;
    height: 43px;
}
.textmid1{
font-size: 15px;
}
.textmid2{
    display: flex;
    height: 20px;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 42px;
}
.textmid2 .textblack{
    font-size: 15px;
}
.textmid2 .textorange{

    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    color: #f3512b;
    font-weight: bold;
    
}
.textunder{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
.textlast{
    margin-top: 42px;
    text-align: center;
    font-size: 15px;
}
.gohomebutton{
    width: 123px;
    height: 43px;
    margin: 0 204px 0 203px;
    border-radius: 5px;
    background-color: #2b4ac4;
    text-align: center;
}
.buttontext{
    width: 28px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 43px;
    color: #fff;
    margin-top: 12px;
    padding-top : 12px
}