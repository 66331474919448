/**
 * Version : 1.0
 * 파일명 : profile.css
 * 작성일자 : 2021-11-16
 * 작성자 : 권도훈
 * 설명 : 프로필 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 타임존, 언어 툴팁 위치 수정
*/
.profile_addCardPopup,
.profile_editCardPopup {
  margin: 11% auto;
  width: 488px;
  height: 575px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 800;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.profile_addCardPopup_header {
  margin: 0 24px 32px 24px;
  padding-top: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_addCardPopup_header img {
  width: 24px;
  height: 24px;
}
.profile_addCardPopup_body {
  margin: 10px 24px;
  font-size: 14px;
}
.profile_addCardPopup_text {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-bottom: 16px;
}
.profile_addCardPopup_input {
  font-size: 18px;
  color: #3e3e4f;
  border: none;
  background: none;
  text-align: center;
}
.profile_cardNum_input {
  width: 56px;
  height: 30px;
  margin-bottom: 19px;
}
.profile_date_input {
  width: 48px;
  height: 30px;
  margin-bottom: 19px;
}
.profile_password_input {
  width: 149px;
  height: 24px;
  margin-bottom: 19px;
  text-align: right !important;
}
.profile_registrationNum_input {
  width: 80%;
  height: 24px;
  margin-bottom: 19px;
  text-align: right !important;
}
.profile_cardName_input {
  height: 24px;
  width: 300px;
  text-align: right;
  margin-bottom: 19px;
}
.profile_addCardPopup_cardNum {
  height: 78px;
}
.profile_addCardPopup_cardNumDiv {
  float: right;
}
.profile_cardNum_input::placeholder,
.profile_date_input::placeholder,
.profile_cardName_input::placeholder {
  color: #d2d2d2;
}
.profile_cardNum_input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.profile_addCardPopup_cardLogo {
  margin: 16px 0px 15px 24px;
}
/* .profile_addCardPopup_cardNum_title {
  font-weight: bold;
  padding: 5px 0px;
}
.profile_addCardPopup_cardNum_title img {
  width: 68px;
  height: 15px;
} */

.profile_addCardPopup_cardInfo {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
}

.profile_addCardPopup_div {
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  /* margin: 0px 4px;
  border: none;
  font-size: 16px; */
}
.profile_addCardPopup_dateDiv,
.profile_addCardPopup_passowrdDiv {
  width: 216px;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile_addCardPopup_cardType {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  padding: 16px;
  margin-bottom: 8px;
}
.profile_addCardPopup_checkbox {
  display: flex;
  align-items: center;
}
/* .proife_addCardPopup_cardNum {
  width: 55px;
  text-align: center;
}
.proife_addCardPopup_date {
  width: 25px;
  text-align: center;
}
.proife_addCardPopup_cardName {
  width: 100%;
  text-align: right;
  padding: 2px 0px;
} */
.profile_addCardPopup_input:focus {
  outline: none;
}

.profile_addCardPopup_infoTitle {
  margin: 16px 0px 15px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
}
.profile_addCardPopup_infoInput {
  text-align: right;
}

.profile_addCardPopup_btnArea {
  display: flex;
  margin-top: 32px;
}

.profile_addCardPopup_Btn {
  text-align: center;
  width: 216px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 8px;
}
.profile_addCardPopup_ok {
  background: #2b4ac4;
}
.profile_addCardPopup_cancle {
  background: #d7263d;
}
.profile_addCardPopup_ok:hover {
  background: #3654cc;
}
.profile_addCardPopup_cancle:hover {
  background: #df3f54;
}

.profile_addCardPopup_Btn:disabled {
  color: #000000;
  background: #afb3bf;
  cursor: default;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 40px;
  height: 954px;
}
.profile_content_header {
  font-size: 24px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 24px;
  margin-bottom: 32px;
}
.profile_content_body {
  /* margin: 32px 40px; */
  width: 932px;
  height: 824px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 32px;
  position: relative;
}
.porfile_support_body {
  background: rgba(255, 255, 255, 0.5);
  margin: 0px 0px 32px 0px;
  width: 932px;
  height: 824px;
  border: none;
  bottom: 0px;
  /* border-top-left-radius: 32px;
   border-top-right-radius: 32px; */
  border-radius: 32px;
  position: relative;
}
.profile_profile_content_child {
  position: relative;
  height: 100%;
  margin: 0 32px;
}
.profile_loading {
  position: absolute;
}
/* .profile_profile_content_child,
.profile_loading {
  width: 93%;
} */

.profile_content_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 16px; */
  margin-bottom: 4px;
  height: 36px;
}
.profile_content_title.pay {
  margin-bottom: 16px;
}

.pf-content-title-txt {
  font-family: Spoqa Han Sans Neo;
  font-weight: bold;
  letter-spacing: 0px;
}
.pf-content-title-txt.title {
  font-size: 16px;
  color: #3e3e4f;
}
.pf-content-title-txt.comment {
  font-size: 14px;
  color: #666b82;
  margin-left: 8px;
}

.profile_content_whiteBtn {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_red {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #a02741;
  border-radius: 8px;
  color: #a02741;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_orange {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #c44308;
  border-radius: 8px;
  color: #c44308;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_yellow {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #c68a00;
  border-radius: 8px;
  color: #c68a00;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_bean {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #749420;
  border-radius: 8px;
  color: #749420;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_green {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #1b7c37;
  border-radius: 8px;
  color: #1b7c37;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_mint {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #229a80;
  border-radius: 8px;
  color: #229a80;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_pine {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2993bc;
  border-radius: 8px;
  color: #2993bc;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_pink {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #a836c7;
  border-radius: 8px;
  color: #a836c7;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_purple {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #7855d0;
  border-radius: 8px;
  color: #7855d0;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}
.profile_content_Btn_black {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #666b82;
  border-radius: 8px;
  color: #666b82;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  outline: none;
}

.profile_content_purpleBtn {
  width: 64px;
  height: 36px;
  background: #ecedf7;
  color: #bfc1e5;
  font-size: 14px;
  font-weight: bold;
  border: none;
}
.profile_content_purpleBtn:active {
  outline: none;
}
.profile_content_purpleBtn:disabled {
  background: #8b5fbf;
  color: #ffffff;
}

.profile_content_cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 740px;
}

.profile_content_card {
  width: 272px;
  height: 160px;
  border-radius: 16px;
  /* background: #a3b1e5; */
  margin-right: 26px;
  margin-bottom: 26px;
}

.profile_content_card section {
  height: 113px;
  padding: 18px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 안에 내용이 없어서 그런지 경고 떠서 커밋이 안되가지고 잠시 주석처리했습니다. */
/* .profile_content_cardTitle {
} */
.profile_content_cardTitle,
.profile_content_cardInput {
  /* color: #3e3e4f; */
  font-size: 22px;
  background: none;
  border: none;
  outline: none;
}
.profile_content_cardTitle {
  width: 216px;
}
.profile_content_cardInput {
  width: 200px;
}
.profile_content_cardTitleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_content_cardEditDiv {
  display: flex;
  flex-direction: row;
  width: 240px;
  align-items: center;
  border-bottom: 1px solid #2b4ac4;
}
.profile_content_cardEditBtn {
  font-size: 14px;
  font-weight: bold;
  color: #b8c3eb;
  margin-left: 8px;
}

.profile_content_cardLogo {
  width: 108px;
  height: 14px;
}
.profile_content_cardInfo {
  height: 18px;
  /* color: #5b74d2; */
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile_content_addCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  color: #4865cc;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile_content_addCard div {
  margin-top: 24px;
}

.profile_content_payTable,
.profile_content_licenseTable {
  max-height: 696px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}
.profile_content_payTable_header,
.profile_content_licenseTable_header {
  height: 56px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  text-align: center;
  background: #f1f2fa;
  font-size: 16px;
}
.profile_content_payTable_header {
  background: #f8f9fd;
  font-size: 14px;
}
/* .profile_content_licenseTable_header {
  
  
} */

.profile_content_payTable_body,
.profile_content_licenseTable_body {
  height: 52px;
  background: #f8f9fd;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  color: #3e3e4f;
  text-align: center;
}

.profile_content_FreeLicense {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  margin-left: 8px;
}

.profile_content_payTable_body button {
  color: #cad2f0;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
}

.profile_content_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 6px;
  margin-bottom: 22px;
}
.profile_content_paging div {
  margin-right: 40px;
}
.profile_content_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.profile_licenseSettingPopup_paging {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #3e3e4f;
  position: absolute;
  bottom: 0;
}
.profile_licenseSettingPopup_paging div {
  margin-right: 40px;
}
.profile_licenseSettingPopup_paging img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

.profile_content_license,
.profile_content_paylog {
  height: 758px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_sidebar {
  width: 472px;
  height: 954px;
  background: rgba(255, 255, 255, 0.5);
  padding: 0 36px;
}

.profile_sidebar_header {
  margin-top: 24px;
  color: #2b4ac4;
  font-size: 24px;
  font-weight: bold;
}

.profile_sidebar_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile_sidebar_avatar {
  width: 104px;
  height: 104px;
  border-radius: 999px;
  margin-top: 32px;
}

.profile_sidebar_info {
  margin-top: 40px;
  margin-bottom: 48px;
  color: #3e3e4f;
  font-weight: bold;
  text-align: center;
}
.profile_sidebar_name {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  font-size: 20px;
}
.profile_sidebar_nameEditor {
  width: 250px;
  height: 24px;
  background: none;
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b8c3eb;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
}
.profile_sidebar_email {
  margin-top: 16px;
  font-size: 16px;
}

.profile_sidebar_btn_side,
.profile_sidebar_btn_center {
  width: 344px;
  height: 64px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  margin-bottom: 16px;
}

.profile_sidebar_btn_side {
  color: #8b5fbf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
}

.profile_sidebar_btn_side_support {
  width: 344px;
  height: 64px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  margin-bottom: 16px;

  color: #8b5fbf;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.profile_sidebar_btn_support {
  margin-left: 24px;
}
.profile_sidebar_btn_support_sub {
  margin-left: 23px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b94a3;
}

.profile_sidebar_btn_left {
  margin-left: 24px;
}
.profile_sidebar_btn_right {
  margin-right: 32px;
}

.profile_sidebar_btn_center {
  background: rgb(43, 74, 196, 0.2);
  color: #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_sidebar_img {
  bottom: 51px;
}

.profile_popup_product {
  width: 880px;
  /* height: 858px;
  margin: 2% auto; */
  background: white;
  border-radius: 32px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.profile_popup_product_header {
  margin: 32px 24px 0 40px;
  width: 824px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #8b5fbf;
}
.profile_popup_product_body {
  margin-bottom: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile_popup_product_coment {
  height: 352px;
  width: 688px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.profile_popup_product_list {
  width: 688px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.pf-paydetail-popup {
  width: 354px;
  height: 554px;
  background: #ffffff;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf-paydetail-container {
  margin: 8px 16px 12px 16px;
  width: calc(100% - 32px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
  position: relative;
}

.pf-paydetail-loading {
  height: 488px;
  width: 306px;
}

.pf-paydetail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  width: 306px;
}
.pf-paydetail-header > p {
  margin: 0;
}

.pf-paydetail-section {
  width: 306px;
  height: 72px;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-bottom: 8px;
}

.pf-paydetail-section-inner {
  margin: 14px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 28px);
}

.pf-paydetail-section-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
}
.pf-paydetail-section-item:first-child {
  margin-bottom: 8px;
}

.pf-paydetail-section-item > p,
.pf-paydetail-section-item > div > p {
  font-size: 14px;
  letter-spacing: 0px;
  color: #2b4ac4;
  margin: 0;
}
.pf-paydetail-section-item > div > p {
  font-weight: bold;
  margin-left: 4px;
}

.pf-paydetail-section-item > div {
  display: flex;
  align-items: center;
}

.pf-paydetail-section-line {
  background: #2b4ac4;
  width: 1px;
  height: 13px;
}

.pf-paydetail-list {
  width: 306px;
}

.pf-paydetail-list-header,
.pf-paydetail-list-content-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pf-paydetail-list-header {
  background: #dfe1e5;
  font-weight: bold;
  margin-bottom: 8px;
  border-radius: 6px;
}
.pf-paydetail-list-content {
  background: #f9f9fa;
  border-radius: 6px;
}

.pf-paydetail-list-content-item {
  position: relative;
}
.pf-paydetail-list-content-item.line {
  text-decoration: line-through;
  text-decoration-color: red;
  color: #9b9faf;
}

.pf-paydetail-list-content-comment {
  color: #d7263d;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  right: 20px;
}

.pf-paydetail-paging {
  width: 306px;
  height: 32px;
  position: absolute;
  bottom: 0;
}

.pf-paydetail-paging > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paydetail-paging > div > font {
  letter-spacing: 0px;
  color: #666b82;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
}

.pf-paydetail-paging > div > div > img {
  width: 24px;
  height: 24px;
}
.pf-paydetail-paging > div > div > img {
  margin: 0 4px 0 0;
}
.pf-paydetail-paging > div > div > img:last-child {
  margin: 0 !important;
}

.profile_popup_pay_info {
  text-align: center;
  /* width: 688px; */
  width: 350px;
  text-align: left;
}

.profile_popup_addPaymentBtn,
.profile_popup_selected_productInfo {
  width: 350px;
  height: 206px;
  display: flex;
  flex-direction: column;
}

.profile_popup_addPaymentBtn {
  background: #ededf0;
  border: none;
  border-radius: 32px;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_popup_addPaymentBtn div {
  margin-top: 32px;
}

.profile_popup_selected_productInfo {
  background: #eaedf9;
  border: 1px solid #2b4ac4;
  border-radius: 24px;
  box-shadow: 5px 5px 16px #00000029;
  align-items: flex-start;
}

/* .profile_popup_pay_info div {
  padding: 15px 0 5px 0;
} */

.profile_popup_pay_selectedCard {
  height: 100px;
}

.profile_popup_pay_cardItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile_popup_pay_cardName {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile_popup_pay_sdkBtn {
  background-color: #ffffff;
  border: 1px solid #9b9faf;
  color: #666b82;
}

.profile_popup_payBtn {
  background: #2b4ac4;
  color: #ffffff;
  border: none;
}
.profile_popup_pay_sdkBtn,
.profile_popup_payBtn {
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  height: 56px;
  outline: none;
}
.profile_popup_payBtn:disabled {
  cursor: default;
  background-color: #dfe1e5;
  font-size: 16px;
  font-weight: bold;
  color: #666b82;
}
.profile_popup_pay_sdkBtn:hover {
  background: #2b4ac4;
  color: #ffffff;
}

.profile_popup_btnArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_loading {
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 그룹 프로필 */
.profile_sidebar_btn_imgUpload {
  padding: 7px 76px 11px;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.group_profile_sidebar_groupname {
  font: normal normal bold 20px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.group_profile_sidebar_createDate {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #84889b;
  margin-top: 16px;
  margin-bottom: 16px;
}
.group_profile_sidebar_leader_icon {
  margin-right: 8px;
  vertical-align: top;
}
.group_profile_sidebar_leader {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.group_profile_sidebar_colorName {
  display: inline-block;
  text-transform: uppercase;
}
.group_profile_sidebar_groupcolor {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-left: 8px;
}
.group_profile_thead {
  background: #f1f2fa 0% 0% no-repeat padding-box;
  border-radius: 12px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3b3f4c;
  /* width: 868px; */
  height: 56px;
}
.group_profile_tbody {
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 12px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  height: 56px;
}

.group_profile_invite_btn_red {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a02741;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a02741;
}
.group_profile_invite_btn_orange {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c44308;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c44308;
}
.group_profile_invite_btn_yellow {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c68a00;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c68a00;
}
.group_profile_invite_btn_bean {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #749420;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #749420;
}
.group_profile_invite_btn_green {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1b7c37;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #1b7c37;
}
.group_profile_invite_btn_mint {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #229a80;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #229a80;
}
.group_profile_invite_btn_pine {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2993bc;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
}
.group_profile_invite_btn_pink {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a836c7;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a836c7;
}
.group_profile_invite_btn_purple {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7855d0;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #7855d0;
}
.group_profile_invite_btn_black {
  padding: 0 20px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666b82;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #666b82;
}

.group_profile_delete_member {
  text-align: right;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c1c3cd;
}

.groupCategoryEditPopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 286px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.groupCategoryEditPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupColorEditPopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 286px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #36252529;
  outline: none;
  display: flex;
  flex-direction: column;
}
.groupColorEditPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostInvitePopup {
  text-align: center;
  margin: 14% auto;
  /* padding: 16px 24px 24px; */
  width: 480px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.groupMeetingHostInvitePopup_background {
  width: 480px;
  /* height: 444px; */
}
.groupMeetingHostInvitePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostInvitePopup_header {
  margin-top: 32px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  align-items: center;
  justify-content: center;
}
.groupMeetingHostDeletePopup {
  text-align: center;
  margin: 14% auto;
  width: 400px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  outline: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 16px #00000029;
}

.groupMeetingHostDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupDeletePopup {
  text-align: center;
  margin: 14% auto;
  width: 530px;
  background: white;
  border-radius: 24px;
  z-index: 99;
  outline: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 16px #00000029;
}

.groupDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.groupMeetingHostDeletePopup_header {
  margin-top: 32px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  align-items: center;
  justify-content: center;
}
.LicenseAlert {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 429px;
  background: white;
  /* transition: all 0.5s ease-out; */
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.LicenseAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.LicenseAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.LicenseAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.setLicenseAlert {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 330px;
  background: white;
  /* transition: all 0.5s ease-out; */
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.setLicenseAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.setLicenseAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.setLicenseAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.assignSchedule {
  margin: 18% auto;
  padding: 22px 16px 8px 24px;
  width: 320px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.assignSchedule .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.assignSchedule .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.assignSchedule .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.EditThemaAlert {
  margin: 18% auto;
  padding: 32px 24px 16px 40px;
  width: 460px;
  background: white;
  border-radius: 16px;
  z-index: 999;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.EditThemaAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
}
.EditThemaAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.EditThemaAlert .btnNo {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}
.licenseSettingPopup {
  padding: 16px 24px;
  width: 664px;
  height: 546px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.licenseSettingPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.licenseSettingPopup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.licenseSettingPopup .licenseSettingPopupTitle {
  color: #3b3f4c;
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.licenseSettingPopup .licenseInfo,
.licenseSettingPopup .licenseInfoActive {
  margin-top: 6px;
  width: 664px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.licenseSettingPopup .licenseInfo {
  background: #dfe1e5 0% 0% no-repeat padding-box;
  outline: 1px solid #afb3bf;
}
.licenseSettingPopup .licenseInfoActive {
  background: #eaedf9 0% 0% no-repeat padding-box;
  outline: 1px solid #8b9ddf;
}

.licenseInfo-inner,
.licenseInfo-text-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.licenseInfo-inner {
  /* margin: 12px 16px 12px 24px; */
  height: calc(100% - 24px);
  width: calc(100% - 40px);
}
.licenseInfo-text-area > div {
  margin-bottom: 1px;
}
.licenseInfo-text-area > div:first-child {
  margin-right: 24px;
}

.lc-setting-popup-header-font {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.lc-setting-popup-header-font.type {
  color: #8b9ddf;
  font-weight: bold;
  margin-right: 8px;
}
.lc-setting-popup-header-font.type.active {
  color: #2b4ac4 !important;
}
.lc-setting-popup-header-font.value {
  color: #9b9faf;
}
.lc-setting-popup-header-font.value.active {
  color: #3b3f4c !important;
}

.lc-content-header {
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lc-content-header > div {
  display: flex;
  align-items: center;
}
.lc-content-header > div > font {
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  letter-spacing: 0px;
  color: #666b82;
  margin-left: 8px;
}

.lc-content-inner {
  position: absolute;
  margin: 0px 8px 11px 8px;
  height: calc(100% - 11px);
}

.lc-content-relative {
  height: 100%;
  position: relative;
}

.lc-content-table {
  border-spacing: 0 2px;
}

.lc-content-table-tr {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  height: 32px;
}

.lc-content-table-tr.thead {
  background: #d1d3da 0% 0% no-repeat padding-box;
}

.lc-content-table-tr > th:first-child,
.lc-content-table-tr > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.lc-content-table-tr > th:last-child,
.lc-content-table-tr > td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.lc-content-table-tr > th,
.lc-content-table-tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lc-content-table-left {
  text-align: left;
}
.lc-content-table-center {
  text-align: center;
}

.licenseSettingPopup .releaseLicenseBtn {
  width: 72px;
  height: 36px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  float: right;
  border: none;
  margin: 10px 16px;
}
.licenseSettingContent {
  margin-top: 8px;
  width: 664px;
  height: 456px;
  background: #f1f1f3 0% 0% no-repeat padding-box;
  border-radius: 12px;
  position: relative;
}
.licenseSettingContent.disable {
  height: 388px !important;
}

.scheduleListTitle {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.licenseSettingPopup .licenseScheduleList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.licenseSettingPopup .licenseScheduleList table {
  border-spacing: 0 5px;
  margin: 0 auto;
}
/* .licenseSettingPopup .licenseStateBtn {
  float: right;
} */
.licenseSettingPopup .licenseStateBtn .MuiSwitch-root {
  width: 40px;
  height: 24px;
  margin-left: 8px;
  padding: 0px;
}
.licenseSettingPopup .licenseStateBtn .MuiFormControlLabel-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-switchBase {
  color: #6e85d7;
  padding: 0;
  margin: 3px 2px;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-colorPrimary.Mui-checked {
  color: #2b4ac4;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
}
.licenseSettingPopup .licenseStateBtn .MuiSwitch-track {
  background-color: #b3bfe9;
  opacity: 1;
  width: 40px;
  height: 24px;
  border-radius: 12px;
}
.licenseSettingPopup
  .licenseStateBtn
  .MuiSwitch-colorPrimary.Mui-checked
  + .MuiSwitch-track {
  background-color: #ffffff;
}

.licenseSettingPopup .scheduleReassignBtn {
  border: none;
  background: #8b9ddf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #e5e9f8;
  opacity: 0.62;
  width: 91px;
  height: 36px;
}
.licenseSettingPopup .scheduleReassignBtnActive {
  cursor: pointer;
  border: none;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #e5e9f8;
  width: 91px;
  height: 36px;
}
.licenseSettingPopup .licenseScheduleList_thead {
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  height: 40px;
}

.licenseSettingPopup .licenseScheduleList_tbody td {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  text-align: center;
  height: 40px;
  color: #3e3e4f;
}
.licenseSettingPopup .licenseScheduleList_thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.licenseSettingPopup .licenseScheduleList_thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.licenseSettingPopup .licenseScheduleList .noSchedule {
  padding: 32px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
}
.licenseSettingPopup .balloon {
  margin-top: 8px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 283px;
  border-radius: 10px;
  padding: 12px 15px 14px 16px;
  position: absolute;
  right: 0px;
  text-align: left;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);
}
.licenseSettingPopup .balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 260px;
}
.licenseSettingPopup .guideTooltip {
  margin-top: 8px;
  margin: 172px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 384px;
  border-radius: 10px;
  padding: 12px 20px 16px;
  position: absolute;
  /* right: 40px; */
  text-align: center;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);
}
.profile_licenseSettingPopup_footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_licenseSettingPopup_allocateBtn {
  background: #3b3f4c 0% 0% no-repeat padding-box;
}
.profile_licenseSettingPopup_otherGroupBtn {
  background: #2b4ac4 0% 0% no-repeat padding-box;
}
.profile_licenseSettingPopup_unsubscribeBtn {
  background: #c63353 0% 0% no-repeat padding-box;
}

.profile_licenseSettingPopup_allocateBtn,
.profile_licenseSettingPopup_otherGroupBtn,
.profile_licenseSettingPopup_unsubscribeBtn {
  width: 216px;
  height: 48px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.profile_licenseSettingPopup_setGroupBtn {
  width: 216px;
  height: 48px;
  /* margin: 0 196px; */
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.setGroupLicensePopup {
  margin: 14% auto;
  padding: 16px 24px 24px;
  width: 472px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
}
.setGroupLicensePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.setGroupLicensePopup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.setGroupLicensePopup .setGroupLicensePopupTitle {
  text-align: left;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  margin-top: 8px;
  margin-left: 8px;
  color: #2b4ac4;
}

.setGroupLicensePopup .groupSearchInput {
  width: 360px;
  height: 48px;
  text-align: center;
  margin: 0px auto;
  display: block;
  background-color: #eaedf9;
  border: none;
  border-radius: 10px;
}

.setGroupLicensePopup .groupSearchInput::placeholder {
  font: normal normal normal 14px/21px Spoqa Han Sans Neo;
  color: #a3b1e5;
}

.setGroupLicensePopup .groupSearchInput:focus {
  outline: none;
}

.setGroupLicensePopup .licenseGroupSettingBtn {
  border: none;
  width: 360px;
  height: 40px;
  background: #6e85d7 0% 0% no-repeat padding-box;
  margin-top: 16px;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #cad2f0;
}

.setGroupLicensePopup .licenseGroupSettingBtnActive {
  border: none;
  width: 360px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  margin-top: 16px;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ffffff;
  cursor: pointer;
}

.setGroupLicensePopup .groupListPopup {
  width: 360px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 10px;
  margin: 8px 56px;
  overflow-y: auto;
}

.setGroupLicensePopup .groupListPopup .groupListPopupItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}

.group_profile_tbody input {
  max-width: 76px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  border-radius: 8px;
  border: none;
  outline: none;
  text-align: center;
  /* background: #ecedf7 0% 0% no-repeat padding-box; */
}
.group_profile_tbody input:disabled {
  background: none;
}
.group_profile_tbody input:focus {
  /* min-width: 96px; */
  height: 36px;
  background: #ecedf7 0% 0% no-repeat padding-box;
}
.meetingHostAuthTooltip {
  z-index: 80;
  margin-top: 18px;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 328px;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  right: -175px;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);
}
.meetingHostAuthTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 30px;
}
/* .profile_content_div {
  margin: 20px 40px;
} */

/* .profile_content_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_content_title_text {
  font-size: 18px;
  font-weight: bold;
  color: #2b4ac4;
}
.profile_content_title_btn {
  background-color: #2b4ac4;
  color: #ffffff;
  font-size: 14px;
  border: none;
  font-weight: bold;
  height: 30px;
}
.profile_content_title_btn:active {
  outline: none;
} */

/* .profile_sidebar_avatar {
  width: 104px;
  height: 104px;
  border-radius: 999px;
  margin-top: 64px;
}
.profile_sidebar_name {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 40px;
}
.profile_sidebar_email {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 16px;
  margin-bottom: 32px;
}

.profile_sidebar_groupname {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 40px;
}
.profile_sidebar_createDate {
  font-size: 16px;
  font-weight: bold;
  color: #3e3e4f;
  margin-top: 16px;
}
.profile_sidebar_leader_icon {
  width: 16px;
  height: 16px;
}
.profile_sidebar_leader {
  font-size: 16px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 16px;
}

.profile_sidebar_btn_side {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  width: 344px;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 16px;
}
.profile_sidebar_btn_center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  width: 344px;
  height: 64px;
  color: #2b4ac4;
  background: rgba(43, 74, 196, 0.2);
  margin-top: 16px;
}
.profile_sidebar_point {
  color: #ffffff;
  background: #2b4ac4;
}
.profile_sidebar_bluetext {
  color: #2b4ac4;
}
.profile_sidebar_leftdiv {
  margin-left: 24px;
}
.profile_sidebar_rightdiv {
  margin-right: 32px;
} */

.comment_timezone_balloon,
.comment_language_balloon {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 150px;
  height: 30px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  inset: 80px auto auto 0px;
  /* transform: translate(1025px, 646px); */
  transform: translate(1265px, 492px);
  align-items: center;
  justify-content: center;
}
.comment_excel_balloon {
  position: absolute;
  margin: 16px;
  border-radius: 10px;
  width: 150px;
  height: 30px;
  background: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  inset: 80px auto auto 0px;
  /* transform: translate(1025px, 646px); */
  transform: translate(1025px, 552px);
  align-items: center;
  justify-content: center;
}
.comment_timezone_balloon:after,
.comment_language_balloon:after,
.comment_excel_balloon:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  left: 48px;
}

.comment_timezone_balloon:after {
  left: 48px;
}

.comment_language_balloon:after {
  left: 104px;
}
.SupcontentAll {
  height: 824px;
  overflow-y: scroll;
  /* -ms-overflow-style: none; 
 scrollbar-width: 1px;  */
  width: 950px;
}
/* .SupcontentAll::-webkit-scrollbar {
  display: none;
}  */
.SupcontentAll::-webkit-scrollbar {
  width: 8px;
}
.SupcontentAll::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.SupcontentAll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.support_header_title {
  display: inline;
  margin-bottom: 20px;
}

.py-product-popup {
  width: 624px;
  height: 560px;
  background: white;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-product-popup-inner {
  width: 600px;
  height: 100%;
}

.py-product-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
.py-product-popup-header > font {
  color: #2b4ac4;
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  letter-spacing: 0px;
}

.py-product-popup-container-loading,
.py-product-popup-container {
  height: calc(100% - 56px);
  width: 600px;
}
.py-product-popup-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.py-product-popup-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.py-product-list {
  width: 576px;
  height: 216px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-top: 10px;
  /* justify-content: space-between; */
}
/* .py-product-list.selected {
  border-bottom: 2px dashed #D1D3DA;
} */

.py-product-list-item {
  width: 576px;
  height: 216px;
  /* background: #DBE0F5;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; */
}
/* .py-product-list-item.selected {
  background: #2B4AC4 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 16px #0000001A;
} */

.py-product-list-item-inner {
  /* height: 118px;
  width: 232px; */
  margin: 26px 16px 16px 48px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-item-info {
  width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.py-product-list-item-info-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.py-product-list-item-info-title {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 24px;
  font-weight: bold;
}
.py-product-list-item-info-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-product-list-item-info-amount > font {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 30px;
}
.py-product-list-item-info-amount-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.py-product-list-item-info-amount-comment > font:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #ee6c4d;
}
.py-product-list-item-info-amount-comment > font:last-child {
  font-size: 15px;
  color: #3b3f4c;
  text-decoration: line-through;
  text-decoration-line: 2px solid #ee6c4d;
  text-decoration-color: #ee6c4d;
}
/* .py-product-list-item-info-amount > font:last-child {
  letter-spacing: 0px;
  color: #6E85D7;
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
} */

.py-product-list-item-info-bot {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.py-product-list-item-info-coment {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: 0px;
  color: #666b82;
  font-size: 14px;
  font-weight: bold;
}

.py-product-list-item-info-paydt {
  margin-top: 5px;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 12px;
  font-weight: bold;
}

.py-product-list-item-title {
  height: 37px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-list-item-title > font {
  font: normal normal bold 20px/72px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #8b9ddf;
}
.py-product-list-item-title.selected > font {
  color: #ffffff;
}

.py-product-list-item-amount {
  margin-top: 24px;
  margin-bottom: 4px;
  height: 37px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.py-product-list-item-amount-date,
.py-product-list-item-amount-value {
  letter-spacing: 0px;
  color: #8b9ddf;
}
.py-product-list-item-amount-date.selected,
.py-product-list-item-amount-value.selected {
  color: #ffffff;
}
.py-product-list-item-amount-date {
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  margin-bottom: 5px;
  margin-right: 6px;
}
.py-product-list-item-amount-value {
  font-size: 28px;
  font-family: Spoqa Han Sans Neo;
}

.py-product-list-item-comment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  letter-spacing: 0px;
  color: #8b9ddf;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-item-comment.selected {
  color: #a3b1e5;
}

.py-product-list-btn-area {
  height: 174px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
.py-product-list-btn-area-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.py-product-list-counter {
  width: 128px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.py-product-list-counter-input {
  width: 104px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9b9faf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-list-counter-input > input {
  width: 65px;
  outline: none;
  border: none;
  text-align: center;
}
.py-product-list-counter-text {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-counter-input-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #9b9faf;
}
.py-product-list-counter-input-btn > div {
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-list-counter-input-btn > div:first-child {
  border-bottom: 1px solid #9b9faf;
}
.py-product-list-seleted-btn {
  width: 160px;
  height: 56px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-list-seleted-btn > img {
  margin-left: 6px;
}

.py-product-list-non-selected-comment {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-weight: bold;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  text-align: center;
}

.py-product-list-counter-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0px;
  color: #666b82;
  font-size: 12px;
  font-weight: bold;
  margin-top: 14px;
}

.py-product-payment {
  width: 576px;
  height: 472px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.py-product-selected-info {
  width: 184px;
  margin-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.py-product-selected-info-container {
  height: 274px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.py-product-selected-info-title,
.py-product-payment-info-title {
  height: 36px;
  background: #eaedf9;
  display: flex;
  border-radius: 8px;
  align-items: center;
}
.py-product-selected-info-title {
  width: 184px;
}
.py-product-payment-info-title {
  width: 344px;
}
.py-product-selected-info-title > font,
.py-product-payment-info-title > font {
  margin-left: 16px;
  color: #2b4ac4;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-product-selected-info-content {
  width: 184px;
  height: 222px;
  background: #2b4ac4;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.py-product-selected-info-content-top {
  height: 85px;
  width: 160px;
  border-bottom: 2px dashed #6e85d7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.py-product-selected-info-content-top > font,
.py-product-selected-info-content-bot > font {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
}
.py-product-selected-info-content-top > font:first-child {
  font-size: 20px;
  font-weight: bold;
}
.py-product-selected-info-content-top > font:last-child {
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.py-product-selected-info-content-bot {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.py-product-selected-info-content-bot > font {
  font-size: 14px;
  font-weight: bold;
}
.py-product-selected-info-content-bot > font:nth-child(2) {
  margin-top: 16px;
  margin-bottom: 4px;
}

.py-product-selected-info-btn {
  width: 184px;
  height: 40px;
  border: 1px solid #4865cc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4865cc;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-selected-info-btn > img {
  margin-right: 8px;
}

.py-product-payment-info {
  width: 368px;
  height: 100%;
  border-left: 1px solid #dbe0f5;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.py-product-payment-info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-product-payment-info-card {
  background: #dbe0f5;
  width: 344px;
  height: 222px;
  border-radius: 20px;
  margin: 16px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.py-product-payment-info-card.none {
  margin: 16px 0;
}
.py-product-payment-info-card.none > font {
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: bold;
  color: #2b4ac4;
  margin-top: 24px;
}

.py-product-payment-info-card-item {
  width: 272px;
  height: 152px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #00000029;
  border-radius: 12px;
}
.py-product-payment-info-card-item-inner {
  margin: 16px;
}

.py-product-payment-info-del-btn {
  width: 344px;
  height: 40px;
  background: #ffffff;
  outline: 1px solid #4865cc;
  border-radius: 10px;
  border: none;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  margin-bottom: 8px;
}

.py-product-payment-info-comment {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
  margin-bottom: 23px;
}

.py-product-payment-info-terms {
  text-decoration: underline;
  text-decoration-color: #666b82;
  color: #666b82;
  font-weight: bold;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
}

.py-product-payment-info-btn {
  width: 344px;
  height: 38px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border: none;
  outline: none;
  background: #2b4ac4;
  color: #ffffff;
}
.py-product-payment-info-btn:disabled {
  background: #eaedf9;
  color: #cad2f0;
}

.py-product-auth {
  width: 576px;
  height: 816px;
}

.py-product-auth-header {
  width: 576px;
  height: 36px;
  border-radius: 8px;
  background: #eaedf9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-auth-header > font {
  margin-left: 16px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-product-auth-container {
  height: 436px;
  width: 576px;
  background: #ededf0;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Spoqa Han Sans Neo;
}

.py-product-auth-container-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 26px);
}
.py-product-auth-container-comment-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-auth-container-comment-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.py-product-auth-container-comment-title {
  font-size: 14px;
}
.py-product-auth-container-comment-detail {
  font-size: 12px;
  margin-top: 8px;
}
.py-product-auth-container-comment-cancle {
  margin-bottom: 25px;
  width: 184px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  outline: 1px solid #c63353;
  border-radius: 10px;
  border: none;
  letter-spacing: 0px;
  color: #c63353;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.1s ease-out;
}
.py-product-auth-container-comment-cancle:hover {
  background: #c63353;
  color: #ffffff;
}

.py-product-auth-container-content {
  width: 336px;
  height: 416px;
  background: #dfe1e5;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-container-content-inner {
  margin: 16px 16px 0;
  width: 304px;
}

.py-product-auth-content-title {
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.py-product-auth-content-body {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-btn:disabled {
  background: #6e85d7 !important;
}
.py-product-auth-btn:hover {
  background: #7489d4;
}
.py-product-auth-btn {
  width: 304px;
  height: 40px;
  border-radius: 8px;
  background: #2b4ac4;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  outline: none;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.py-product-auth-term-list {
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.py-product-auth-phone-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 237px;
  width: 265px;
}
.py-product-auth-num-list {
  width: 265px;
}

.py-product-auth-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 427px;
  width: 100%;
}

.py-product-auth-success-comment {
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  margin-bottom: 24px;
}

.py-product-add {
  width: 576px;
  height: 816px;
}

.py-product-add-header {
  width: 576px;
  height: 36px;
  border-radius: 8px;
  background: #eaedf9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.py-product-add-header > font {
  margin-left: 16px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-product-add-container {
  height: 428px;
  width: 576px;
  background: #eaedf9;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.py-product-add-item {
  width: 224px;
  height: 264px;
  background: #ffffff;
  box-shadow: 5px 5px 16px #00000029;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-add-item:first-child {
  margin-right: 16px;
}
.py-product-add-item-inner {
  width: 182px;
  height: 193px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.py-product-add-item-section {
  width: 100%;
  text-align: right;
}
.py-product-add-item-section-title,
.py-product-add-item-section-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-weight: bold;
}
.py-product-add-item-section-title {
  font-size: 20px;
  margin-bottom: 8px;
}
.py-product-add-item-section-comment {
  font-size: 14px;
}

.py-product-transfer,
.py-product-card {
  width: 448px;
  height: 412px;
  background: #dbe0f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-card {
  align-items: flex-start;
}
.py-product-transfer-inner,
.py-product-card-inner {
  margin: 12px 16px 16px 16px;
}

.py-product-transfer-section {
  margin-bottom: 8px;
}
.py-product-card-section {
  margin-bottom: 4px;
}
.py-product-transfer-section > p,
.py-product-card-section > p,
.py-product-card-type-inner {
  margin: 0px;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
}
.py-product-transfer-title,
.py-product-card-title {
  font-weight: bold;
  margin-bottom: 4px;
}
.py-product-card-comment {
  margin-bottom: 8px !important;
  margin-top: 4px !important;
}

.py-product-card-type {
  width: 384px;
  height: 48px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
}
.py-product-card-type-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
  margin-left: 16px;
  font-weight: bold;
}

.py-product-card-type-item:first-child {
  margin-right: 28px;
}

.py-product-card-type-item {
  display: flex;
  align-items: center;
}
.py-product-card-type-item > img {
  margin-right: 8px;
}

.py-product-transfer-bank {
  width: 416px;
  height: 184px;
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border: 1px solid #eaedf9;
  border-radius: 10px;
}
.py-product-transfer-bank-inner {
  margin-left: 16px;
}
.py-product-transfer-bank-header {
  height: 34px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-transfer-bank-list {
  height: 134px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 1px;
  padding-top: 1px;
}

.py-product-transfer-input {
  width: 416px;
  height: 46px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.py-product-transfer-input-inner {
  width: 382px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-transfer-input-inner > span {
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-transfer-input-inner > input {
  border: none;
  outline: none;
  background: none;
  text-align: right;
  width: 260px;
  font-size: 14px;
  color: #3e3e4f;
}

.py-product-transfer-btn-area {
  margin-top: 8px;
}
.py-product-transfer-btn-area.confirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-transfer-btn-area > button {
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  color: #ffffff;
  outline: none;
  border: none;
  transition: all 0.1s ease-out;
}
.py-product-transfer-btn-cancle,
.py-product-transfer-btn-auth {
  width: 204px;
  height: 36px;
}
.py-product-transfer-btn-ok {
  width: 416px;
  height: 36px;
}
.py-product-transfer-btn-cancle {
  background: #d7263d;
}
.py-product-transfer-btn-cancle:hover {
  background: #df4b5e;
}
.py-product-transfer-btn-auth,
.py-product-transfer-btn-ok {
  background: #2b4ac4;
}
.py-product-transfer-btn-auth:hover,
.py-product-transfer-btn-ok:hover {
  background: #4f67c5;
}
.py-product-transfer-btn-auth:disabled {
  background: #84889b;
  color: #dfe1e5;
}

.py-product-transfer-bank-item {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  background: #ffffff;
  height: 36px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.py-product-transfer-bank-item.selected {
  background: #3e3e4f;
}
.py-product-transfer-bank-item.selected > div > div {
  color: #ffffff;
}

.py-product-transfer-bank-item-inner {
  height: 100%;
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.py-product-transfer-bank-item-inner > div {
  margin-left: 4px;
  font-size: 12px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.py-product-transfer-bank-list-loading {
  width: 100%;
  height: 100%;
}

.py-product-transfer-ars {
  width: 416px;
  height: 292px;
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.py-product-transfer-ars-comment {
  letter-spacing: 0px;
  color: #3e3e4f;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.py-product-transfer-ars-num {
  letter-spacing: 0px;
  color: #2b4ac4;
  font-family: Spoqa Han Sans Neo;
  font-size: 36px;
  font-weight: bold;
  margin: 60px 0 70px 0;
}

.py-product-payment-info-loading {
  width: 344px;
  height: 222px;
  background: #dbe0f5;
  border-radius: 20px;
  margin: 16px 0;
}

.py-product-payment-info-card-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-product-payment-info-card-item-top > section > p {
  margin: 0;
  text-align: right;
  letter-spacing: 0px;
  color: #2b4ac4;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-product-payment-info-card-item-bot {
  margin-top: 47px;
}
.py-product-payment-info-card-item-bot.transfer {
  margin-top: 63px;
}

.py-product-payment-info-card-item-bot > section > p {
  margin: 0;
  text-align: right;
  letter-spacing: 0px;
  color: #2b4ac4;

  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-payment-info-card-item-bot > section > p:first-child {
  font-size: 12px;
  color: #9b9faf;
}
.py-product-payment-info-card-item-bot > section > p:last-child {
  font-size: 16px;
  color: #3e3e4f;
}

.py-product-card-input {
  background: #f8f9fd;
  border: 1px solid #eaedf9;
  border-radius: 10px;
  margin-bottom: 4px;
}
.py-product-card-input.num {
  width: 384px;
  height: 88px;
}
.py-product-card-input.expire {
  width: 384px;
  height: 46px;
}
.py-product-card-input.cvc,
.py-product-card-input.password {
  width: 188px;
  height: 80px;
}

.py-product-card-input-inner.num {
  margin: 12px 16px;
}
.py-product-card-input-inner.expire {
  margin: 7px 16px;
}
.py-product-card-input-inner.cvc,
.py-product-card-input-inner.password {
  margin: 12px 16px;
}

.py-product-card-input-comment {
  letter-spacing: 0px;
  color: #84889b;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}
.py-product-card-input-num-area {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.py-product-card-input-cvc-area,
.py-product-card-input-password-area {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.py-product-card-input-num-area > input,
.py-product-card-input-expire-area > input,
.py-product-card-input-cvc-area > input,
.py-product-card-input-password-area > input {
  background: none;
  border: none;
  outline: none;
}
.py-product-card-input-num-area > input,
.py-product-card-input-expire-area > input {
  text-align: center;
}
.py-product-card-input-cvc-area > input,
.py-product-card-input-password-area > input {
  text-align: right;
}
.py-product-card-input-num-area > input {
  width: 48px;
  height: 30px;
}
.py-product-card-input-expire-area > input {
  width: 40px;
  height: 30px;
}
.py-product-card-input-cvc-area > input {
  width: 100%;
  height: 21px;
}
.py-product-card-input-password-area > input {
  width: 132px;
  height: 21px;
}
.py-product-card-input-num-area > input::placeholder,
.py-product-card-input-expire-area > input::placeholder,
.py-product-card-input-cvc-area > input::placeholder,
.py-product-card-input-password-area > input::placeholder {
  color: #d1d3da;
}

.py-product-card-input-expire-area,
.py-product-card-input-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-product-card-btn-area {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-product-card-btn {
  width: 188px;
  height: 36px;
  border-radius: 8px;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  color: #ffffff;
  border: none;
  outline: none;
}
.py-product-card-btn.cancle {
  background: #d7263d;
}
.py-product-card-btn.ok {
  background: #3e3e4f;
}
.py-product-card-btn:disabled {
  background: #84889b;
  color: #dfe1e5;
}

.py-popup-success {
  width: 437px;
  height: 494px;
  background: white;
  border-radius: 16px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-popup-success-title {
  margin-top: 24px;
  margin-bottom: 16px;
  letter-spacing: 0px;
  color: #3e3e4f;
  font-size: 20px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
}

.py-popup-success-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-popup-success-info.top {
  margin-top: 24px;
  margin-bottom: 16px;
}
.py-popup-success-info.bot {
  margin-bottom: 24px;
}

.py-popup-success-section {
  text-align: center;
  margin-top: 4px;
}
.py-popup-success-section-font {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.py-popup-success-section-font.blue {
  color: #1240b8;
}
.py-popup-success-section-font.purple {
  color: #7300e9;
}

.py-popup-success-comment {
  letter-spacing: 0px;
  color: #84889b;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 16px;
}

.py-popup-success-btn {
  background: #2b4ac4;
  width: 320px;
  height: 48px;
  border-radius: 10px;
  color: #ffffff;
  font-family: Spoqa Han Sans Neo;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  border: none;
  outline: none;
  transition: transform 0.5s ease;
}

.py-popup-success-btn:hover {
  background: #4865cc;
}

.pf-paylog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-header-title {
  color: #8b5fbf;
  font-weight: bold;
  margin: 16px 0;
}

.pf-paylog-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dbe0f5;
  outline: 1px solid #a3b1e5;
  border-radius: 12px;
  height: 64px;
}

.pf-paylog-payment-content {
  margin: 14px 24px 14px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pf-paylog-payment-info > p,
.pf-paylog-payment-nm > p {
  margin: 0;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
  color: #3e3e4f;
}
.pf-paylog-payment-info > p:first-child {
  color: #2b4ac4;
  font-weight: bold;
}

.pf-paylog-payment-line {
  height: 21px;
  border: 1px solid #2b4ac4;
  margin: 0 23px;
}

.pf-paylog-payment-nm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
}

.pf-paylog-payment-change {
  letter-spacing: 0px;
  color: #6e85d7;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 150px;
}

.pf-paylog-payment-add {
  display: flex;
  align-items: center;
}
.pf-paylog-payment-add > font {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #2b4ac4;
  margin-bottom: 1px;
}

.pf-paylog-payment-outstanding {
  width: 152px;
  height: 36px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0;
  font-weight: bold;
  transition: 0.2 ease;
  transition: all 0.1s ease-out;
}
.pf-paylog-payment-outstanding:hover {
  background: #3654cc;
}
.pf-paylog-payment-outstanding:disabled {
  background: #8b9ddf;
}

.pf-paylog-container {
  width: 100%;
  height: calc(100% - 120px);
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pf-paylog-content-table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  height: 624px;
  display: block;
}
.pf-paylog-content-table > tbody {
  position: relative;
}
.pf-paylog-content-loading {
  height: 100%;
}
.pf-paylog-content-loading,
.pf-paylog-content-table-loading {
  width: 100%;
  position: absolute;
}

.pf-paylog-content-table-tr {
  height: 48px;
  background: #f9f9fa;
  font-size: 14px;
  text-align: center;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.pf-paylog-content-table-tr.thead {
  font-weight: bold;
  background: #ededf0;
}

.pf-paylog-content-table-tr > th:first-child,
.pf-paylog-content-table-tr > td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.pf-paylog-content-table-tr > th:last-child,
.pf-paylog-content-table-tr > td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pf-paylog-content-table-th.left,
.pf-paylog-content-table-td.left {
  text-align: left;
}

.pf-paylog-content-table-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #84889b;
}
.pf-paylog-content-table-btn > img {
  margin-right: 8px;
}

.pf-paylog-payment-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 64px;
  outline: 1px solid #a3b1e5;
  border: none;
  background: #dbe0f5;
}

.pf-paylog-content-table-empty {
  /* height: 100%; */
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b3b3;
  font-weight: bold;
}

.pf-paylog-content-paging {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: calc(100% - 624px);
}

.py-outstanding-popup {
  width: 400px;
  height: 392px;
  background: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-outstanding-popup-inner {
  width: calc(100% - 32px);
  height: 100%;
  margin: 8px 16px;
  position: relative;
}

.py-outstanding-popup-close {
  position: absolute;
  right: 0;
}

.py-outstanding-header {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.py-outstanding-container {
  /* height: calc(100% - 36px); */
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0px;
  font-family: Spoqa Han Sans Neo;
}

.py-outstanding-container-loading,
.pf-license-container-loading {
  height: 100%;
  width: 100%;
  position: absolute;
}

.py-outstanding-container-section {
  color: #3e3e4f;
  font-weight: bold;
  margin: 16px 0;
  text-align: center;
}
.py-outstanding-container-section > p {
  margin: 0;
}
.py-outstanding-container-section > p:first-child {
  font-size: 20px;
  margin-bottom: 8px;
}
.py-outstanding-container-section > p:last-child {
  font-size: 14px;
}

.py-outstanding-container-content {
  width: 280px;
  height: 136px;
  background: #eaedf9;
  border-radius: 10px;
  font-family: Spoqa Han Sans Neo;
}

.py-outstanding-container-content-inner {
  margin: 18px 23px 15px 23px;
}

.py-outstanding-content-item {
  color: #3e3e4f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.py-outstanding-content-item > font:first-child {
  font-size: 14px;
  font-weight: bold;
}
.py-outstanding-content-item > font:last-child {
  font-size: 16px;
}

.py-outstanding-content-comment {
  margin-top: 16px;
  text-align: center;
}
.py-outstanding-content-comment > p {
  margin: 0;
  font-size: 14px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #2b4ac4;
}

.py-outstanding-container-btn {
  width: 280px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  border: none;
  outline: none;
  margin-top: 8px;
  transition: all 0.1s ease-out;
}
.py-outstanding-container-btn:hover {
  background: #7489d4;
}
.py-outstanding-container-btn:active {
  background: #2b4ac4;
}
.py-outstanding-container-btn:disabled {
  background: #eaedf9;
}

.py-outstanding-container-comment {
  margin-top: 12px;
  font-size: 12px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #d7263d;
}

.pf-paylog-detail-list {
  color: #000000;
}
.pf-paylog-detail-list.line {
  text-decoration: line-through;
  text-decoration-color: red;
}

.pf-common-container {
  margin-top: 16px;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
}

.py-outstanding-result-popup {
  width: 360px;
  height: 304px;
  background: #ffffff;
  /* box-shadow: 0px 5px 16px #00000029; */
  border-radius: 12px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.py-outstanding-result-popup.success {
  width: 320px;
  height: 240px;
}

.py-outstanding-result-container {
  position: relative;
  width: calc(100% - 32px);
  height: calc(100% - 16px);
  margin: 8px 16px;
}

.py-outstanding-result-close {
  position: absolute;
  right: 0;
}

.py-outstanding-result-content {
  margin: 8px 9px 14px 9px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.py-outstanding-result-section {
  margin: 16px 0;
  text-align: center;
}

.py-outstanding-result-section > p {
  margin: 0;
  font-size: 14px;
  color: #3e3e4f;
}
.py-outstanding-result-section-bold {
  font-weight: bold !important;
}
.py-outstanding-result-section-title {
  font-size: 20px !important;
  font-weight: bold;
  text-transform: lowercase;
  margin-bottom: 8px !important;
}
.py-outstanding-result-section-title.success {
  color: #2b4ac4 !important;
}

.py-outstanding-result-section-msg.err {
  color: #c63353 !important;
  font-weight: bold;
  margin: 16px 0 !important;
}

.py-outstanding-result-section-msg.comment {
  color: #666b82 !important;
  font-size: 12px !important;
}

.py-outstanding-result-ok {
  width: 264px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  outline: none;
  transition: all 0.1s ease-out;
}
.py-outstanding-result-ok.success {
  width: 256px !important;
}
.py-outstanding-result-ok:hover {
  background: #7489d4;
}
.py-outstanding-result-ok:active {
  background: #2b4ac4;
}

.pf-license-content {
  position: relative;
  height: calc(100% - 40px);
}

.pf-license-table {
  /* height: 696px; */
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 12px;
}

.pf-license-table-tr {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}
.pf-license-table-tr.thead {
  font-size: 16px;
  height: 56px;
  background: #f1f2fa;
}
.pf-license-table-tr.tbody {
  font-size: 14px;
  height: 52px;
  background: #f8f9fd;
}
.pf-license-table-tr.tbody.new {
  background: linear-gradient(
      93deg,
      rgba(26, 255, 212, 0.2) 0%,
      rgba(139, 95, 191, 0.2) 100%
    )
    transparent !important;
}

.pf-license-table-tr > th:first-child,
.pf-license-table-tr > td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pf-license-table-tr > th:last-child,
.pf-license-table-tr > td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pf-license-table-font.bold {
  font-weight: bold;
}

.pf-license-table-font.left {
  text-align: left;
}
.pf-license-table-font.center {
  text-align: center;
}
.pf-license-table-font.unsubscribe,
.pf-license-table-font.free,
.pf-license-table-font.new {
  font-family: Spoqa Han Sans Neo !important;
  font-size: 12px;
  font-weight: bold;
}
.pf-license-table-font.unsubscribe,
.pf-license-table-font.red {
  color: #c63353 !important;
}
.pf-license-table-font.free {
  color: #d7263d;
}
.pf-license-table-font.new {
  color: #16ddb5;
}

.pf-license-table-unsubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pf-license-table-unsubscribe > img {
  margin-right: 4px;
}

.pf-license-paging {
  position: absolute;
  height: 38px;
  width: 276px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
/* .pf-license-content-inner {
    height: 758px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.py-unsubscribe-popup {
  width: 286px;
  height: 240px;
  background: #ffffff;
  box-shadow: -5px 3px 20px #00000029;
  border-radius: 16px;
  z-index: 910;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-popup-inner {
  margin: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3b3f4c;
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 48px);
}

.py-unsubscribe-popup-loading {
  position: absolute;
  width: 100%;
  height: 100%;
}

.py-unsubscribe-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
}
.py-unsubscribe-comment-1 {
  font-size: 14px;
  margin: 10px 0;
}

.py-unsubscribe-comment-2 {
  color: #c63353;
  font-size: 10px;
  margin-bottom: 25px;
  text-align: center;
}

.py-unsubscribe-btn-area {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.py-unsubscribe-btn-ok {
  color: #2b4ac4;
  margin-right: 30px;
}

.py-unsubscribe-btn-cancle {
  color: #c63353;
}

.py-unsubscribe-result-popup {
  width: 408px;
  height: 328px;
  background: #ffffff;
  box-shadow: -5px 3px 20px #00000029;
  border-radius: 16px;
  z-index: 910;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-result-popup-inner {
  margin: 24px 33px;
  width: calc(100% - 66px);
  height: calc(100% - 48px);
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.py-unsubscribe-result-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.py-unsubscribe-result-comment {
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.py-unsubscribe-result-expire {
  color: #1240b8;
}

.py-unsubscribe-result-btn {
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 320px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
}

.pf-pay-fail-popup {
  width: 320px;
  height: 288px;
  background: #ffffff;
  border-radius: 16px;
  z-index: 900;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.pf-pay-fail-close {
  position: absolute;
  margin-top: 8px;
  margin-right: 16px;
  right: 0;
}

.pf-pay-fail-popup-inner {
  margin: 16px 38px;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf-pay-fail-popup-title {
  font-size: 20px;
  font-weight: bold;
  color: #3e3e4f;
  margin: 16px 0 8px;
}

.pf-pay-fail-popup-comment {
  font-size: 14px;
  color: #3e3e4f;
  text-align: center;
}

.pf-pay-fail-popup-comment > div > font {
  color: #2b4ac4 !important;
  font-weight: bold !important;
}

.pf-pay-fail-popup-comment.red {
  font-size: 12px !important;
  color: #d7263d !important;
  margin-top: 12px;
  margin-bottom: 20px;
}

.pf-pay-fail-popup-btn {
  width: 240px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  border: none;
}

.host-excel-upload {
  position: absolute;
  z-index: 400;
  max-width: 424px;
  height: 120px;
  background: white;
  box-shadow: 0px 5px 20px #00000029;
  border-radius: 10px;
  right: 64px;
  top: 650px;
}

.host-excel-upload-close {
  position: absolute;
  right: 8px;
  top: 4px;
}

.host-excel-upload-inner {
  padding: 16px;
}
.host-excel-upload-section {
  margin-bottom: 8px;
}
.host-excel-upload-section > p {
  margin: 0;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
}
.host-excel-upload-section > p:first-child {
  color: #2b4ac4;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px !important;
}
.host-excel-upload-section > p:last-child {
  color: #666b82;
  font-size: 14px;
}

.host-excel-upload-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.host-excel-select-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.host-excel-select-btn {
  width: 152px;
  height: 36px;
}
.host-excel-select-btn {
  background: #2b4ac4;
  color: #ffffff;
  border: 1px solid #2b4ac4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.host-excel-select-btn:hover {
  background: #1f378e;
}
.host-excel-fileinfo {
  width: 240px;
  height: 36px;
  background: #eaedf9;
  border-radius: 10px;
  margin-right: 8px;
}
.host-excel-fileinfo-inner {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.host-excel-fileinfo-inner > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.host-excel-fileinfo-inner > div > div {
  margin-left: 8px;
  width: 167px;
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3e3e4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}
.host-excel-upload-btn {
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.1s ease-out;
}
.host-excel-upload-btn:hover {
  background: #1f378e;
}
.host-excel-upload-btn {
  width: 64px;
  height: 36px;
  background: #2b4ac4;
  border: 1px solid #2b4ac4;
  color: #ffffff;
}
