/**
 * Version : 1.0
 * 파일명 : popup.css
 * 작성일자 : 2021-12-29
 * 작성자 : 강연승
 * 설명 : 팝업 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 팝업 위치 수정
*/
.platformPopup {
  /* margin: 5% auto; */
  margin: 10% auto;
  padding: 16px 16px 21px 16px;
  width: 656px;
  background: white;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.platformPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.platformPopup .popup_title {
  text-align: left;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 8px;
}
.platformPopup .popup_expln {
  margin-bottom: 23px;
  text-align: center;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.platformPopup .closeBtn {
  cursor: pointer;
  float: right;
}
.platformPopup .popup_contents_background {
  padding: 24px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 16px;
  margin-top: 16px;
}
.platformPopup .popup_contents {
  /* background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 24px;
  margin-top: 16px;
  padding: 0px 100px; */
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(296px, 0fr));
}
.platformPopup .platformBox {
  width: 248px;
  height: 116px;
  padding: 20px 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000026;
  border-radius: 16px;
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.platformPopup .selectPlatform {
  border: 1px solid #6e85d7;
  width: 246px;
  height: 114px;
  padding: 20px 24px;
}
.platformPopup .platformBoxText {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
.platformPopup .platformBoxImg {
  display: flex;
  justify-content: flex-end;
}
.platformPopup .platformBox.platformBox_webex.selectPlatform {
  background: #f7f9ff 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_zoom.selectPlatform {
  background: #f6faff 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_chime.selectPlatform {
  background: #f2fbfb 0% 0% no-repeat padding-box;
}
.platformPopup .platformBox.platformBox_pexip.selectPlatform {
  background: #fff8f9 0% 0% no-repeat padding-box;
}
/* .platformPopup .platformBox.platformBox_webex .platformImg {
  margin-top: 32px;
  margin-bottom: 16px;
}
.platformPopup .platformBox.platformBox_zoom .platformImg {
  margin-top: 27px;
  margin-bottom: 19px;
}
.platformPopup .platformBox.platformBox_chime .platformImg {
  margin-top: 56px;
  margin-bottom: 49px;
}
.platformPopup .platformBox.platformBox_pexip .platformImg {
  margin-top: 60px;
  margin-bottom: 47px;
} */

.platformPopup .platformBox .platformNm {
  font: normal normal bold 16px/24px Spoqa Han Sans Neo;
  color: #9b9faf;
  /* margin-top: 8px;
  margin-bottom: 16px; */
}
.platformPopup .platformBox .platformExpln {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #9b9faf;
  /* margin-bottom: 8px; */
}
.platformPopup .platformBox .platformNm.webexActive {
  color: #1bc4ff;
}
.platformPopup .platformBox .platformExpln.webexActive {
  color: #2f65fb;
}
.platformPopup .platformBox .platformNm.zoomActive {
  color: #2c8cfb;
}
.platformPopup .platformBox .platformExpln.zoomActive {
  color: #0c4cba;
}
.platformPopup .platformBox .platformNm.chimeActive {
  color: #1abbc0;
}
.platformPopup .platformBox .platformExpln.chimeActive {
  color: #00868c;
}
.platformPopup .platformBox .platformNm.pexipActive {
  color: #3e3e4f;
}
.platformPopup .platformBox .platformExpln.pexipActive {
  color: #fa2a45;
}
.platformPopup .popup_footer {
  margin-top: 16px;
  text-align: right;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.platformPopup .popup_footer .chkLabel {
  margin-left: 8px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.platformPopup .popup_footer .confirmBtn {
  cursor: pointer;
  margin-left: 16px;
  border: none;
  width: 64px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
}

.resSuccessPopup {
  margin: 6% auto;
  /* padding: 8px 16px 24px 16px; */
  width: 416px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.resSuccessPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.resSuccessPopup .popup_title {
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-align: center;
}
.resSuccessPopup .closeBtn {
  cursor: pointer;
  float: right;
  margin: 8px 16px 0 0;
  /* position: absolute;
  top: 210px;
  right: 720px; */
}
.resSuccessPopup .platformImg {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}
.resSuccessPopup .popup_contents_background {
  padding: 0px 24px 24px 16px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 16px 16px;
}
.resSuccessPopup .popup_contents {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  margin-bottom: 16px;
  margin-left: 32px;
}
.resSuccessPopup .popup_contents .infoLabel {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  width: 61px;
  margin-right: 10px;
  text-align: right;
  color: #2b4ac4;
  display: inline-block;
}

.resSuccessPopup .div_scheduleURL {
  margin: 24px 20px 0px 20px;
  padding: 14px 21px 9px 24px;
  text-align: center;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.resSuccessPopup .scheduleURL {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  margin-right: 20px;
  display: inline-block;
}
.resSuccessPopup .scheduleURLText {
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
}

.resSuccessPopup .copyIcon {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.resSuccessPopup .popup_contents .scheduleTitle {
  text-align: left;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  vertical-align: bottom;
  margin-top: 24px;
}
.resSuccessPopup .popup_contents .scheduleRecording {
  text-align: left;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
  margin-top: 16px;
}

.resSuccessPopup .popup_contents .scheduleInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  display: inline-block;
}
.resSuccessPopup .popup_contents .scheduleContents {
  word-break: break-all;
  width: 273px;
  max-height: 72px;
  overflow-y: auto;
  white-space: pre-line;
}
.resSuccessPopup .popup_contents .participantsList {
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.resSuccessPopup .popup_contents .scheduleFileList {
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.resSuccessPopup .popup_contents .scheduleFileInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  cursor: pointer;
  display: block;
}
.resSuccessPopup .popup_footer {
  text-align: center;
  margin-top: 28px;
}
.resSuccessPopup .confirmBtn {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
}
.makeGroupPopup {
  text-align: center;
  width: 480px;
  /* padding: 29px 80px 40px; */
  /* min-height: 444px; */
  margin: 13% auto;
  background: white;
  border-radius: 32px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 16px #00000029;
}
.makeGroupPopupOverlay {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.makeGroupPopup_background {
  width: 480px;
  /* height: 444px; */
}

.makeGroupPopup_header {
  margin-top: 29px;
  color: #2b4ac4;
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
}

.makeGroupPopup_body {
  width: 480px;
  margin: 0px 17px 0px 17px;
}

.inviteGroupAgreePopup {
  text-align: center;
  width: 536px;
  /* padding: 29px 80px 40px; */
  /* min-height: 444px; */
  margin: 10% auto;
  background: white;
  border-radius: 32px;
  z-index: 999;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 16px #00000029;
}
.inviteGroupAgreePopup_Overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

/* 그룹 거절 확인 alert */
.denyAlert .MuiBackdrop-root {
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.denyAlert .MuiPaper-elevation24 {
  box-shadow: -5px 3px 20px #00000029;
}
.denyAlert .MuiDialog-paperWidthXs {
  width: 255px;
  padding: 24px 8px 8px 24px;
  border-radius: 16px;
}
.denyAlert .btnArea {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
.denyAlert .btnYes {
  width: 64px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  cursor: pointer;
  border: none;
}
.denyAlert .btnYes {
  width: 58px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  border: none;
  margin-right: 8px;
}

.scheduleDetail {
  margin: 5% auto;
  padding: 10px 16px 24px 16px;
  width: 374px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.scheduleDetail_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.scheduleDetail .closeBtn {
  cursor: pointer;
  float: right;
  /* margin: 8px 16px 0 0; */
  /* position: absolute;
  top: 210px;
  right: 720px; */
}
.scheduleDetail .popup_header,
.scheduleDetail .popup_contents {
  margin-left: 24px;
}
.scheduleDetail .scheduleTitle {
  font: normal normal bold 24px/32px Spoqa Han Sans Neo;
  /* color: #2b4ac4; */
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  vertical-align: bottom;
}
.scheduleDetail .scheduleDetail_PlatFormDiv {
  margin-top: 32px;
}
.scheduleDetail .scheduleDetail_HostKeyDiv {
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-top: 8px;
  width: 170px;
  height: 18px;
  padding: 7px 16px 7px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3068fe;
}
.scheduleDetail .scheduleDetail_HostKeyExpln {
  margin-top: 8px;
  font: normal normal bold 12px/18px Spoqa Han Sans Neo;
  color: #c63353;
}

.scheduleDetail .scheduleDetail_GroupNm {
  margin-top: 14px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.scheduleDetail .scheduleDetail_div {
  display: flex;
  margin-top: 16px;
}
.scheduleDetail .scheduleDetail_label {
  width: 61px;
  margin-right: 10px;
  display: inline-block;
  text-align: right;
  color: #2b4ac4;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.scheduleDetail .scheduleDetail_label.timeline {
  color: #2b4ac4;
}
.scheduleDetail .scheduleDetail_label.history {
  color: #3e3e4f;
}
.scheduleDetail .scheduleDetail_recYN.history {
  color: #3e3e4f;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-top: 10px;
}
.scheduleDetail .scheduleDetail_recYN.timeline {
  width: 35px;
  height: 16px;
  padding: 4px 0;
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 6px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  /* margin-top: 10px; */
  display: inline-block;
}
.scheduleDetail .scheduleDetail_Contents {
  text-align: left;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  display: inline-block;
}
.scheduleDetail .scheduleDetail_Contents.participantsList {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 72px;
  overflow-y: auto;
}
.scheduleDetail .scheduleDetail_Contents.scheduleContents {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
  white-space: pre-line;
}
.scheduleDetail .scheduleDetail_Contents.scheduleFileList {
  font: normal normal normal 14px/24px Spoqa Han Sans Neo;
  word-break: break-all;
  width: 273px;
  max-height: 48px;
  overflow-y: auto;
}
.scheduleDetail .scheduleDetail_Contents .scheduleFileList .scheduleFileInfo {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  cursor: pointer;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}
.scheduleDetail .scheduleDetail_Contents .meetingLink {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}

.scheduleDetail .div_scheduleURL {
  margin: 24px 20px 0 20px;
  padding: 14px 21px 9px 24px;
  text-align: center;
  background: #dbe0f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.scheduleDetail .scheduleURL {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #6e85d7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  margin-right: 20px;
  display: inline-block;
}
.scheduleDetail .scheduleURLText {
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 12px/16px Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #6e85d7;
}

.scheduleDetail .copyIcon {
  display: inline-block;
  cursor: pointer;
}

.scheduleDetail .footer {
  margin-top: 24px;
  padding-top: 20px;
  text-align: center;
}
.scheduleDetail .footer .editBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #2b4ac4;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleDetail .footer .delBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #c63353;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleDetail .footer .enterBtn {
  padding: 8px 16px 10px;
  border-radius: 8px;
  background-color: #3e3e4f;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.workTimePopup {
  margin: 18% auto;
  padding: 36px 38px 30px;
  width: 498px;
  background: white;
  border-radius: 23px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.workTimePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.workTimePopup .popupTitle {
  font: normal normal bold 21px/20px Spoqa Han Sans Neo;
  text-align: left;
  color: #111;
}
.workTimePopup .popupText {
  font: normal normal 500 17px/16px Spoqa Han Sans Neo;
  text-align: left;
  color: #436bff;
  margin-top: 19px;
}
.workTimePopup .timeArea {
  display: flex;
  justify-content: center;
  margin-top: 43px;
  margin-bottom: 39px;
}
.workTimePopup .selectArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workTimePopup .selectBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49px;
  height: 15px;
  padding: 15px 13px 15px 21px;
  border-radius: 10px;
  background-color: #ebedf5;
  font: normal normal normal 18px/15px Spoqa Han Sans Neo;
  color: #111;
}
.workTimePopup .selectIcon {
  width: 13px;
  height: 9px;
}
.workTimePopup .timeText {
  margin-top: 7px;
  font: normal normal 500 15px/15px Spoqa Han Sans Neo;
  color: #909090;
}
.workTimePopup .timeColon {
  margin: 17px 4px 0;
  font: normal normal normal 21px/12px Spoqa Han Sans Neo;
  text-align: center;
  color: #111;
}
.workTimePopup .timeWave {
  margin: 19px 12px 0;
  font: normal normal 500 30px/6px Spoqa Han Sans Neo;
  text-align: center;
  color: #111;
}
.workTimePopup .btnConfirm {
  width: 127px;
  height: 45px;
  font: normal normal normal 18px/17px Spoqa Han Sans Neo;
  text-align: left;
  color: #fff;
  background-color: #436bff;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: none;
}
.workTimePopup .selectPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 83px;
  height: 100px;
  margin-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}
.workTimePopup .selectPopup .selectItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: center;
  height: 30px;
  width: 83px;
  color: #3e3e4f;
  line-height: 30px;
}

/* 통계 상세 팝업 */
.data-statistics-detail-popup {
  margin: 8% auto;
  padding: 12px 16px 20px 24px;
  width: 694px;
  background: white;
  transition: all 0.5s ease-out;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.data-statistics-detail-popup-header {
  display: flex;
  height: 36px;
  align-items: flex-end;
  justify-content: space-between;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-title {
  font: normal normal bold 18px/24px Spoqa Han Sans Neo;
  color: #2b4ac4;
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-btnPDF {
  width: 138px;
  height: 36px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 16px;
  border: 1px solid #3e3e4f;
  border-radius: 8px;
  padding: 0 15px 0 12px;
  cursor: pointer;
}
.data-statistics-detail-popup-header
  .data-statistics-detail-popup-header-btnPDF
  .data-statistics-detail-popup-header-btnPDF-text {
  margin-left: 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.data-statistics-detail-popup-info {
  margin-top: 12px;
  margin-right: 8px;
  padding: 12px 24px 20px;
  height: 62px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}
.data-statistics-detail-popup-groupParticipantInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-dateTimeInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-meetingCntInfo {
  display: flex;
  align-items: flex-end;
}
.data-statistics-detail-popup-eduAttendInfo {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}
.data-statistics-detail-popup-groupInfo {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-groupProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 999px;
  background: #cd704b;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}
.data-statistics-detail-popup-groupNm {
  margin-left: 8px;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-participant-info {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.data-statistics-detail-popup-participant-name {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-participant-email {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-dateRange {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.data-statistics-detail-popup-useTime {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
}
.data-statistics-detail-popup-edu-total {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.data-statistics-detail-popup-edu-attend {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: space-between;
}
.data-statistics-detail-popup-meetingCnt {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
}
.data-statistics-detail-popup-data {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-duration {
  margin-left: 8px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-cnt {
  margin-left: 24px;
  font: normal normal normal 14px/18px spoqa Han Sans Neo;
}
.data-statistics-detail-popup-attendance-percent {
  margin-top: 8px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-percent-header {
  display: flex;
  align-items: center;
  height: 32px;
  background: #cad2f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0 16px 0 24px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-percent-body {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-detail-popup-attendance-percent-emptyBody {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
}
.data-statistics-detail-popup-attendance-percent-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 56px;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo-label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  text-align: center;
  color: #3e3e4f;
}
.data-statistics-detail-popup-attendance-noWorkTimeInfo-value {
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo,
.data-statistics-detail-popup-attendance-eduInfo,
.data-statistics-detail-popup-attendance-socialInfo {
  display: flex;
  flex-direction: column;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-box {
  width: 14px;
  height: 14px;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-workTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #8b5fbf;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #37b392;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-box {
  width: 14px;
  height: 14px;
  background: #37b392 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-weekWorkTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #37b392;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-box {
  width: 14px;
  height: 14px;
  background: #ee6c4d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-workTimeInfo-noWorkTime-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-eduInfo-attendance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-box {
  width: 14px;
  height: 14px;
  background: #2993bc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-attendance-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2993bc;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #efac00;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-box {
  width: 14px;
  height: 14px;
  background: #efac00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-tardy-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #efac00;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-box {
  width: 14px;
  height: 14px;
  background: #ee6c4d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-eduInfo-absent-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #ee6c4d;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-socialInfo-attend {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-box {
  width: 14px;
  height: 14px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-attend-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 32px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-box {
  width: 14px;
  height: 14px;
  background: #c63353 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-socialInfo-noAttend-value {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #c63353;
  margin-left: 32px;
}

.data-statistics-detail-popup-attendance-log {
  margin-top: 16px;
  margin-right: 8px;
}
.data-statistics-detail-popup-attendance-log-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  background: #cad2f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0 12px 0 24px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-log-paging {
  display: flex;
  align-items: center;
}
.data-statistics-detail-popup-attendance-log-paging img {
  cursor: pointer;
  margin: 0 4px;
}
.data-statistics-detail-popup-attendance-log-body {
  margin-top: 12px;
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  height: 178px;
}
.data-statistics-detail-popup-attendance-log-table th {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  height: 26px;
  color: #3e3e4f;
}
.data-statistics-detail-popup-attendance-log-table tbody tr {
  height: 28px;
  margin-top: 4px;
}
.data-statistics-detail-popup-attendance-log-table td {
  font-size: 14px;
  line-height: 18px;
  /* font: normal normal normal 14px/18px Spoqa Han Sans Neo; */
}
.data-statistics-detail-popup-attendance-log-table-left {
  padding-left: 8px;
  text-align: left;
}
.data-statistics-detail-popup-attendance-log-table-center {
  text-align: center;
}
.data-statistics-detail-popup-attendance-log-table-title {
  padding-left: 8px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 184px;
}
.data-statistics-detail-popup-attendance-type-WORK_TIME_IN {
  font-weight: bold;
  color: #8b5fbf;
}
.data-statistics-detail-popup-attendance-type-WORK_TIME_OUT {
  font-weight: bold;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-type-WORK_WEEKEND {
  font-weight: bold;
  color: #37b392;
}
.data-statistics-detail-popup-attendance-type-ATTENDANCE {
  font-weight: bold;
  color: #2993bc;
}
.data-statistics-detail-popup-attendance-type-TARDY {
  font-weight: bold;
  color: #efac00;
}
.data-statistics-detail-popup-attendance-type-ABSENT {
  font-weight: bold;
  color: #ee6c4d;
}
.data-statistics-detail-popup-attendance-type-ATTEND {
  font-weight: bold;
  color: #2b4ac4;
}
.data-statistics-detail-popup-attendance-type-NO_ATTEND {
  font-weight: bold;
  color: #c63353;
}
.data-statistics-detail-popup-attendance-log-body-empty {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 500 16px/16px Spoqa Han Sans Neo;
  color: #8b8e99;
  height: 178px;
}
