/**
 * Version : 1.0
 * 파일명 : recipt.css
 * 작성일자 : 2022-04-15
 * 작성자 : 권도훈
 * 설명 : 전표 css
 * 수정일자 : 2022-04-21
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
*/
.rp-container {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f4f6;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
}

.rp-content {
    padding: 24px;
    width: 352px;
    background-color: rgb(255, 255, 255);
}
.rp-table {
    width: 100%;
}

.rp-table-tr.empty {
    height: 30px;
}

.rp-table-title {
    font-size: 24px;
    font-weight: bold;
    color: #3E3E4F;
}
.rp-table-text {
    font-weight: bold;
    font-size: 14px;
}
.rp-table-text.key {
    color: #84889B;
}
.rp-table-text.value {
    color: #666B82;
}
.rp-table-text.blue {
    color: #2F82F6 !important;
}

.rp-table-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rp-table-div.margin {
    margin-top: 8px;
}
.rp-table-div.line {
    border-bottom: 1px solid #e2e2e2;
    margin: 16px 0;
}

.rp-table-footer {
    font-size: 14px;
    color: #84889B;
    display: flex;
    flex-direction: column;
}
.rp-table-footer.title {
    font-weight: bold;
}

.rp-table-btn {
    width: 36px;
    height: 36px;
    background: #AFB3BF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-out;
}
.rp-table-btn:first-child {
    margin-right: 6px;
}
.rp-table-btn:hover {
    background: #c2c2c2;
}
.rp-table-btn:active {
    background: #AFB3BF;
}

.rp-table-btn > img {
    width: 20px;
    height: 20px;
}