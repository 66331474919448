@media screen and (min-width:600px) {
    /**
    HEADER
    **/
    header {
        z-index: 999;
        position: fixed;
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(255, 255, 255);
    }
    .hd_content {
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hd_content > section {
        width: 500px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hd_txt_btn_area {
        display: flex;
        width: 280px;
        justify-content: space-between;
    }
    .hd_txt_btn_area > button {
        background: none;
        outline: none;
        border: none;
        color: rgb(139, 95, 191);
        font-weight: bold;
    }
    .hd_btn {
        background: rgb(43, 74, 196);
        border-radius: 20px;
        width: 88px;
        height: 40px;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: bold;
        border: none;
    }
    /**
    BODY
    **/
    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        font-family: "Spoqa Han Sans Neo";
    }
    /* 우측 상단 이동 버튼 */
    .bd_top_btn {
        z-index: 999;
        position: fixed;
        right: 5%;
        bottom: 5%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        cursor: pointer;
        width: 66px;
        height: 66px;
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px;
        border-radius: 150px 70px;
        background: rgb(139, 95, 191);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 최상단 로고 섹션 */
    .bd_title_section {
        width: 100%;
        height: 921px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: rgb(242, 244, 251);
    }
    .bd_title_section > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1200px;
    }
    .bd_title_logo {
        margin-bottom: 24px;
    }
    .bd_title_logo_article {
        font-size: 32px;
        font-weight: bold;
        color: rgb(43, 74, 196);
        text-align: center;
        margin-bottom: 32px;
    }
    .bd_title_logo_article > p {
        margin: 0px;
    }
    .bd_title_btn {
        background: rgb(43, 74, 196);
        border: none;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 16px;
        height: 48px;
        width: 240px;
        font-family: "Spoqa Han Sans Neo";
        margin-bottom: 72px;
    }
    .bd_title_pebble_article {
        text-align: center;
        margin-bottom: 152px;
    }
    .bd_title_pebble_article > p {
        font-size: 28px;
        font-weight: bold;
        color: rgb(62, 62, 79);
    }
    /* 캘린더 이미지 섹션 */
    .bd_calendar_section {
        position: absolute;
        inset: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bd_calendar_section > img {
        width: 1124px;
        height: 702px;
    }
    /* Pebble 홍보 문구 섹션 */
    .bd_pr_section {
        width: 100%;
        height: 3176px;
        background: rgb(43, 74, 196);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .bd_pr_content {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .bd_pr_title_article {
        text-align: center;
        color: rgb(255, 255, 255);
        font-weight: bold;
    }
    .bd_pr_title {
        font-size: 32px;
    }
    .bd_pr_description {
        font-size: 20px;
    }
    .bd_pr_content > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        height: 180px;
        margin-bottom: 100px;
    }
    .bd_pr_content > ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 200px;
        text-align: center;
    }
    .bd_pr_content > ul > li.center {
        margin: 0px 65px;
    }
    .bd_pr_content > ul > li > title {
        height: 126px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
    }
    .bd_pr_feature {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 72px;
    }
    .bd_pr_feature > p {
        color: rgb(255, 255, 255);
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
    .bd_pr_feature > img {
        position: absolute;
        bottom: 10px;
    }
    .bd_pr_feature_item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 72px;
    }
    .bd_pr_feature_item.last {
        margin-bottom: 136px;
    }
    .bd_pr_feature_item > article.left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        margin-left: 70px;
        margin-bottom: 70px;
    }
    .bd_pr_feature_item > article.right {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        margin-left: 70px;
        margin-bottom: 70px;
    }
    .bd_pr_feature_item_title {
        width: 224px;
        height: 56px;
        background: rgb(31, 55, 142);
        border-radius: 72px 28px;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }
    .bd_pr_feature_item_description {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: bold;
    }
    .bd_pr_feature_item_description.right {
        text-align: right;
    }
    /* 사용가이드 섹션 */
    .bd_guide_section {
        width: 100%;
        height: 875px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .bd_guide_title {
        text-align: center;
        margin-top: 128px;
        font-size: 32px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        margin-bottom: 64px;
    }
    .bd_guide_video {
        width: 1024px;
        height: 600px;
        background: rgb(223, 225, 229);
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 10px;
        border-radius: 32px;
    }
    /* 제품 설명 섹션 */
    .bd_product_section {
        width: 100%;
        height: 736px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }
    /* 무료 체험 섹션 */
    .bd_ep_section {
        width: 100%;
        height: 1300px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 120px;
    }
    .bd_ep_item_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_item_list > .center {
        margin: 0 75px;
    }
    .bd_ep_item_title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_item_title.blue {
        color: rgb(43, 74, 196);
    }
    .bd_ep_item_title.orange {
        color: #EE6C4D;
    }
    .bd_ep_item_title.purple {
        color: #8B5FBF;
    }
    .bd_ep_item_description {
        margin-top: 32px;
        margin-bottom: 61px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        text-align: center;
    }
    .bd_ep_fuction_area {
        margin-bottom: 136px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 1000px;
    }
    .bd_ep_fuction_title {
        color: rgb(43, 74, 196);
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_logo {
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .bd_ep_fuction_area > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_area > ul > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_fuction_area > ul > li > div {
        z-index: 99;
    }
    .bd_ep_fuction_area > ul > li > img {
        position: absolute;
    }
    .bd_ep_btn_area {
        margin-top: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bd_ep_btn {
        background: rgb(238, 108, 77);
        border-radius: 10px;
        width: 240px;
        height: 56px;
        outline: none;
        border: none;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: bold;
    }
    /**
    FOOTER
    **/
    footer {
        width: 100%;
        height: 200px;
        background-color: rgb(62, 62, 79);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ft_content {
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .ft_btn_area {
        display: flex;
        width: 100%;
        justify-content: center;
        color: rgb(255, 255, 255);
        margin-top: 24px;
    }
    .ft_btn {
        font-size: 18px;
    }
    .ft_btn.center1 {
        margin: 0px 18px 0px 36px;
    }
    .ft_btn.center2 {
        margin: 0px 36px 0px 18px;
    }
    .ft_info {
        font-size: 14px;
        margin-top: 24px;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}

@media screen and (max-width:599px) {
    /**
    HEADER
    **/
    header {
        z-index: 999;
        position: fixed;
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: rgb(255, 255, 255);
        justify-content: flex-start;
    }
    .hd_content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    .hd_content > section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hd_content > div > img {
        width: 70%;
        margin-left: 40px;
    }
    .hd_txt_btn_area {
        display: flex;
        width: 200px;
        justify-content: space-between;
    }
    .hd_txt_btn_area > button {
        background: none;
        outline: none;
        border: none;
        color: rgb(139, 95, 191);
        font-weight: bold;
        font-size: 10px;
    }
    .hd_btn {
        background: rgb(43, 74, 196);
        border-radius: 20px;
        width: 60px;
        height: 30px;
        color: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: bold;
        border: none;
        margin: 0 5px;
    }
    /**
    BODY
    **/
    .body {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        font-family: "Spoqa Han Sans Neo";
    }
    /* 우측 상단 이동 버튼 */
    .bd_top_btn {
        z-index: 999;
        position: fixed;
        right: 5%;
        bottom: 5%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        cursor: pointer;
        width: 66px;
        height: 66px;
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px;
        border-radius: 150px 70px;
        background: rgb(139, 95, 191);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 최상단 로고 섹션 */
    .bd_title_section {
        width: 100%;
        height: 670px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: rgb(242, 244, 251);
    }
    .bd_title_section > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1200px;
    }
    .bd_title_logo {
        margin-bottom: 24px;
        width: 150px;
    }
    .bd_title_logo_article {
        font-size: 24px;
        font-weight: bold;
        color: rgb(43, 74, 196);
        text-align: center;
        margin-bottom: 32px;
    }
    .bd_title_logo_article > p {
        margin: 0px;
    }
    .bd_title_btn {
        background: rgb(43, 74, 196);
        border: none;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 16px;
        height: 48px;
        width: 240px;
        font-family: "Spoqa Han Sans Neo";
        margin-bottom: 40px;
    }
    .bd_title_pebble_article {
        text-align: center;
        margin-bottom: 90px;
    }
    .bd_title_pebble_article > p {
        font-size: 20px;
        font-weight: bold;
        color: rgb(62, 62, 79);
    }
    .bd_title_pebble_article > img {
        width: 80%;
    }
    /* 캘린더 이미지 섹션 */
    .bd_calendar_section {
        position: absolute;
        inset: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(0, 600px) !important;
    }
    .bd_calendar_section > img {
        width: 350px;
        height: 230px;
    }
    /* Pebble 홍보 문구 섹션 */
    .bd_pr_section {
        width: 100%;
        height: 2355px;
        background: rgb(43, 74, 196);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .bd_pr_content {
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .bd_pr_title_article {
        text-align: center;
        color: rgb(255, 255, 255);
        font-weight: bold;
    }
    .bd_pr_title {
        font-size: 24px;
    }
    .bd_pr_description {
        font-size: 14px;
        max-width: 200px;
    }
    .bd_pr_content > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 50px;
        flex-direction: column;
    }
    .bd_pr_content > ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 200px;
        text-align: center;
    }
    .bd_pr_content > ul > li.center {
        margin: 0px 65px;
    }
    .bd_pr_content > ul > li > title {
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
    }
    .bd_pr_feature {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
    }
    .bd_pr_feature > p {
        color: rgb(255, 255, 255);
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_pr_feature > img {
        position: absolute;
        bottom: 12px;
        width: 390px;
    }
    .bd_pr_feature_item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
    }
    .bd_pr_feature_item.center {
        flex-direction: column-reverse;
    }
    .bd_pr_feature_item > img {
        width: 50%;
        margin-bottom: 20px;
    }
    .bd_pr_feature_item.last {
        margin-bottom: 136px;
    }
    .bd_pr_feature_item > article.left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
    .bd_pr_feature_item > article.right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
    .bd_pr_feature_item_title {
        width: 200px;
        height: 56px;
        background: rgb(31, 55, 142);
        border-radius: 72px 28px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }
    .bd_pr_feature_item_description {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: bold;
    }
    .bd_pr_feature_item_description.right {
        text-align: left;
    }
    /* 사용가이드 섹션 */
    .bd_guide_section {
        width: 100%;
        height: 560px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bd_guide_section > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bd_guide_title {
        text-align: center;
        margin-top: 80px;
        font-size: 22px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        margin-bottom: 64px;
    }
    .bd_guide_video {
        width: 400px;
        height: 250px;
        background: rgb(223, 225, 229);
        box-shadow: rgb(0 0 0 / 16%) 0px 5px 10px;
        border-radius: 32px;
    }
    /* 제품 설명 섹션 */
    .bd_product_section {
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
    }
    .bd_product_section > div > img {
        width: 98%;
    }
    /* 무료 체험 섹션 */
    .bd_ep_section {
        width: 100%;
        height: 1832px;
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-top: 120px;
        margin-bottom: 100px;
    }
    .bd_ep_item_list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bd_ep_item_list > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .bd_ep_item_list > li > img {
        width: 60%;
    }
    .bd_ep_item_list > .center {
        margin: 0 75px;
    }
    .bd_ep_item_title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_item_title.blue {
        color: rgb(43, 74, 196);
    }
    .bd_ep_item_title.orange {
        color: #EE6C4D;
    }
    .bd_ep_item_title.purple {
        color: #8B5FBF;
    }
    .bd_ep_item_description {
        margin-top: 32px;
        margin-bottom: 61px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(62, 62, 79);
        text-align: center;
    }
    .bd_ep_fuction_area {
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bd_ep_fuction_title {
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .bd_ep_fuction_logo {
        margin-top: 50px;
        margin-bottom: 60px;
        width: 50%;
    }
    .bd_ep_fuction_area > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: rgb(43, 74, 196);
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        flex-direction: column;
        height: 250px;
    }
    .bd_ep_fuction_area > ul > li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bd_ep_fuction_area > ul > li > div {
        z-index: 99;
        font-size: 18px;
    }
    .bd_ep_fuction_area > ul > li > img {
        position: absolute;
    }
    .bd_ep_btn_area {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bd_ep_btn {
        background: rgb(238, 108, 77);
        border-radius: 10px;
        width: 200px;
        height: 48px;
        outline: none;
        border: none;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: bold;
    }
    /**
    FOOTER
    **/
    footer {
        width: 100%;
        height: 200px;
        background-color: rgb(62, 62, 79);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ft_content {
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .ft_content > img {
        width: 20%;
    }
    .ft_btn_area {
        display: flex;
        width: 100%;
        justify-content: center;
        color: rgb(255, 255, 255);
        margin-top: 24px;
    }
    .ft_btn {
        font-size: 14px;
    }
    .ft_btn.center1 {
        margin: 0px 10px 0px 10px;
    }
    .ft_btn.center2 {
        margin: 0px 0px 0px 10px;
    }
    .ft_info {
        font-size: 10px;
        margin-top: 18px;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}