/**
 * Version : 1.0
 * 파일명 : paging.css
 * 작성일자 : 2022-04-22
 * 작성자 : 권도훈
 * 설명 : 페이징 CSS
*/
.pg-common-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pg-common-font {
  font-size: 14px;
  font-weight: bold;
  font-family: Spoqa Han Sans Neo;
  letter-spacing: 0px;
  color: #3e3e4f;
}

.pg-common-div > div {
  display: flex;
  align-items: center;
}

.pg-common-img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.pg-common-img:last-child {
  margin-right: 0;
}
