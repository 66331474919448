/**
 * Version : 1.0
 * 파일명 : empty.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 잘못된 페이지 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Empty_Div {
  text-align: center;
}