/**
 * Version : 1.0
 * 파일명 : alertsupimage.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : alert모달창 CSS
 **/
.supportPopupimage {
    margin-left: 10% ;
    margin-top: 5%;
    width: 80%;
    height: 80%;
    background: rgba(120, 120, 120, 0.8);
    border-radius: 20px;
    z-index: 99;
    box-shadow: 0px 5px 16px #00000029;
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .supportPopupimage_Overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(62, 62, 79, 0.3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .modal_image_background{
      text-align: center;
      width: 100%;
      height: 100%;
    
  }
  .modal_image_center{
    line-height: 725px;
  }