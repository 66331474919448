/**
 * Version : 1.0
 * 파일명 : coachmark.css
 * 작성일자 : 2022-06-08
 * 작성자 : 권도훈
 * 설명 : 코치마크 CSS
*/
.ReactModal__Body--open {
    position: relative;
    width: 100%;
    height: 100%;
}
.coachmark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.73);
    z-index: 80;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 100%;
    min-height: 100%;
}

.coachmark {
    height: 100%;
    min-width: 1680px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    border: none;
}

.cm-slide-btn-area {
    position: absolute;
    width: calc(100% - 82px);
    margin: 0 41px;
    display: flex;
}
.cm-slide-btn {
    position: absolute;
    width: 34px;
    height: 65px;
}
.cm-slide-btn.none {
    display: none;
}
.cm-slide-btn:first-child {
    left: 0;
}
.cm-slide-btn:last-child {
    transform: rotate(180deg);
    right: 0;
}

.cm-bot-area {
    position: absolute;
    top: 45px;
    right: 42px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.cm-close-btn {
    width: 55px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm-close-btn-img {
    transition: transform 0.1s ease-out;
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.cm-close-btn-img.hover {
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.cm-pagecount-area {
    margin-right: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cm-pagecount-current,
.cm-pagecount-total {
    font-family: SpoqaHanSansNeo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.cm-pagecount-current {
    color: #FFFFFF;
}
.cm-pagecount-total {
    color: rgba(255, 255, 255, 0.68);
}
.cm-pagecount-line {
    border: 1px solid rgba(255, 255, 255, 0.68);
    margin: 0 14px;
    font-size: 10px;
    height: 12px;
}