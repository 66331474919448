.alarm_Div {
    width: 360px;
    min-height: 69px;
    max-height: 608px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 12px;
    /* right: 231px; */
    position: absolute;
    /* float: right; */
    z-index: 920;
    overflow-y: auto;
    inset: 0px auto auto 0px;
    overflow-x: hidden;
}

.alarm_Item {
    font-size: 16px;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid #AEC7DE;
    width: 312px;
}
