/**
 * Version : 1.0
 * 파일명 : sidebar.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 사이드바 CSS
 * 수정일자 : 2022-06-13
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 라디오 버튼 영역 크기 수정
*/
.sidebar_adress_input {
  width: 100%;
  height: 20px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  font-size: 18px;
}
.sidebar_adress_btn {
  font-weight: bold;
  color: white;
  background-color: blue;
  width: 280px;
  height: 60px;
  border: none;
  border-radius: 15px !important;
  cursor: pointer;
  margin-top: 10px;
  margin: 0 auto;
  display: block;
}
.sidebar_adress_div {
  height: 740px;
}
.Sidebar_MeetingBtn {
  width: 392px;
  height: 126px;
  background-color: #2b4ac4;
  border-radius: 32px;
  margin: 24px 0;
  padding: 0 32px;
  /* width: 280px;
    height: 60px;
    margin-top: 30px !important;
    margin-bottom: 30px !important; */
}
.meetingNowBtn {
  width: 392px;
  height: 126px;
  background-color: #2b4ac4;
  border: none;
  border-radius: 32px;
  margin: 24px 0;
  padding: 0 32px;
  cursor: pointer;
}
.meetingNowBtn_red {
  background-color: #d4667e;
}
.meetingNowBtn_orange {
  background-color: #c44308;
}
.meetingNowBtn_yellow {
  background-color: #d49500;
}
.meetingNowBtn_bean {
  background-color: #87ad26;
}
.meetingNowBtn_green {
  background-color: #1b7c37;
}
.meetingNowBtn_mint {
  background-color: #229a80;
}
.meetingNowBtn_blue {
  background-color: #8b9ddf;
}
.meetingNowBtn_pine {
  background-color: #2688ae;
}
.meetingNowBtn_pink {
  background-color: #a836c7;
}
.meetingNowBtn_purple {
  background-color: #613eba;
}
.meetingNowBtn_black {
  background-color: #000000;
}

.sidebar_schedule {
  border-radius: 32px 32px 0 0;
  background-color: rgba(255, 255, 255, 0.32);
  /* margin-left: 20px;
    width: 360px;
    background: rgba(152, 215, 2216, 0.4);
    height: 75vh;
    overflow-y: auto;
    border-radius: 15px; */
}
.sidebar_schedule ::-webkit-scrollbar {
  width: 8px;
}
.sidebar_schedule ::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: rgba(209, 211, 218, 1);
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
.sidebar_schedule ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.sidebar_toggleBtn {
  float: right;
  text-align: center;
  border: 1px solid;
  width: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  background: blue;
  border: none;
  color: white;
}
.sidebar_header {
  margin-left: 32px;
  margin-right: 32px;
  padding: 14px 0;
}
.sidebar_title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  display: inline;
  line-height: 40px;
}
.sidebar_title_timeline {
  color: #2b4ac4;
}
.groupSidebar_title_timeline {
  color: #3b3f4c;
}
.sidebar_title_history {
  color: #3e3e4f;
}
.sidebar_scheduleList {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.5);
  clear: both;
}
.timelineNoSchedule {
  width: 175px;
  height: 21px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 20px 7px 20px 24px;
  border-radius: 16px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  text-align: center;
  padding: 17px 84px 18px 85px;
}

.resSidebar {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: -5px 3px 20px #00000029;
  height: 954px;
  /* background: rgba(152, 215, 2216, 0.4);
    height: 100%; */
}
.resSidebar_margin {
  margin: 0 50px;
}
.resSidebar_top {
  padding: 22px 0px;
  font-weight: bold;
}
.resSidebar_title {
  display: inline;
  font: normal normal bold 20px/28px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.resSidebar_closeBtn {
  display: inline;
  float: right;
}
.resSidebar .resIcon {
  margin-right: 10px;
  vertical-align: middle;
}
.resSidebar .resIcon_content {
  margin-right: 10px;
  vertical-align: top;
}
.resSidebar .inputFileBtn {
  padding: 8px 16px 9px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #2b4ac4;
  cursor: pointer;
  vertical-align: middle;
  line-height: 44px;
}
.resSidebar_input {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 47px;
  width: 344px;
  padding: 0 12px;
  border-bottom: 1px solid #ffffff;
}
.resSidebar_input:disabled {
  border: none;
  border-radius: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 344px;
  padding: 0 12px;
}
.resSidebar_input_participant {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 47px;
  width: 232px;
  padding: 0 12px;
  border-bottom: 1px solid #ffffff;
}
.resSidebar_btn_addParticipant {
  border: 1px solid #afb3bf;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  /* padding: 11px; */
  background: #dfe1e5 0% 0% no-repeat padding-box;
}
.resSidebar_btn_addParticipant_active {
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  color: #2b4ac4;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  /* padding: 11px; */
  background-color: #ffffff;
}
.resSidebar_startDate,
.resSidebar_endDate {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 208px;
  padding: 0 12px;
}
.resSidebar .startArea .react-datepicker-wrapper,
.resSidebar .endArea .react-datepicker-wrapper,
.resSidebar .startArea .react-datepicker__tab-loop,
.resSidebar .endArea .react-datepicker__tab-loop {
  display: inline-block;
}
.resSidebar .startArea .selectTimeActive,
.resSidebar .endArea .selectTimeActive {
  margin-left: 8px;
  width: 108px;
  padding: 10px 0 10px 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid #2b4ac4;
  display: inline-block;
}
.resSidebar .startArea .selectTime,
.resSidebar .endArea .selectTime {
  margin-left: 8px;
  width: 108px;
  padding: 10px 0 10px 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: inline-block;
}
.resSidebar .startArea .durationPopup,
.resSidebar .endArea .durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 4px;
  margin-left: 280px;
  z-index: 2;
}
.resSidebar .startArea .MuiInputBase-formControl,
.resSidebar .endArea .MuiInputBase-formControl {
  height: 48px;
  width: 124px;
}
.resSidebar .startArea .MuiInputBase-formControl div,
.resSidebar .endArea .MuiInputBase-formControl div {
  padding: 12px 15px;
}

/* 사이드바 데이트 피커 아이콘 */
.resSidebar .startArea .MuiIconButton-edgeEnd {
  position: absolute !important;
  right: 184px;
  top: 245px;
}
.resSidebar .endArea .MuiIconButton-edgeEnd {
  position: absolute !important;
  right: 184px;
  top: 301px;
}

.resSidebar_textArea {
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 10px;
  display: inline-block;
  padding: 12px;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
  min-height: 47px;
  width: 344px;
  resize: none;
}
.resSidebar .platformChkboxAreaActive {
  margin-top: 7px;
  margin-left: 40px;
  width: 252px;
  /* height: 56px; */
  height: 28px;
  display: grid;
  padding: 8px 58px;
  grid-gap: 0px 32px;
  /* align-content: center;
  justify-content: center; */
  grid-template-columns: repeat(auto-fill, minmax(110px, 0fr));
  /* background: #f8f9fd 0% 0% no-repeat padding-box; */
  background-color: #ffffff;
  border-radius: 10px;
}
.resSidebar .platformChkboxArea {
  display: none;
}
.recButtonOn {
  display: inline-block;
  width: 86px;
  height: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/24px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-right: 8px;
  padding: 8px 0 10px;
}
.recButtonOff {
  display: inline-block;
  width: 86px;
  height: 16px;
  background: #dfe1e5 0% 0% no-repeat padding-box;
  border: 1px solid #afb3bf;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/24px Spoqa Han Sans Neo;
  color: #9b9faf;
  margin-right: 8px;
  padding: 8px 0 10px;
}
.resSidebar input::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.resSidebar input:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.resSidebar input:focus::placeholder {
  color: #2b4ac4;
}

.resSidebar textarea::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.resSidebar textarea:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.resSidebar textarea:focus::placeholder {
  color: #2b4ac4;
}

.resSidebar_saveBtnActive {
  width: 360px;
  border: 1px solid #2b4ac4;
  background: #2b4ac4;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 24px 56px;
  box-shadow: 0px 6px 15px #00000029;
  cursor: pointer;
  /* width: 300px;
    border: 1px solid blue;
    background: blue;
    height: 35px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-top: 15px; */
}
.resSidebar_saveBtn {
  width: 360px;
  border: none;
  background: #84889b;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 32px 56px;
  box-shadow: none;
  cursor: default;
}
.resSidebar .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.sidebarAddressPopup {
  /* width: 296px; */
  width: 368px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  /* border-radius: 24px; */
  border-radius: 16px;
  margin: 8px 40px;
  overflow-y: auto;
  /* right: 64px; */
}
.sidebarAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 96px;
  margin-right: 10px;
}
.sidebarAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.favoriteAddressPopup {
  /* width: 296px; */
  width: 330px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  /* border-radius: 24px; */
  border-radius: 16px;
  margin: 8px 40px;
  overflow-y: auto;
  /* right: 64px; */
}
.favoriteAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 96px;
  margin-right: 10px;
}
.favoriteAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
/*
   *    &participantSidebar
   */
.participantSidebar {
  height: 954px;
  display: flex;
  clear: both;
  flex-direction: column;
  border: none;
  border-radius: 0 32px 32px 0;
  box-shadow: 12px 12px 30px #0000001a;
  background-color: rgb(255, 255, 255);
  transition: 0.8s ease;
}
.participantTab {
  width: 114px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-weight: bold;
  color: #d1d3da;
  border-bottom: 2px solid #d1d3da;
}
.activeParticipantTab {
  color: #2b4ac4;
  border-bottom: 2px solid #2b4ac4;
}
.participantList {
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 296px;
  padding-left: 16px;
  margin-top: 10px;
  margin-left: 44px;
  max-height: 150px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}
.resSidebar .participantList {
  background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 344px;
  padding-left: 24px;
  padding-right: 0px;
  margin-top: 8px;
  margin-left: 40px;
  max-height: 104px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}

.resSidebar .participantList li,
.resSidebar .fileList li {
  height: 32px;
}
.resSidebar .participantList .participantEmail {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}
.bookmarkSidebar .participantList .participantEmail {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
/* .resSidebar .participantList button {
  width: 10px;
  height: 10px;
  float: right;
} */

.resSidebar .fileList {
  border-radius: 10px;
  width: 344px;
  padding-left: 16px;
  padding-right: 0px;
  margin-top: 8px;
  margin-left: 40px;
  max-height: 80px;
  overflow-y: auto;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
}

/* .resSidebar .fileList button {
  width: 10px;
  height: 10px;
  float: right;
} */

.bookmarkSidebar {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: -5px 3px 20px #00000029;
  height: 954px;
}
.bookmarkSidebar_margin {
  margin: 0 40px;
}
.bookmarkSidebar_top {
  padding: 32px 0px 24px;
}
.bookmarkSidebar_title {
  display: inline;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.bookmarkSidebar_expln {
  display: inline;
  font: normal normal normal 14px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.bookmarkSidebar_closeBtn {
  display: inline;
  float: right;
  cursor: pointer;
  /* position: absolute;
  top: 24px;
  right: 24px; */
}
.bookmarkSidebar_input {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 308px;
  padding: 0 10px;
}
.bookmarkSidebar_input_participant {
  border: none;
  border-radius: 10px;
  display: inline-block;
  /* margin-bottom: 10px; */
  color: #3e3e4f;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  width: 199px;
  padding: 0 10px;
}
.bookmarkSidebar_btn_addParticipant {
  border: 1px solid #afb3bf;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  color: #9b9faf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  background: #dfe1e5 0% 0% no-repeat padding-box;
}
.bookmarkSidebar_btn_addParticipant_active {
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  color: #2b4ac4;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
  height: 48px;
  padding: 0 10px;
  background-color: #ffffff;
}
.bookmarkSidebar_textArea {
  border: none;
  border-radius: 10px;
  display: inline-block;
  padding: 12px 24px 16px 16px;
  color: #3e3e4f;
  font: normal normal normal 16px/24px Spoqa Han Sans Neo;
  min-height: 69px;
  width: 288px;
  resize: none;
}
.bookmarkSidebar input::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.bookmarkSidebar input:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.bookmarkSidebar input:focus::placeholder {
  color: #2b4ac4;
}

.bookmarkSidebar textarea::placeholder {
  color: #afb3bf;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.bookmarkSidebar textarea:focus {
  outline: none;
  color: #2b4ac4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #2b4ac4;
}
.bookmarkSidebar textarea:focus::placeholder {
  color: #2b4ac4;
}

.bookmarkSidebar_saveBtnActive {
  width: 360px;
  border: 1px solid #2b4ac4;
  background: #2b4ac4;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 24px 56px;
  box-shadow: 0px 6px 15px #00000029;
  cursor: pointer;
}
.bookmarkSidebar_saveBtn {
  width: 360px;
  border: none;
  background: #84889b;
  height: 48px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 32px 56px;
  box-shadow: none;
  cursor: default;
}
.bookmarkSidebar .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.recTooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  width: 376px;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  margin-top: 10px;
  right: 240px;
}
.recTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 40px;
}
.fileTooltip {
  z-index: 80;
  background-color: #3e3e4f;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 12px 16px 16px;
  position: absolute;
  text-align: left;
  margin-top: 10px;
  right: 240px;
}
.fileTooltip:after {
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3e3e4f;
  content: "";
  position: absolute;
  top: -10px;
  right: 15px;
}
