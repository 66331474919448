/**
 * Version : 1.0
 * 파일명 : profilefaq.css
 * 작성일자 : 2022-03-30
 * 작성자 : 정지윤
 * 설명 : 고객센터 FAQ css
  * 수정일자 : 2022-04-12
 * 수정자 : 정지윤
 * 수정내역 :사이즈 변경
 **/
.faq_main{
    margin-top:18px;
}
.faq_body_title_noclick{
    margin: 0px 36px 17px 37px;

    height: auto;
    width: 788px;
    border-radius: 10px;
    height: 34px;
    border: solid 1px rgba(144, 144, 144, 0.3);
    padding: 12px 44px 12px 27px;
    line-height: 18px;

    max-height: 100px;
    min-height : 34px;
    transition: max-height 1s ease-out, min-height 0.3s ease-out;
    -webkit-transition: max-height 1s ease, min-height 0.3s ease;

}
.opensrr{
    width: 788px;
    height: auto;
    margin: 0px 36px 17px 37px;
    padding: 12px 44px 34px 26px;
    border-radius: 10px;
    border: solid 1px #2b4ac4;
    font-size: 17px;
    max-height: 1000px;
    min-height: 100px;
}
.faq_body_title_noclick_header{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    
}
.faq_body_title_text1{
    line-height: 34px;
    margin-left: 47px;
}
.faq_body_title_text{
    line-height: 34px;
    margin-left: 48px;
}
.faq_body_title_onclick{
    width: 788px;
    max-height: 600px;
    margin: 0px 36px 17px 37px;
    padding: 12px 44px 34px 26px;
    border-radius: 10px;
    border: solid 1px 
    #2b4ac4;
    font-size: 17px;
    /* transition: all 0.1s cubic-bezier(0.22, 0.01, 0.71, 1.2); */

}

.faq_body_title_onclick_header{
    justify-content: space-between;
    display: flex;
}
.faq_body_line{
    width: 788px;
    height: 1px;
    margin: 12px 0 14px;
    background-color: #ddd;
}
.dttm_img{
    display: flex;
   
}
.imgcursorfaq{
    margin-top: 10px;
}
.faq_body_content{
    display: flex;
}
.faq_body_content_text{
    margin-left: 47px;
    line-height: 2;
    font-size: 16px;
}
.faq_body_files{
    margin-left: 78px;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.faq_body_filedownload{
    margin-left: 20px;
}
.FAQ_Search{
    display: flex;
    margin-left: 256px;
 
}
.FAQ_Searchbar{
    height: 30px;
    width: 364px;
    padding: 13px 26px 12px 30px;
    border-radius: 10px;
    border-color: rgba(217, 217, 217, 0.3) ;
    background-color: rgba(217, 217, 217, 0.3);
    color: #909090;
    display: flex;
    justify-content: space-between;

}
.FAQ_Searchbar_input{
    width: 300px;
    background-color: rgba(217, 217, 217, 0.05);
    border: 0px;
    outline: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #111;
}
.FAQ_Searchbar ::placeholder{
    
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Searchbar_input ::placeholder{
    font-family: SpoqaHanSansNeo;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #909090;
}
.FAQ_Select_Category{
    height: 55px;
    border-radius: 10px;
    margin-right: 20px;
    background-color: rgba(217, 217, 217, 0.3);
    color: #909090;
    border: 0px;
    outline: none;
    text-align: center;
}
.FAQ_answermark{
    height: 35px;
    width: 35px;
}
.faq_body_content_text p{
    margin: 0px;
}

.faq_file_title{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
color: #2b4ac4;
}
.FAQpaging{
    margin-top: 6px;
    padding-right: 320px;
    padding-left: 320px;
    padding-bottom: 6px;
}