/**
 * Version : 1.0
 * 파일명 : modal.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 :  2021-10-06
 * 수정자 : 권도훈
 * 수정내역 : 
*/
.Modal {
    margin: 18% auto;
    width: 339px;
    height: 117px;
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;

    /* margin: 18% auto;
    width: 400px;
    height: 200px;
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 9999; */
}
.Overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.1);
}
.modal_okBtn,
.modal_cancelBtn {
padding: 10px 30px;
color: white;
border: none;
margin-left: 2px;
margin-right: 2px;
font-weight: bold;
border-radius: 5px;
}
.modal_okBtn {
background: blue;
}
.modal_okBtn:hover {
background: #4169e1;
}
.modal_cancelBtn {
background: gray;
}
.modal_cancelBtn:hover {
background: #a9a9a9;
}