/**
 * Version : 1.0
 * 파일명 : landing.css
 * 작성일자 : 2021-10-06
 * 작성자 : 권도훈
 * 설명 : 랜딩페이지 CSS
 * 수정일자 : 2022-02-10
 * 수정자 : 강연승
 * 수정내역 : 웹폰트 변경
*/
@import url(https://fonts.googleapis.com/css?family=Muli);
.Landing_menu {
  font-family: "Spoqa Han Sans Neo", sans-serif;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 1em;
  margin: 15px 40px;
  text-transform: uppercase;
}
.Landing_menu li {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 0.6em;
  padding: 12px 15px;
  position: relative;
}
.Landing_menu li div {
  color: blue;
  font-weight: bold;
  text-decoration: none;
}
.Landing_menu li:before,
.Landing_menu li:after {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  height: 2px;
  width: 35%;
  background-color: #528cb3;
  position: absolute;
  content: "";
  opacity: 0;
}
.Landing_menu li:before {
  top: 0;
  left: 0;
}
.Landing_menu li:after {
  bottom: 0;
  right: 0;
}
.Landing_menu li:hover:before,
.Landing_menu li.hover:before,
.Landing_menu li:hover:after,
.Landing_menu li.hover:after {
  opacity: 1;
}
.Landing_menu li:hover:before,
.Landing_menu li.hover:before {
  left: 65%;
}
.Landing_menu li:hover:after,
.Landing_menu li.hover:after {
  right: 65%;
}
.Landing_Header {
  margin-top: 34px;
  margin-left: 200px;
  margin-right: 140px;
}
.Landing_LoginBtn {
  width: 5rem;
  height: 2.5rem;
}
.Landing_Body {
  height: 45rem;
}
.header_sample {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  /* color: white; */
  background: white;
}
.main_sample {
  padding: 7rem 7rem 0 7rem;
  height: 100%;
}
.footer_sample {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.slider-div {
  position: absolute;
}

.Landing_Header_Btn {
  min-width: 120px;
  height: 48px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 39px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border: none;
}
.Landing_Header_Btn:active {
  outline: none;
}

/* .maintainance {
  height: 100%;
  background: #f2f4fb;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
} */
.maintainance{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}