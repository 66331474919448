/**
 * Version : 1.0
 * 파일명 : alert.css
 * 작성일자 : 2021-11-01
 * 작성자 : 권도훈
 * 설명 : 모달 CSS
 * 수정일자 : 2022-03-25
 * 수정자 : 권도훈
 * 수정내역 : 사용자 인증 팝업 분리
*/
.payment {
    margin: 20% auto;
    width: 339px;
    /* min-height: 117px; */
    background: white;
    transition: all 0.5s ease-out;
    border-radius: 15px;
    z-index: 999;
    outline: none;
}
.paymentOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 800;
}
.modal_okBtn,
.modal_cancelBtn {
padding: 10px 30px;
color: white;
border: none;
margin-left: 2px;
margin-right: 2px;
font-weight: bold;
border-radius: 5px;
}
.modal_okBtn {
background: blue;
}
.modal_okBtn:hover {
background: #4169e1;
}
.modal_cancelBtn {
background: gray;
}
.modal_cancelBtn:hover {
background: #a9a9a9;
}

.product_card {
    width: 320px;
    height: 232px;
    background: #FFFFFF;
    box-shadow: 5px 5px 16px #00000029;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_card_title {
    width: 294px;
    height: 72px;
    border-bottom: 1px solid #AFB3BF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #3E3E4F;
}
.product_card_amount {
    margin-top: 16px;
    font-size: 32px;
    /* font-weight: bold; */
    color: #2B4AC4;
}
.product_card_coment {
    margin-top: 13px;
    font-size: 20px;
    font-weight: bold;
    color: #3E3E4F;
}
.product_card_vat {
    margin-top: 7px;
    font-size: 14px;
    font-weight: bold;
    color: #AFB3BF;
}
.product_card_count {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.product_card_counter{
    width: 104px;
    height: 49px;
    border: 1px solid #9B9FAF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product_card_count_num {
    text-align: center;
    width: 69px;
}
.product_card_count_btn {
    display: flex;
    flex-direction: column;
}
.product_card_count_btn div {
    width: 35px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #9B9FAF;
}
.product_card_count_label {
    font-size: 16px;
    font-weight: bold;
    color: #3E3E4F;
    margin-left: 8px;
}
.product_card_payBtn {
    margin-top: 24px;
    width: 146px;
    height: 48px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    outline: none;
    background: #2B4AC4;
    border-radius: 12px;
}

.profile_popup_pay_body {
    width: 816px;
    /* max-height: 648px; */
    height: 648px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile_popup_pay_title {
    width: 816px;
    height: 40px;
    background: #F8F9FD;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #8B5FBF;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.profile_popup_pay_title font {
    margin-left: 16px;
}

.profile_popup_pay_centerBorder {
    /* height: 648px; */
    max-height: 648px;
    border: 2px dashed #DFE1E5;
}

.profile_popup_payment_Title,
.profile_popup_selectedProduct_Title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
}

.profile_popup_payment_Title {
    color: #433D4A;
}

.profile_popup_selectedProduct_Title {
    color: #2B4AC4;
}

.profile_popup_selectBox_option {
    font-size: 14px;
    padding: 4px 0;
    color: #3E3E4F;
    outline: none;
    border: none;
}