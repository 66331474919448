/**
 * Version : 1.0
 * 파일명 : schedule.css
 * 작성일자 : 2021-10-06
 * 작성자 : 강연승
 * 설명 : 스케줄 CSS
 * 수정일자 : 2022-06-14
 * 수정자 : 강연승
 * 수정내역 : sobridge 데모 - 플랫폼 선택 area 수정
*/
.scheduleModalBtn {
  padding: 8px 16px 10px;
  border-radius: 4px;
  background-color: #2b4ac4;
  border: none;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin-left: 16px;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
}
.scheduleModalLabel {
  width: 70px;
  font-weight: bold;
  padding-right: 16px;
  display: inline-block;
  text-align: right;
  color: #1f378e;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}
.scheduleModalLabel_History {
  width: 70px;
  font-weight: bold;
  padding-right: 16px;
  display: inline-block;
  text-align: right;
  color: #3e3e4f;
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
}

.scheduleModalContent {
  display: inline-block;
  font: normal normal normal 16px/21px Spoqa Han Sans Neo;
}
.scheduleModalDiv {
  margin: 1rem auto;
}
.scheduleModalInput {
  font-size: 18px;
  width: 17rem;
}
/* .scheduleDetail .MuiDialog-paperWidthXs {
  max-width: 368px;
} */
/*
  *   &initial
  */
.initialCalendar {
  width: 70%;
  /* height: 100vh; */
  float: left;
  padding: 25px;
  /* padding-left: 30px; */
  border-right: 1px solid #000;
  /* display: inline-block; */
}
.initialSidebar {
  width: 25%;
  /* float: right; */
  padding: 10px;
  padding-top: 60px;
  /* height: 500px; */
  display: inline-block;
}
.initial_btnArea {
  text-align: center;
  padding-top: 35vh;
}
.initial_btn {
  width: 25vh;
  height: 13vh;
  background-color: aquamarine;
  margin: 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
}
#initialResSchedule {
  /* float: right; */
  /* position:absolute !important; */
  left: unset !important;
  bottom: unset !important;
  right: 0px !important;
  top: 0px !important;
  margin-top: 8.7vh;
}
#initialResSchedule .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0) !important;
}
#initialResSchedule .MuiDialog-paper {
  margin: 0 !important;
}
#initialResSchedule .MuiDialog-paperWidthXl {
  width: 183.5vh !important;
}
#initialResSchedule .MuiDialog-scrollPaper {
  /* display: block; */
  float: right;
}
#initialMeetingNow .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
  inset: 77px 0px 0px 300px !important;
}
.initial_btnArea {
  text-align: center;
  padding-top: 35vh;
}
.initial_btn {
  width: 25vh;
  height: 13vh;
  background-color: aquamarine;
  margin: 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
}
.input_participant {
  margin-top: 30px;
  flex: 1;
  text-align: center;
  width: 320px;
  font-size: 1.25rem;
  outline: none;
  border: none;
  border-bottom: 1px solid #0602d7;
}
.participant_List {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin: 0 36px;
  max-height: 96px;
  overflow-y: auto;
}
.participant_List > ul {
  padding: 0;
}
.participant_List > ul > li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
  padding: 5px 0px 9px;
}
/* .participant_List > ul > li > button {
  float: right;
  line-height: 20px;
  padding: 0;
} */
.border_bottom {
  border-bottom: 2px solid blue;
}
.meetingNow .btnArea {
  display: flex;
  justify-content: center;
}
.meetingNow .btnArea .startBtn {
  width: 312px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin: 24px 0px;
  cursor: pointer;
}
.btnArea .startBtn {
  width: 312px;
  height: 40px;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  color: white;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  margin: 24px 0px;
  cursor: pointer;
}
.res_schedule button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.res_schedule > section {
  width: 90%;
  /* max-width: 450px; */
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  /* animation: modal-show .3s; */
  overflow: hidden;
}
.res_schedule > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  /* background-color: #f1f1f1; */
  font-weight: 700;
}
.res_schedule > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.res_schedule > section > main {
  padding: 16px;
  /* border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6; */
}
.res_schedule > section > main > div > div {
  clear: both;
  margin: 20px auto;
  text-align: center;
  /* height:50px; */
  /* width:350px; */
}
.res_schedule .inputHeader {
  display: inline;
  line-height: 30px;
  font-size: 15px;
}
.res_schedule .inputValue {
  width: 250px;
  height: 30px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}
.res_schedule .inputDate {
  width: 250px;
  height: 30px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}
.res_schedule .areaValue {
  width: 252px;
  height: 50px;
  text-align: left;
  margin-left: 30px;
  /* float:right; */
  display: inline;
}

.res_schedule > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.res_schedule > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}

.res_schedule > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #3985c9;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  height: 40px;
}
.resIcon {
  margin-right: 12px;
  vertical-align: middle;
}
.resIcon_content {
  margin-right: 12px;
  vertical-align: top;
}

.durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 315px;
}
.durationItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: center;
  height: 36px;
  width: 120px;
  color: #3e3e4f;
  line-height: 36px;
}
.bookmark_btn_meeting {
  width: 88px;
  height: 36px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px 0px 0px 8px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b9ddf;
  border: none;
  cursor: pointer;
}

.bookmark_btn_group {
  width: 74px;
  height: 36px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 0px 8px 8px 0px;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #8b9ddf;
  border: none;
  cursor: pointer;
}

.bookmark_btn_active {
  color: #ffffff;
  background: #2b4ac4 0% 0% no-repeat padding-box;
}

.myCalendar .exchange_btn,
.mybookmark.exchange_btn {
  background-color: #3b3f4c;
  border-radius: 10px;
  border: none;
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
.groupCalendar .exchange_btn,
.groupBookmark.exchange_btn {
  background-color: #666b82;
  border-radius: 10px;
  border: none;
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
.bookmark_header {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.group_bookmark_header {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #3b3f4c;
}

.bookmark_item {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #2b4ac4;
}

.group_bookmark_item_red {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d4667e;
}
.group_bookmark_item_orange {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #c44308;
}
.group_bookmark_item_yellow {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d49500;
}
.group_bookmark_item_bean {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #87ad26;
}
.group_bookmark_item_green {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #28b750;
}
.group_bookmark_item_mint {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #6cd6bf;
}
.group_bookmark_item_pine {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #75bdd9;
}
.group_bookmark_item_pink {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #d6a0e5;
}
.group_bookmark_item_purple {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #a994e1;
}
.group_bookmark_item_black {
  width: 224px;
  height: 108px;
  border-radius: 16px;
  padding: 16px 16px 20px 24px;
  border: 1px solid #84889b;
}

.bookmark_item_title {
  color: #2b4ac4;
}
.group_bookmark_item_title_red {
  color: #861e35;
}
.group_bookmark_item_title_orange {
  color: #732c0e;
}
.group_bookmark_item_title_yellow {
  color: #7d5b14;
}
.group_bookmark_item_title_bean {
  color: #4a5f15;
}
.group_bookmark_item_title_green {
  color: #104920;
}
.group_bookmark_item_title_mint {
  color: #10483b;
}
.group_bookmark_item_title_pine {
  color: #134457;
}
.group_bookmark_item_title_pink {
  color: #3d1448;
}
.group_bookmark_item_title_purple {
  color: #2d1665;
}
.group_bookmark_item_title_black {
  color: #000000;
}

.bookmark_item_content {
  color: #2b4ac4;
}
.group_bookmark_item_content_red {
  color: #d0778a;
}
.group_bookmark_item_content_orange {
  color: #d78e71;
}
.group_bookmark_item_content_yellow {
  color: #cdb14c;
}
.group_bookmark_item_content_bean {
  color: #aed057;
}
.group_bookmark_item_content_green {
  color: #28b750;
}
.group_bookmark_item_content_mint {
  color: #27b395;
}
.group_bookmark_item_content_pine {
  color: #4faace;
}
.group_bookmark_item_content_pink {
  color: #bd66d4;
}
.group_bookmark_item_content_purple {
  color: #7855d0;
}
.group_bookmark_item_content_black {
  color: #84889b;
}

.bookmarkResultPopup {
  margin: 14% auto;
  padding: 32px 0 40px;
  width: 320px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.bookmarkResultPopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.bookmarkDeletePopup {
  margin: 14% auto;
  /* padding: 32px 0 40px; */
  width: 480px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: -5px 3px 20px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.bookmarkDeletePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.meetingNowAddressPopup {
  width: 296px;
  max-height: 176px;
  z-index: 81;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px #00000029;
  position: absolute;
  border-radius: 10px;
  top: 340px;
  overflow-y: auto;
}
.meetingNowAddressPopup .addressPopupItem .addressNM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  margin-right: 10px;
}
.meetingNowAddressPopup .addressPopupItem .addressEmail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 160px;
}
.noLicensePopup {
  margin: 13% auto;
  padding: 32px 32px 40px;
  width: 576px;
  background: white;
  border-radius: 16px;
  z-index: 99;
  box-shadow: 0px 5px 16px #00000029;
  outline: none;
  display: flex;
  flex-direction: column;
}
.noLicensePopup_Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 79, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.noLicensePopup .popupTitle {
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  color: #2b4ac4;
}
.noLicensePopup .popupContents {
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #3e3e4f;
  margin-top: 32px;
}
.noLicensePopup .btnArea {
  margin-top: 40px;
}
.noLicensePopup .btnOK {
  width: 136px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: #2b4ac4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
}
.noLicensePopup .btnGoLicense {
  width: 208px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: #8b5fbf 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font: normal normal bold 14px/21px Spoqa Han Sans Neo;
  color: #ffffff;
  margin-left: 16px;
}
/* .meetingNow {
  text-align: center;
  margin: 4rem;
} */
.meetingNow {
  /* transition: all 0.5s ease-out; */
  width: 384px;
  margin-top: 137px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 30px;
}
.meetingNowResult {
  margin-top: 90px;
  /* transition: all 0.8s ease-in 0s; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 24px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 30px;
  width: 490px;
}
.meetingNow .meetingNow_header {
  display: flex;
  justify-content: space-between;
}
.meetingNow .meetingNow_header_title {
  display: flex;
  text-align: left;
  color: #2b4ac4;
  font: normal normal bold 20px/27px Spoqa Han Sans Neo;
  margin-top: 24px;
  margin-left: 32px;
}
.meetingNow .meetingNow_header_closeIcon {
  display: flex;
  margin-top: 8px;
  margin-right: 12px;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
.meetingNow .meetingNow_durationArea {
  display: flex;
  flex-direction: column;
  margin: 25px 32px 0 32px;
}
.meetingNow .meetingNow_durationArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_durationArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_durationArea_contents {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.meetingNow .meetingNow_durationArea_selectBox_label {
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #3e3e4f;
}
.meetingNow .meetingNow_durationArea_selectBox {
  display: flex;
  justify-content: center;
  margin-left: 8px;
}
.meetingNow .meetingNow_recordArea {
  display: flex;
  flex-direction: column;
  margin: 25px 32px 0 32px;
}
.meetingNow .meetingNow_recordArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_recordArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 6px;
}
.meetingNow .meetingNow_recordArea_contents {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.meetingNow .meetingNow-record-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 36px;
  margin-left: 20px;
  border-radius: 9px;
  border: solid 1px #afb3bf;
  background-color: #dfe1e5;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9faf;
}
.meetingNow .meetingNow-record-btn-active {
  border: solid 1px #2b4ac4;
  background-color: #2b4ac4;
  color: #fff;
}
/* 즉시 미팅 */
.meetingNow .selectHour {
  height: 34px;
  width: 94px;
  padding: 0 8px 0 16px;
  border: 1px solid #2b4ac4;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meetingNow_durationPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  padding: 8px 0;
  position: absolute;
  width: 120px;
  height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 36px;
}
.meetingNow_durationItem {
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  cursor: pointer;
  text-align: left;
  padding-left: 16px;
  height: 32px;
  width: 120px;
  color: #3e3e4f;
  line-height: 32px;
}
.meetingNow .meetingNow_platformArea {
  display: flex;
  flex-direction: column;
  margin: 16px 32px 0 32px;
}
.meetingNow .meetingNow_platformArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_platformArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_platformArea_contents {
  margin-top: 8px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-evenly;
}
.meetingNow .meetingNow_platformArea_webexBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_webexBtn {
  border: 1px solid #3672ff;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_zoomBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_zoomBtn {
  border: 1px solid #2d8cff;
  border-radius: 16px;
  padding: 6px 7px;
}
.meetingNow .meetingNow_platformArea_chimeBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_chimeBtn {
  border: 1px solid #3ab5bb;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_pexipBtn_disable {
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_pexipBtn {
  border: 1px solid #0a2136;
  border-radius: 16px;
  padding: 7px 7px;
}
.meetingNow .meetingNow_platformArea_selectBox {
  width: 240px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 8px;
  margin-top: 8px;
  text-align: center;
  font: normal normal bold 14px/18px Spoqa Han Sans Neo;
  color: #a3b1e5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.meetingNow .meetingNow_platformArea_contents .platformSelectBox {
  margin-top: 165px;
  width: 184px;
  height: 80px;
  padding: 16px 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #00000029;
  border-radius: 10px;
  position: absolute;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(88px, 0fr));
}

.meetingNow .meetingNow_guestArea {
  display: flex;
  flex-direction: column;
  margin: 22px 32px 0 32px;
}
.meetingNow .meetingNow_guestArea_header {
  display: flex;
  align-items: center;
}
.meetingNow .meetingNow_guestArea_title {
  font: normal normal bold 16px/21px Spoqa Han Sans Neo;
  color: #2b4ac4;
  margin-left: 4px;
}
.meetingNow .meetingNow_guestArea_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.meetingNow .meetingNow_guestArea_input {
  width: 296px;
  height: 40px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetingNow .meetingNow_guestArea_input input {
  width: 242px;
  height: 18px;
  background: #eaedf9 0% 0% no-repeat padding-box;
  outline: none;
  border: none;
  border-radius: 8px;
  text-align: center;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
}
.meetingNow .meetingNow_guestArea_input input::placeholder {
  text-align: center;
  font: normal normal normal 14px/18px Spoqa Han Sans Neo;
  color: #b8c3eb;
}
.meetingNow .participant_List .participantItem {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meetingNowResult-webex-meetingInfo {
  margin: 16px 0 0 69px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.meetingNowResult-webex-meetingInfo-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.meetingNowResult .link-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
}
.meetingNowResult .link-copy > img {
  width: 18px;
  height: 18px;
}
.meetingNowResult-webex-meetingInfo-label {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b5fbf;
  width: 55px;
  margin-right: 18px;
}
.meetingNowResult-webex-meetingInfo-value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #111;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 280px;
}
